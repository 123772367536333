import React, { useRef, useState } from 'react';

import caretDown from '../../assets/icons/caret-down.svg';
import sort from '../../assets/icons/basil-sort.svg';
import './HomeSortButton.scss';
import DropdownPopup from '../dropdown-popup/DropdownPopup';
import Option from '../../models/option.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {filterVacancies, getActivatedVacancies, setVacanciesSortOption} from '../../store/slices/vacanciesSlice';
import { ASSETS_URL } from '../../constants';
import { useOutsideHandler } from '../../utils/outside-click-handler';
import { useTranslation } from 'react-i18next';

const HomeSortButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const selectedOption = useSelector(
    (state: RootState) => state.vacanciesReducer.sortOption
  );
  const sortOptions = useSelector(
    (state: RootState) => state.vacanciesReducer.sortOptions
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  useOutsideHandler(wrapperRef, setOpen);

  function selectOption(selectedOption: Option): void {
    dispatch(setVacanciesSortOption(selectedOption));
    dispatch(getActivatedVacancies(1)); // fetch the list from the back end, showing the first page
    dispatch(filterVacancies()); // refresh the list on the front end
    setIsDropdownOpen(false);
  }

  function setOpen(isOpen: boolean): void {
    setIsDropdownOpen(isOpen);
  }

  return (
    <div className='sort relative' ref={wrapperRef}>
      <button
        className='sort__button gap-2 text-nowrap mobile-hidden'
        onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
      >
        <span>{selectedOption.name}</span>
        <img
          src={ASSETS_URL + caretDown}
          alt='caret'
          className={isDropdownOpen ? 'caret-up' : ''}
        />
      </button>

      <button
        className='sort__button-mobile no-mobile-hidden'
        onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
      >
        <img src={ASSETS_URL + sort} alt='sort' className='blue-filter' />
        <span>{t('candidateHomePage.sort.button')}</span>
      </button>

      {isDropdownOpen && (
        <DropdownPopup optionList={sortOptions} selectOption={selectOption} />
      )}
    </div>
  );
};

export default HomeSortButton;
