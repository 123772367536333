import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik, Form as FormikForm } from 'formik';
import VacancyExperience from '../../../../models/vacancy-experience';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  setVacancyFormExperience,
  setVacancyFormExperienceRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';

interface Props {
  experience: VacancyExperience;
  close: () => void;
}

const ExperienceModal = ({ experience, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.experience_rating
  );
  const [experienceRating, setExperienceRating] = useState<number>(0);
  const validationSchema = object({
    sector: string().required(t('errors.fieldRequired')),
    position: string().required(t('errors.fieldRequired')),
    duration: string().required(t('errors.fieldRequired')),
  });

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.experience.description')}
      </p>

      <Row>
        <Col md={12}>
          <Formik
            initialValues={{
              sector: experience.sector,
              position: experience.position,
              duration: experience.duration,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(setVacancyFormExperience([values]));
              dispatch(setVacancyFormExperienceRating(experienceRating));
              dispatch(updateProgress());
              close();
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <ImportanceSelect
                  defaultValue={rating}
                  setRating={(rate: number) => setExperienceRating(rate)}
                />
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold mb-3'>
                    {t('createOffer.card1.key1')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control modal-input'
                      type='text'
                      name='sector'
                      placeholder={t(
                        'createOffer.step2.experience.sectorPlaceholder'
                      )}
                    />
                    {errors.sector && touched.sector ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.sector}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold mb-3'>
                    {t('companyVacancyDetails.position')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control modal-input'
                      type='text'
                      name='position'
                      placeholder={t('vacancyDetails.placeholders.position')}
                    />
                    {errors.position && touched.position ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.position}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold mb-3'>
                    {t('vacancyDetails.labels.experienceAmount')} (
                    {t('time.pluralYear')})
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      as='select'
                      name='duration'
                      className='form-control modal-input cursor-pointer'
                    >
                      <option value={undefined}>
                        {t('vacancyDetails.labels.experienceAmount')} (
                        {t('time.pluralYear')})
                      </option>
                      {Array.from({ length: 9 }, (_, i) => i + 1).map(
                        (item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        )
                      )}
                      <option value={10}>10+</option>
                    </Field>
                    {errors.duration && touched.duration ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.duration}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <div className='d-flex align-items-center justify-content-end mt-4'>
                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='submit'
                  >
                    {t('addButton')}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default ExperienceModal;
