import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import EditButton from '../edit-button/EditButton';
import { ASSETS_URL } from '../../../constants';
import { Image } from 'react-bootstrap';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  openInfoModal: () => void;
}
const CandidateEditInfoSection = ({ openInfoModal }: Props) => {
  const { t } = useTranslation();
  const candidate = useSelector((state: RootState) => state.candidateReducer);

  return (
    <div className='zestur-card mobile-hidden'>
      <div className='d-flex justify-content-end'>
        <EditButton action={openInfoModal} index={0}></EditButton>
      </div>
      <div className='candidate-profile'>
        <Image
          className='candidate-profile__avatar object-fit-cover'
          src={candidate.avatar || candidateImage}
        />
        <h2 className='h1'>
          {candidate.contactInfo.name + ' ' + candidate.contactInfo.lastName}
        </h2>
        <p>{candidate.experience[0]?.job_position}</p>
      </div>
      <div className='mt-5 text-main-black'>
        {candidate.seniorityLevel && (
          <>
            <h5 className='fw-semibold'>
              {t('candidateInfoComponent.seniority')}
            </h5>
            <p>{candidate.seniorityLevel}</p>
          </>
        )}
        {candidate.description?.length > 1 && (
          <>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.description')}
            </h5>
            <p>{candidate.description}</p>
          </>
        )}
        <h5 className='fw-semibold mt-4'>
          {t('candidateInfoComponent.email')}
        </h5>
        <p className='text-break'>{candidate.candidate.email}</p>
        <h5 className='fw-semibold mt-4'>
          {t('candidateInfoComponent.phone')}
        </h5>
        <p>{candidate.contactInfo.phone}</p>
        <h5 className='fw-semibold mt-4'>
          {t('candidateInfoComponent.location')}
        </h5>
        <p>{candidate.address}</p>
      </div>
    </div>
  );
};

export default CandidateEditInfoSection;
