// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actual-checkbox {
  position: absolute;
  bottom: -2rem;
  left: 0.2rem;
  display: flex;
  gap: 0.5rem; }
  @media (max-width: 600px) {
    .actual-checkbox {
      bottom: -1.5rem;
      right: 0;
      left: initial; } }
`, "",{"version":3,"sources":["webpack://./src/components/ui/candidate-experience-modal/CandidateExperienceModal.scss","webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,WAAW,EAAA;ECJT;IDDJ;MAQI,eAAe;MACf,QAAQ;MACR,aAAa,EAAA,EAEhB","sourcesContent":["@import '../../../assets/scss/breakpoint/breakpoints-mixins';\n\n.actual-checkbox {\n  position: absolute;\n  bottom: -2rem;\n  left: 0.2rem;\n  display: flex;\n  gap: 0.5rem;\n\n  @include mobile-breakpoint {\n    bottom: -1.5rem;\n    right: 0;\n    left: initial;\n  }\n}","@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
