// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search__input {
  position: relative; }

.search__button {
  position: absolute;
  right: 0.2em;
  top: 50%;
  transform: translateY(-50%); }
`, "",{"version":3,"sources":["webpack://./src/components/ui/SearchWithButton/SearchWithButton.scss"],"names":[],"mappings":"AACE;EACE,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,YAAY;EACZ,QAAQ;EACR,2BAA2B,EAAA","sourcesContent":[".search {\n  &__input {\n    position: relative;\n  }\n\n  &__button {\n    position: absolute;\n    right: 0.2em;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
