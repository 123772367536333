import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Vacancy from '../../models/vacancy.interface';
import Option from '../../models/option.interface';
import axios from 'axios';
import { config } from '../../_config/config';
import tokenService from '../../_services/token.service';
import { RootState } from '../store';
import {AdminOffer} from "../../models/admin-offer.interface";
import {AdminApplication} from "../../models/admin-application.interface";

interface VacanciesState {
  initialVacancies: Vacancy[];
  displayedVacancies: Vacancy[];
  vacancyDetails: Vacancy | null;

  tagList: Option[];
  sortOption: Option;
  sortOptions: Option[];
  seachValue: string;

  vacanciesCount: number;

  vacanciesReceived: boolean;
  vacancyDetailsReceived: boolean;

  offerCount: number;
  adminOffers: AdminOffer[];
  applicationCount: number;
  adminApplications: AdminApplication[];
}

const initialState: VacanciesState = {
  initialVacancies: [],
  displayedVacancies: [],
  vacancyDetails: null,
  tagList: [
    { name: 'Saved', selected: false, filterProperty: 'saved' },
    //{ name: 'Match', icon: 'work', selected: false, filterProperty: 'match' },
  ],
  sortOption: { name: 'Más reciente', sortType: 'newest', filterProperty: '-created_at' },
  sortOptions: [
    { name: 'Más reciente', sortType: 'newest', filterProperty: '-created_at' },
    { name: 'Más antiguo', sortType: 'oldest', filterProperty: 'created_at' },
    { name: 'Alfabético', sortType: 'alphabetical', filterProperty: 'name' }
  ],
  seachValue: '',

  vacanciesCount: 0,

  vacanciesReceived: false,
  vacancyDetailsReceived: false,

  offerCount: 0,
  adminOffers: [],
  applicationCount: 0,
  adminApplications: [],
};

export const getActivatedVacancies = createAsyncThunk(
  'vacancies/getVacancies',
  async (pageNumber: number, { getState }) => {
    const token = tokenService.getLocalAccessToken();
    const state: RootState = getState() as RootState;

    const response = await axios.get(
      `${config.SERVER_URL}offer/?status=2&order_by=${state.vacanciesReducer.sortOption.filterProperty}&page=${pageNumber}&search=${state.vacanciesReducer.seachValue}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }
);

export const getVacancyDetails = createAsyncThunk(
  'vacancies/getVacancyDetails',
  async (id: string) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(`${config.SERVER_URL}offer/${id}/`, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  }
);

export const vacancyDelete = createAsyncThunk(
  'vacancy/vacancyDelete',
  async (id: string) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.delete(`${config.SERVER_URL}offer/${id}/`, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
    });

    return response;
  }
);


export const getOffers = createAsyncThunk(
  'vacancies/getOffers',
  async ({
    pageNumber,
    searchValue,
    size,
    orderBy = '',
  }: {
    pageNumber: number;
    searchValue: string;
    size: number;
    orderBy?: string;
  }) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + `offer` +
       `/?page=${pageNumber}&size=${size}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const getApplications = createAsyncThunk(
  'vacancies/getApplicationss',
  async ({
    pageNumber,
    searchValue,
    size,
    orderBy = '',
  }: {
    pageNumber: number;
    searchValue: string;
    size: number;
    orderBy?: string;
  }) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + `offer/candidate/apply/list`// +
       //`/?page=${pageNumber}`,
       ,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);


const vacanciesSlice = createSlice({
  name: 'vacancies',
  initialState,
  reducers: {
    resetVacancyDetails: (state) => {
      state.vacancyDetails = null;
    },

    setVacancySaveStatus: (state, action) => {
      const changedVacancyIndex = state.displayedVacancies.findIndex(
        (vacancy) => vacancy.id === action.payload.id
      );
      const changedVacancyIndexInitial = state.initialVacancies.findIndex(
        (vacancy) => vacancy.id === action.payload.id
      );

      if (changedVacancyIndex >= 0) {
        const changedVacancy = state.displayedVacancies[changedVacancyIndex];
        changedVacancy.saved = action.payload.status;
      }

      if (changedVacancyIndexInitial >= 0) {
        const changedInitialVacancy =
          state.initialVacancies[changedVacancyIndexInitial];
        changedInitialVacancy.saved = action.payload.status;
      }
    },

    setVacancyDetailsSave: (state, action) => {
      if (state.vacancyDetails) {
        state.vacancyDetails.saved = action.payload;

        const changedVacancyIndex = state.displayedVacancies.findIndex(
          (vacancy) => vacancy.id === state.vacancyDetails?.id
        );
        const changedVacancyIndexInitial = state.initialVacancies.findIndex(
          (vacancy) => vacancy.id === state.vacancyDetails?.id
        );

        if (changedVacancyIndex >= 0) {
          const changedVacancy = state.displayedVacancies[changedVacancyIndex];
          changedVacancy.saved = action.payload.status;
        }

        if (changedVacancyIndexInitial >= 0) {
          const changedInitialVacancy =
            state.initialVacancies[changedVacancyIndexInitial];
          changedInitialVacancy.saved = action.payload.status;
        }
      }
    },

    setVacancyDetailsStatus: (state, action) => {
      if (state.vacancyDetails) {
        state.vacancyDetails.status = action.payload;
      }
    },

    filterVacancies: (state) => {
      const lowerCasedSearchValue = state.seachValue.toLowerCase();

      // Searching
      const filteredBySearch = state.initialVacancies.filter((vacancy) => {
        const searchedIn = [
          vacancy.position,
          vacancy.company.name,
          vacancy.description,
          vacancy.location
        ]
          .join(' ')
          .toLowerCase();
        return searchedIn.includes(lowerCasedSearchValue);
      });

      // Filtering
      const selectedTags = state.tagList.filter((tag) => tag.selected);
      let filteredByTags = filteredBySearch;

      selectedTags.forEach((tag) => {
        switch (tag.filterProperty) {
          case 'location':
            filteredByTags = filteredByTags.filter(
              (vacancy) => vacancy.location === tag.name
            );
            break;

          case 'saved':
            filteredByTags = filteredByTags.filter((vacancy) => vacancy.saved);
            break;
        }
      });

      const result = filteredByTags;

      state.displayedVacancies = result;
    },

    // Tags
    toggleVacanciesTagSelection: (state, actions) => {
      const selectedTagIndex = state.tagList.findIndex(
        (tag) => tag.name === actions.payload.name
      );

      state.tagList[selectedTagIndex].selected =
        !state.tagList[selectedTagIndex].selected;
    },

    uncheckVacanciesTags: (state) => {
      state.tagList.map((tag) => (tag.selected = false));
    },

    // Sorting
    setVacanciesSortOption: (state, actions) => {
      state.sortOption = actions.payload;
    },

    // Search
    setVacanciesSearchValue: (state, actions) => {
      state.seachValue = actions.payload;
    },

    setVacancyDetails: (state, action) => {
      state.vacancyDetails = action.payload;
    },

    // Vacancy request status
    setVacancyDetailsRequestStatus: (state, action) => {
      if (!state.vacancyDetails) return;
      state.vacancyDetails.apply = action.payload;
    },

    updateVacanciesTranslation: (state, action) => {
      state.tagList.map((item, index) => {
        item.name = action.payload.vacancyFilter[index];
      });
      state.sortOption.name = action.payload.vacancySort[state.sortOptions.findIndex(item => item.sortType == state.sortOption.sortType)];
      state.sortOptions.map((item, index) => {
        item.name = action.payload.vacancySort[index];
      });
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getActivatedVacancies.fulfilled, (state, action) => {
        state.vacanciesReceived = !state.vacanciesReceived;
        state.vacanciesCount = action.payload.body.total;
        state.displayedVacancies = action.payload.body.data;
        state.initialVacancies = action.payload.body.data;
      })

      .addCase(getVacancyDetails.fulfilled, (state, action) => {
        state.vacancyDetailsReceived = true;
        state.vacancyDetails = action.payload.body;
        if (state.vacancyDetails) {
          state.vacancyDetails.drivingLicense =
            action.payload.body.driving_license;
        }
      })

    .addCase(getOffers.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.offerCount = action.payload.body.totalElements;
        state.adminOffers = action.payload.body.data;
      })

    .addCase(getApplications.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.applicationCount = action.payload.totalElements; // todo - why not body?
        state.adminApplications = action.payload.data;
      })

    ;
  },
});

export const {
  filterVacancies,
  toggleVacanciesTagSelection,
  uncheckVacanciesTags,
  setVacanciesSortOption,
  setVacanciesSearchValue,
  setVacancyDetails,
  setVacancySaveStatus,
  setVacancyDetailsSave,
  resetVacancyDetails,
  setVacancyDetailsRequestStatus,
  setVacancyDetailsStatus,
  updateVacanciesTranslation,
} = vacanciesSlice.actions;
export default vacanciesSlice.reducer;
