import React, { useState } from 'react';
import Vacancy from '../../models/vacancy.interface';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../constants';
import deactivateIcon from '../../assets/icons/deactivate-white.svg';
import deleteIcon from '../../assets/icons/delete-white.svg';
import { useTranslation } from 'react-i18next';
import Pagination from '../pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { getCompanyVacancies } from '../../store/slices/companyVacanciesSlice';
import VacanciesTableMobile from '../vacancies-tablemobile/VacanciesTableMobile';
import ActionBar from '../ui/action-bar/ActionBar';
import VacanciesTableDeleteModal from './VacanciesTableDeleteModal';
import { TableColumn } from '../../models/table-column.interface';
import VacanciesTableHead from './VacanciesTableHead';
import VacanciesTableBody from './VacanciesTableBody';

interface TableProps {
  isLoading: boolean;
  activeFilter: number;
  pageNumber: number;
  setPageNumberHandler: (page: number) => void;
  vacancies: Vacancy[];
  columns: TableColumn[];
  handleRowCheck: (id: string) => void;
  uncheckAll: () => void;
  checkAll: () => void;
  deleteChecked: () => void;
  deactivateChecked: () => void;
  activateChecked: () => void;
  selectAll: boolean;
  searchValue: string;
  maxAmount: number;
  setAmount: (value: number) => void;
  lastSort: string;
}

const VacanciesTable = ({
  isLoading,
  activeFilter,
  pageNumber,
  setPageNumberHandler,
  vacancies,
  columns, // Array to pass columns on which the data will be shown
  handleRowCheck,
  uncheckAll,
  checkAll,
  deleteChecked,
  deactivateChecked,
  activateChecked,
  selectAll,
  searchValue,
  maxAmount,
  setAmount,
  lastSort,
}: TableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const vacanciesReducer = useSelector(
    (state: RootState) => state.companyVacanciesReducer
  );

  const count = [
    vacanciesReducer.total,
    vacanciesReducer.pending,
    vacanciesReducer.active,
    vacanciesReducer.inactive,
  ];

  const barActions = [
    {
      action: activateChecked,
      image: ASSETS_URL + deactivateIcon,
      text: t('vacanciesTablet.actionBar.activate'),
    },
    {
      action: deactivateChecked,
      image: ASSETS_URL + deactivateIcon,
      text: t('vacanciesTablet.actionBar.deactivate'),
    },
    {
      action: handleShowDeleteModal,
      image: ASSETS_URL + deleteIcon,
      text: t('vacanciesTablet.actionBar.delete'),
    },
    {
      action: uncheckAll,
      text: t('vacanciesTablet.actionBar.cancel'),
    },
  ];

  function handleShowDeleteModal(): void {
    setShowDeleteModal(true);
  }

  function handleCloseDeleteModal(): void {
    setShowDeleteModal(false);
  }

  function onPageChange(page: number): void {
    dispatch(
      getCompanyVacancies({
        pageNumber: page,
        status: activeFilter,
        searchValue,
        size: maxAmount,
        orderBy: lastSort,
      })
    );
    setPageNumberHandler(page);
  }

  return (
    <>
      <div className='position-realative w-100'>
        <table className='app-table mt-4 tablet-hidden' cellSpacing={5}>
          <VacanciesTableHead
            {...{ checkAll, uncheckAll, columns, selectAll }}
          />
          <VacanciesTableBody {...{ vacancies, handleRowCheck }} />
        </table>

        <div className='no-tablet-hidden'>
          <VacanciesTableMobile
            vacancies={vacancies}
            handleCheck={handleRowCheck}
          />
        </div>
        {vacancies.filter((item) => item.checked).length ? (
          <ActionBar
            selectedCount={vacancies.filter((item) => item.checked).length || 0}
          >
            {barActions.map((item) => (
              <button onClick={item.action} key={item.text}>
                {item.image && <Image src={ASSETS_URL + item.image} />}
                {item.text}
              </button>
            ))}
          </ActionBar>
        ) : null}
      </div>

      {isLoading ? (
        <div className='table-loader'>
          <div className='spinner'>
            {Array(10)
              .fill(null)
              .map((_, index) => (
                <div key={index}></div>
              ))}
          </div>
        </div>
      ) : null}

      <VacanciesTableDeleteModal
        deleteChecked={deleteChecked}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <Pagination
        totalPages={Math.ceil(count[activeFilter] / maxAmount) || 1}
        currentPage={pageNumber}
        pageSize={maxAmount}
        withAmountSelection={true}
        setAmount={setAmount}
        changeCurrentPage={onPageChange}
      />
    </>
  );
};

export default VacanciesTable;
