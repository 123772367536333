import React from 'react';
import DeleteButton from '../delete-button/DeleteButton';
import EditButton from '../edit-button/EditButton';

interface Props {
  index: number;
  edit: (index: number) => void;
  deleteCard: (index: number) => void;
}

const RegistrationCardActions = ({ edit, deleteCard, index }: Props) => {
  return (
    <>
      <EditButton action={edit} index={index} />
      <DeleteButton action={deleteCard} index={index} />
    </>
  );
};

export default RegistrationCardActions;
