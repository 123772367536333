import React, { useState } from 'react';
import './Header.scss';
import Menu from '../menu/Menu';
import NotificationMenu from '../../notification-menu/NotificationMenu';
import CompanyHeader from './CompanyHeader';
import CandidateHeader from './CandidateHeader';

interface HeaderProps {
  isCandidate?: boolean;
  isCompany?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  isCandidate = false,
  isCompany = false,
}) => {
  const [isMenu, setIsMenu] = useState(false);
  function closeMenu(): void {
    setIsMenu(false);
  }

  return (
    <>
      {isMenu && <Menu isCompany={isCompany} closeMenu={closeMenu} />}
      <NotificationMenu />
      {isCompany ? <CompanyHeader setIsMenu={setIsMenu} /> : null}
      {isCandidate ? <CandidateHeader setIsMenu={setIsMenu} /> : null}
    </>
  );
};

export default Header;
