import React, {useState} from 'react';

import './ProfileCompany.scss';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SmartPlanModal from "../../../components/ui/smart-plan-modal/SmartPlanModal";
import {CompanyState} from "../../../store/slices/companySlice";
import {toast} from "react-toastify";

const ProfilePlansCompany = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [showSmartModal, setShowSmartModal] = useState(false);
  const handleCloseSmartModal = () => setShowSmartModal(false);
  const handleShowSmartModal = () => setShowSmartModal(true);

  const defaultPlanBenefits: string[] = t('planPage.default.benefits', {
    returnObjects: true,
  });
  const monthlyPlanBenefits: string[] = t('planPage.default.monthlybenefits', {
    returnObjects: true,
  });
  const smartPlanBenefits: string[] = t('planPage.smart.benefits', {
    returnObjects: true,
  });
  const premiumPlanBenefits: string[] = t('planPage.premium.benefits', {
    returnObjects: true,
  });

  const handleSubmitSmartModal = async (data: CompanyState)=> {
    // todo - send company data when there is a back end email to send
    //             dispatch(
    //               sendSmartQuoteEmail({ company: data })
    //             );
    toast.success(t('smartModal.messageSent'));
  };

  function closeSmartModal(): void {
    setShowSmartModal(false);
  }

  return (
    <div className='zestur-card'>
      <h2 className='general-heading mt-4 mb-4'>{t('planPage.title')}</h2>
      <p className='general-sub-heading'>{t('planPage.description')}</p>
      <Row className='mt-4 tablet-gap-1'>
        <Col xs={12} lg={4}>
          <div className='plans-container'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.default.title')}
              </div>
              <div className='plans-container-header__rate'>
                {t('planPage.default.monthlyprice')}
                <span className='plans-container-header__subrate'>
                  {t('planPage.default.yearlyprice')}
                </span>
              </div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {monthlyPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary primary-btn-color w-100'
                onClick={() => navigate('/company/profile/plans/detail')}
              >
                {t('planPage.default.button')}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className='plans-container'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.default.title')}
              </div>
              <div className='plans-container-header__rate'>250€&nbsp;{t('planPage.annual')}</div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {defaultPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary primary-btn-color w-100'
                onClick={() => navigate('/company/profile/plans/annual')}
              >
                {t('planPage.default.button')}
              </button>
            </div>
          </div>
        </Col>
        <Col xs={12} lg={4}>
          <div className='plans-container bg'>
            <div className='plans-container-header'>
              <div className='plans-container-header__title'>
                {t('planPage.smart.title')}
              </div>
              <div className='plans-container-header__rate'>
                {t('planPage.smart.name')}
              </div>
            </div>
            <div className='plans-container-content mt-4'>
              <ul>
                {smartPlanBenefits.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <div className='d-flex align-items-center justify-content-center mt-5 mb-3'>
              <button
                className='btn btn-primary bg-white text-primary w-100'
                onClick={handleShowSmartModal}
              >
                {t('planPage.smart.button')}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <p className='general-sub-heading mt-4 mb-4'>{t('planPage.postWord')}</p>
      <SmartPlanModal
        show={showSmartModal}
        handleClose={handleCloseSmartModal}
        handleSubmit={handleSubmitSmartModal}
      />
    </div>
  );
};

export default ProfilePlansCompany;
