import React from 'react';
import './VacancyMatchButton.scss';

interface Props {
  hideClass?: 'mobile-hidden' | 'tablet-hidden' | 'no-mobile-hidden';
  match: number;
}

const VacancyMatchButton = ({ hideClass, match }: Props) => {
  return (
    <button
      className={'match-tag ' + hideClass}
      onClick={(event) => {
        event.stopPropagation();
        console.log('Match window open');
      }}
    >
      Match: {match}%
    </button>
  );
};

export default VacancyMatchButton;
