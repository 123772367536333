import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import candidatePicture from '../../assets/icons/avatar-placeholder.svg';
import { ASSETS_URL } from '../../constants';
import { useTranslation } from 'react-i18next';
import { ChatCompany } from '../../models/chat-company.interface';
import SearchWithButton from '../ui/SearchWithButton/SearchWithButton';

interface Props {
  companies: ChatCompany[];
  userClick: any;
  loadMore?: any;
  search: (value: string) => void;
  candidateName: string;
}

const ChatCompanyList = ({
  companies,
  userClick,
  loadMore,
  search,
  candidateName,
}: Props) => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<number | null>(null);

  const getSelectedUser = (user: ChatCompany, i: number) => {
    setSelectedUser(i);
    userClick(user);
  };

  const getMoreUsers = () => {
    loadMore(true);
  };
  return (
    <div className='cb-candidate-list'>
      <div className='cb-candidate-list-crr-user mb-4'>
        <span>{candidateName}</span>
      </div>
      <div className='cb-candidate-list-search'>
        <SearchWithButton
          search={search}
          placeholder={t('chatPage.searchPlaceholder')}
        />
      </div>
      <div className='cb-candidate-list-header'>
        <div className='d-flex align-items-center justify-content-between'>
          <label>{t('chatPage.companies')}</label>
          {/* <button className='btn'>+</button> */}
        </div>
      </div>
      <div className='cb-candidate-list-body'>
        {companies.map((company, index: number) => (
          <div
            onClick={() => getSelectedUser(company, index)}
            className={
              'cursor-pointer d-flex align-items-center candidate ' +
              `${index === selectedUser ? 'active' : ''}`
            }
            key={`${index}`}
          >
            <Image
              className='object-fit-cover'
              src={ASSETS_URL + (company?.company_logo || candidatePicture)}
            />
            <span className='d-block text-truncate'>
              {company?.company_name} - {company?.offer_title}
            </span>
          </div>
        ))}
        {/* <div className='d-flex align-items-center justify-content-center'>
          <button
            className='btn btn-primary primary-btn-color px-5'
            onClick={getMoreUsers}
          >
            {t('chatPage.moreUsersButton')}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ChatCompanyList;
