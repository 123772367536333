import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import candidatePicture from '../../assets/icons/avatar-placeholder.svg';
import chatAttach from '../../assets/icons/chat-attach.svg';
import chatImage from '../../assets/icons/chat-image.svg';
import chatSend from '../../assets/icons/chat-send.svg';
import chatSticker from '../../assets/icons/chat-sticker.svg';

import { ASSETS_URL } from '../../constants';
import * as endpoints from '../../constants/endpoints';
import { Image } from 'react-bootstrap';
import WebSocketService from '../../core/services/socket';
import ApiService from '../../core/services/network.service';
import tokenService from '../../_services/token.service';
import { useTranslation } from 'react-i18next';
import { decodeToken } from '../../models/decode-token';

interface Message {
  body: string;
  created_at: string;
  id: string;
  receiver: string;
  sender: string;
  updated_at: string;
}

interface ChatProps {
  userId: string;
  loggedUserName: string;
  currentUserAvatar: string;
  dialogPersonAvatar: string;
  dialogPersonName: string;
}

const ChatBox = ({
  userId,
  loggedUserName,
  currentUserAvatar,
  dialogPersonAvatar,
  dialogPersonName,
}: ChatProps) => {
  const { t } = useTranslation();
  const [messages, setMessages] = useState<Message[]>([]);
  const token = tokenService.getLocalAccessToken();
  const decodedToken = decodeToken(token);
  const inputRef: any = useRef(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [webSocketService, setWebsocketService] =
    useState<WebSocketService | null>();

  useEffect(() => {
    return () => {
      if (webSocketService) {
        webSocketService.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (webSocketService) {
      webSocketService.disconnect();
    }
    setMessages([]);

    const newWebSocket = new WebSocketService(
      `message/${userId}/?token=${token}`
    );
    newWebSocket.onMessage(handleReceiveMessage);
    setWebsocketService(newWebSocket);
    fetchMessages(userId);
  }, [userId]);

  useEffect(() => {
    // Scroll to the bottom of the messages container when a new message is added
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    const inputValue: { body: string } = { body: inputRef?.current?.value };

    if (!inputValue.body) return;

    webSocketService?.sendMessage(JSON.stringify(inputValue));
    inputRef.current.value = '';
  };

  const handleReceiveMessage = (message: string) => {
    setMessages((prevMessages: Message[]) => [
      ...prevMessages,
      JSON.parse(message),
    ]);
  };

  const fetchMessages = (id: string) => {
    if (id) {
      ApiService.request('GET', endpoints.MESSAGE.replace('{id}', id)).then(
        (res: any) => {
          setMessages(res);
        }
      );
    }
  };

  return (
    <div className={`chat-main`}>
      <div className='chat-container' ref={messagesContainerRef}>
        <div className='messages'>
          {messages.map((message: Message, index: number) => {
            return (
              <div key={index} className='message-container'>
                {message.receiver === decodedToken?.user_id && (
                  <div className='sender-info'>
                    <img
                      src={dialogPersonAvatar || ASSETS_URL + candidatePicture}
                      alt={'avatar'}
                      className='sender-image object-fit-cover'
                    />
                    <div className='sender-name'>
                      {dialogPersonName}
                      <small className='time'>
                        {moment(message.created_at).format('h:mm a')}
                      </small>
                    </div>
                  </div>
                )}

                {message.receiver !== decodedToken?.user_id && (
                  <div className='receiver-info'>
                    <div className='receiver-name'>
                      <small className='time'>
                        {moment(message.created_at).format('h:mm a')}
                      </small>{' '}
                      {loggedUserName}
                    </div>
                    <img
                      src={currentUserAvatar || ASSETS_URL + candidatePicture}
                      alt={'avatar'}
                      className='receiver-image object-fit-cover'
                    />
                  </div>
                )}
                <div
                  className={`message ${
                    message.receiver != decodedToken?.user_id
                      ? 'user-message'
                      : 'receiver-message'
                  }`}
                >
                  {message.body}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='input-container'>
        <input
          type='text'
          ref={inputRef}
          onKeyDown={handleKeyDown}
          placeholder={t('chatPage.chatBoxPlaceholder')}
        />
        <div className='d-flex align-items-center justify-content-between'>
          <div>
            <button className='btn'>
              <Image src={ASSETS_URL + chatSticker} />
            </button>
            <button className='btn'>
              <Image src={ASSETS_URL + chatAttach} />
            </button>
            <button className='btn'>
              <Image src={ASSETS_URL + chatImage} />
            </button>
          </div>
          <button className='btn' onClick={handleSendMessage}>
            <Image src={ASSETS_URL + chatSend} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
