import { createSlice } from '@reduxjs/toolkit';

interface AuthPageState {
  isFormView: boolean;
}

const initialState: AuthPageState = {
  isFormView: false,
};

const authPageSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setFormView: (state, actions) => {
      state.isFormView = actions.payload;
    },
  },
});

export const { setFormView } = authPageSlice.actions;
export default authPageSlice.reducer;
