import React, { useState, useEffect, useRef } from 'react';

import './Offer.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';

import icDone from '../../../assets/images/ic_done.svg';
import Stepper from '../../../components/stepper/Stepper';
import OffersStep1 from './steps/OfferStep1';
import OffersStep2 from './steps/OfferStep2';
import OffersStep3 from './steps/OfferStep3';
import { ASSETS_URL } from '../../../constants';
import RoundedProgressBar from '../../../components/rounded-progress-bar/RoundedProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import StepperComponent from '../../../models/stepper-component.interface';
import { AppDispatch, RootState } from '../../../store/store';
import {
  createVacancy,
  resetVacancyForm,
  setStatus,
  updateProgress,
  updateVacancy,
} from '../../../store/slices/vacancyCreateSlice';
import OfferSubmitModal from './offer-submit-modal/OfferSubmitModal';
import { salaryRange } from '../../../utils/format-salary';
import { useTranslation } from 'react-i18next';
import { getItemById } from '../../../utils/get-item-by-id';
import { ListItem } from '../../../models/list-item';
import useCheckVerified from '../../../hook/useCheckVerified';

const Offer = () => {
  const { t } = useTranslation();
  const vacancyForm = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy
  );
  const createdVacancyId = useSelector(
    (state: RootState) => state.vacancyCreateReducer.createdVacancyId
  );
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const stepOneProgress = useSelector(
    (state: RootState) => state.vacancyCreateReducer.stepOneProgress
  );
  const stepTwoProgress = useSelector(
    (state: RootState) => state.vacancyCreateReducer.stepTwoProgress
  );
  const stepThreeProgress = useSelector(
    (state: RootState) => state.vacancyCreateReducer.stepThreeProgress
  );
  const submitRef: any = useRef(null);
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });

  useCheckVerified('company');
  useEffect(() => {
    return () => {
      dispatch(resetVacancyForm());
    };
  }, []);

  useEffect(() => {
    dispatch(updateProgress());
  }, [currentStep]);

  useEffect(() => {
    if (!vacancyForm.position) return;
    if (vacancyForm.status === 2) return;

    if (createdVacancyId) {
      dispatch(
        updateVacancy({ vacancyId: createdVacancyId, withMessage: false })
      );
    } else {
      dispatch(createVacancy(vacancyForm));
    }
  }, [vacancyForm]);

  const components: StepperComponent[] = [
    {
      id: 1,
      count: stepOneProgress,
      countMax: 5,
      data: {
        title: t('createOffer.card1.title'),
        key1: t('createOffer.card1.key1') + ':',
        key2: t('createOffer.card1.key2') + ':',
        key3: t('companyVacancyDetails.position') + ':',
        key4: t('createOffer.card1.key4') + ':',
        key5: t('createOffer.card1.key5') + ':',
        key6: '',
      },
      component: OffersStep1,
    },
    {
      id: 2,
      count: stepTwoProgress,
      countMax: 6,
      component: OffersStep2,
      data: {
        title: t('createOffer.card2.title'),
        key1: t('createOffer.card2.key1'),
        key2: t('vacancyDetails.labels.quality'),
        key3: t('createOffer.card2.key3'),
        key4: t('vacancyDetails.labels.capability'),
        key5: t('vacancyDetails.subtitles.language'),
        key6: t('vacancyDetails.labels.license'),
      },
    },
    {
      id: 3,
      count: stepThreeProgress,
      countMax: 3,
      component: OffersStep3,
      data: {
        title: t('createOffer.card3.title'),
        key1: t('createOffer.card3.key1') + ':',
        key2: t('createOffer.card3.key2') + ':',
        key3: t('vacancyDetails.labels.description') + ':',
        key4: '',
        key5: '',
        key6: '',
      },
    },
  ];

  const handleLoadComponent = (component: React.ReactNode, step: number) => {
    setCurrentStep(step);
  };

  const handleSubmitClick = async () => {
    if (!createdVacancyId) return;
    dispatch(setStatus(2));
    const response = await dispatch(
      updateVacancy({ vacancyId: createdVacancyId, withMessage: false })
    );

    const responseStatus = response.meta.requestStatus === 'fulfilled';
    if (responseStatus) {
      handleShowFinalModal();
    }
  };

  const onLastBtnClick = () => {
    handleShow();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [showFinalModal, setShowFinalModal] = useState(false);

  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);

  return (
    <Row>
      <Col md={5}>
        <div className='zestur-card mb-4'>
          <h6 className='offer-heading py-2'>
            {t('createOffer.cardsTitle')}:
            <span className='text-main-black'>{vacancyForm.position}</span>
          </h6>
        </div>

        {components.map(({ id, count, countMax, component, data }, index) => (
          <div
            className={
              'zestur-card mb-4 position-relative ' +
              (currentStep == index ? 'card-border-primary' : '')
            }
            key={id}
            onClick={() => handleLoadComponent('component', index)}
          >
            <h6 className='offer-heading py-2'> {data?.title} </h6>
            <div className='g-counter'>
              <RoundedProgressBar value={count} min={0} max={countMax} />
            </div>
            {index != 2 ? (
              <>
                <Row>
                  <Col md={6}>
                    <p className='general-sub-heading'>
                      {data?.key1 +
                        ' ' +
                        (index === 0 ? vacancyForm.sector : '')}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className='general-sub-heading'>
                      {data?.key2 +
                        ' ' +
                        (index === 0 ? vacancyForm.location : '')}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className='general-sub-heading'>
                      {data?.key3 +
                        ' ' +
                        (index === 0 ? vacancyForm.position : '')}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p className='general-sub-heading'>
                      {data?.key4 +
                        ' ' +
                        (index === 0 ? vacancyForm.end_date : '')}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className='general-sub-heading'>
                      {data?.key5 +
                        ' ' +
                        (index === 0 ? vacancyForm.number_of_vacancies : '')}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className='general-sub-heading'>{data?.key6}</p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md={12}>
                    <p className='general-sub-heading'>
                      {data?.key1 + ' '}
                      {vacancyForm.salary_min && vacancyForm.salary_min
                        ? salaryRange(
                            +vacancyForm.salary_min,
                            +vacancyForm.salary_max,
                            t('salary.minPrefix'),
                            t('salary.maxPrefix')
                          )
                        : ''}
                    </p>
                  </Col>
                  <Col md={12}>
                    <p className='general-sub-heading'>
                      {data?.key2}{' '}
                      {getItemById(jobTypeList, vacancyForm.job_type)}
                    </p>
                  </Col>
                  <Col md={12}>
                    <p className='general-sub-heading'>
                      {data?.key3} {vacancyForm.description}
                    </p>
                  </Col>
                </Row>
              </>
            )}
          </div>
        ))}
      </Col>
      <Col md={7}>
        <div className='zestur-card pb-5'>
          <Stepper
            components={components}
            currentStep={currentStep}
            onStepClick={setCurrentStep}
            onLastBtnClick={onLastBtnClick}
          />

          <OfferSubmitModal
            show={show}
            close={handleClose}
            vacancyForm={vacancyForm}
            handleSubmitClick={handleSubmitClick}
          />

          <Modal show={showFinalModal} centered onHide={handleCloseFinalModal}>
            <Modal.Body className='text-center py-5'>
              <Image className='mt-5' src={ASSETS_URL + icDone} />
              <h1 className='fw-bold my-4'>
                {t('createOffer.finalModal.title')}
              </h1>
              <p className='f-12 mt-4'>
                {t('createOffer.finalModal.description')}
              </p>
              <Button
                type='button'
                className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3'
                onClick={() => navigate('/company/vacancies')}
              >
                {t('createOffer.finalModal.button')}
              </Button>
            </Modal.Body>
          </Modal>
          <Button
            ref={submitRef}
            type='submit'
            className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3 d-none'
          >
            {t('submitButton')}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Offer;
