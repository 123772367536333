import React, { useEffect } from 'react';
import { Image, Modal, Button } from 'react-bootstrap';
import robot from '../../../../assets/images/registration/robot-no-shadow.png';
import checkmark from '../../../../assets/icons/checkmark-with-background.svg';
import { ASSETS_URL } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import Step from '../../../../models/step.interface';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../actions/auth';
import { RootState } from '../../../../store/store';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function SubmittedForm(props: Step) {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const registrationError = useSelector(
    (state: RootState) => state.candidateReducer.registrationError
  );
  const candidateCred = useSelector(
    (state: RootState) => state.candidateReducer.candidate
  );
  const navigate = useNavigate();

  const candidateResume = useSelector(
    (state: RootState) => state.candidateReducer.uploadedResume[0]
  );

  useEffect(() => {
    if (!registrationError) {
      dispatch(login(candidateCred.email, candidateCred.password, !!candidateResume));
      toast.info(t('messages.emailVerify'));
    } else {
      props.handlePrev();
      toast.error(registrationError);
    }
  }, []);

  return (
    <div className='d-flex align-items-center max-height justify-content-center'>
      <Modal.Dialog>
        <Modal.Body className='modalBox candidate'>
          <div>
            <h6 className='text-center'>
              {t('candidateRegistration.submitModal.title')}
            </h6>

            <div className='candidate-image'>
              <Image src={ASSETS_URL + robot} alt='logo Image' fluid />
              <Image src={ASSETS_URL + checkmark} alt='checkmark' fluid />
            </div>
            <p>{t('candidateRegistration.submitModal.description')}</p>
          </div>

          <Button
            type='submit'
            onClick={() => navigate('/auth/candidate/sign-in')}
          >
            {t('registrationForm.steps.actions.finishButton')}
          </Button>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

export default SubmittedForm;
