import React from 'react';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';
import { salaryRange } from '../../utils/format-salary';
import { useTranslation } from 'react-i18next';
import { VacancyProps } from '../../models/vacancy-props.interface';
import { getItemById } from '../../utils/get-item-by-id';
import { ListItem } from '../../models/list-item';

const VacancyDetailsSalary = ({ vacancy }: VacancyProps) => {
  const { t } = useTranslation();
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });

  return (
    <div className='details__salary d-flex justify-content-between'>
      <VacancyInfo
        title={`${t('vacancyDetails.labels.range')}:`}
        info={salaryRange(
          +vacancy.salary_min,
          +vacancy.salary_max,
          t('salary.minPrefix'),
          t('salary.maxPrefix')
        )}
      />
      <VacancyInfo
        title={`${t('vacancyDetails.labels.type')}:`}
        info={getItemById(jobTypeList, vacancy.job_type)}
      />
    </div>
  );
};

export default VacancyDetailsSalary;
