import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './ProgressBarTitle.module.scss';

interface Props {
  progressValue: number;
  startStep: number;
  endStep: number;
}

const ProgressBarTitle = ({ progressValue, startStep, endStep }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.progressBarTitle}>
      <div className='d-flex justify-content-between justify-items-center'>
        <h4>{t('registrationForm.steps.progressTitle')} </h4>
        <h5>
          {t('registrationForm.steps.progressSubtitle.firstPart')} {startStep}{' '}
          {t('registrationForm.steps.progressSubtitle.secondPart')} {endStep}
        </h5>
      </div>
      <ProgressBar now={progressValue} />
    </div>
  );
};

export default ProgressBarTitle;
