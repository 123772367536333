import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object } from 'yup';
import Experience from '../../../models/experience.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import './CandidateExperienceModal.scss';
import { getExperienceEndDateString } from '../../../utils/get-experience-end-date-string';
import { defaultNotSelectedDate } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  index: number;
  handleClose: () => void;
  handleSubmit: (data: Experience, logo?: File) => void;
}

const experienceSchema = object();

const CandidateExperienceModal = ({
  index = -1,
  show,
  handleClose,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [isActual, setIsActual] = useState<boolean>(false);
  const candidateId: string = useSelector(
    (state: RootState) => state.candidateReducer.candidate.userId
  );
  const candidateExperience = useSelector(
    (state: RootState) => state.candidateReducer.experience
  );

  // yyyy-mm-dd regex
  const dateRegex = /\d{4}-\d{2}-\d{2}/;

  useEffect(() => {
    if (index < 0) {
      setIsActual(false);
      return;
    }

    if (candidateExperience[index].end_date === defaultNotSelectedDate) {
      setIsActual(true);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('experienceForm.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            company: index >= 0 ? candidateExperience[index].company : '',
            position: index >= 0 ? candidateExperience[index].job_position : '',
            jobType: index >= 0 ? candidateExperience[index].job_type : '',
            location: index >= 0 ? candidateExperience[index].location : '',
            startDate: index >= 0 ? candidateExperience[index].start_date : '',
            endDate:
              index >= 0
                ? getExperienceEndDateString(
                    candidateExperience[index].end_date,
                    t('experienceForm.actualPlaceholder')
                  )
                : '',
            description:
              index >= 0 ? candidateExperience[index].description : '',
          }}
          onSubmit={(values) => {
            if (index >= 0) {
              handleSubmit({
                id: candidateExperience[index].id || '',
                user: candidateId,
                company: values.company,
                job_position: values.position,
                job_type: values.jobType,
                location: values.location,
                start_date: values.startDate || defaultNotSelectedDate,
                end_date: dateRegex.test(values.endDate)
                  ? values.endDate
                  : defaultNotSelectedDate,
                description: values.description,
              });
            } else {
              handleSubmit({
                company: values.company,
                user: candidateId,
                job_position: values.position,
                job_type: values.jobType,
                location: values.location,
                start_date: values.startDate || defaultNotSelectedDate,
                end_date: values.endDate || defaultNotSelectedDate,
                description: values.description,
              });
            }

            handleClose();
          }}
          validationSchema={experienceSchema}
        >
          {({ errors, touched, values }) => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row className='mobile-column first-row'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('experienceForm.name')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='company'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom01'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('experienceForm.position')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='position'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('experienceForm.jobType')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='jobType'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('experienceForm.location')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      aria-describedby='inputGroupPrepend'
                      name='location'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom05'>
                  <Form.Label className='label modal-label'>
                    {t('experienceForm.startDate')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='date'
                      aria-describedby='inputGroupPrepend'
                      name='startDate'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom06'>
                  <Form.Label className='label modal-label'>
                    {t('experienceForm.endDate')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    {isActual ? (
                      <Field
                        className='form-control mt-2 px-4 py-3'
                        type='text'
                        aria-describedby='inputGroupPrepend'
                        name='endDate'
                        value={t('experienceForm.actualPlaceholder')}
                        disabled={isActual}
                      />
                    ) : (
                      <Field
                        className='form-control mt-2 px-4 py-3'
                        type='date'
                        aria-describedby='inputGroupPrepend'
                        name='endDate'
                      />
                    )}
                    <div className='actual-checkbox'>
                      <input
                        className='cursor-pointer'
                        type='checkbox'
                        checked={isActual}
                        onChange={() => {
                          setIsActual((prevValue) => !prevValue);

                          if (!isActual) {
                            values.endDate = defaultNotSelectedDate;
                          } else {
                            values.endDate = '';
                          }
                        }}
                      ></input>
                      <span>{t('experienceForm.actualCheckboxLabel')}</span>
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('experienceForm.description')}
                  </Form.Label>
                  <InputGroup hasValidation className='textarea'>
                    <Field
                      as='textarea'
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      name='description'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      maxLength={400}
                    />
                    <div className='textarea__counter'>
                      {values.description.length}/400
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateExperienceModal;
