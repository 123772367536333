import { createSlice } from '@reduxjs/toolkit';

interface ThemeState {
  dark: boolean;
}

const initialState: ThemeState = {
  dark: false,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setDarkTheme: (state, actions) => {
      state.dark = actions.payload;

      if (state.dark) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    },
  },
});

export const { setDarkTheme } = themeSlice.actions;
export default themeSlice.reducer;
