import React, { useState } from 'react';
import kebabIcon from '../../../assets/icons/menu-kebab.svg';
import './DotsButton.scss';
import EditButton from '../edit-button/EditButton';
import DeleteButton from '../delete-button/DeleteButton';
import { ASSETS_URL } from '../../../constants';

interface Props {
  index: number;
  deleteHandler: (index: number) => void;
  edit: (index: number) => void;
}

const DotsButton = ({ index, deleteHandler, edit }: Props) => {
  const [isDropdown, setIsDropdown] = useState(false);

  return (
    <div className='position-relative'>
      <button
        className='action-button'
        onClick={() => setIsDropdown((prevValue) => !prevValue)}
      >
        <img src={ASSETS_URL + kebabIcon} alt='actions' className='blue-filter' />
      </button>

      {isDropdown && (
        <div className='action-dropdown'>
          <EditButton index={index} action={edit} />
          <DeleteButton index={index} action={deleteHandler} isRed={true} />
        </div>
      )}
    </div>
  );
};

export default DotsButton;
