import { REFRESH_TOKEN } from './types';

import AuthService from '../_services/auth.service';
import {
  setCandidateTokenExhausted,
  uploadResume,
} from '../store/slices/candidateSlice';
import {
  setCompanyTokenExhausted,
  uploadCompanyLogo,
} from '../store/slices/companySlice';
import { setTokenExhausted } from '../store/slices/authSlice';

export const login =
  (
    email: string,
    password: string,
    withResume: boolean = false,
    withLogoUpload: boolean = false
  ) =>
  (dispatch: any) => {
    return AuthService.login(email, password).then(
      (data) => {
        if (withResume) {
          dispatch(uploadResume());
        }

        if (withLogoUpload) {
          dispatch(uploadCompanyLogo());
        }

        dispatch(setCandidateTokenExhausted(false));
        dispatch(setCompanyTokenExhausted(false));
        dispatch(setTokenExhausted(false));

        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        return Promise.reject(message);
      }
    );
  };

export const refreshToken = (accessToken: any) => (dispatch: any) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: accessToken,
  });
};
