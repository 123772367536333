import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import ApiService from '../../../core/services/network.service';
import mapPin from '../../../assets/icons/map-pin.svg';
import { ASSETS_URL } from '../../../constants';
import * as endpoints from '../../../constants/endpoints';
import tokenService from '../../../_services/token.service';
import CompanyQRModal from '../../../components/ui/company-qr-modal/CompanyQRModal';
import { formatSalaryCandidateCard } from '../../../utils/format-salary';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getAssignedCandidates } from '../../../store/slices/companyCandidatesSlice';
import { useTranslation } from 'react-i18next';
import CompanyInfoSidebar from '../../../components/ui/company-info-sidebar/CompanyInfoSidebar';
import NavigationButton from '../../../components/ui/navigation-button/NavigationButton';
import CompanyNewestCandidates from '../../../components/ui/company-newest-candidates/CompanyNewestCandidates';
import styles from './HomeCompany.module.scss';
import { decodeToken } from '../../../models/decode-token';

const HomeCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const candidates = useSelector(
    (state: RootState) => state.companyCandidatesReducer.assignedCandidates
  );
  const companyDetails = useSelector(
    (state: RootState) => state.companyReducer
  );
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [offersList, setOffersList] = useState([]);
  const token = tokenService.getLocalAccessToken();
  const decodedToken = decodeToken(token);

  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);

  useEffect(() => {
    dispatch(
      getAssignedCandidates({
        pageNumber: 1,
        searchValue: '',
        size: 4,
        statusValue: '',
      })
    );

    if (token && decodedToken && decodedToken.company_id) {
      fetchOffers(decodedToken?.company_id);
    }
  }, []);

  const fetchOffers = (id: any) => {
    ApiService.request<any>(
      'GET',
      endpoints.OFFERS_COMPANY.replace('{id}', id),
      null,
      false,
      {
        params: {
          page: 1,
          size: 3,
          status: 2,
        },
      }
    ).then((res) => {
      setOffersList(res?.body?.data);
    });
  };

  return (
    <>
      <Row>
        <Col lg={9}>
          <div className='zestur-card'>
            <div className='zestur-card__header'>
              <h4>{t('companyHomePage.vacancies.title')}</h4>
              <div className='action'>
                <Link
                  to={'/company/vacancies'}
                  className='dark-blue-link text-small'
                >
                  {t('companyHomePage.navigationButton')}
                </Link>
              </div>
            </div>
            <div className='zestur-card__body'>
              <Row className={styles.tabletGap}>
                {offersList?.slice(0, 3).map((_: any, index) => (
                  <Col lg={4} key={index}>
                    <div className='card-fixed'>
                      <h4 className='card-fixed__header'>{_?.position}</h4>
                      <div className='text mb-2 card-fixed__description'>
                        {_?.description?.substring(0, 50)} ...
                      </div>
                      <div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='text card-fixed__details'>
                              {t('companyHomePage.vacancies.salaryPrefix')}
                            </div>
                            <div className='text-dark ms-2 card-fixed__salary'>
                              {formatSalaryCandidateCard(
                                _?.salary_min,
                                _?.salary_max,
                                t('salary.minPrefix'),
                                t('salary.maxPrefix')
                              )}{' '}
                              <span>€</span>
                            </div>
                          </div>
                          <div className='text d-flex gap-2'>
                            <Image src={ASSETS_URL + mapPin} />
                            <span className='card-fixed__details'>
                              {_?.location?.substring(0, 20)}
                            </span>
                          </div>
                        </div>
                        <NavigationButton
                          text={t('companyHomePage.vacancies.navigation')}
                          url={`/company/vacancies/${_.id}/detail`}
                          classes='btn btn-primary primary-btn-color ms-auto'
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div className='zestur-card mt-4'>
            <div className='zestur-card__header'>
              <h4>{t('companyHomePage.candidates.title')}</h4>
              <div className='action'>
                <Link
                  to={'/company/applicants'}
                  className='dark-blue-link text-small'
                >
                  {t('companyHomePage.navigationButton')}
                </Link>
              </div>
            </div>
            <div className='zestur-card__body'>
              <Row>
                <Col>
                  <CompanyNewestCandidates candidates={candidates} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={3} className='tablet-mt-4'>
          <CompanyInfoSidebar
            companyDetails={companyDetails}
            handleShowFinalModal={handleShowFinalModal}
          />
        </Col>
      </Row>
      <CompanyQRModal
        show={showFinalModal}
        handleClose={handleCloseFinalModal}
      />
    </>
  );
};

export default HomeCompany;
