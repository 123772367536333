import React, { useRef, useState } from 'react';
import share from '../../../assets/icons/share-network.svg';
import DropdownPopup from '../../dropdown-popup/DropdownPopup';
import { useOutsideHandler } from '../../../utils/outside-click-handler';
import styles from './ShareButton.module.scss';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
} from 'react-share';
import { config } from '../../../_config/config';
import { useLocation } from 'react-router';

const ShareButton = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  useOutsideHandler(wrapperRef, setOpen);
  const location = useLocation();

  function setOpen(isOpen: boolean): void {
    setIsDropdownOpen(isOpen);
  }
  const getUrl = () => `${config.APP_URL}${location.pathname}`;

  const options = [
    {
      name: '',
      PrefixComponent: (
        <div className='d-flex gap-1'>
          <FacebookShareButton url={getUrl()}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={getUrl()}>
            <XIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton url={getUrl()}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      ),
    },
  ];

  return (
    <div ref={wrapperRef}>
      <button
        className='details__share'
        onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
      >
        <img src={share} alt='share' />
      </button>
      {isDropdownOpen && (
        <DropdownPopup
          optionList={options}
          selectOption={() => {}}
          additionalClass={styles.dropdown}
          width='130px'
        />
      )}
    </div>
  );
};

export default ShareButton;
