// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-selector {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 1.5rem; }
  .language-selector__box {
    cursor: pointer;
    left: 0;
    width: 2rem;
    height: 1.5rem;
    position: absolute;
    opacity: 0; }
  @media (max-width: 600px) {
    .language-selector {
      width: 42px;
      height: 2rem;
      margin-left: 1.5rem; }
      .language-selector__box {
        width: 42px;
        height: 2rem; } }
`, "",{"version":3,"sources":["webpack://./src/components/language-selector/LanguageSelector.scss","webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,cAAc,EAAA;EAEd;IACE,eAAe;IACf,OAAO;IACP,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,UAAU,EAAA;ECXV;IDDJ;MAgBI,WAAW;MACX,YAAY;MACZ,mBAAmB,EAAA;MACnB;QACE,WAAW;QACX,YAAY,EAAA,EACb","sourcesContent":["@import '../../assets/scss/breakpoint/breakpoints-mixins';\n\n.language-selector {\n  position: relative;\n  cursor: pointer;\n  width: 2rem;\n  height: 1.5rem;\n\n  &__box {\n    cursor: pointer;\n    left: 0;\n    width: 2rem;\n    height: 1.5rem;\n    position: absolute;\n    opacity: 0;\n  }\n\n  @include mobile-breakpoint {\n    width: 42px;\n    height: 2rem;\n    margin-left: 1.5rem;\n    &__box {\n      width: 42px;\n      height: 2rem;\n    }\n  }\n}\n","@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
