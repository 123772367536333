import React from 'react';
import Certificate from '../../../models/certificate.interface';
import RegistrationCardActions from '../registration-card-actions/RegistrationCardActions';
import DotsButton from '../dots-button/DotsButton';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  certificate: Certificate;
  editHandler: (index: number) => void;
  deleteHandler: (index: number) => void;
  withEdit?: boolean;
}

const CandidateCertificateCard = ({
  certificate,
  index,
  editHandler,
  deleteHandler,
  withEdit = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='info-section mb-4'>
      <div className='ms-1 info-section-title d-flex justify-content-between'>
        {certificate.qualification}
        {withEdit && (
          <>
            <div className='actions mobile-hidden'>
              <RegistrationCardActions
                edit={editHandler}
                deleteCard={deleteHandler}
                index={index}
              />
            </div>
            <div className='no-mobile-hidden'>
              <DotsButton
                edit={editHandler}
                deleteHandler={deleteHandler}
                index={index}
              />
            </div>
          </>
        )}
      </div>
      <div className='d-flex align-items-center justify-content-start'>
        <div className='ms-1'>
          <div className='d-flex align-items-center mt-3'>
            <div className='info-section-sub-title'>
              {certificate.institution}
            </div>
            <div className='info-section-date ms-5 data-gray'>
              <a
                href={certificate.certificate_link}
                target='_blank'
                rel='noreferrer'
              >
                {t('certificationModal.link')}
              </a>
            </div>
          </div>
          <div className='d-flex align-items-center mt-3'>
            <div className='info-section-sub-title'>
              {t('certificationCard.year')}:{' '}
              {certificate.registration_date.substring(0, 4)}
            </div>
            <div className='info-section-date ms-5 data-gray'>
              # {t('certificationModal.title')}: {certificate.certificate_code}
            </div>
          </div>
        </div>
      </div>
      <div className='ps-1 mt-3 mb-4'>
        <div className='info-section-description'>
          {certificate.description}
        </div>
      </div>
    </div>
  );
};

export default CandidateCertificateCard;
