import React from 'react';

import { Col, Row } from 'react-bootstrap';
import SearchBar from '../../../components/shared/search-bar/SearchBar';
import HomeFilter from './filter/HomeFilter';
import Vacancies from './vacancies/Vacancies';

const HomeCandidateMain = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <SearchBar location='candidate' />
        </Col>
      </Row>

      <Row>
        <HomeFilter />
      </Row>

      <Row>
        <Vacancies />
      </Row>
    </>
  );
};

export default HomeCandidateMain;
