// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-progress {
  width: 100%;
  z-index: 12;
  align-items: end;
  gap: 1rem; }
  .mobile-progress .progress {
    width: 100%;
    height: 16px !important; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/registration-mobile-progress/RegistrationMobileProgress.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,SAAS,EAAA;EAJX;IAOI,WAAW;IACX,uBAAsB,EAAA","sourcesContent":[".mobile-progress {\n  width: 100%;\n  z-index: 12;\n  align-items: end;\n  gap: 1rem;\n\n  .progress {\n    width: 100%;\n    height: 16px!important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
