import React, { useState, useEffect } from 'react';
import { Field, Formik, Form as FormikForm } from 'formik';
import './VacancyEdit.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  getVacancyDetails,
  resetVacancyDetails,
  setVacancyDetailsStatus,
} from '../../../../store/slices/vacanciesSlice';
import { getLanguages } from '../../../../store/slices/languageSlice';
import LanguageOption from '../../../../models/language-option.interface';
import CandidateTag from '../../../../models/candidate-tag.interface';
import RegistrationTag from '../../../../components/ui/registration-tag/RegistrationTag';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import {
  resetVacancyForm,
  setStatus,
  setVacancyFormCapabilities,
  setVacancyFormCapabilitiesRating,
  setVacancyFormEducation,
  setVacancyFormEducationRating,
  setVacancyFormExperience,
  setVacancyFormExperienceRating,
  setVacancyFormJobDescription,
  setVacancyFormLanguage,
  setVacancyFormLanguageRating,
  setVacancyFormLicense,
  setVacancyFormLicenseRating,
  setVacancyFormMainInfo,
  setVacancyFormQualities,
  setVacancyFormQualitiesRating,
  updateVacancy,
} from '../../../../store/slices/vacancyCreateSlice';
import { date, object, string } from 'yup';
import SelectLicenseForm from '../../../../components/ui/select-license-form/SelectLicenseForm';
import {
  setIsVehicle,
  updateLicenseListSelection,
} from '../../../../store/slices/licenseSlice';
import { setLicense } from '../../../../store/slices/candidateSlice';
import Language from '../../../../models/language.interface';
import { getVacancyNumberByStatus } from '../../../../utils/get-vacancy-status-number';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import { getLocalizedLanguageOption } from '../../../../utils/languages-switch/get-localized-language-option';
import { getSortedLanguages } from '../../../../utils/languages-switch/get-sorted-languages';
import { toast } from 'react-toastify';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';
import { getCurrentLevelEducationName } from '../../../../utils/get-current-level-education-name';
import { getEducationList } from '../../../../utils/get-education-list';

const VacancyEdit = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const vacancyDetails = useSelector(
    (state: RootState) => state.vacanciesReducer.vacancyDetails
  );
  const languages = useSelector(
    (state: RootState) => state.languageReducer.languages
  );
  const [vacancyId, setVacancyId] = useState<string>('');
  const [selectedCapabilityTags, setSelectedCapabilityTags] = useState<
    CandidateTag[]
  >([]);
  const [selectedQualityTags, setSelectedQualityTags] = useState<
    CandidateTag[]
  >([]);
  const [selectedLanguageTags, setSelectedLanguageTags] = useState<
    CandidateTag[]
  >([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string>(
    vacancyDetails?.training?.[0]?.level || ''
  );
  const [selectedEducationTags, setSelectedEducationTags] = useState<
    CandidateTag[]
  >([]);
  const licenseList = useSelector(
    (state: RootState) => state.licenseReducer.licenseList
  );
  const isVehicle = useSelector(
    (state: RootState) => state.licenseReducer.isVehicle
  );
  const [skillList, setSkillList] = useState<Option[]>(
    t('aptitudeList', { returnObjects: true })
  );
  const [hardSkillList, setHardSkillList] = useState<Option[]>(
    t('aptitudeList', {
      returnObjects: true,
    })
  );
  const currentSkills = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.offerqualities
  );
  const currentHardSkills = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.offercapabilitie
  );
  const validationSchema = object({
    sector: string().required(t('errors.fieldRequired')),
    position: string().required(t('errors.fieldRequired')),
    location: string().required(t('errors.fieldRequired')),
    number_of_vacancies: string().required(t('errors.fieldRequired')),
    end_date: date().required(t('errors.fieldRequired')),
    salary: string().required(t('errors.fieldRequired')),
    job_type: string().required(t('errors.fieldRequired')),
    description: string().required(t('errors.fieldRequired')),
    address: string().required(t('errors.fieldRequired')),
  });
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });
  const salaryRangeList: { value: string; option: string }[] = t(
    'salaryRangeList',
    { returnObjects: true }
  );
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });
  const englishAptitudeList: ListItem[] = t('aptitudeList', {
    lng: 'en',
    returnObjects: true,
  });

  const levelList: ListItem[] = t('educationLevelList', {
    returnObjects: true,
  });
  const englishLevelList: ListItem[] = t('educationLevelList', {
    lng: 'en',
    returnObjects: true,
  });

  useEffect(() => {
    if (vacancyDetails?.drivingLicense?.length) {
      dispatch(updateLicenseListSelection(vacancyDetails?.drivingLicense));
    }
  }, [vacancyDetails?.drivingLicense]);

  useEffect(() => {
    dispatch(setIsVehicle(vacancyDetails?.is_vehicle || false));
  }, [vacancyDetails?.is_vehicle]);

  function addSelectedHardTag(tag: CandidateTag): void {
    setIsFormDirty(true);
    setSelectedCapabilityTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.id === tag.id)) return prevValue;
      tag.description = getItemById(englishAptitudeList, tag.id);
      copy.push(tag);
      return copy;
    });
  }

  function removeHardSkillFromList(skillIndex: number): void {
    setHardSkillList((prev) => {
      const skills = [...prev];
      skills.splice(skillIndex, 1);
      return skills;
    });
  }

  function deleteSelectedCapabilityTag(tag: ListItem): void {
    setIsFormDirty(true);
    setSelectedCapabilityTags((prevValue) => {
      return prevValue.filter(
        (item) => (item.id?.length! > 12 ? item.name : item.id) !== tag.id
      );
    });
  }

  useEffect(() => {
    const getOfferDetails = async (vacancyId: string) => {
      const response = await dispatch(getVacancyDetails(vacancyId));
      const data = response.payload?.body;

      if (data) {
        dispatch(setVacancyFormLanguageRating(data.language_rating));
        dispatch(setVacancyFormExperienceRating(data.experience_rating));
        dispatch(setVacancyFormEducationRating(data.education_rating));
        dispatch(setVacancyFormQualitiesRating(data.qualities_rating));
        dispatch(setVacancyFormCapabilitiesRating(data.capabilities_rating));
        dispatch(setVacancyFormLicenseRating(data.license_rating));
      }
    };
    const pathSegments = location.pathname.split('/');
    const id = pathSegments.at(-2);
    setPathSegments(pathSegments.slice(1));

    if (id) {
      setVacancyId(id);
      getOfferDetails(id);
    }

    if (!languages.length) {
      dispatch(getLanguages());
    }

    return () => {
      dispatch(resetVacancyDetails());
      dispatch(resetVacancyForm());
    };
  }, []);

  useEffect(() => {
    if (
      vacancyDetails?.language.length &&
      languages.length &&
      !selectedLanguageTags.length
    ) {
      vacancyDetails?.language.map((lang) => {
        setSelectedLanguageTags((prev) => {
          const copy = [...prev];
          copy.push({
            name: getLanguageString(lang, true),
            id: lang.language_id,
          });
          return copy;
        });
      });

      setSelectedLanguages(vacancyDetails?.language);
    }
  }, [vacancyDetails?.language.length, languages.length]);

  useEffect(() => {
    if (vacancyDetails?.offercapabilitie?.length) {
      setSelectedCapabilityTags(vacancyDetails.offercapabilitie);
    }

    let result: Option[] = t('aptitudeList', { returnObjects: true });

    currentHardSkills.forEach((skill) => {
      result = result.filter((aptitude) => skill.name !== aptitude);
    });

    setHardSkillList(result);
  }, [vacancyDetails?.offercapabilitie]);

  useEffect(() => {
    if (vacancyDetails?.qualities?.length) {
      setSelectedQualityTags(vacancyDetails.qualities);
    }

    let result: Option[] = t('aptitudeList', { returnObjects: true });

    currentSkills.forEach((skill) => {
      result = result.filter((aptitude) => skill.name !== aptitude);
    });

    setSkillList(result);
  }, [vacancyDetails?.qualities]);

  useEffect(() => {
    setSkillList(aptitudeList);
    setHardSkillList(aptitudeList);
  }, [i18n.language]);

  useEffect(() => {
    if (isFormDirty) {
      toast.info(t('messages.saveReminder'));
    }
  }, [isFormDirty]);

  useEffect(() => {
    if (vacancyDetails?.language.length) {
      selectedLanguages.map((lang, index) => {
        setSelectedLanguageTags((prev) => {
          let result: CandidateTag[];
          if (!index) {
            result = [];
          } else {
            result = [...prev];
          }

          result.push({
            name: getLanguageString(lang, true),
            id: lang.language_id,
          });
          return result;
        });
      });
    }
  }, [i18n.language]);

  function getLanguageString(
    language: Language,
    serverData: boolean = false
  ): string {
    const lang = languages.find(
      (item) =>
        item.id === (serverData ? language.language_id : language.language)
    );
    const languageName: string = getLocalizedLanguageOption(
      lang || languages[0],
      i18n.language
    );

    const levelString: string =
      levels.find((item) => item.id === language.level.toString())?.name || '';
    return languageName + ': ' + levelString;
  }

  function deleteSelectedLanguageTag(tag: ListItem): void {
    setIsFormDirty(true);
    setSelectedLanguageTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });

    setSelectedLanguages((prev) => {
      return prev.filter((item) => (item.language_id || item.id) !== tag.id);
    });
  }

  function addTag(language: Language): void {
    setIsFormDirty(true);
    const alreadyExists = selectedLanguages.some(
      (item) => item.language_id === language.language
    );

    if (alreadyExists) return;

    setSelectedLanguageTags((prev) => {
      const copy = [...prev];
      copy.push({
        name: getLanguageString(language),
        id: language.language,
      });
      return copy;
    });

    setSelectedLanguages((prev) => {
      const copy = [...prev];
      copy.push({
        level: language.level,
        language_id: language.language,
        language: '',
      });
      return copy;
    });
  }

  function deleteSelectedQualityTag(tag: ListItem): void {
    setIsFormDirty(true);
    setSelectedQualityTags((prevValue) => {
      return prevValue.filter(
        (item) => (item.id?.length! > 12 ? item.name : item.id) !== tag.id
      );
    });

    setSkillList((prev) => {
      const skills = [...prev];
      skills.push(tag);
      (skills as ListItem[]).sort((a, b) => a.name.localeCompare(b.name));
      return skills;
    });
  }

  function addSelectedQualityTag(tag: CandidateTag): void {
    setIsFormDirty(true);
    setSelectedQualityTags((prevValue) => {
      const copy = [...prevValue];

      if (copy.some((item) => item.name === tag.name)) return prevValue;

      tag.description = getItemById(englishAptitudeList, tag.id);
      copy.push(tag);
      return copy;
    });
  }

  function removeSkillFromList(skillIndex: number): void {
    setSkillList((prev) => {
      const skills = [...prev];
      skills.splice(skillIndex, 1);
      return skills;
    });
  }

  useEffect(() => {
    if (vacancyDetails?.training?.length) {
      const tags: CandidateTag[] = [];
      const education = vacancyDetails?.training[0];

      education.name?.forEach((nameId, index) => {
        tags.push({
          id: nameId,
          description: education.name_description[index],
          name: getCurrentLevelEducationName(education.level, nameId, ''),
        });
      });

      setSelectedEducationTags(tags);
      setSelectedLevel(education.level);
    }
  }, [vacancyDetails?.training?.length]);

  function addSelectedEducationTag(tag: CandidateTag): void {
    setSelectedEducationTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.id === tag.id)) return prevValue;
      tag.description = getCurrentLevelEducationName(
        selectedLevel,
        tag.id || '',
        'en'
      );
      copy.push(tag);
      return copy;
    });
  }

  function deleteSelectedEducationTag(tag: ListItem): void {
    setSelectedEducationTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });
  }

  return (
    <div className='zestur-card'>
      {vacancyDetails && (
        <Formik
          initialValues={{
            sector: vacancyDetails.sector || '',
            position: vacancyDetails.position,
            number_of_vacancies: vacancyDetails.number_of_vacancies,
            location: vacancyDetails.location,
            end_date: vacancyDetails.end_date,
            salary:
              vacancyDetails.salary_min.split('.')[0] +
              ' - ' +
              vacancyDetails.salary_max.split('.')[0],
            job_type: vacancyDetails.job_type,
            description: vacancyDetails.description,
            address: vacancyDetails.company.address,
            offerexperience: vacancyDetails.experience[0],
            offerlanguage: { level: 0, language: '' },
            offercapabilitie: vacancyDetails.offercapabilitie,
            drivingLicense: vacancyDetails.drivingLicense,
            offerqualities: vacancyDetails.qualities,
            offertraining: {
              ...vacancyDetails.training?.[0],
            },
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(
              setVacancyFormMainInfo({
                sector: values.sector,
                position: values.position,
                places: values.number_of_vacancies,
                location: values.location,
                date: values.end_date,
              })
            );

            if (values.offerexperience) {
              dispatch(
                setVacancyFormExperience([
                  {
                    sector: values.offerexperience.sector,
                    position: values.offerexperience.position,
                    duration: values.offerexperience.duration,
                  },
                ])
              );
            }

            if (values.offertraining.level) {
              const educationIds: string[] = [];
              const descriptions: string[] = [];

              selectedEducationTags.forEach((item) => {
                educationIds.push(item.id || '');
                descriptions.push(item.description || '');
              });

              dispatch(
                setVacancyFormEducation([
                  {
                    level: values.offertraining.level,
                    level_description: getItemById(
                      englishLevelList,
                      values.offertraining.level
                    ),
                    name: educationIds,
                    name_description: descriptions,
                  },
                ])
              );
            }

            if (selectedLanguages.length) {
              const payload = selectedLanguages.map((lang) => {
                return { level: lang.level, language: lang.language_id };
              });
              dispatch(setVacancyFormLanguage(payload));
            }

            dispatch(
              setStatus(getVacancyNumberByStatus(vacancyDetails.status))
            );

            dispatch(setVacancyFormQualities(selectedQualityTags));
            dispatch(setVacancyFormCapabilities(selectedCapabilityTags));

            const licenses = {
              license: licenseList
                .slice(1)
                .filter((license) => license.selected),
              isVehicle: isVehicle,
            };
            dispatch(setLicense(licenses));
            dispatch(setVacancyFormLicense(licenses));
            dispatch(
              setVacancyFormJobDescription({
                salary: values.salary,
                jobType: values.job_type,
                description: values.description,
              })
            );
            dispatch(updateVacancy({ vacancyId, withMessage: true }));
          }}
        >
          {({ values, errors, touched, dirty }) => {
            if (dirty && !isFormDirty) {
              setTimeout(() => setIsFormDirty(true), 0);
            }

            return (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                <Row>
                  <Col md={6}>
                    {pathSegments && (
                      <Breadcrumbs
                        names={[
                          t('breadcrumbs.home'),
                          t('breadcrumbs.vacancies'),
                          vacancyDetails?.position || '',
                        ]}
                        segments={[
                          pathSegments[0],
                          pathSegments[1],
                          pathSegments[2] + '/' + pathSegments[3],
                        ]}
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    <div className='d-flex align-items-center'>
                      <button
                        className='btn btn-outline-primary btn-outline-color w-100 me-2'
                        onClick={() => {
                          navigate(`/company/vacancies/${vacancyId}/detail`);
                        }}
                      >
                        {t('cancelButton')}
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary primary-btn-color w-100'
                      >
                        {t('submitButton')}
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row className='offer-edit-row'>
                  <Col>
                    <h2 className='page-heading-2 fw-bold mt-1 mb-4'>
                      {vacancyDetails.position}
                    </h2>

                    <div className='d-flex justify-content-between mt-4 mb-4'>
                      <h2 className='general-heading fw-bold text-dark-blue'>
                        {t('companyVacancyDetails.title')}
                      </h2>
                      <div className='d-flex gap-2'>
                        <button
                          className={`btn w-100 me-2 ${
                            vacancyDetails.status === 'Publish'
                              ? 'btn-primary primary-btn-color'
                              : 'btn-outline-primary btn-outline-color'
                          }`}
                          type='button'
                          onClick={() => {
                            dispatch(setVacancyDetailsStatus('Publish'));
                            setIsFormDirty(true);
                          }}
                        >
                          {t(`vacanciesTablet.columns.state.Publish`)}
                        </button>
                        <button
                          className={`btn w-100 me-2 ${
                            vacancyDetails.status === 'Inactive'
                              ? 'btn-primary primary-btn-color'
                              : 'btn-outline-primary btn-outline-color'
                          }`}
                          type='button'
                          onClick={() => {
                            dispatch(setVacancyDetailsStatus('Inactive'));
                            setIsFormDirty(true);
                          }}
                        >
                          {t(`vacanciesTablet.columns.state.Inactive`)}
                        </button>
                      </div>
                    </div>

                    <Row className='align-items-end'>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t(`companyVacancyDetails.companyNameLabel`)}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              type='text'
                              value={vacancyDetails?.company.name}
                              className='form-control vacancy-edit-input'
                              disabled={true}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t(`companyVacancyDetails.sector`)}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='select'
                              name='sector'
                              className='form-control vacancy-edit-input cursor-pointer'
                            >
                              <option value=''></option>
                              {sectorOptions.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                            {errors.sector && touched.sector ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.sector}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className='mt-2 align-items-end'>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('companyVacancyDetails.position')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              type='text'
                              placeholder={t(
                                'vacancyDetails.placeholders.position'
                              )}
                              name='position'
                              className='form-control vacancy-edit-input'
                            />
                            {errors.position && touched.position ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.position}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('companyVacancyDetails.vacanciesAmount')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              type='number'
                              min={0}
                              placeholder=''
                              name='number_of_vacancies'
                              className='form-control vacancy-edit-input'
                            />
                            {errors.number_of_vacancies &&
                            touched.number_of_vacancies ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.number_of_vacancies}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-2 align-items-end'>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.range')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='select'
                              name='salary'
                              className='form-control vacancy-edit-input cursor-pointer'
                              aria-label='Default select example'
                            >
                              {salaryRangeList.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.option}
                                </option>
                              ))}
                            </Field>
                            {errors.salary && touched.salary ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.salary}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.type')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='select'
                              name='job_type'
                              className='form-control vacancy-edit-input cursor-pointer'
                              aria-label='Default select example'
                            >
                              {jobTypeList.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-2 align-items-end'>
                      <Col md={12}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.shortDescription')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='textarea'
                              className='form-control vacancy-edit-input'
                              name='description'
                              rows={7}
                            />
                            {errors.description && touched.description ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.description}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <h2 className='general-heading fw-bold mt-4 mb-4 text-dark-blue'>
                      {t('vacancyDetails.infoTitle')}
                    </h2>
                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.subtitles.experience')}
                    </h6>
                    <Row className='mt-2 align-items-end'>
                      <ImportanceSelect
                        defaultValue={vacancyDetails.experience_rating}
                        setRating={(rate: number) =>
                          dispatch(setVacancyFormExperienceRating(rate))
                        }
                      />
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.experience')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              className='form-control vacancy-edit-input'
                              type='text'
                              name='offerexperience.position'
                              placeholder={t(
                                'vacancyDetails.placeholders.position'
                              )}
                            />
                            {errors.position && touched.position ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.position}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.experienceAmount')} (
                            {t('time.pluralYear')})
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='select'
                              name='offerexperience.duration'
                              className='form-control vacancy-edit-input cursor-pointer'
                            >
                              {Array.from({ length: 9 }, (_, i) => i + 1).map(
                                (item, index) => (
                                  <option value={item} key={index}>
                                    {item}
                                  </option>
                                )
                              )}
                              <option value={10}>10+</option>
                            </Field>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.subtitles.language')}
                    </h6>
                    <Row className='mt-2'>
                      <ImportanceSelect
                        defaultValue={vacancyDetails.language_rating}
                        setRating={(rate: number) =>
                          dispatch(setVacancyFormLanguageRating(rate))
                        }
                      />
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('languageForm.languageTitle')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              name='offerlanguage.language'
                              as='select'
                              className='form-control vacancy-edit-input cursor-pointer'
                              aria-label='Default select example'
                            >
                              <option value=''>
                                {t('languageForm.languageOption')}
                              </option>
                              {languages
                                ? getSortedLanguages(
                                    [...languages],
                                    i18n.language
                                  ).map(
                                    (lang: LanguageOption, index: number) => (
                                      <option
                                        value={lang.id || lang.language_id}
                                        key={index}
                                      >
                                        {getLocalizedLanguageOption(
                                          lang,
                                          i18n.language
                                        )}
                                      </option>
                                    )
                                  )
                                : null}
                            </Field>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('languageForm.levelTitle')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              name='offerlanguage.level'
                              as='select'
                              className='form-control vacancy-edit-input cursor-pointer'
                              aria-label='Default select example'
                            >
                              <option value=''>
                                {t('languageForm.levelOption')}
                              </option>
                              {levels.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </Field>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className='selected-tags'>
                      {selectedLanguageTags.map(
                        (tag: CandidateTag, index: number) => {
                          return (
                            <RegistrationTag
                              key={index}
                              tag={tag as ListItem}
                              deleteTag={deleteSelectedLanguageTag}
                            />
                          );
                        }
                      )}
                    </div>

                    <div className='d-flex justify-content-end mt-2'>
                      <button
                        className='btn btn-primary primary-btn-color px-5'
                        type='button'
                        onClick={() => {
                          if (
                            !values.offerlanguage.language ||
                            !values.offerlanguage.level
                          )
                            return;
                          addTag(values.offerlanguage);
                        }}
                      >
                        {t('addButton')}
                      </button>
                    </div>

                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.subtitles.skill')}
                    </h6>
                    <Row className='mt-2'>
                      <Col md={6}>
                        <ImportanceSelect
                          defaultValue={vacancyDetails.qualities_rating}
                          setRating={(rate: number) =>
                            dispatch(setVacancyFormQualitiesRating(rate))
                          }
                        />
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.quality')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Typeahead
                              id='basic-typeahead-single'
                              className='autocomplete-modal-input offer-edit__autocomplete fw-semibold w-100'
                              labelKey='name'
                              onChange={(element) => {
                                const selected: ListItem =
                                  element[0] as ListItem;
                                const selectedIndex = (
                                  skillList as ListItem[]
                                ).findIndex((item) => item.id === selected.id);
                                addSelectedQualityTag(selected);
                                removeSkillFromList(selectedIndex);
                              }}
                              options={skillList}
                              placeholder={t(
                                'vacancyDetails.placeholders.quality'
                              )}
                              selected={[]}
                            />
                          </InputGroup>
                        </Form.Group>
                        {selectedQualityTags?.length > 0 && (
                          <div className='selected-tags'>
                            {selectedQualityTags.map(
                              (tag: CandidateTag, index: number) => {
                                const localId =
                                  tag.id?.length! > 12 ? tag.name : tag.id;
                                const skill: ListItem = {
                                  name: getItemById(aptitudeList, localId),
                                  id: localId || '',
                                };

                                return (
                                  <RegistrationTag
                                    key={index}
                                    tag={skill}
                                    deleteTag={deleteSelectedQualityTag}
                                  />
                                );
                              }
                            )}
                          </div>
                        )}
                      </Col>
                      <Col md={6}>
                        <ImportanceSelect
                          defaultValue={vacancyDetails.capabilities_rating}
                          setRating={(rate: number) =>
                            dispatch(setVacancyFormCapabilitiesRating(rate))
                          }
                        />
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.capability')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Typeahead
                              id='basic-typeahead-single'
                              className='autocomplete-modal-input offer-edit__autocomplete fw-semibold w-100'
                              labelKey='name'
                              onChange={(element) => {
                                const selected: ListItem =
                                  element[0] as ListItem;
                                const selectedIndex = (
                                  hardSkillList as ListItem[]
                                ).findIndex((item) => item.id === selected.id);
                                addSelectedHardTag(selected);
                                removeHardSkillFromList(selectedIndex);
                              }}
                              options={hardSkillList}
                              placeholder={t(
                                'vacancyDetails.placeholders.capability'
                              )}
                              selected={[]}
                            />
                          </InputGroup>
                        </Form.Group>
                        <div className='selected-tags'>
                          {selectedCapabilityTags.map(
                            (tag: CandidateTag, index: number) => {
                              const localId =
                                tag.id?.length! > 12 ? tag.name : tag.id;
                              const skill: ListItem = {
                                name: getItemById(aptitudeList, localId),
                                id: localId || '',
                              };

                              return (
                                <RegistrationTag
                                  key={index}
                                  tag={skill}
                                  deleteTag={deleteSelectedCapabilityTag}
                                />
                              );
                            }
                          )}
                        </div>
                      </Col>
                    </Row>

                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.labels.license')}
                    </h6>

                    <Row className='mt-2'>
                      <ImportanceSelect
                        defaultValue={vacancyDetails.license_rating}
                        setRating={(rate: number) =>
                          dispatch(setVacancyFormLicenseRating(rate))
                        }
                      />
                      <Col md={12}>
                        <Form.Group className='mb-3'>
                          <SelectLicenseForm
                            titleClass='mb-3 fw-semibold'
                            inputClass='vacancy-edit-input'
                            licenseList={licenseList}
                            isVehicle={isVehicle}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.subtitles.education')}
                    </h6>

                    <Row className='mt-2'>
                      <ImportanceSelect
                        defaultValue={vacancyDetails.training_rating}
                        setRating={(rate: number) =>
                          dispatch(setVacancyFormEducationRating(rate))
                        }
                      />
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.subtitles.education')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              as='select'
                              name='offertraining.level'
                              className='form-control vacancy-edit-input cursor-pointer'
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                              ) => {
                                const value = event.target.value;
                                values.offertraining.level = value;
                                setSelectedEducationTags([]);
                                setSelectedLevel(value);
                              }}
                            >
                              <option value=''></option>
                              {levelList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Field>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.educationTitle')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Typeahead
                              id='basic-typeahead-single'
                              className='autocomplete-modal-input offer-edit__autocomplete fw-semibold w-100'
                              labelKey='name'
                              onChange={(element) => {
                                const selected: ListItem =
                                  element[0] as ListItem;
                                addSelectedEducationTag(selected);
                              }}
                              options={getEducationList(
                                values.offertraining.level || '',
                                ''
                              )}
                              placeholder={t(
                                'vacancyDetails.placeholders.educationTitle'
                              )}
                              selected={[]}
                              disabled={!values.offertraining.level}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        {selectedEducationTags?.length > 0 && (
                          <div className='selected-tags'>
                            {selectedEducationTags.map(
                              (tag: CandidateTag, index: number) => {
                                const localId = tag.id || tag.name;
                                const skill: ListItem = {
                                  name: getCurrentLevelEducationName(
                                    values.offertraining.level || '',
                                    localId,
                                    ''
                                  ),
                                  id: localId,
                                };

                                return (
                                  <RegistrationTag
                                    key={index}
                                    tag={skill}
                                    deleteTag={deleteSelectedEducationTag}
                                  />
                                );
                              }
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('vacancyDetails.subtitles.location')}
                    </h6>

                    <Row className='mt-2'>
                      <Col md={12}>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('vacancyDetails.labels.location')}
                          </Form.Label>
                          <Field
                            name='location'
                            className='form-control vacancy-edit-input'
                            type='text'
                            placeholder=''
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <h6 className='offer-form-header mt-5 mb-4'>
                      {t('companyVacancyDetails.endDate')}
                    </h6>

                    <Row className='mt-2'>
                      <Col md={6}>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label className='mb-3 fw-semibold'>
                            {t('companyVacancyDetails.endDateLabel')}
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Field
                              type='date'
                              name='end_date'
                              className='form-control vacancy-edit-input'
                            />
                            {errors.end_date && touched.end_date ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.end_date}
                              </p>
                            ) : null}
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </FormikForm>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default VacancyEdit;
