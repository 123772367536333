import React from 'react';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import { checkIfAdvancedMatch } from '../../utils/check-if-advanced-match';
import { Spinner } from 'react-bootstrap';
import MatchButton from '../ui/match-button/MatchButton';
import TriggerMatchButton from '../ui/trigger-match-button/TriggerMatchButton';
import { AIModels, matchStarted } from '../../constants/constants';
import { setIsMatchModal, setMatchData } from '../../store/slices/modalSlice';
import { getTableExperienceYears } from '../../utils/get-table-experience-year';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import {ASSETS_URL} from "../../constants";
import iconSeen from "../../assets/icons/eye-open.svg";
import './CandidatesTable.scss';

interface Props {
  candidates: AssignedCandidate[];
  handleRowCheck: (id: string) => void;
  handleStatusChange: (status: React.ChangeEvent<HTMLSelectElement> ) => void;
}

const candidateStatus = [
  {id : 1, name: 'pending', key: 'candidatesTablet.filters.pendingCandidates', selectable: false},
  {id : 2, name: 'seen', key: 'candidatesTablet.filters.seenCandidates', selectable: false},
  {id : 3, name: 'selected', key: 'candidatesTablet.filters.selectedCandidates', selectable: true},
  {id : 4, name: 'discarded', key: 'candidatesTablet.filters.discardedCandidates', selectable: true},
  {id : 5, name: 'withdrawn', key: 'candidatesTablet.filters.withdrawnCandidates', selectable: false}
];

const CandidateTableBody = ({ candidates, handleRowCheck, handleStatusChange }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <tbody>
      {candidates.map((candidate: AssignedCandidate, index: number) => {
        const lastMatch = candidate?.ai_match?.[0];
        const currentStatus = candidate?.status.toLowerCase() == 'pending' && candidate.is_seen ? "2"
            : candidateStatus.find(obj => {
              return obj.name === candidate.status.toLowerCase()
            })?.id.toString()
        return (
          <tr key={candidate.id || index}>
            <td>
              <div className='zestur-check'>
                <input
                  className='checkbox'
                  type='checkbox'
                  id={`checkbox-${index}-`}
                  name='checkbox'
                  checked={candidate.checked}
                  onChange={() => handleRowCheck(candidate.id || '')}
                />
                <label htmlFor={`checkbox-${index}-`} className='px-3'></label>
              </div>
              {candidate.candidate.first_name +
                ' ' +
                candidate.candidate.last_name}

              {candidate.is_seen && (
               <span className="seen"><img src={ASSETS_URL + iconSeen} alt='Seen'/></span>
              ) }

            </td>

            <td>{candidate.offer?.name}</td>

            <td className='cursor-pointer'>
              {candidate.offer?.name !== "QR Offer" &&
              <div className='d-flex justify-content-between align-items-center'>
                {checkIfAdvancedMatch(lastMatch?.ai_model) ? (
                  <>
                    {lastMatch?.process_status === matchStarted ? (
                      <Spinner
                        animation='border'
                        variant='info'
                        style={{ width: '1rem', height: '1rem' }}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          dispatch(setMatchData(candidate));
                          dispatch(setIsMatchModal(true));
                        }}
                      >
                        <span>{`${Math.round(
                          (+lastMatch?.ai_match! || 0) * 10
                        )}%`}</span>
                      </div>
                    )}
                  </>
                ) : (
                          <MatchButton
                              withManual={false}
                              processing={
                                  lastMatch?.process_status === matchStarted &&
                                  lastMatch?.ai_model === AIModels.turbo
                              }
                              percentage={lastMatch?.ai_match || ''}
                          />
                )}

                <TriggerMatchButton
                  id={candidate.id || ''}
                  offerId={candidate.offer?.id || ''}
                  candidateId={candidate.candidate.id}
                />
              </div>
              }
            </td>
            <td>
              {candidate.candidate.experience?.length > 0 && (
                <div className='d-flex flex-column'>
                  <span className='fw-bold'>
                    {candidate.candidate.experience[0].company}
                  </span>
                  <span>{candidate.candidate.experience[0].job_position}</span>
                </div>
              )}
            </td>

            <td>
              {candidate.candidate.experience?.length > 0 && (
                <span>
                  {getTableExperienceYears(
                    candidate.candidate.experience[0]
                  ) === 0
                    ? t('candidatesTablet.columns.noExperience')
                    : getTableExperienceYears(
                        candidate.candidate.experience[0]
                      ) === 1
                    ? `1 ${t('time.singularYear')}`
                    : getTableExperienceYears(
                        candidate.candidate.experience[0]
                      ) + ` ${t('time.pluralYear')}`}
                </span>
              )}
            </td>

            <td>
              <span>{candidate.updated_at?.substring(0,16).replace('T',' ')}</span>
            </td>

            <td>
              <Link
                className='table-link dark-blue-link'
                to={`/company/applicants/${candidate.candidate.id}/${candidate.offer?.id}`}
              >
                {t('candidatesTablet.columns.link.button')}
              </Link>
            </td>

            <td>
      <select
        id={candidate.offer?.id + '|' + candidate.candidate.id}
        className='form-select'
        defaultValue={currentStatus}
        onChange={(e) => handleStatusChange(e)}
        disabled={candidate.status.toLowerCase() == 'withdrawn'}
      >
        {candidateStatus.map((status) => (
          <option value={status.id} key={status.id} disabled={!status.selectable}>{t(status.key)}</option>
        ))}
      </select>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default CandidateTableBody;
