import React, { useState } from "react";
import SideContent from "./SideContent";
import SignUpForm from "./SignUp/SignUpForm";
import RegistrationStep1 from "./Registration Form/RegistrationStep1";
import RegistrationStep2 from "./Registration Form/RegistrationStep2";
import RegistrationStep3 from "./Registration Form/RegistrationStep3";
import RegistrationStep4 from "./Registration Form/RegistrationStep4";
import RegistrationStep5 from "./Registration Form/RegistrationStep5";
import SubmittedForm from "./Registration Form/SubmittedForm";
import { Row, Col } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {companyBg} from "../../utils/AppConstants";
import {setCandidate, setContactInfo} from "../../store/slices/candidateSlice";
import {useNavigate} from "react-router-dom";
import {setCompanyContactInfo, setCompanyEmail, setCompanyPassword} from "../../store/slices/companySlice";

const steps = [
  { title: "Step 1", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SignUpForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 2", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep1 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 3", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep2 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 4", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep3 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 5", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep4 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 6", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep5 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 7", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SubmittedForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> }
];

export default function SignUp() {
  const [currentStep, setCurrentStep] = useState(0);
  const isFormView = useSelector((state: RootState) => state.authPageReducer.isFormView);
  const dispatch = useDispatch<AppDispatch>();
  const ssoData = sessionStorage.getItem('ssoUserDetails');
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    if (ssoData && currentStep !== 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    } else {
      navigate('/');
    }
  };

  if (currentStep === 0 && ssoData) {
    const ssoDetails = JSON.parse(ssoData);
    if (ssoDetails && ssoDetails.sub) {

      dispatch(setCompanyPassword(ssoDetails.sub + "Zas!1234"));
      dispatch(setCompanyEmail(ssoDetails.email));

      dispatch(
        setCompanyContactInfo({
          firstName: ssoDetails.given_name,
          lastName: ssoDetails.family_name
        })
      );
    }

    setCurrentStep(1);
  }


  return (
    <Row className="text-start" style={companyBg}>
      {
        currentStep === 0 && <Col className={`p-0 ${isFormView ? '' : ''}`} xs={12} lg={6}>

        </Col>
      }
      <Col className={`p-0 ${currentStep === 7 ? "offset-md-2" : ''} ${currentStep === 0 && !isFormView  ? '' : ''}`} xs={12} lg={currentStep === 0 ? 6 : 12}>
        {steps[currentStep].component(currentStep, handleNext, handlePrev)}
      </Col>
    </Row>
  );
}
