import React from 'react';
import { VacancyProps } from '../../models/vacancy-props.interface';
import { useTranslation } from 'react-i18next';
import {
  addRequestedVacancy,
  deleteRequestedVacancy,
  offerApply,
  offerUnApply,
} from '../../store/slices/candidateSlice';
import moment from 'moment';
import { setVacancyDetailsRequestStatus } from '../../store/slices/vacanciesSlice';
import Vacancy from '../../models/vacancy.interface';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import VacancyDetailsActionIcons from './VacancyDetailsActionIcons';
import {setCandidateStatus} from "../../store/slices/companyCandidatesSlice";
import {getCandidateNumberByStatus} from "../../utils/get-candidate-status-number";

const VacancyDetailsActions = ({ vacancy }: VacancyProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const candidate = useSelector((state: RootState) => state.candidateReducer);

  function addRequested(vacancy: Vacancy): void {
    if (!candidate.is_verified) {
      toast.error(t('verifyProfileMessage.askForVerify'));
      return;
    }
    dispatch(offerApply(vacancy?.id));
    const vac = { ...vacancy };
    vac.requestDate = moment(new Date()).format('DD/MM/YY');
    dispatch(addRequestedVacancy(vac));
    dispatch(setVacancyDetailsRequestStatus(true));
  }

  function removeRequested(vacancy: Vacancy): void {
    // We need to keep withdrawn offers so companies can still see who applied, so don't delete them
    //dispatch(offerUnApply(vacancy.id));
    dispatch(setCandidateStatus({offer_id: vacancy.id, candidate_id: candidate.candidate.userId, status: getCandidateNumberByStatus('withdrawn')}));
    dispatch(deleteRequestedVacancy(vacancy));
    dispatch(setVacancyDetailsRequestStatus(false));
  }

  return (
    <div className='details__actions'>
      {vacancy.apply ? (
        <button
          className='details__applicant-empty empty-button'
          onClick={() => removeRequested(vacancy)}
        >
          X&nbsp;&nbsp;&nbsp;{t('vacancyDetails.remove')}
        </button>
      ) : (
        <button
          className='details__applicant'
          onClick={() => addRequested(vacancy)}
        >
          {t('vacancyDetails.add')}
        </button>
      )}
      <div className='details__buttons tablet-hidden'>
        <VacancyDetailsActionIcons vacancy={vacancy} />
      </div>
    </div>
  );
};

export default VacancyDetailsActions;
