import { config } from '../_config/config';

export const SERVER_URL = `${config.SERVER_URL}`;

export const REFRESH_TOKEN = `${SERVER_URL}token/refresh/`;

export const USER_BASE_URL = `${SERVER_URL}account/users/`;
export const USER_ACCOUNT = `${USER_BASE_URL}me/`;

export const MESSAGE = `${SERVER_URL}message/{id}/`;

export const SOCKET_CONNECTION = `ws://127.0.0.1:8000/ws/v1/message/{id}/`;

// Company Endpoints

// Register Company
export const COMPANY_BASE_URL = `${SERVER_URL}company/`;
export const COMPANY_DETAIL = `${SERVER_URL}company/{id}/`;

// Candidate
export const CANDIDATES_BASE_URL = `${SERVER_URL}candidate`;
export const CANDIDATES_DETAIL = `${CANDIDATES_BASE_URL}/{id}/`;

// Offers
export const OFFERS_BASE_URL = `${SERVER_URL}offer/`;
export const OFFERS_DETAIL = `${OFFERS_BASE_URL}{id}/`;
export const OFFERS_COMPANY = `${OFFERS_BASE_URL}company/{id}/`;
export const OFFER_COUNT = `${OFFERS_BASE_URL}offer-count/{id}/`;

// Language 
export const LANGUAGE_BASE_URL = `${SERVER_URL}lookups/language/`;

// Admin
export const ADMIN_HOME = `${SERVER_URL}admin/`;
