import React from 'react';
import SearchWithButton from '../ui/SearchWithButton/SearchWithButton';

interface Props {
  changeSearchValue: (value: string) => void;
}

const VacanciesTableFilter = ({ changeSearchValue }: Props) => {
  return (
    <div className='d-flex align-items-center justify-content-end'>
      <div className='filter-item'>
        <SearchWithButton search={changeSearchValue} placeholder='' />
      </div>
    </div>
  );
};
export default VacanciesTableFilter;
