import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import VacancyInfo from '../../../../models/vacancy-info.interface';
import CandidateTag from '../../../../models/candidate-tag.interface';
import RegistrationTag from '../../../../components/ui/registration-tag/RegistrationTag';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  setVacancyFormCapabilities,
  setVacancyFormCapabilitiesRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Formik, Form as FormikForm } from 'formik';
import { Typeahead } from 'react-bootstrap-typeahead';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';

interface Props {
  capabilities: VacancyInfo[];
  close: () => void;
}

const CapabilitiesModal = ({ capabilities, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentSkills = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.offercapabilitie
  );
  const [selectedTags, setSelectedTags] = useState<CandidateTag[]>([]);
  const [capabilityRating, setCapabilityRating] = useState<number>(0);
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.capabilities_rating
  );
  const [hardSkillList, setHardSkillList] = useState<Option[]>(
    t('aptitudeList', {
      returnObjects: true,
    })
  );

  const initSkillList: ListItem[] = t('aptitudeList', { returnObjects: true });
  const englishInitSkillList: ListItem[] = t('aptitudeList', {
    lng: 'en',
    returnObjects: true,
  });

  function addSelectedTag(tag: CandidateTag): void {
    setSelectedTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.id === tag.id)) return prevValue;
      tag.description = getItemById(englishInitSkillList, tag.id);
      copy.push(tag);
      return copy;
    });
  }

  function removeSkillFromList(skillIndex: number): void {
    setHardSkillList((prev) => {
      const skills = [...prev];
      skills.splice(skillIndex, 1);
      return skills;
    });
  }

  function deleteSelectedTag(tag: ListItem): void {
    setSelectedTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });
  }

  useEffect(() => {
    if (capabilities.length) {
      setSelectedTags(capabilities);
    }

    let result: Option[] = t('aptitudeList', { returnObjects: true });

    currentSkills.forEach((skill) => {
      result = result.filter((aptitude) => skill.name !== aptitude);
    });

    setHardSkillList(result);
  }, []);

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.capability.description')}
      </p>

      <Formik
        initialValues={{}}
        onSubmit={(_) => {
          dispatch(setVacancyFormCapabilities(selectedTags));
          dispatch(setVacancyFormCapabilitiesRating(capabilityRating));
          dispatch(updateProgress());
          setSelectedTags([]);
          close();
        }}
      >
        {({}) => (
          <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
            <ImportanceSelect
              defaultValue={rating}
              setRating={(rate: number) => setCapabilityRating(rate)}
            />
            <Row>
              <Col md={12}>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label className='fw-bold mb-3'>
                    {t('createOffer.step2.capability.label')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Typeahead
                      id='basic-typeahead-single'
                      className='mt-2 autocomplete-modal-input w-100'
                      labelKey='name'
                      onChange={(element) => {
                        const selected: ListItem = element[0] as ListItem;
                        const selectedIndex = (
                          hardSkillList as ListItem[]
                        ).findIndex((item) => item.id === selected.id);
                        addSelectedTag(selected);
                        removeSkillFromList(selectedIndex);
                      }}
                      options={hardSkillList}
                      placeholder={t('vacancyDetails.placeholders.quality')}
                      selected={[]}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className='selected-tags'>
                  {selectedTags.length > 0 && (
                    <div className='selected-tags'>
                      {selectedTags.map((tag: CandidateTag, index: number) => {
                        const skill: ListItem = {
                          name: getItemById(initSkillList, tag.id),
                          id: tag.id || '',
                        };
                        return (
                          <RegistrationTag
                            key={index}
                            tag={skill}
                            deleteTag={deleteSelectedTag}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className='d-flex align-items-center justify-content-end mt-4'>
                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='submit'
                  >
                    {t('addButton')}
                  </button>
                </div>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default CapabilitiesModal;
