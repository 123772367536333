import React from 'react';
import { Image } from 'react-bootstrap';
import logo from '../../assets/images/job-portal/zestur-logo-internal.svg';
import contentLifecycle from '../../assets/icons/content-lifecycle.svg';
import sheet from '../../assets/icons/sheet.svg';
import eye from '../../assets/icons/eye.svg';
import { ASSETS_URL } from '../../constants';
import SideContentFeature from './SideContentFeature/SideContentFeature';
import './SideContent.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { setFormView } from '../../store/slices/authPageSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  isCompany: boolean;
}

interface InfoSection {
  title: string;
  description: string;
}

export default function SideContent({ isCompany }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;

  function createAccountButtonHandler(route: string): void {
    dispatch(setFormView(true));
    navigate(`/auth/${route}/sign-up`);
  }

  function loginButtonHandler(route: string): void {
    dispatch(setFormView(true));
    navigate(`/auth/${route}/sign-in`);
  }

  const companyInfo: InfoSection[] = t('auth.companyInfo', {
    returnObjects: true,
  });
  const candidateInfo: InfoSection[] = t('auth.candidateInfo', {
    returnObjects: true,
  });

  return (
    <div className='content'>
      <Image
        src={logoURL + logo}
        alt='logo Image'
        fluid
        className='content__logo cursor-pointer'
        onClick={() => navigate('/')}
      />
      <div className='signAbout'>
        <div className='position-relative'>
          <h2 className='mb-0'>
            {t('companyAuthSide.title.firstPart')}{' '}
            <br className='mobile-hidden' />
            {isCompany
              ? t('companyAuthSide.title.secondPart')
              : t('candidateAuthSide.title.secondPart')}
          </h2>
          <h1 className='fw-bold mb-0 tablet-hidden'>ZAS!</h1>
        </div>
        <p className='fw-normal content__subtitle'>
          {isCompany
            ? t('companyAuthSide.subtitle')
            : t('candidateAuthSide.subtitle')}
        </p>
        <SideContentFeature
          image={contentLifecycle}
          imageAlt='content lifecycle'
          title={isCompany ? companyInfo[0].title : candidateInfo[0].title}
          description={
            isCompany
              ? companyInfo[0].description
              : candidateInfo[0].description
          }
        />
        <SideContentFeature
          image={sheet}
          imageAlt='sheet'
          title={isCompany ? companyInfo[1].title : candidateInfo[1].title}
          description={
            isCompany
              ? companyInfo[1].description
              : candidateInfo[1].description
          }
        />
        <SideContentFeature
          image={eye}
          imageAlt='eye'
          title={companyInfo[2].title}
          description={
            isCompany
              ? companyInfo[2].description
              : candidateInfo[2].description
          }
        />

        <div className='no-tablet-hidden content__buttons'>
          <button
            className='w-100 primary-button p-3 btn btn-primary'
            type='button'
            onClick={() =>
              createAccountButtonHandler(isCompany ? 'company' : 'candidate')
            }
          >
            {t('auth.createButton')}
          </button>
          <button
            type='button'
            className='w-100 empty-button'
            onClick={() =>
              loginButtonHandler(isCompany ? 'company' : 'candidate')
            }
          >
            {t('auth.loginButton')}
          </button>
        </div>
      </div>
    </div>
  );
}
