import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';

interface EmailCheckState {
  emailExistsError: boolean;
}

const initialState: EmailCheckState = {
  emailExistsError: false,
};

export const checkEmail = createAsyncThunk(
  'checkEmail/check',
  async (email: string) => {
    const response = await axios.post(
      `${config.SERVER_URL}account/users/check_email/`,
      { email }
    );

    return response.data;
  }
);

const checkEmailSlice = createSlice({
  name: 'checkEmail',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(checkEmail.fulfilled, (state, action) => {
        state.emailExistsError = false;
      })

      .addCase(checkEmail.rejected, (state, action) => {
        state.emailExistsError = true;

        toast.error(i18n.t('messages.emailInUse'));
      });
  },
});

export default checkEmailSlice.reducer;
