import { configureStore } from '@reduxjs/toolkit';
import { axiosMiddleware } from '../_services/axios-interceptor';
import themeReducer from './slices/themeSlice';
import vacanciesReducer from './slices/vacanciesSlice';
import authPageReducer from './slices/authPageSlice';
import candidateReducer from './slices/candidateSlice';
import languageReducer from './slices/languageSlice';
import resetReducer from './slices/resetSlice';
import companyReducer from './slices/companySlice';
import emailCheckReducer from './slices/emailCheckSlice';
import vacancyCreateReducer from './slices/vacancyCreateSlice';
import licenseReducer from './slices/licenseSlice';
import companyCandidatesReducer from './slices/companyCandidatesSlice';
import companyVacanciesReducer from './slices/companyVacanciesSlice';
import chatReducer from './slices/chatSlice';
import authReducer from './slices/authSlice';
import emailVerificationReducer from './slices/emailVerificationSlice';
import notificationReducer from './slices/notificationSlice';
import reportReducer from './slices/reportSlice';
import modalReducer from './slices/modalSlice';
import matchDocumentReducer from './slices/matchDocumentSlice';

const store = configureStore({
  reducer: {
    themeReducer,
    vacanciesReducer,
    authPageReducer,
    candidateReducer,
    languageReducer,
    resetReducer,
    companyReducer,
    emailCheckReducer,
    vacancyCreateReducer,
    licenseReducer,
    companyCandidatesReducer,
    companyVacanciesReducer,
    chatReducer,
    authReducer,
    emailVerificationReducer,
    notificationReducer,
    reportReducer,
    modalReducer,
    matchDocumentReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['candidate/addResume'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg'],
        // Ignore these paths in the state
        ignoredPaths: ['candidateReducer.uploadedResume.0.file'],
      },
      // serializableCheck: false,
    }).concat(axiosMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
