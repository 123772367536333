import React, {ChangeEvent} from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import RegistrationActionButton from '../../../../components/RegistrationActionButton';
import logo from '../../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../../constants';
import Step from '../../../../models/step.interface';
import RegistrationBackButton from '../../../../components/ui/registration-back-arrow/RegistrationBackArrow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { object, string } from 'yup';
import {
  createCandidate,
  setContactInfo,
  setLicense,
  checkNif
} from '../../../../store/slices/candidateSlice';
import SelectLicenseForm from '../../../../components/ui/select-license-form/SelectLicenseForm';
import { useTranslation } from 'react-i18next';
import RestrictedInput from '../../../../components/ui/restricted-input/RestrictedInput';
import { validateNif } from '../../../../utils/validate-nif';
import Loader from '../../../../components/ui/loader/Loaders';
import ProgressBarTitle from '../../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../../components/mobile-progress/MobileProgress';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

function RegistrationStep1(props: Step) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const licenseList = useSelector(
    (state: RootState) => state.licenseReducer.licenseList
  );
  const isVehicle = useSelector(
    (state: RootState) => state.licenseReducer.isVehicle
  );
  const contactInfo = useSelector(
    (state: RootState) => state.candidateReducer.contactInfo
  );
  const resumeAnalysing = useSelector(
    (state: RootState) => state.candidateReducer.resumeAnalysing
  );
  const logoURL: string = `${ASSETS_URL}`;
  const navigate = useNavigate();

  const contactSchema = object({
    name: string().required(t('errors.fieldRequired')),
    lastName: string().required(t('errors.fieldRequired')),
    country: string().required(t('errors.fieldRequired')),
    city: string().required(t('errors.fieldRequired')),
    numberCode: string().required(t('errors.fillCode')),
    phoneNumber: string().required(t('errors.fieldRequired')),
    birthDate: string().required(t('errors.fieldRequired')),
    nif: string()
      .required(t('errors.fieldRequired'))
      .test('verifyIdentification', t('errors.candidateNif'), validateNif)
      .test('verifyNIF', t('errors.candidateNifExists'), function (value) {
                return new Promise((resolve, reject) => {
                  dispatch(checkNif(value))
                      .then((exists) => {
                        resolve(exists.payload == true) // false is an error, true means valid / does not exist
                    }).catch(() => {
                        resolve(true)
                    })
                })
            })
    ,
  });

  return (
    <div className='register-form'>
      {resumeAnalysing && <Loader />}
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={100}
            startStep={1}
            endStep={1}
          />
        </Col>

        <Col xs={12} md={12} lg={10} className='d-flex justify-content-center' style={{margin:"0 auto"}}>
          <div className='company-register-logo'>
            <Image src={ASSETS_URL + logo} alt='logo Image' fluid onClick={() => navigate('/')} />
          </div>
          <Formik
            initialValues={contactInfo}
            onSubmit={(values) => {
              dispatch(setContactInfo(values));
              dispatch(
                setLicense({
                  license: licenseList
                    .slice(1)
                    .filter((license) => license.selected),
                  isVehicle: isVehicle,
                })
              );
              dispatch(createCandidate()).then((result) => {
                  if (!result.hasOwnProperty('error')) {
                      // Push the event to dataLayer upon success
                      if (window.dataLayer) {
                          window.dataLayer.push({
                              'event': 'SignUp'
                          });
                      }
                    props.handleNext();
                  } else {
                    toast.error(`${t('messages.unexpectedError')}`, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                      });
                  }
              });
            }}
            validationSchema={contactSchema}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <FormikForm className='bg-blue-gradient form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={1}
                    endStep={1}
                    progressValue={100}
                  />
                  <div className='d-flex align-items-center gap-2'>
                    <RegistrationBackButton
                      data={props}
                      class='mobile-hidden'
                    />
                    <h2 className='text-main-black'>
                      {t('candidateRegistration.step1.title')}
                    </h2>
                  </div>
                </div>
                {/* form-fields */}
                <Row className='form-fields candidate h-auto' style={{padding:'2em', borderRadius: "20px"}}>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom01'
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.nameLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.name}
                              valueName='name'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.name && touched.name ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.name}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className='mobile-mt-2'
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom02'
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.surnameLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.lastName}
                              valueName='lastName'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.lastName && touched.lastName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.lastName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      className='mobile-mt-2'
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom02'
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.cityLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.city}
                              valueName='city'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.city && touched.city ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.city}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className='mobile-mt-2'
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom01'
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.regionLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.country}
                              valueName='country'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.country && touched.country ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.country}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className='align-items-end'>
                    <Form.Group
                      className='mobile-mt-2 pe-0'
                      as={Col}
                      md={1}
                      xs={3}
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.phoneLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 text-center py-3'
                          type='text'
                          placeholder='+34'
                          aria-describedby='inputGroupPrepend'
                          name='numberCode'
                        />
                        {errors.numberCode && touched.numberCode ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.numberCode}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className='mobile-mt-2' as={Col} md={5} xs={9}>
                      <Form.Label className='label'></Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <div className='w-100'>
                              <RestrictedInput
                                setFieldValue={setFieldValue}
                                fieldValue={values.phoneNumber}
                                valueName='phoneNumber'
                                restriction='number'
                              />
                            </div>
                          )}
                        </Field>
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneNumber}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>

                    <Form.Group
                      className='mobile-mt-2 pe-md-2'
                      as={Col}
                      md={3}
                      xs={12}
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.birthLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='date'
                          aria-describedby='inputGroupPrepend'
                          name='birthDate'
                        />
                        {errors.birthDate && touched.birthDate ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.birthDate}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className='mobile-mt-2 ps-md-2'
                      as={Col}
                      md={3}
                      xs={12}
                    >
                      <Form.Label className='label'>
                        {t('candidateRegistration.step1.nifLabel')} *
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 px-4 py-3'
                          type='text'
                          placeholder=''
                          aria-describedby='inputGroupPrepend'
                          name='nif'
                        />
                        {errors.nif && touched.nif ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.nif}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <SelectLicenseForm
                    licenseList={licenseList}
                    isVehicle={isVehicle}
                  />
                </Row>
                <Row className='mt-4 no-mobile-hidden'></Row>
                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                  submitDisabled={resumeAnalysing}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep1;
