/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useState } from 'react';
import {Col, Row, Form, InputGroup, Button, Image} from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { useNavigate } from 'react-router-dom';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import { checkEmail } from '../../../store/slices/emailCheckSlice';
import {backgroundImage, candidateBg, companyBg} from '../../../utils/AppConstants';
import SocialIcons from '../SocialIcons';
import { useDispatch, useSelector } from 'react-redux';
import { setFormView } from '../../../store/slices/authPageSlice';
import back from '../../../assets/icons/back.svg';
import Step from '../../../models/step.interface';
import { AppDispatch, RootState } from '../../../store/store';
import EyeIcon from '../../../components/ui/eye-icon/EyeIconWhite';
import { boolean } from 'yup';
import {
  createCompany,
  setCompanyEmail,
  setCompanyPassword,
  setTerms,
} from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { getPasswordSchema } from '../../../utils/get-password-schema';
import {ASSETS_URL} from "../../../constants";
import logo from "../../../assets/images/job-portal/zestur-logo-internal.svg";
import LanguageSelector from "../../../components/language-selector/LanguageSelector";

function SignUpForm(props: Step) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const company = useSelector((state: RootState) => state.companyReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);
  const navigate = useNavigate();

  const passSchema = getPasswordSchema();
  const validationSchema = passSchema.shape({
    terms: boolean().oneOf([true], t('errors.termsNotAccepted')),
    email: string()
      .email(t('errors.emailInvalid'))
      .required(t('errors.fieldRequired')),
  });

  return (
    <div
      className='bg-image h-100 w-100 d-flex justify-content-center align-items-center registration'
    >
      <div className='main'>
        <button
          onClick={() => navigate('/')}
          className='auth-back'
        >
          <img src={back} alt='back' />
        </button>
        <div className='form text-start'>
          <Formik
            initialValues={{
              email: company.email,
              password: company.password,
              confirmPassword: company.password,
            }}
            onSubmit={(values) => {
              dispatch(checkEmail(values.email)).then((res: any) => {
                if (!res.error) {
                  props.handleNext();
                }
              });
              dispatch(setCompanyPassword(values.password));
              dispatch(setCompanyEmail(values.email));
            }}
            validationSchema={validationSchema}
          >
            {({ errors, touched }) => (
              <FormikForm>
                  <div className='home-logo'>
                    <Image src={ASSETS_URL + logo} alt='logo Image' fluid onClick={() => navigate('/')} />
                  </div>
                  <div className='home-lang-select'>
                    <LanguageSelector />
                  </div>
                  <Row className='mt-2'>
                    <Form.Group as={Col} xs={12} controlId='validationCustom01'>
                      <Form.Label className='input-label'>
                        {t('candidateRegistration.emailLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control auth-input'
                          type='email'
                          placeholder={t(
                            'candidateRegistration.emailPlaceholder'
                          )}
                          aria-describedby='inputGroupPrepend'
                          name='email'
                        />
                        {errors.email && touched.email ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.email}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                      <Form.Label className='input-label'>
                        {t('candidateRegistration.passwordLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control auth-input'
                          type={showPassword ? 'text' : 'password'}
                          placeholder='*********'
                          aria-describedby='inputGroupPrepend'
                          name='password'
                          autoComplete='on'
                        />
                        <EyeIcon
                          isOpen={showPassword}
                          action={() => setShowPassword((prev) => !prev)}
                        />
                        {errors.password && touched.password ? (
                          <p
                            className={`text-danger error-message candidate ${
                              errors.password.length > 47 ? 'long' : null
                            }`}
                          >
                            {errors.password}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustomUsername'
                    >
                      <Form.Label className='input-label'>
                        {t('candidateRegistration.passwordConfirmLabel')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control auth-input'
                          type={showConfirmationPassword ? 'text' : 'password'}
                          placeholder='*********'
                          aria-describedby='inputGroupPrepend'
                          name='confirmPassword'
                          autoComplete='on'
                        />
                        <EyeIcon
                          isOpen={showConfirmationPassword}
                          action={() =>
                            setShowConfirmationPassword((prev) => !prev)
                          }
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <p
                            className={`text-danger error-message candidate ${
                              errors.confirmPassword?.length > 47
                                ? 'long'
                                : null
                            }`}
                          >
                            {errors.confirmPassword}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Button className='w-100 submitbtn p-3 company' type='submit'>
                    {t('registrationForm.title')}
                  </Button>
                  <p className='confrom text-center small'>
                    {t('registrationForm.subtitle')} {}
                    <span>
                      <a
                        className='cursor-pointer'
                        onClick={() => navigate('/')}
                      >
                        {t('registrationForm.loginUrl')}
                      </a>
                    </span>
                  </p>
                  <p>&nbsp;</p>
                </FormikForm>
            )}
          </Formik>
        </div>
        {/*
        <h3>{t('registrationForm.registrationProposal')}</h3>
        <SocialIcons />
        */}
      </div>
    </div>
  );
}

export default SignUpForm;
