import React from 'react';
import OffersStepProps from '../../../models/offer-step.interface';
import { useTranslation } from 'react-i18next';

interface Props extends Partial<OffersStepProps> {
  isLastStep?: boolean;
  type?: 'submit' | 'button';
}
const OfferStepActions = ({
  currentStep,
  goToPreviousStep,
  goToNextStep,
  isLastStep = false,
  type = 'submit',
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex align-items-center mt-5'>
      <button
        type='button'
        className='btn btn-outline-primary btn-outline-color w-100 py-3 me-2'
        onClick={goToPreviousStep}
        disabled={currentStep === 0}
      >
        {t('createOffer.cancelButton')}
      </button>
      {type === 'submit' ? (
        <button
          type={type}
          className='btn btn-primary primary-btn-color w-100 py-3'
        >
          {isLastStep
            ? t('createOffer.finalSubmit')
            : t('createOffer.submitButton')}
        </button>
      ) : (
        <button
          type={type}
          className='btn btn-primary primary-btn-color w-100 py-3'
          onClick={goToNextStep}
        >
          {t('createOffer.submitButton')}
        </button>
      )}
    </div>
  );
};

export default OfferStepActions;
