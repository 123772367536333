import React, { useEffect, useState } from 'react';
import FilterTag from '../../../../components/filter-tag/FilterTag';
import './HomeFilter.scss';
import HomeFilterButton from '../../../../components/home-filter-button/HomeFilterButton';
import HomeSortButton from '../../../../components/home-sort-button/HomeSortButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  filterVacancies,
  toggleVacanciesTagSelection,
  uncheckVacanciesTags,
} from '../../../../store/slices/vacanciesSlice';
import Option from '../../../../models/option.interface';
import { useTranslation } from 'react-i18next';

const HomeFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isTagSelected, setIsTagSelected] = useState(false);
  const tagList = useSelector(
    (state: RootState) => state.vacanciesReducer.tagList
  );

  const sortOptions = useSelector(
    (state: RootState) => state.vacanciesReducer.sortOptions
  );

  useEffect(() => {
    setIsTagSelected(tagList.some((tag) => tag.selected));
    dispatch(filterVacancies());
  }, [dispatch, tagList]);

  function uncheckAllTags(): void {
    dispatch(uncheckVacanciesTags());
  }

  function toggleSelectedTag(selectedOption: Option): void {
    dispatch(toggleVacanciesTagSelection(selectedOption));
  }

  return (
    <div className='filter-row d-flex align-items-center flex-wrap'>
      <HomeFilterButton tagList={tagList} selectOption={toggleSelectedTag} />
      <div className='filter-selected mobile-hidden'>
        <div className='d-flex tag-container'>
          {tagList.map((tag) => {
            if (!tag.selected) return;

            if (isTagSelected === false) {
              setIsTagSelected(true);
            }

            return (
              <FilterTag
                uncheckTag={toggleSelectedTag}
                key={tag.name}
                icon={tag.icon}
                name={tag.name}
              />
            );
          })}
        </div>

        {isTagSelected && (
          <button className='clean-button' onClick={() => uncheckAllTags()}>
            {t('candidateHomePage.filter.clearButton')}
          </button>
        )}
      </div>

      <div className='no-mobile-hidden'>
        <HomeSortButton />
      </div>
    </div>
  );
};

export default HomeFilter;
