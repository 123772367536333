import { useEffect } from 'react';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const useCheckVerified = (profile: 'company' | 'candidate') => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const company = useSelector((state: RootState) => state.companyReducer);

  useEffect(() => {
    if (profile === 'company') {
      checkCompanyVerified();
    }

    if (profile === 'candidate') {
      checkCandidateVerified();
    }
  }, []);

  const checkCompanyVerified = () => {
    if (company.companyId && !company.is_verified) {
      error();
      navigate(-1);
    }
  };

  const checkCandidateVerified = () => {
    if (candidate.candidate.userId && !candidate.is_verified) {
      error();
      navigate(-1);
    }
  };

  const error = () => {
    toast.error(t('verifyProfileMessage.askForVerify'));
  };
};

export default useCheckVerified;
