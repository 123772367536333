import Language from '../../models/language.interface';

export function getLanguageTranslation(
  lang: Language,
  localization: string
): string {
  switch (localization) {
    case 'en':
      return lang.language_en || '';
    case 'fr':
      return lang.language_fr || '';
    case 'sp':
      return lang.language_es || '';
    case 'pt':
      return lang.language_pr || '';
    default:
      return lang.language_en || '';
  }
}
