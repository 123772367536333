import React from 'react';
import Vacancy from '../../models/vacancy.interface';
import warningIcon from '../../assets/icons/warning.svg';
import checkmarkIcon from '../../assets/icons/checkmark-with-background.svg';
import timelineIcon from '../../assets/icons/timeline.svg';
import styles from './VacanciesTableMobile.module.scss';
import { useTranslation } from 'react-i18next';
import eyeIcon from '../../assets/icons/eye-svgrepo-com.svg';
import editIcon from '../../assets/icons/edit-svgrepo-com.svg';
import TableActionButton from '../ui/table-action-button/TableActionButton';

interface Props {
  vacancies: Vacancy[];
  handleCheck: (id: string) => void;
}

const VacanciesTableMobile = ({ vacancies, handleCheck }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.vacancyCardContainer}>
      {vacancies.map((vacancy: Vacancy, index: number) => {
        return (
          <div
            className={`${styles.vacancyCard} ${
              vacancy.checked ? styles.vacancyCardActive : ''
            }`}
            onClick={() => {
              handleCheck(vacancy.id);
            }}
            key={index}
          >
            <h6 className='text-main-black'>{vacancy.position}</h6>
            <p>{vacancy.company.name}</p>
            <p>
              #{' '}
              {t(
                vacancy.number_of_vacancies === 1
                  ? 'vacant.singular'
                  : 'vacant.plural'
              )}{' '}
              {vacancy.number_of_vacancies}
            </p>
            <div>
              <p className='text-main-black'>{vacancy.location}</p>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
              <div>
                <TableActionButton
                  customClass='p-1 me-2'
                  url={vacancy.id + '/detail'}
                  icon={eyeIcon}
                />
                <TableActionButton
                  customClass='p-1'
                  url={vacancy.id + '/edit'}
                  icon={editIcon}
                />
              </div>

              <img
                width={16}
                height={16}
                src={
                  vacancy.status === 'Publish'
                    ? checkmarkIcon
                    : vacancy.status === 'Inactive'
                    ? warningIcon
                    : vacancy.status === 'Draft'
                    ? timelineIcon
                    : warningIcon
                }
                alt=''
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default VacanciesTableMobile;
