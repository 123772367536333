import React, { useEffect, useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import RegistrationLanguageSelect from '../registration-language-select/RegistrationLanguageSelect';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  addLanguage,
  editLanguage,
  updateCandidate,
} from '../../../store/slices/candidateSlice';
import './CandidateLanguageModal.scss';
import { getLanguages } from '../../../store/slices/languageSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  index: number;
  handleClose: () => void;
}

const CandidateLanguageModal = ({ index = -1, show, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const languageOptions = useSelector(
    (state: RootState) => state.languageReducer.languages
  );
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedLevel, setSelectedLevel] = useState(+'');

  useEffect(() => {
    if (index >= 0) {
      setSelectedLanguage(candidate.languages[index].language);
      setSelectedLevel(candidate.languages[index].level);
    } else {
      setSelectedLanguage('');
      setSelectedLevel(+'');
    }
  }, [show]);

  useEffect(() => {
    if (!languageOptions.length) {
      dispatch(getLanguages());
    }
  }, []);

  function selectLanguage(event: React.ChangeEvent<HTMLSelectElement>): void {
    setSelectedLanguage(event.target.value);
  }

  function selectLevel(event: React.ChangeEvent<HTMLSelectElement>): void {
    setSelectedLevel(+event.target.value);
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('languageForm.languageTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{}}
          onSubmit={(_) => {
            if (index >= 0) {
              dispatch(
                editLanguage({
                  index: index,
                  language: {
                    id: candidate.languages[index].id || '',
                    language: selectedLanguage,
                    level: selectedLevel,
                    user: candidate.candidate.userId,
                  },
                })
              );
            } else {
              dispatch(
                addLanguage({
                  language: selectedLanguage,
                  level: selectedLevel,
                  user: candidate.candidate.userId,
                })
              );
            }

            dispatch(updateCandidate());
            handleClose();
          }}
        >
          {() => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row className='language-form'>
                <RegistrationLanguageSelect
                  languages={languageOptions}
                  index={index}
                  lang={selectedLanguage}
                  level={selectedLevel}
                  withHeaders={true}
                  selectLanguage={selectLanguage}
                  selectLevel={selectLevel}
                />
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateLanguageModal;
