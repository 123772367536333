import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ASSETS_URL } from '../../../constants';
import { Image } from 'react-bootstrap';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import EditButton from '../edit-button/EditButton';
import { useTranslation } from 'react-i18next';

interface Props {
  openInfoModal: () => void;
}

const CandidateEditInfoSectionMobile = ({ openInfoModal }: Props) => {
  const { t } = useTranslation();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const [isShowFullCard, setIsShowFullCard] = useState(false);

  return (
    <div className='zestur-card no-mobile-hidden mb-4 text-main-black'>
      <div className='d-flex justify-content-end'>
        <EditButton action={openInfoModal} index={0}></EditButton>
      </div>
      <div className='candidate-profile'>
        <h2>{t('candidateInfoComponent.mobileTitle')}</h2>
        <Image
          className='candidate-profile__avatar object-fit-cover'
          src={candidate.avatar || candidateImage}
        />
      </div>
      <div className='mt-4 mb-2'>
        {isShowFullCard && (
          <>
            {candidate.seniorityLevel && (
              <>
                <h5 className='fw-semibold'>
                  {t('candidateInfoComponent.seniority')}
                </h5>
                <p>{candidate.seniorityLevel}</p>
              </>
            )}
            {candidate.description?.length > 1 && (
              <h5 className='fw-semibold mt-4'>
                {t('candidateInfoComponent.description')}
              </h5>
            )}
          </>
        )}
        {candidate.description?.length > 1 && <p>{candidate.description}</p>}
        {isShowFullCard && (
          <>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.email')}
            </h5>
            <p className='text-break'>{candidate.candidate.email}</p>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.phone')}
            </h5>
            <p>{candidate.contactInfo.phone}</p>
            <h5 className='fw-semibold mt-4'>
              {t('candidateInfoComponent.location')}
            </h5>
            <p>{candidate.address}</p>
          </>
        )}
      </div>
      <div className='detail-expand'>
        <button onClick={() => setIsShowFullCard((prevValue) => !prevValue)}>
          {isShowFullCard
            ? t('candidateInfoComponent.mobileHide')
            : t('candidateInfoComponent.mobileShow')}
        </button>
      </div>
    </div>
  );
};

export default CandidateEditInfoSectionMobile;
