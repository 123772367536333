import { Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setVacancyFormEducation,
  setVacancyFormEducationRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import { useTranslation } from 'react-i18next';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';
import { RootState } from '../../../../store/store';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import { Typeahead } from 'react-bootstrap-typeahead';
import CandidateTag from '../../../../models/candidate-tag.interface';
import RegistrationTag from '../../../../components/ui/registration-tag/RegistrationTag';
import { getEducationList } from '../../../../utils/get-education-list';
import { OfferEducation } from '../../../../models/offer-education.interface';
import { getCurrentLevelEducationName } from '../../../../utils/get-current-level-education-name';

interface Props {
  education: OfferEducation;
  close: () => void;
}

const TrainingModal = ({ education, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [trainingRating, setTrainingRating] = useState<number>(0);
  const [selectedLevel, setSelectedLevel] = useState<string>(education.level);
  const [selectedTags, setSelectedTags] = useState<CandidateTag[]>([]);
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.training_rating
  );
  const levelList: ListItem[] = t('educationLevelList', {
    returnObjects: true,
  });
  const englishLevelList: ListItem[] = t('educationLevelList', {
    lng: 'en',
    returnObjects: true,
  });

  useEffect(() => {
    setSelectedTags([]);
  }, [selectedLevel]);

  useEffect(() => {
    if (education.name.length) {
      const tags: CandidateTag[] = [];

      education.name.forEach((nameId, index) => {
        tags.push({
          id: nameId,
          description: education.name_description[index],
          name: getCurrentLevelEducationName(education.level, nameId, ''),
        });
      });

      setSelectedTags(tags);
    }
  }, []);

  function addSelectedTag(tag: CandidateTag): void {
    setSelectedTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.id === tag.id)) return prevValue;
      tag.description = getCurrentLevelEducationName(
        selectedLevel,
        tag.id || '',
        'en'
      );
      copy.push(tag);
      return copy;
    });
  }

  function deleteSelectedTag(tag: ListItem): void {
    setSelectedTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });
  }

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.education.description')}
      </p>

      <Row>
        <Col md={12}>
          <Formik
            initialValues={{
              level: education.level,
              name: education.name,
            }}
            onSubmit={(values) => {
              const educationIds: string[] = [];
              const descriptions: string[] = [];

              selectedTags.forEach((item) => {
                educationIds.push(item.id || '');
                descriptions.push(item.description || '');
              });

              dispatch(
                setVacancyFormEducation(
                  values.level
                    ? [
                        {
                          level: values.level,
                          level_description: getItemById(
                            englishLevelList,
                            values.level
                          ),
                          name: educationIds,
                          name_description: descriptions,
                        },
                      ]
                    : []
                )
              );

              dispatch(setVacancyFormEducationRating(trainingRating));
              dispatch(updateProgress());
              close();
            }}
          >
            {({ values }) => (
              <FormikForm>
                <ImportanceSelect
                  defaultValue={rating}
                  setRating={(rate: number) => setTrainingRating(rate)}
                />
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold mb-3'>
                    {t('vacancyDetails.subtitles.education')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      as='select'
                      name='level'
                      className='form-control modal-input'
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        values.level = event.target.value;
                        setSelectedLevel(event.target.value);
                      }}
                    >
                      <option value=''></option>
                      {levelList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                  </InputGroup>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Label className='fw-bold mb-2'>
                    {t('createOffer.step2.education.label')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Typeahead
                      id='basic-typeahead-single'
                      className='mt-2 autocomplete-modal-input w-100'
                      labelKey='name'
                      onChange={(element) => {
                        const selected: ListItem = element[0] as ListItem;
                        addSelectedTag(selected);
                      }}
                      options={getEducationList(values.level, '')}
                      placeholder=''
                      selected={[]}
                      disabled={!values.level}
                    />
                  </InputGroup>
                </Form.Group>
                <Col md={12}>
                  {selectedTags?.length > 0 && (
                    <div className='selected-tags'>
                      {selectedTags.map((tag: CandidateTag, index: number) => {
                        const localId = tag.id || tag.name;
                        const skill: ListItem = {
                          name: getCurrentLevelEducationName(
                            values.level,
                            localId,
                            ''
                          ),
                          id: localId,
                        };

                        return (
                          <RegistrationTag
                            key={index}
                            tag={skill}
                            deleteTag={deleteSelectedTag}
                          />
                        );
                      })}
                    </div>
                  )}
                </Col>
                <div className='d-flex align-items-center justify-content-end mt-4'>
                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='submit'
                  >
                    {t('addButton')}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default TrainingModal;
