import React, { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import './CandidateLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  getCandidate,
  setCandidateExistsError,
  setCandidateTokenExhausted,
} from '../../store/slices/candidateSlice';
import { setTokenExhausted } from '../../store/slices/authSlice';
import { setCompanyTokenExhausted } from '../../store/slices/companySlice';
import { getNotifications } from '../../store/slices/notificationSlice';
import { checkIfCandidateToken } from '../../utils/check-if-candidate-token';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import Header from '../../components/shared/header/Header';
import VerificationWarning from '../../components/ui/verification-warning/VerificationWarning';

const CandidateLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const candidateTokenExhausted = useSelector(
    (state: RootState) => state.candidateReducer.tokenExhausted
  );
  const tokenExhausted = useSelector(
    (state: RootState) => state.authReducer.tokenExhausted
  );
  const candidateDoNotExists = useSelector(
    (state: RootState) => state.candidateReducer.candidateDoNotExists
  );

  useEffect(() => {
    if (!checkIfCandidateToken()) {
      dispatch(setCandidateExistsError(true));
      //toast.error(i18n.t('messages.candidateNotExists'));
      return;
    }

    dispatch(getCandidate());
    dispatch(getNotifications());
  }, [dispatch]);

  // useEffect(() => {
  //   if (!candidateTokenExhausted) return;
  //   dispatch(refreshToken());
  // }, [candidateTokenExhausted]);

  useEffect(() => {
    return () => {
      dispatch(setTokenExhausted(false));
      dispatch(setCandidateExistsError(false));
      dispatch(setCompanyTokenExhausted(false));
      dispatch(setCandidateTokenExhausted(false));
    };
  }, []);

  useEffect(() => {
    console.log('tokenExhausted', tokenExhausted);
    if (tokenExhausted) {
      navigate('/auth/candidate/sign-in');
    }
  }, [tokenExhausted]);

  useEffect(() => {
    console.log('candidateDoNotExists', candidateDoNotExists);
    if (candidateDoNotExists) {
      navigate('/auth/candidate/sign-in');
    }
  }, [candidateDoNotExists]);

  useEffect(() => {
    console.log('candidateTokenExhausted', candidateTokenExhausted);
    if (candidateTokenExhausted) {
      navigate('/auth/candidate/sign-in');
    }
  }, [candidateTokenExhausted]);

  useEffect(() => {
    const company = searchParams.get('company');
    if (company) {
      sessionStorage.setItem('searchedCompany', company);
    }
  }, []);

  return (
    <>
      {candidate.candidate.userId && !candidate.is_verified && (
        <VerificationWarning userEmail={candidate.candidate.email} />
      )}
      <div className='app-wrapper'>
        <Header isCandidate={true} />
        <div className='zestur-candidate-wrapper'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default CandidateLayout;
