import React, { useState, useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import { ASSETS_URL } from '../../../constants';
import ChatBox from '../../../components/chat-box/ChatBox';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import ChatCandidateList from '../../../components/chat-candidate-list/ChatCandidateList';
import { getCandidatesChatList } from '../../../store/slices/chatSlice';
import { ChatCandidate } from '../../../models/chat-candidate.interface';

const ChatCompany = () => {
  const dispatch = useDispatch<AppDispatch>();
  const assignedUsers = useSelector(
    (state: RootState) => state.chatReducer.candidateList
  );
  const company = useSelector((state: RootState) => state.companyReducer);
  const [candidates, setCandidates] = useState<ChatCandidate[]>([]);
  const [currentUser, setCurrentUser] = useState<ChatCandidate | null>(null);

  useEffect(() => {
    dispatch(getCandidatesChatList());
  }, []);

  useEffect(() => {
    setCandidates(assignedUsers);
  }, [assignedUsers]);

  const selectedUser = (e: any) => {
    if (e) {
      setCurrentUser(e);
    }
  };

  const loadMore = (e: any) => {
    // setPage((prevPage: number) => {
    //   const newPage = prevPage + 1;
    //   // fetchUsers(newPage, searchValue);
    //   return newPage;
    // });
  };

  const onSearch = (value: string) => {
    setCandidates(() =>
      assignedUsers.filter((item) =>
        item.candidate_first_name
          .concat(item.candidate_last_name)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  return (
    <div className='zestur-card p-0'>
      <Row>
        <Col md={4}>
          <ChatCandidateList
            companyName={company.name}
            candidates={candidates}
            userClick={selectedUser}
            loadMore={loadMore}
            search={onSearch}
          />
        </Col>
        <Col md={8}>
          <div className='cb-user-crr fw-semibold mb-4'>
            {currentUser ? (
              <>
                <Image
                  src={
                    ASSETS_URL +
                    (currentUser.candidate_avatar || candidateImage)
                  }
                  className='object-fit-cover'
                />
                {currentUser.candidate_first_name}{' '}
                {currentUser.candidate_last_name} - {currentUser.offer_title}
              </>
            ) : null}
          </div>
          {currentUser ? (
            <ChatBox
              currentUserAvatar={company.logo}
              dialogPersonAvatar={currentUser.candidate_avatar}
              dialogPersonName={
                currentUser.candidate_first_name +
                ' ' +
                currentUser.candidate_last_name
              }
              loggedUserName={company.name}
              userId={currentUser.candidate_id}
            />
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default ChatCompany;
