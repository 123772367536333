// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VacanciesTableMobile_vacancyCardContainer__UoL84 {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr); }

.VacanciesTableMobile_vacancyCard__u2oDy {
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #213867; }
  .VacanciesTableMobile_vacancyCard__u2oDy h6 {
    height: 40px;
    font-weight: 600; }
  .VacanciesTableMobile_vacancyCard__u2oDy p {
    color: #a7a7a7; }

.VacanciesTableMobile_vacancyCardActive__4-CJZ {
  background-color: #f3f3f3 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/vacancies-tablemobile/VacanciesTableMobile.module.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,WAAW;EACX,qCAAqC,EAAA;;AAGvC;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,yBCViB,EAAA;EDMnB;IAOI,YAAY;IACZ,gBCMgB,EAAA;EDdpB;IAWI,cCXe,EAAA;;ADenB;EACE,oCAAyC,EAAA","sourcesContent":["@import '../../assets/scss/variable';\n\n.vacancyCardContainer {\n  display: grid;\n  gap: 0.5rem;\n  grid-template-columns: repeat(2, 1fr);\n}\n\n.vacancyCard {\n  padding: 10px;\n  cursor: pointer;\n  border-radius: 10px;\n  border: 1px solid $dark-blue;\n\n  h6 {\n    height: 40px;\n    font-weight: $font-semi-bold;\n  }\n  p {\n    color: $graylight;\n  }\n}\n\n.vacancyCardActive {\n  background-color: $white-smoke !important;\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n\n// Redesign 2025\n$main-grey: #FAFAF4;\n$candidate: #4EC6DB;\n$candidate-border: #011640;\n$company: #D5006D;\n$company-border: #AE085A;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vacancyCardContainer": `VacanciesTableMobile_vacancyCardContainer__UoL84`,
	"vacancyCard": `VacanciesTableMobile_vacancyCard__u2oDy`,
	"vacancyCardActive": `VacanciesTableMobile_vacancyCardActive__4-CJZ`
};
export default ___CSS_LOADER_EXPORT___;
