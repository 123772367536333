// src/components/ui/tab-content/TabContent.tsx
import React from 'react';

interface TableProps {
  headings: string[];
  data: string[][];
}

const Table: React.FC<TableProps> = ({ headings, data }) => {
  return (
    <table>
      <thead>
        <tr>
          {headings.map((heading, index) => (
            <th key={index}>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
