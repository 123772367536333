import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form as FormikForm } from 'formik';
import VacancyInfo from '../../../../models/vacancy-info.interface';
import { AppDispatch, RootState } from '../../../../store/store';
import CandidateTag from '../../../../models/candidate-tag.interface';
import { Option } from 'react-bootstrap-typeahead/types/types';
import RegistrationTag from '../../../../components/ui/registration-tag/RegistrationTag';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  setVacancyFormQualities,
  setVacancyFormQualitiesRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';

interface Props {
  qualities: VacancyInfo[];
  close: () => void;
}

const QualitiesModal = ({ qualities, close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTags, setSelectedTags] = useState<CandidateTag[]>([]);
  const [qualitiesRating, setQualitiesRating] = useState<number>(0);
  const [skillList, setSkillList] = useState<Option[]>(
    t('aptitudeList', { returnObjects: true })
  );
  const currentSkills = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.offerqualities
  );
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.qualities_rating
  );
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });
  const englishAptitudeList: ListItem[] = t('aptitudeList', {
    lng: 'en',
    returnObjects: true,
  });

  function deleteSelectedTag(tag: ListItem): void {
    setSelectedTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });

    setSkillList((prev) => {
      const skills = [...prev];
      skills.push(tag);
      (skills as ListItem[]).sort((a, b) => a.name.localeCompare(b.name));
      return skills;
    });
  }

  function addSelectedTag(tag: CandidateTag): void {
    setSelectedTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.id === tag.id)) return prevValue;
      tag.description = getItemById(englishAptitudeList, tag.id);
      copy.push(tag);
      return copy;
    });
  }

  function removeSkillFromList(skillIndex: number): void {
    setSkillList((prev) => {
      const skills = [...prev];
      skills.splice(skillIndex, 1);
      return skills;
    });
  }

  useEffect(() => {
    if (qualities.length) {
      setSelectedTags(qualities);
    }

    let result: Option[] = t('aptitudeList', { returnObjects: true });

    currentSkills.forEach((skill) => {
      result = result.filter((aptitude) => skill.name !== aptitude);
    });

    setSkillList(result);
  }, []);

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.quality.description')}
      </p>

      <Row>
        <Col md={12}>
          <Formik
            initialValues={{}}
            onSubmit={(_) => {
              dispatch(setVacancyFormQualities(selectedTags));
              dispatch(setVacancyFormQualitiesRating(qualitiesRating));
              dispatch(updateProgress());
              setSelectedTags([]);
              close();
            }}
          >
            {({}) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                <ImportanceSelect
                  defaultValue={rating}
                  setRating={(rate: number) => setQualitiesRating(rate)}
                />
                <Row>
                  <Form.Group as={Col} className='mb-3'>
                    <Form.Label className='fw-bold mb-3'>
                      {t('createOffer.step2.quality.label')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Typeahead
                        id='basic-typeahead-single'
                        className='mt-2 autocomplete-modal-input w-100'
                        labelKey='name'
                        onChange={(element) => {
                          const selected: ListItem = element[0] as ListItem;
                          const selectedIndex = (
                            skillList as ListItem[]
                          ).findIndex((item) => item.id === selected.id);

                          addSelectedTag(selected);
                          removeSkillFromList(selectedIndex);
                        }}
                        options={skillList}
                        placeholder={t('vacancyDetails.placeholders.quality')}
                        selected={[]}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                {selectedTags.length > 0 && (
                  <div className='selected-tags'>
                    {selectedTags.map((tag: CandidateTag, index: number) => {
                      const skill: ListItem = {
                        name: getItemById(aptitudeList, tag.id),
                        id: tag.id || '',
                      };
                      return (
                        <RegistrationTag
                          key={index}
                          tag={skill}
                          deleteTag={deleteSelectedTag}
                        />
                      );
                    })}
                  </div>
                )}
                <div className='d-flex align-items-center justify-content-end mt-4'>
                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='submit'
                  >
                    {t('addButton')}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default QualitiesModal;
