import React, { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Image, Spinner } from 'react-bootstrap';
import RegistrationActionButton from '../../../../components/RegistrationActionButton';
import logo from '../../../../assets/images/job-portal/zestur-logo-internal.svg';
import fileUpload from '../../../../assets/images/login/FileUplode.png';
import { ASSETS_URL } from '../../../../constants';
import './form-utils.scss';
import RegistrationBackButton from '../../../../components/ui/registration-back-arrow/RegistrationBackArrow';
import Step from '../../../../models/step.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { Formik, Form } from 'formik';
import {
  addResume,
  sendResumeToAI,
  setResumeAnalysing,
} from '../../../../store/slices/candidateSlice';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../components/ui/loader/Loaders';
import { getLanguages } from '../../../../store/slices/languageSlice';
import {useNavigate} from "react-router-dom";

function RegistrationStepFirst(props: Step) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [file, setFile] = useState<File | null>(null);
  const uploadedFileName = useSelector(
    (state: RootState) => state.candidateReducer.uploadedCVName
  );
  const resumeAnalysing = useSelector(
    (state: RootState) => state.candidateReducer.resumeAnalysing
  );
  const logoURL: string = `${ASSETS_URL}`;
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const selectedFile: File[] = Array.from(e.target.files);
      setFile(selectedFile[0]);
    }
  };

  const handleFileDrop = (files: File[]) => {
    dispatch(setResumeAnalysing(true));
    dispatch(sendResumeToAI(files[0]));
    setFile(files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      props.handleNext();
      return;
    }

    dispatch(addResume({ file: file }));
    props.handleNext();
    setFile(null);
  };

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  return (
    <div className='bg-blue-gradient register-form'>
      {resumeAnalysing && <Loader />}
      <Row className=''>
        <Col xs={12} className='no-mobile-hidden'>
          <div className='register-mobile-header__background'>
            <div className='register-mobile-header'>
              <RegistrationBackButton
                data={props}
                title={t('registrationForm.steps.backButton')}
                white={true}
                class=''
              />
            </div>
          </div>
        </Col>
        <Col xs={12} md={12} lg={12} className='d-flex justify-content-center'>
          <div className='company-register-logo'>
            <Image src={ASSETS_URL + logo} alt='logo Image' fluid onClick={() => navigate('/')} />
          </div>
          <Formik
            initialValues={{}}
            onSubmit={() => {
              handleUpload();
            }}
          >
            <Form className='form w-100 d-flex flex-column'>
              <Dropzone
                onDrop={(acceptedFiles: File[]) => {
                  handleFileDrop(acceptedFiles);
                }}
                accept={{
                  'application/pdf': ['.pdf'],
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className='file-upload'>
                    <h2 className='upload-heading-main mobile-hidden'>
                      {t('candidateRegistration.step0.dropzoneTitle')}
                    </h2>
                    <h2 className='upload-heading-main no-mobile-hidden'>
                      {t('candidateRegistration.step0.dropzoneTitleMobile')}
                    </h2>
                    <p>{t('candidateRegistration.step0.dropzoneSubtitle')}</p>
                    <div className='fileuploaddata w-100' {...getRootProps()}>
                      <label>
                        <Image
                          className='FileUplodeImg object-fit-cover'
                          src={logoURL + fileUpload}
                          alt='logo Image'
                          fluid
                        />
                      </label>
                      {(file || uploadedFileName) && (
                        <ul>
                          <li>{file?.name || uploadedFileName}</li>
                        </ul>
                      )}
                      <input
                        type='file'
                        id='upload-button'
                        style={{ display: 'none' }}
                        onChange={handleChange}
                        {...getInputProps()}
                      />
                    </div>
                    <p>
                      {t('candidateRegistration.step0.dropzoneDescription')}{' '}
                      <label className='cursor-pointer' {...getRootProps()}>
                        <span>
                          {t('candidateRegistration.step0.dropzoneUrl')}
                        </span>
                      </label>
                    </p>
                  </div>
                )}
              </Dropzone>
              <RegistrationActionButton
                data={props}
                isSubmit={true}
              ></RegistrationActionButton>
            </Form>
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStepFirst;
