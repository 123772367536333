// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vacancies-table-filter {
  display: grid;
  column-gap: 0.5rem;
  grid-template-columns: repeat(4, 1fr); }
  @media (max-width: 600px) {
    .vacancies-table-filter {
      grid-template-columns: repeat(2, 1fr); } }
`, "",{"version":3,"sources":["webpack://./src/views/company/vacancies/VacanciesCompany.scss","webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,qCAAqC,EAAA;ECFnC;IDDJ;MAMI,qCAAqC,EAAA,EAExC","sourcesContent":["@import '../../../assets/scss/breakpoint/breakpoints-mixins';\n\n.vacancies-table-filter {\n  display: grid;\n  column-gap: 0.5rem;\n  grid-template-columns: repeat(4, 1fr);\n\n  @include mobile-breakpoint {\n    grid-template-columns: repeat(2, 1fr);\n  }\n}\n","@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
