import tokenService from "../_services/token.service";
import axios from "axios";
import {config} from "../_config/config";
import {toast} from "react-toastify";

export async function submitPaymentAddress(
      first_name: string,
      last_name: string,
      email: string,
      address: string,
      city: string,
      state: string,
      postcode: string,
      country_code: string,
      plan: string
  ) {

    const token = tokenService.getLocalAccessToken();

    try {
      const response = await axios.post(`${config.SERVER_URL}pay/bill-address/`, {
            first_name: first_name,
            last_name: last_name,
            email: email,
            address: address,
            city: city,
            state: state,
            postcode: postcode,
            country_code: country_code
          },
          {
            headers: {
              Authorization: 'Bearer ' + token,
            }
          });

      if (response.data.body.id) {
        const payresponse = await axios.post(`${config.SERVER_URL}pay/payment`, {
              payment_plan: plan,
              bill_address_id: response.data.body.id
            },
            {
              headers: {
                Authorization: 'Bearer ' + token,
              }
            });

        if (payresponse.data.body.url) {
          const frm = document.getElementById('paymentForm');
          Object.keys(payresponse.data.body.parameters).forEach(key =>
              {
                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.name = key;
                hiddenInput.value = payresponse.data.body.parameters[key];
                if (frm) {
                  frm.appendChild(hiddenInput);
                  // @ts-ignore
                  frm.action = payresponse.data.body.url;
                }
              }
          );

          // @ts-ignore
          frm && frm.submit();
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("error");
    }
  };

