import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import './RegistrationMobileProgress.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number;
  step: number;
  maxStep: number;
}

const RegistrationMobileProgress = ({ value, step, maxStep }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='mobile-progress d-flex flex-column justify-content-end'>
      <ProgressBar now={value} />
      <h5>
        {t('registrationForm.steps.progressSubtitle.firstPart')} {step}{' '}
        {t('registrationForm.steps.progressSubtitle.secondPart')} {maxStep}
      </h5>
    </div>
  );
};

export default RegistrationMobileProgress;
