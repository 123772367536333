import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { Field, Formik, Form as FormikForm } from 'formik';
import { getLanguages } from '../../../../store/slices/languageSlice';
import LanguageOption from '../../../../models/language-option.interface';
import {
  setVacancyFormLanguage,
  setVacancyFormLanguageRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import Language from '../../../../models/language.interface';
import CandidateTag from '../../../../models/candidate-tag.interface';
import RegistrationTag from '../../../../components/ui/registration-tag/RegistrationTag';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getLocalizedLanguageOption } from '../../../../utils/languages-switch/get-localized-language-option';
import { getSortedLanguages } from '../../../../utils/languages-switch/get-sorted-languages';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';

interface Props {
  language: Language[];
  close: () => void;
}

const LanguagesModal = ({ language, close }: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const languages = useSelector(
    (state: RootState) => state.languageReducer.languages
  );
  const [selectedTags, setSelectedTags] = useState<CandidateTag[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Language[]>([]);
  const [languagesRating, setLanguagesRating] = useState<number>(0);
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.language_rating
  );
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });

  useEffect(() => {
    if (!languages.length) {
      dispatch(getLanguages());
    }

    if (language.length) {
      language.map((lang) => {
        setSelectedTags((prev) => {
          const copy = [...prev];
          copy.push({
            name: getLanguageString(lang),
            id: lang.language || lang.language_id,
          });
          return copy;
        });
      });

      setSelectedLanguages(language);
    }
  }, []);

  function deleteSelectedTag(tag: ListItem): void {
    setSelectedTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });

    setSelectedLanguages((prev) => {
      return prev.filter((item) => item.language !== tag.id);
    });
  }

  function addTag(language: Language): void {
    const alreadyExists = selectedLanguages.some(
      (item) => item.language === language.language
    );

    if (alreadyExists) return;

    setSelectedTags((prev) => {
      const copy = [...prev];
      copy.push({ name: getLanguageString(language), id: language.language });
      return copy;
    });

    setSelectedLanguages((prev) => {
      const copy = [...prev];
      copy.push(language);
      return copy;
    });
  }

  function getLanguageString(language: Language): string {
    const languageName: string =
      languages.find((item) => item.id === language.language)?.name || '';
    const levelString: string =
      levels.find((item) => item.id === language.level.toString())?.name || '';
    return languageName + ': ' + levelString;
  }

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.language.description')}
      </p>
      <Row>
        <Col md={12}>
          <Formik
            initialValues={{
              level: 0,
              language: '',
            }}
            onSubmit={(values) => {
              dispatch(setVacancyFormLanguage(selectedLanguages));
              dispatch(setVacancyFormLanguageRating(languagesRating));
              dispatch(updateProgress());
              close();
            }}
          >
            {({ values, errors, touched }) => (
              <FormikForm>
                <ImportanceSelect
                  defaultValue={rating}
                  setRating={(rate: number) => setLanguagesRating(rate)}
                />
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label className='fw-bold mb-3'>
                    {t('vacancyDetails.labels.language')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      name='language'
                      as='select'
                      className='form-control modal-input cursor-pointer'
                      aria-label='Default select example'
                    >
                      <option value=''>
                        {t('languageForm.languageOption')}
                      </option>
                      {languages
                        ? getSortedLanguages([...languages], i18n.language).map(
                            (lang: LanguageOption, index: number) => (
                              <option value={lang.id} key={index}>
                                {getLocalizedLanguageOption(
                                  lang,
                                  i18n.language
                                )}
                              </option>
                            )
                          )
                        : null}
                    </Field>
                    {errors.language && touched.language ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.language}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  className='mb-3'
                  controlId='exampleForm.ControlInput1'
                >
                  <Form.Label className='fw-bold mb-3'>
                    {t('vacancyDetails.labels.languageLevel')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      name='level'
                      as='select'
                      className='form-control modal-input cursor-pointer'
                      aria-label='Default select example'
                    >
                      <option value=''>{t('languageForm.levelOption')}</option>
                      {levels.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>
                    {errors.level && touched.level ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.level}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>

                <div className='selected-tags'>
                  {selectedTags.map((tag: CandidateTag, index: number) => {
                    return (
                      <RegistrationTag
                        key={index}
                        tag={tag as ListItem}
                        deleteTag={deleteSelectedTag}
                      />
                    );
                  })}
                </div>
                <div className='d-flex align-items-center justify-content-end mt-4 gap-2'>
                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='button'
                    onClick={() => {
                      if (!values.language || !values.level) return;
                      addTag(values);
                    }}
                  >
                    {t('addButton')}
                  </button>

                  <button
                    className='btn btn-primary primary-btn-color px-5'
                    type='submit'
                  >
                    {t('submitButton')}
                  </button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default LanguagesModal;
