// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ShareButton_dropdown__5u\\+Qy {
  right: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/share-button/ShareButton.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA","sourcesContent":[".dropdown {\n  right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `ShareButton_dropdown__5u+Qy`
};
export default ___CSS_LOADER_EXPORT___;
