import LanguageOption from '../../models/language-option.interface';

export function getLocalizedLanguageOption(
  option: LanguageOption,
  lang: string
): string {
  switch (lang) {
    case 'en':
      return option.name;
    case 'fr':
      return option.fr_name || '';
    case 'sp':
      return option.es_name || '';
    case 'pt':
      return option.pr_name || '';
    default:
      return option.name;
  }
}
