// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qr-logo {
  width: 42px;
  height: 42px;
  top: 50%;
  left: 50%;
  box-sizing: content-box;
  border: 5px solid #213867;
  transform: translateX(-50%) translateY(-50%); }
`, "",{"version":3,"sources":["webpack://./src/components/ui/company-qr-modal/CompanyQRModal.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,SAAS;EACT,uBAAuB;EACvB,yBCNiB;EDQjB,4CAA4C,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.qr-logo {\n  width: 42px;\n  height: 42px;\n  top: 50%;\n  left: 50%;\n  box-sizing: content-box;\n  border: 5px solid $dark-blue;\n  -webkit-transform: translateX(-50%) translateY(-50%);\n  transform: translateX(-50%) translateY(-50%);\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
