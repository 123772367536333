import React from 'react';

import './ProfileCompany.scss';
import { useTranslation } from 'react-i18next';
import icDone from '../../../assets/images/ic_done.svg';
import {ASSETS_URL} from "../../../constants";
import {Image} from "react-bootstrap";

const ProfilePlansSuccess = () => {
  const { t } = useTranslation();

  return (
  <>
        <div className='zestur-card'>
          <div className='d-flex justify-content-evenly'>
            <Image className='mt-5' src={ASSETS_URL + icDone} />
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <div className='general-heading d-flex justify-content-evenly'>
            {t('payments.success')}
          </div>
          <p>&nbsp;</p>
          <div className='d-flex justify-content-evenly'>
            Thank you for your purchase.
          </div>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
    </>
  );
};

export default ProfilePlansSuccess;
