import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import tokenService from '../../_services/token.service';
import { config } from '../../_config/config';
import { decodeToken } from '../../models/decode-token';

interface notificationState {
  notificationMenuOpen: boolean;
  newNotificationComes: boolean;
  notifications: any[];
}

const initialState: notificationState = {
  notificationMenuOpen: false,
  newNotificationComes: false,
  notifications: [],
};

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async () => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);

    const response = await axios.get(
      `${config.SERVER_URL}account/users/notification/${
        decodedToken?.company_id || decodedToken?.user_id
      }/`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotificationMenuState: (state, action) => {
      state.notificationMenuOpen = action.payload;
    },

    addNotification: (state, action) => {
      state.notifications.unshift(action.payload);
    },

    setNewNotificationComes: (state, action) => {
      state.newNotificationComes = action.payload;
    },
    toggleNotificationMenuState: (state) => {
      if (!state.notificationMenuOpen) {
        state.newNotificationComes = false;
      }
      state.notificationMenuOpen = !state.notificationMenuOpen;
    },
  },

  extraReducers(builder) {
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export const {
  setNotificationMenuState,
  toggleNotificationMenuState,
  addNotification,
  setNewNotificationComes,
} = notificationSlice.actions;
export default notificationSlice.reducer;
