import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';

interface BreadcrumbProps {
  to: string;
  label: string;
  last: boolean;
}

interface Props {
  names: string[];
  segments: string[];
}

const Breadcrumb = ({ to, label, last }: BreadcrumbProps) => (
  <span>
    <Link className={styles.breadcrumbLink} to={to}>
      {label} {!last && ' > '}
    </Link>
  </span>
);

const Breadcrumbs = ({ names, segments }: Props) => {
  return (
    <div className={styles.container}>
      {names.map((name: string, index: number) => {
        return (
          <Breadcrumb
            key={index}
            to={`/${segments.slice(0, index + 1).join('/')}`}
            label={name}
            last={names.length === index + 1}
          />
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
