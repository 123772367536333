import React from 'react';
import { TableColumn } from '../../models/table-column.interface';
import sortIcon from '../../assets/icons/basil-sort.svg';

interface Props {
  columns: TableColumn[];
  selectAll: boolean;
  checkAll: () => void;
  uncheckAll: () => void;
}

const CandidateTableHead = ({
  columns,
  selectAll,
  checkAll,
  uncheckAll,
}: Props) => {
  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th key={`${column.key}`}>
            {column.checkbox && (
              <>
                <div className='zestur-check'>
                  <input
                    className='checkbox'
                    type='checkbox'
                    id={`checkbox-${index}`}
                    name='checkbox'
                    checked={selectAll}
                    onChange={selectAll ? uncheckAll : checkAll}
                  />
                  <label htmlFor={`checkbox-${index}`} className='px-3'></label>
                </div>
              </>
            )}
            {column.header}
            {column.sortFunction && (
              <img
                width={22}
                src={sortIcon}
                className='blue-filter cursor-pointer'
                alt='sort'
                onClick={column.sortFunction}
              ></img>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default CandidateTableHead;
