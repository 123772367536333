import React from 'react';
import RegistrationCardActions from '../registration-card-actions/RegistrationCardActions';
import DotsButton from '../dots-button/DotsButton';
import Education from '../../../models/education.interface';
import { useTranslation } from 'react-i18next';
import { defaultNotSelectedDate } from '../../../constants/constants';

interface Props {
  index: number;
  education: Education;
  editHandler: (index: number) => void;
  deleteHandler: (index: number) => void;
  withEdit?: boolean;
}

const CandidateEducationCard = ({
  education,
  index,
  editHandler,
  deleteHandler,
  withEdit = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='info-section mb-4'>
      <div className='ms-1 info-section-title d-flex justify-content-between'>
        {education.title}
        {withEdit && (
          <>
            <div className='actions mobile-hidden'>
              <RegistrationCardActions
                edit={editHandler}
                deleteCard={deleteHandler}
                index={index}
              />
            </div>
            <div className='no-mobile-hidden'>
              <DotsButton
                edit={editHandler}
                deleteHandler={deleteHandler}
                index={index}
              />
            </div>
          </>
        )}
      </div>
      <div className='d-flex align-items-center justify-content-start'>
        <div className='ms-1'>
          <div className='d-flex align-items-center mt-3'>
            <div className='info-section-sub-title'>
              {education.institution}
            </div>
            <div className='info-section-date ms-5 data-gray'>
              {t('certificationCard.year')}:{' '}
              {education.end_date === defaultNotSelectedDate
                ? ''
                : education.end_date?.substring(0, 4)}
            </div>
          </div>
        </div>
      </div>
      <div className='ps-1 mt-3 mb-4'>
        <div className='info-section-description'>{education.description}</div>
      </div>
    </div>
  );
};

export default CandidateEducationCard;
