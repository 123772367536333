import React from 'react';
import { Col, Row, InputGroup, Form as RbForm, Button } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface Props {
  handleSubmit: (email: string) => void;
  changeShowState: () => void;
}

const ForgotPasswordForm = ({ handleSubmit, changeShowState }: Props) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('errors.emailInvalid'))
      .required(t('errors.emailRequired')),
  });

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values.email);
      }}
    >
      <Form className='form'>
        <h2 className='register text-center'>{t('resetPasswordForm.title')}</h2>
        <p className='confrom text-center'>
          {t('resetPasswordForm.subtitle')} {}
          <span className='cursor-pointer' onClick={changeShowState}>
            {t('registrationForm.loginUrl')}
          </span>
        </p>
        <Row className='mt-2 text-start mb-5'>
          <RbForm.Group as={Col} xs={12} controlId='validationCustom01'>
            <RbForm.Label className='input-label'>
              {t('resetPasswordForm.emailLabel')}
            </RbForm.Label>
            <InputGroup>
              <Field
                className='form-control auth-input'
                type='text'
                placeholder={t('resetPasswordForm.emailPlaceholder')}
                aria-describedby='inputGroupPrepend'
                name='email'
              />
            </InputGroup>
            <ErrorMessage
              name='email'
              component='p'
              className='text-danger position-absolute error-message'
            />
          </RbForm.Group>
        </Row>

        <Button className='w-100 primary-button p-3' type='submit'>
          {t('resetPasswordForm.submitButton')}
        </Button>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
