// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Breadcrumbs_container__b6K9O {
  display: flex;
  flex-wrap: wrap;
  gap: 6px; }

.Breadcrumbs_breadcrumbLink__mkaY7 {
  white-space: nowrap;
  text-decoration: none;
  color: black;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs/Breadcrumbs.module.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ,EAAA;;AAGV;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EAEZ,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,gBCMkB;EDLlB,mBAAmB,EAAA","sourcesContent":["@import '../../assets/scss/variable';\n\n.container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 6px;\n}\n\n.breadcrumbLink {\n  white-space: nowrap;\n  text-decoration: none;\n  color: black;\n\n  font-family: 'Open Sans';\n  font-size: 16px;\n  font-style: normal;\n  font-weight: $font-semi-bold;\n  line-height: normal;\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Breadcrumbs_container__b6K9O`,
	"breadcrumbLink": `Breadcrumbs_breadcrumbLink__mkaY7`
};
export default ___CSS_LOADER_EXPORT___;
