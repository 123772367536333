import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import './VacancyDetails.scss';
import {
  getVacancyDetails,
  resetVacancyDetails,
  setVacancyDetailsSave,
} from '../../store/slices/vacanciesSlice';
import VacancyMatchButton from '../ui/vacancy-match/VacancyMatchButton';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';
import { getSavedOffers } from '../../store/slices/candidateSlice';
import { useTranslation } from 'react-i18next';
import VacancyDetailsSkills from './VacancyDetailsSkills';
import VacancyDetailsExperience from './VacancyDetailsExperience';
import VacancyDetailsLanguage from './VacancyDetailsLanguage';
import VacancyDetailsInfo from './VacancyDetailsInfo';
import VacancyDetailsActions from './VacancyDetailsActions';
import VacancyDetailsActionIcons from './VacancyDetailsActionIcons';
import VacancyDetailsEducation from './VacancyDetailsEducation';
import VacancyDetailsLicense from './VacancyDetailsLicense';
import VacancyDetailsSalary from './VacancyDetailsSalary';

const VacancyDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const vacanciesReducer = useSelector(
    (state: RootState) => state.vacanciesReducer
  );
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const vacancy = useSelector(
    (state: RootState) => state.vacanciesReducer.vacancyDetails
  );

  useEffect(() => {
    if (!candidate.savedOffers.length) {
      dispatch(getSavedOffers());
    }

    return () => {
      dispatch(resetVacancyDetails());
    };
  }, []);

  useEffect(() => {
    function updateVacancySaveState() {
      candidate.savedOffers.forEach((saved) => {
        if (saved.offer === vacancy?.id) {
          dispatch(setVacancyDetailsSave(true));
        }
      });
    }

    if (candidate.savedOffersReceived && vacancy) {
      updateVacancySaveState();
    }
  }, [
    candidate.savedOffersReceived,
    vacanciesReducer.vacancyDetailsReceived,
    vacancy?.id,
  ]);

  useEffect(() => {
    const vacancyPathId = location.pathname.split('/').at(-1);

    if (!vacancyPathId) return;

    dispatch(getVacancyDetails(vacancyPathId));
  }, [location, navigate]);

  return (
    <>
      {vacancy && (
        <div className='details position-relative'>
          {vacancy.company.cover ? (
            <img
              src={vacancy.company.cover}
              alt='preview'
              className='w-100 details__image'
            />
          ) : (
            <div className='w-100 details__image'></div>
          )}

          <div className='details__general d-flex justify-content-between'>
            <VacancyDetailsInfo vacancy={vacancy} />
            <VacancyDetailsActions vacancy={vacancy} />
          </div>

          <div className='details__content'>
            <VacancyInfo
              title={`${t('vacancyDetails.labels.description')}:`}
              info={vacancy.description}
            />
            <VacancyDetailsSalary vacancy={vacancy} />

            <div className='details__info-container'>
              <h4>{t('vacancyDetails.infoTitle')}</h4>
              <div className='d-flex flex-column'>
                <VacancyDetailsExperience vacancy={vacancy} />
                <VacancyDetailsLanguage vacancy={vacancy} />
                <VacancyDetailsSkills vacancy={vacancy} />
                <VacancyDetailsLicense vacancy={vacancy} />
                {vacancy.training?.[0] && (
                  <VacancyDetailsEducation vacancy={vacancy} />
                )}

                {vacancy.company.address && (
                  <div className='details__info'>
                    <h5>{t('vacancyDetails.subtitles.location')}</h5>
                    <div>
                      <VacancyInfo
                        title={`${t('vacancyDetails.labels.location')}:`}
                        info={vacancy.company.address}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='details__actions-mobile'>
            <div className='details__buttons'>
              <div>
                <VacancyDetailsActionIcons vacancy={vacancy} />
              </div>
              {vacancy.match && <VacancyMatchButton match={vacancy.match} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VacancyDetails;
