export const getVacancyNumberByStatus = (status: string) => {
  switch (status) {
    case 'Draft':
      return 1;
    case 'Publish':
      return 2;
    case 'Inactive':
      return 3;
    default:
      return 1;
  }
};
