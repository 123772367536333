import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import tokenService from '../../_services/token.service';

interface VerificationState {
  withMessage: boolean;
}

const initialState: VerificationState = {
  withMessage: false,
};

export const verificateEmail = createAsyncThunk(
  'emailVerification/post',
  async ({ token, uid }: { token: string; uid: string }) => {
    const response = await axios.post(
      `${config.SERVER_URL}account/users/activation/`,
      { token, uid }
    );

    return response.data;
  }
);

export const resendVerificationEmail = createAsyncThunk(
  'emailVerification/resend',
  async (email: string) => {
    let currentLanguage = localStorage.getItem('lang');
    if (!currentLanguage || currentLanguage == 'sp') {
      currentLanguage = 'es';
    } else if (currentLanguage == 'pt') {
      currentLanguage = 'pr';
    }

    const token = tokenService.getLocalAccessToken();
    const response = await axios.post(
      `${config.SERVER_URL}account/users/resend_activation/?current_language=` + currentLanguage,
      {
        email,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

const emailVerificationSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setEmailVerification: (state, action) => {
      state.withMessage = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(verificateEmail.fulfilled, (state) => {
        state.withMessage = true;
      })
      .addCase(verificateEmail.rejected, (state) => {
        state.withMessage = true;
      })

      .addCase(resendVerificationEmail.fulfilled, () => {
        toast.success(i18n.t('messages.emailVerify'));
      });
  },
});

export const { setEmailVerification } = emailVerificationSlice.actions;
export default emailVerificationSlice.reducer;
