import React from 'react';
import { VacancyProps } from '../../models/vacancy-props.interface';
import { Link } from 'react-router-dom';
import VacancyMatchButton from '../ui/vacancy-match/VacancyMatchButton';
import logoPlaceholder from '../../assets/icons/avatar-placeholder.svg';

const VacancyDetailsInfo = ({ vacancy }: VacancyProps) => {
  return (
    <div className='position-relative d-flex gap-3 align-items-center'>
      <div
        className={`details__logo-container${
          vacancy.match ? '' : ' without-match'
        } position-absolute object-fit-cover rounded-circle`}
      >
        <img
          src={vacancy.company.logo || logoPlaceholder}
          alt='logo'
          className={`details__logo object-fit-cover rounded-circle`}
        />
      </div>
      <div className='details__title d-flex flex-column gap-2'>
        <p>{vacancy.position}</p>
        <p className='d-flex align-items-center gap-2'>
          <Link to={'location'} className='darkblue-link'>
            {vacancy.company.name}
          </Link>{' '}
          <span className='details__location mobile-hidden'>
            {vacancy.location}
          </span>
        </p>
        {vacancy.match && (
          <VacancyMatchButton hideClass='tablet-hidden' match={vacancy.match} />
        )}
      </div>
    </div>
  );
};

export default VacancyDetailsInfo;
