import React from 'react';
import './Pagination.scss';
import firstBtnIcon from '../../assets/icons/chevron-double-left.svg';
import lastBtnIcon from '../../assets/icons/chevron-double-right.svg';
import prevBtnIcon from '../../assets/icons/chevron-left.png';
import nextBtnIcon from '../../assets/icons/Chevron-right.svg';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../constants';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  changeCurrentPage: (page: number) => void;
  pageSize: number;
  withAmountSelection?: boolean;
  setAmount?: (value: number) => void;
  withTitle?: boolean;
  total?: number;
}

const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  changeCurrentPage,
  pageSize,
  withTitle = false,
  withAmountSelection = false,
  setAmount,
  total = 0,
}) => {
  const handlePageChange = (page: number) => {
    changeCurrentPage(page);
  };

  const handlePreviousSet = () => {
    if (currentPage > 1) {
      changeCurrentPage(currentPage - 1);
    }
  };

  const handleNextSet = () => {
    if (currentPage < totalPages) {
      changeCurrentPage(currentPage + 1);
    }
  };

  const handleFirst = () => {
    changeCurrentPage(1);
  };

  const handleLast = () => {
    changeCurrentPage(totalPages);
  };

  return (
    <div className='pagination'>
      {withTitle && (
        <span>
          Mostrando {pageSize} elementos de {total}
        </span>
      )}
      <button
        className={`page-item pagination__button${
          currentPage === 1 ? ' deactivated' : ''
        }`}
        onClick={handleFirst}
        disabled={currentPage === 1}
      >
        <Image src={ASSETS_URL + firstBtnIcon} />
      </button>
      <button
        className={`page-item pagination__button${
          currentPage === 1 ? ' deactivated' : ''
        }`}
        onClick={handlePreviousSet}
        disabled={currentPage === 1}
      >
        <Image src={ASSETS_URL + prevBtnIcon} />
      </button>
      {[currentPage - 1, currentPage, currentPage + 1].map(
        (pageNumber: number) => {
          return (
            pageNumber > 0 &&
            pageNumber <= totalPages && (
              <button
                key={pageNumber}
                className={`page-item ${
                  currentPage === pageNumber ? 'active' : ''
                }`}
                onClick={() => handlePageChange(pageNumber)}
                disabled={currentPage === pageNumber}
              >
                {pageNumber}
              </button>
            )
          );
        }
      )}
      <button
        className={`page-item pagination__button${
          currentPage === totalPages ? ' deactivated' : ''
        }`}
        onClick={handleNextSet}
        disabled={currentPage === totalPages}
      >
        <Image src={ASSETS_URL + nextBtnIcon} />
      </button>
      <button
        className={`page-item pagination__button${
          currentPage === totalPages ? ' deactivated' : ''
        }`}
        onClick={handleLast}
        disabled={currentPage === totalPages}
      >
        <Image src={ASSETS_URL + lastBtnIcon} />
      </button>
      {withAmountSelection && (
        <select
          value={pageSize}
          onChange={(e) => {
            if (!setAmount) return;
            setAmount(+e.target.value);
          }}
        >
          <option value='10'>10</option>
          <option value='20'>20</option>
          <option value='30'>30</option>
        </select>
      )}
    </div>
  );
};

export default Pagination;
