import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import CandidateViewInfoSection from '../../../components/ui/Candidate-view-info-section/CandidateViewInfoSection';

const HomeCandidate = () => {
  return (
    <>
      <Row>
        <Col md={3}>
          <CandidateViewInfoSection />
        </Col>
        <Col md={9}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default HomeCandidate;
