import React, { useEffect, useState } from 'react';

import './VacanciesCompany.scss';
import VacanciesTableFilter from '../../../components/vacancies-table-filter/VacanciesTableFilter';
import VacanciesTable from '../../../components/vacancies-table/VacanciesTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import Vacancy from '../../../models/vacancy.interface';
import moment from 'moment';
import {
  activateVacancies,
  deactivateVacancies,
  deleteVacancies,
  getCompanyVacancies,
} from '../../../store/slices/companyVacanciesSlice';
import TableFilterButton from '../../../components/ui/table-filter-button/TableFilterButton';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../models/list-item';
import { getItemById } from '../../../utils/get-item-by-id';

const VacanciesCompany = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const vacancies = useSelector(
    (state: RootState) => state.companyVacanciesReducer.companyVacancies
  );
  const companyVacanciesData = useSelector(
    (state: RootState) => state.companyVacanciesReducer
  );
  const [displayedVacancies, setDisplayedVacancies] = useState<Vacancy[]>([]);

  const [nameSortDescending, setNameSortDescending] = useState<boolean>(true);
  const [sectorSortDescending, setSectorSortDescending] =
    useState<boolean>(true);
  const [citySortDescending, setCitySortDescending] = useState<boolean>(true);
  const [vacancyNumbersSortDescending, setVacancyNumbersSortDescending] =
    useState<boolean>(true);
  const [dateSortDescending, setDateSortDescending] = useState<boolean>(true);
  const [statusSortDescending, setStatusSortDescending] =
    useState<boolean>(true);
  const [activeFilter, setActiveFilter] = useState<number>(0);
  const filterButton: string[] = t('vacanciesTablet.filters', {
    returnObjects: true,
  });
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });
  const [lastSort, setLastSort] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [maxAmount, setMaxAmount] = useState<number>(10);

  useEffect(() => {
    dispatch(
      getCompanyVacancies({
        pageNumber: 1,
        status: activeFilter,
        searchValue,
        size: maxAmount,
      })
    );
  }, []);

  useEffect(() => {
    setDisplayedVacancies(vacancies);
    setIsLoading(false);
  }, [vacancies]);

  const columns = [
    {
      key: 'position',
      header: t('vacanciesTablet.columns.name'),
      checkbox: true,
      limit: 20,
      sortFunction: sortByName,
    },
    {
      key: 'sector',
      header: t('vacanciesTablet.columns.sector'),
      limit: 20,
      sortFunction: sortBySector,
    },
    {
      key: 'location',
      header: t('vacanciesTablet.columns.location'),
      limit: 20,
      sortFunction: sortByCity,
    },
    {
      key: 'number_of_vacancies',
      header: t('vacanciesTablet.columns.vacanciesCount'),
      sortFunction: sortByVacancyNumbers,
    },
    {
      key: 'end_date',
      header: t('vacanciesTablet.columns.endDate'),
      sortFunction: sortByEndDate,
    },
    {
      key: 'status',
      header: t('vacanciesTablet.columns.status'),
      sortFunction: sortByStatus,
    },
    { key: 'action', header: '', type: 'action' },
  ];

  function sortByName(): void {
    getSortedVacancies(nameSortDescending ? 'name' : '-name');
    setNameSortDescending((prev) => !prev);
  }

  function sortBySector(): void {
    getSortedVacancies(sectorSortDescending ? 'sector' : '-sector');
    setSectorSortDescending((prev) => !prev);
  }

  function sortByCity(): void {
    getSortedVacancies(citySortDescending ? 'city' : '-city');
    setCitySortDescending((prev) => !prev);
  }

  function sortByVacancyNumbers(): void {
    getSortedVacancies(
      vacancyNumbersSortDescending ? 'vacancies' : '-vacancies'
    );
    setVacancyNumbersSortDescending((prev) => !prev);
  }

  function sortByEndDate(): void {
    getSortedVacancies(dateSortDescending ? 'exp_date' : '-exp_date');
    setDateSortDescending((prev) => !prev);
  }

  function sortByStatus(): void {
    getSortedVacancies(statusSortDescending ? 'status' : '-status');
    setStatusSortDescending((prev) => !prev);
  }

  function getSortedVacancies(sortString: string): void {
    setPageNumber(1);
    setLastSort(sortString);
    dispatch(
      getCompanyVacancies({
        pageNumber: 1,
        status: activeFilter,
        searchValue,
        size: maxAmount,
        orderBy: sortString,
      })
    );
  }

  function changeSearchValue(value: string): void {
    setSearchValue(value);

    dispatch(
      getCompanyVacancies({
        pageNumber: 1,
        status: activeFilter,
        searchValue: value || '',
        size: maxAmount,
      })
    );
  }

  function handleRowCheck(id: string): void {
    setDisplayedVacancies((prev) => {
      const copy: Vacancy[] = JSON.parse(JSON.stringify(prev));
      const selectedElementIndex = copy.findIndex((item) => item.id === id);
      copy[selectedElementIndex].checked = !copy[selectedElementIndex].checked;

      return copy;
    });
  }

  function uncheckAll(): void {
    setDisplayedVacancies((prev) => {
      const copy: Vacancy[] = JSON.parse(JSON.stringify(prev));
      copy.map((item) => (item.checked = false));
      return copy;
    });
    setSelectAll(false);
  }

  function checkAll(): void {
    setDisplayedVacancies((prev) => {
      const copy: Vacancy[] = JSON.parse(JSON.stringify(prev));
      copy.map((item) => (item.checked = true));
      return copy;
    });
    setSelectAll(true);
  }

  function deleteChecked(): void {
    const checkedIds: string[] = getCheckedIds();

    if (checkedIds.length) {
      dispatch(deleteVacancies(checkedIds));
    }
  }

  function deactivateChecked(): void {
    const checkedIds: string[] = getCheckedIds();

    if (checkedIds.length) {
      dispatch(deactivateVacancies(checkedIds));
    }
  }

  function activateChecked(): void {
    const checkedIds: string[] = getCheckedIds();

    if (checkedIds.length) {
      dispatch(activateVacancies(checkedIds));
    }
  }

  function getCheckedIds(): string[] {
    return displayedVacancies
      .filter((item) => item.checked)
      .map((item) => item.id);
  }

  function getStatusesCount(index: number): number {
    switch (index) {
      case 0:
        return companyVacanciesData.total;
      case 1:
        return companyVacanciesData.active;
      case 2:
        return companyVacanciesData.pending;
      case 3:
        return companyVacanciesData.inactive;
      default:
        return companyVacanciesData.total;
    }
  }

  function getVacanciesFilteredByStatus(status: string): Vacancy[] {
    return vacancies.filter((vacancy) => vacancy.status === status);
  }

  function showAll(): void {
    setDisplayedVacancies(vacancies);
    uncheckAll();
    updateFilterState(1, 0);
  }

  function onlyDraftFilter(): void {
    setDisplayedVacancies(getVacanciesFilteredByStatus('Draft'));
    uncheckAll();
    updateFilterState(1, 1);
  }

  function onlyActiveFilter(): void {
    setDisplayedVacancies(getVacanciesFilteredByStatus('Publish'));
    uncheckAll();
    updateFilterState(1, 2);
  }

  function onlyInactiveFilter(): void {
    setDisplayedVacancies(getVacanciesFilteredByStatus('Inactive'));
    uncheckAll();
    updateFilterState(1, 3);
  }

  function updateFilterState(page: number, status: number): void {
    dispatch(
      getCompanyVacancies({
        pageNumber: page,
        status: status,
        searchValue: searchValue,
        size: maxAmount,
      })
    );
    setPageNumber(page);
    setActiveFilter(status);
  }

  const filterByStatusFunctions = [
    showAll,
    onlyActiveFilter,
    onlyDraftFilter,
    onlyInactiveFilter,
  ];

  function setPageNumberHandler(page: number): void {
    setPageNumber(page);
    setSelectAll(false);
  }

  function setMaxAmountHandler(value: number): void {
    setMaxAmount(value);
    uncheckAll();
    setPageNumber(1);
    dispatch(
      getCompanyVacancies({
        pageNumber: 1,
        status: activeFilter,
        searchValue,
        size: value,
      })
    );
  }

  return (
    <div className='zestur-card'>
      <div className='vacancies-table-filter mt-3'>
        {filterButton.map((item: string, index: number) => {
          const itemNumber: number = index === 1 ? 2 : index === 2 ? 1 : 0;

          return (
            <TableFilterButton
              key={item}
              title={item}
              isActive={(itemNumber || index) === activeFilter}
              count={getStatusesCount(index)}
              handleClick={filterByStatusFunctions[index]}
            />
          );
        })}
      </div>
      <div className='mt-4'>
        <VacanciesTableFilter changeSearchValue={changeSearchValue} />
      </div>
      <VacanciesTable
        lastSort={lastSort}
        maxAmount={maxAmount}
        setAmount={setMaxAmountHandler}
        searchValue={searchValue}
        pageNumber={pageNumber}
        setPageNumberHandler={setPageNumberHandler}
        activeFilter={activeFilter}
        isLoading={isLoading}
        deleteChecked={deleteChecked}
        deactivateChecked={deactivateChecked}
        activateChecked={activateChecked}
        vacancies={displayedVacancies}
        columns={columns}
        handleRowCheck={handleRowCheck}
        uncheckAll={uncheckAll}
        checkAll={checkAll}
        selectAll={selectAll}
      />
    </div>
  );
};

export default VacanciesCompany;
