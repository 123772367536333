import moment from 'moment';
import { defaultNotSelectedDate } from '../constants/constants';
import Experience from '../models/experience.interface';
import { getYear } from './get-year';

export function getTableExperienceYears(experience: Experience): number {
  const startExperience = experience;
  const endExperience = experience;

  let endDate = endExperience.end_date;
  if (endExperience.end_date === defaultNotSelectedDate) {
    endDate = moment(new Date()).format('YYYY-MM-DD');
  }

  // If the candidate has 2001-2001 then they have 1yr experience
  const endYear = getYear(endDate);
  const startYear = getYear(startExperience.start_date);
  if (startYear === endYear) {
    return 1;
  }

  return endYear - startYear;
}
