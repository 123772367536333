import React, { useEffect } from 'react';
import { useOpenNotificationWebsocket } from '../hook/useOpenNotificationWebsocket';

interface Props {
  children: React.JSX.Element;
}

const WithNotificationWebsocket = ({ children }: Props) => {
  const notificationWebsocket = useOpenNotificationWebsocket();

  useEffect(() => {
    return () => {
      if (notificationWebsocket) {
        notificationWebsocket.disconnect();
      }
    };
  }, [notificationWebsocket]);

  return children;
};

export default WithNotificationWebsocket;
