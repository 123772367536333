import React from 'react';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { HeaderIconAction } from '../../../models/header-action-icon.interface';

interface Props {
  item: HeaderIconAction;
}

const HeaderActionIcon = ({ item }: Props) => {
  const newNotificationComes = useSelector(
    (state: RootState) => state.notificationReducer.newNotificationComes
  );

  return (
    <div
      className={`zestur-header__links-item ${
        item.alt === 'notification' && newNotificationComes
          ? 'withRedDot position-relative'
          : ''
      }`}
      onClick={item.action}
    >
      <Image
        src={ASSETS_URL + item.icon}
        alt={item.alt}
        className={item.className || ''}
      />
    </div>
  );
};

export default HeaderActionIcon;
