import React from 'react';
import { useTranslation } from 'react-i18next';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';
import { VacancyProps } from '../../models/vacancy-props.interface';

const VacancyDetailsExperience = ({ vacancy }: VacancyProps) => {
  const { t } = useTranslation();

  return (
    <div className='details__info'>
      <h5>{t('vacancyDetails.subtitles.experience')}</h5>
      {vacancy.experience.map((experience, index) => (
        <div key={index}>
          <VacancyInfo
            title={`${t('vacancyDetails.labels.experience')}:`}
            info={experience.position}
          />
          <VacancyInfo
            title={`${t('vacancyDetails.labels.experienceAmount')}:`}
            info={experience.duration}
          />
        </div>
      ))}
    </div>
  );
};

export default VacancyDetailsExperience;
