import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tokenService from '../../_services/token.service';
import axios from 'axios';
import { config } from '../../_config/config';
import { ChatCandidate } from '../../models/chat-candidate.interface';
import { ChatCompany } from '../../models/chat-company.interface';

interface chatState {
  candidateList: ChatCandidate[];
  companyList: ChatCompany[];
}

const initialState: chatState = {
  candidateList: [],
  companyList: [],
};

export const getCandidatesChatList = createAsyncThunk(
  'candidate/getCandidatesChatList',
  async () => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + 'candidate/company_candidates/',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const getCompaniesChatList = createAsyncThunk(
  'candidate/getCompaniesChatList',
  async () => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.get(
      config.SERVER_URL + 'candidate/companies/',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getCandidatesChatList.fulfilled, (state, action) => {
        state.candidateList = action.payload.body.data;
      })

      .addCase(getCompaniesChatList.fulfilled, (state, action) => {
        state.companyList = action.payload.body.data;
      });
  },
});

export const {} = chatSlice.actions;
export default chatSlice.reducer;
