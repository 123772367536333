import React from 'react';

import apply from '../../../assets/icons/apply.svg';
import './ApplyButton.scss';
import Vacancy from "../../../models/vacancy.interface";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

interface Props {
  applied?: boolean;
  vacancy: Vacancy;
  toggleApply: (vacancy: Vacancy, applied: boolean) => void;
}

const ApplyButton = ({ applied, vacancy, toggleApply }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <button
      className={`apply-btn${applied ? ' activated' : ''} tablet-hidden`}
      onClick={(event) => {
          navigate(`/candidate/${vacancy.id}`);
        // to make this actually apply, replace above with below
        //event.stopPropagation();
        //toggleApply(vacancy, !applied);
      }}
      title={applied ? t('vacancyDetails.remove') : t('vacancyDetails.add')}
    >
      <img
        src={apply}
        alt='apply'
        className={applied ? 'white-filter' : 'grey-filter'}
      />
    </button>
  );
};

export default ApplyButton;
