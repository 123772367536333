export const readUploadedFile = (file: File, setFunction: Function) => {
  const reader = new FileReader();

  reader.onload = (event: any) => {
    // Set the file and its preview to the state
    setFunction({
      file,
      preview: event.target.result,
    });
  };

  // Read the file as a data URL
  reader.readAsDataURL(file);
};
