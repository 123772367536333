import React from 'react';
import './AddButton.scss';
import plus from '../../../assets/icons/plus-circle.svg';

interface Props {
  text: string;
  handleClick: () => void;
}

const AddButton = ({ handleClick, text }: Props) => {
  return (
    <div className='main-ProgressBar__add d-flex flex-column align-items-center justify-content-center'>
      <img
        src={plus}
        alt='add'
        width={40}
        height={40}
        onClick={handleClick}
        className='cursor-pointer'
      />
      <p>{text}</p>
    </div>
  );
};

export default AddButton;
