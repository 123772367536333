import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import CandidateTag from '../../../models/candidate-tag.interface';
import RegistrationTag from '../registration-tag/RegistrationTag';
import {
  addHardSkills,
  updateCandidate,
} from '../../../store/slices/candidateSlice';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../models/list-item';
import { getItemById } from '../../../utils/get-item-by-id';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const CandidateHardSkillModal = ({ show, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTags, setSelectedTags] = useState<CandidateTag[]>([]);
  const [hardSkillList, setHardSkillList] = useState<Option[]>(
    t('aptitudeList', { returnObjects: true })
  );
  const currentHardSkills = useSelector(
    (state: RootState) => state.candidateReducer.hardSkills
  );
  const englishInitHardSkillList: ListItem[] = t('aptitudeList', {
    lng: 'en',
    returnObjects: true,
  });

  function deleteSelectedTag(tag: ListItem): void {
    setSelectedTags((prevValue) => {
      return prevValue.filter((item) => item.id !== tag.id);
    });

    setHardSkillList((prev) => {
      const skills = [...prev];
      skills.push(tag);
      (skills as ListItem[]).sort((a, b) => a.name.localeCompare(b.name));
      return skills;
    });
  }

  function addSelectedTag(tag: CandidateTag): void {
    setSelectedTags((prevValue) => {
      const copy = [...prevValue];
      if (copy.some((item) => item.name === tag.name)) return prevValue;
      tag.description = getItemById(englishInitHardSkillList, tag.id);
      copy.push(tag);
      return copy;
    });
  }

  function removeSkillFromList(skillIndex: number): void {
    setHardSkillList((prev) => {
      const skills = [...prev];
      skills.splice(skillIndex, 1);
      return skills;
    });
  }

  useEffect(() => {
    if (show === true) {
      let result: ListItem[] = t('aptitudeList', { returnObjects: true });
      currentHardSkills.forEach((skill) => {
        result = result.filter((aptitude) => skill.id !== aptitude.id);
      });
      setHardSkillList(result);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('hardSkillLabel')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{}}
          onSubmit={(_) => {
            const skillNames = Array.from(
              new Set(currentHardSkills.map((item) => item.name))
            );

            const uniqueTags = selectedTags.filter(
              (item) => !skillNames.some((skill) => skill === item.name)
            );

            if (uniqueTags.length) {
              dispatch(addHardSkills(uniqueTags));
              dispatch(updateCandidate());
            }

            setSelectedTags([]);
            handleClose();
          }}
        >
          {({}) => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row>
                <div>
                  <Row>
                    <Form.Group as={Col} controlId='validationCustom01'>
                      <InputGroup hasValidation>
                        <Typeahead
                          id='basic-typeahead-single'
                          className='mt-2 w-100'
                          labelKey='name'
                          onChange={(element) => {
                            const selected: ListItem = element[0] as ListItem;
                            const selectedIndex = (
                              hardSkillList as ListItem[]
                            ).findIndex((item) => item.id === selected.id);

                            addSelectedTag(selected);
                            removeSkillFromList(selectedIndex);
                          }}
                          options={hardSkillList}
                          placeholder=''
                          selected={[]}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>

                  {selectedTags.length > 0 && (
                    <div className='selected-tags'>
                      {selectedTags.map((tag: CandidateTag, index: number) => {
                        return (
                          <RegistrationTag
                            key={index}
                            tag={tag as ListItem}
                            deleteTag={deleteSelectedTag}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateHardSkillModal;
