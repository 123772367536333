import React, { useState } from 'react';
import { Col, Row, InputGroup, Button, Form } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import EyeIcon from '../ui/eye-icon/EyeIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { confirmReseting } from '../../store/slices/resetSlice';
import './ResetPassword.scss';
import { useTranslation } from 'react-i18next';
import { getPasswordSchema } from '../../utils/get-password-schema';

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] =
    useState(false);

  const passSchema = getPasswordSchema();

  return (
    <Row className='justify-content-center'>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values) => {
          const urlArray = location.pathname.split('/');

          dispatch(
            confirmReseting({
              password: values.password,
              uid: urlArray.at(-2) || '',
              token: urlArray.at(-1) || '',
            })
          ).then((res) => {
            if (res.payload?.user_type === 'Candidate') {
              navigate('/auth/candidate/sign-in');
            } else {
              navigate('/auth/company/sign-in');
            }
          });
        }}
        validationSchema={passSchema}
      >
        {({ errors, touched }) => {
          return (
            <Row className='reset-form'>
              <FormikForm className='form'>
                <Row className='mt-2'>
                  <h2 className='register text-center'>
                    {t('resetPasswordPage.title')}
                  </h2>

                  <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                    <Form.Label className='input-label'>
                      {t('resetPasswordPage.passwordLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control auth-input'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='*********'
                        aria-describedby='inputGroupPrepend'
                        name='password'
                        autoComplete='on'
                      />
                      <EyeIcon
                        isOpen={showPassword}
                        action={() => setShowPassword((prev) => !prev)}
                      />
                      {errors.password && touched.password ? (
                        <p
                          className={`text-danger error-message candidate ${
                            errors.password.length > 47 ? 'long' : null
                          }`}
                        >
                          {errors.password}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId='validationCustomUsername'
                  >
                    <Form.Label className='input-label'>
                      {t('resetPasswordPage.confirmLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control auth-input'
                        type={showConfirmationPassword ? 'text' : 'password'}
                        placeholder='*********'
                        aria-describedby='inputGroupPrepend'
                        name='confirmPassword'
                        autoComplete='on'
                      />
                      <EyeIcon
                        isOpen={showConfirmationPassword}
                        action={() =>
                          setShowConfirmationPassword((prev) => !prev)
                        }
                      />
                      {errors.confirmPassword && touched.confirmPassword ? (
                        <p
                          className={`text-danger error-message candidate ${
                            errors.confirmPassword?.length > 47 ? 'long' : null
                          }`}
                        >
                          {errors.confirmPassword}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Button className='w-100 primary-button p-3 mt-5' type='submit'>
                  {t('resetPasswordForm.submitButton')}
                </Button>
              </FormikForm>
            </Row>
          );
        }}
      </Formik>
    </Row>
  );
};

export default ResetPassword;
