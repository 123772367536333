// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  max-height: 40px;
  gap: 8px;
  padding: 9px 16px;
  border-radius: 20px;
  background-color: rgba(233, 233, 233, 0.6);
  font-size: 14px; }
  .tag-icon {
    height: 20px;
    width: 20px; }
  .tag-close {
    height: 16px;
    width: 16px; }

.bigger-icon {
  width: 24px;
  height: 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/filter-tag/FilterTag.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,QAAQ;EACR,iBAAiB;EACjB,mBAAmB;EACnB,0CAA2C;EAC3C,eAAe,EAAA;EAEf;IACE,YAAY;IACZ,WAAW,EAAA;EAGb;IACE,YAAY;IACZ,WAAW,EAAA;;AAIf;EACE,WAAW;EACX,YAAY,EAAA","sourcesContent":[".tag {\n  max-height: 40px;\n  gap: 8px;\n  padding: 9px 16px;\n  border-radius: 20px;\n  background-color: rgba(233, 233, 233, 0.60);\n  font-size: 14px;\n\n  &-icon {\n    height: 20px;\n    width: 20px;\n  }\n\n  &-close {\n    height: 16px;\n    width: 16px;\n  }\n}\n\n.bigger-icon {\n  width: 24px;\n  height: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
