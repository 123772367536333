import React from 'react';
import StepperComponent from '../../models/stepper-component.interface';

interface StepperProps {
  components: StepperComponent[];
  currentStep: number;
  onStepClick: (step: number) => void;
  onLastBtnClick: () => void;
}

const Stepper: React.FC<StepperProps> = ({
  components,
  currentStep,
  onStepClick,
  onLastBtnClick,
}) => {
  const goToPreviousStep = () => {
    // Ensure the previous step is within the bounds
    if (currentStep > 0) {
      // Handle stepper logic here if needed
      onStepClick(currentStep - 1);
    }
  };

  const goToNextStep = () => {
    // Ensure the next step is within the bounds
    if (currentStep < components.length - 1) {
      // Handle stepper logic here if needed
      onStepClick(currentStep + 1);
    }
  };

  const StepComponent = components[currentStep].component;
  return (
    <div>
      <StepComponent
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
        openResultWindow={onLastBtnClick}
      />
    </div>
  );
};

export default Stepper;
