import React from 'react';
import { Button, InputGroup, Modal, Row } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { sendOfferReport } from '../../../store/slices/reportSlice';

interface Props {
  id: string;
  show: boolean;
  handleClose: () => void;
}

const ReportModal = ({ id, show, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const infoSchema = object({
    description: string().required(t('errors.fieldRequired')),
  });

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('offerReport.modalTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ description: '' }}
          onSubmit={(values) => {
            dispatch(
              sendOfferReport({ offerId: id, report: values.description })
            );
            handleClose();
          }}
          validationSchema={infoSchema}
        >
          {({ errors, values, touched }) => (
            <FormikForm className='form w-100 d-flex gap-2 justify-content-evenly flex-column'>
              <Row>
                <InputGroup hasValidation className='textarea'>
                  <Field
                    as='textarea'
                    className='form-control mt-2 px-4 py-3'
                    type='text'
                    name='description'
                    placeholder=''
                    aria-describedby='inputGroupPrepend'
                    maxLength={250}
                    rows={5}
                  />
                  <div className='textarea__counter'>
                    {values.description.length}/250
                  </div>
                  {errors.description && touched.description ? (
                    <p className='text-danger position-absolute error-message candidate'>
                      {errors.description}
                    </p>
                  ) : null}
                </InputGroup>
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ReportModal;
