import React from 'react';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import qrCode from '../../../assets/icons/qr-code.svg';
import informationNameIcon from '../../../assets/icons/information-name.svg';
import informationEmailIcon from '../../../assets/icons/information-email.svg';
import bi_telephone_fill from '../../../assets/icons/bi_telephone-fill.svg';
import ic_twotone_link from '../../../assets/icons/ic_twotone-link.svg';
import mapPinBlack from '../../../assets/icons/map-pin-black.svg';
import companyImage from '../../../assets/icons/avatar-placeholder.svg';

interface Props {
  companyDetails: any;
  handleShowFinalModal: () => void;
}
const CompanyInfoSidebar = ({
  companyDetails,
  handleShowFinalModal,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='zestur-card p-0'>
      <div className='zestur-card-cover'>
        <div className='zestur-card-cover-holder'>
          <Image src={companyDetails.cover} />
        </div>
        <div className='profile-picture'>
          <Image src={companyDetails.logo || companyImage} />
        </div>
      </div>
      <div className='profile-section'>
        <div className='name'>{companyDetails.name}</div>
        <div className='sub-title'>{companyDetails.businessName}</div>
        <div
          className='d-flex align-items-center justify-content-center mb-4 qr-code cursor-pointer'
          onClick={handleShowFinalModal}
        >
          <Image src={ASSETS_URL + qrCode} />
          <span>{t('companyHomePage.profile.qr')}</span>
        </div>
        <div className='d-grid gap-2 px-3'>
          <button
            onClick={() => navigate('/company/profile/plans')}
            className='btn btn-primary btn-block primary-btn-color'
          >
            {t('companyHomePage.profile.premium')}
          </button>
        </div>
        <h5 className='mt-3'>{t('companyHomePage.profile.title')}</h5>
        <ul className='px-0'>
          <li className='d-flex'>
            <Image src={ASSETS_URL + ic_twotone_link} />
            <span className='text-break'>{companyDetails.website}</span>
          </li>
          <li className='d-flex'>
            <Image src={ASSETS_URL + mapPinBlack} />
            <span className='text-break'>{companyDetails.city}</span>
          </li>
          <li className='d-flex'>
            <Image src={ASSETS_URL + informationNameIcon} />
            <span className='text-break'>
              {companyDetails.firstName} {companyDetails.lastName}
            </span>
          </li>
          <li className='d-flex'>
            <Image src={ASSETS_URL + bi_telephone_fill} />
            <span className='text-break'>{companyDetails.phone}</span>
          </li>
          <li className='d-flex'>
            <Image src={ASSETS_URL + informationEmailIcon} />
            <span className='text-break'>{companyDetails.email}</span>
          </li>
        </ul>
        <Link to={'/company/profile'} className='primary-text f-14'>
          {t('companyHomePage.profile.editButton')}
        </Link>
      </div>
    </div>
  );
};

export default CompanyInfoSidebar;
