import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import CompanySidebar from '../../components/shared/sidebar/CompanySidebar';
import './CompanyLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import {
  getCompany,
  setCompanyExistsError,
  setCompanyTokenExhausted,
} from '../../store/slices/companySlice';
import { setTokenExhausted } from '../../store/slices/authSlice';
import { setCandidateTokenExhausted } from '../../store/slices/candidateSlice';
import { getNotifications } from '../../store/slices/notificationSlice';
import MatchModal from '../../components/match-modal/MatchModal';
import { setIsMatchModal } from '../../store/slices/modalSlice';
import { checkIfCompanyToken } from '../../utils/check-if-company-token';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import Header from '../../components/shared/header/Header';
import AiLimitReachedModal from '../../components/AI-limit-reached-modal/AiLimitReachedModal';
import VerificationWarning from '../../components/ui/verification-warning/VerificationWarning';

const CompanyLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const company = useSelector((state: RootState) => state.companyReducer);
  const companyTokenExhausted = useSelector(
    (state: RootState) => state.companyReducer.tokenExhausted
  );
  const tokenExhausted = useSelector(
    (state: RootState) => state.authReducer.tokenExhausted
  );
  const companyDoNotExists = useSelector(
    (state: RootState) => state.companyReducer.companyDoNotExists
  );
  const isMatchModal = useSelector(
    (state: RootState) => state.modalReducer.isMatchModal
  );

  useEffect(() => {
    if (!checkIfCompanyToken()) {
      dispatch(setCompanyExistsError(true));
      toast.error(i18n.t('messages.companyNotExists'));
      return;
    }

    dispatch(getCompany());
    dispatch(getNotifications());
  }, [dispatch]);

  // useEffect(() => {
  //   if (!companyTokenExhausted) return;
  //   dispatch(refreshToken());
  // }, [companyTokenExhausted]);

  useEffect(() => {
    return () => {
      dispatch(setTokenExhausted(false));
      dispatch(setCompanyExistsError(false));
      dispatch(setCompanyTokenExhausted(false));
      dispatch(setCandidateTokenExhausted(false));
    };
  }, []);

  useEffect(() => {
    console.log('tokenExhausted', tokenExhausted);
    if (tokenExhausted) {
      navigate('/auth/company/sign-in');
    }
  }, [tokenExhausted]);

  useEffect(() => {
    console.log('companyDoNotExists', companyDoNotExists);
    if (companyDoNotExists) {
      navigate('/auth/company/sign-in');
    }
  }, [companyDoNotExists]);

  useEffect(() => {
    console.log('companyTokenExhausted', companyTokenExhausted);
    if (companyTokenExhausted) {
      navigate('/auth/company/sign-in');
    }
  }, [companyTokenExhausted]);

  function handleClose(): void {
    dispatch(setIsMatchModal(false));
  }

  return (
    <>
      {company.companyId && !company.is_verified && (
        <VerificationWarning userEmail={company.email} />
      )}
      <div className='app-wrapper'>
        <Header isCompany={true} />
        <CompanySidebar />
        <div className='zestur-company-wrapper'>
          <Outlet />
        </div>

        <MatchModal show={isMatchModal} handleClose={handleClose} />
        <AiLimitReachedModal />
      </div>
    </>
  );
};

export default CompanyLayout;
