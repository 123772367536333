import { ListItem } from '../models/list-item';
import { getEducationList } from './get-education-list';
import { getItemById } from './get-item-by-id';

export function getCurrentLevelEducationName(
  level: string,
  id: string,
  lang: string
) {
  return getItemById(getEducationList(level, lang) as ListItem[], id);
}
