import i18n from '../i18n/i18n';
import { Option } from 'react-bootstrap-typeahead/types/types';

export function getEducationList(level: string, lang: string): Option[] {
  switch (level) {
    case '1':
      return i18n.t('primaryEducationList', {
        lng: lang || i18n.language,
        returnObjects: true,
      });
    case '2':
      return i18n.t('middleEducationList', {
        lng: lang || i18n.language,
        returnObjects: true,
      });
    case '3':
      return i18n.t('superiorEducationList', {
        lng: lang || i18n.language,
        returnObjects: true,
      });
    default:
      return [];
  }
}
