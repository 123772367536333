import React from 'react';
import { useTranslation } from 'react-i18next';
import { VacancyProps } from '../../models/vacancy-props.interface';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';

const VacancyDetailsLicense = ({ vacancy }: VacancyProps) => {
  const { t } = useTranslation();

  return (
    <div className='details__info'>
      <h5>{t('vacancyDetails.subtitles.license')}</h5>
      <div>
        <VacancyInfo
          title={`${t('vacancyDetails.labels.license')}:`}
          info={
            vacancy.drivingLicense?.length
              ? (vacancy.drivingLicense as unknown as string[]).join(', ')
              : t('vacancyDetails.notRequired')
          }
        />
      </div>
    </div>
  );
};

export default VacancyDetailsLicense;
