import React from 'react';
import Vacancy from '../../models/vacancy.interface';
import { getItemById } from '../../utils/get-item-by-id';
import TableActionButton from '../ui/table-action-button/TableActionButton';
import eyeIcon from '../../assets/icons/eye-svgrepo-com.svg';
import warningIcon from '../../assets/icons/warning.svg';
import checkmarkIcon from '../../assets/icons/checkmark-with-background.svg';
import timelineIcon from '../../assets/icons/timeline.svg';
import { useTranslation } from 'react-i18next';
import editIcon from '../../assets/icons/edit-svgrepo-com.svg';
import { ListItem } from '../../models/list-item';

interface Props {
  vacancies: Vacancy[];
  handleRowCheck: (id: string) => void;
}
const VacanciesTableBody = ({ vacancies, handleRowCheck }: Props) => {
  const { t } = useTranslation();
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });

  return (
    <tbody>
      {vacancies.map((vacancy: Vacancy, index: number) => {
        return (
          <tr key={vacancy.id || index}>
            <td>
              <div className='zestur-check'>
                <input
                  className='checkbox'
                  type='checkbox'
                  id={`checkbox-${index}-`}
                  name='checkbox'
                  checked={vacancy.checked}
                  onChange={() => handleRowCheck(vacancy.id)}
                />
                <label htmlFor={`checkbox-${index}-`} className='px-3'></label>
              </div>
              {vacancy.position}
            </td>
            <td>{getItemById(sectorOptions, vacancy.sector)}</td>
            <td>{vacancy.location}</td>
            <td>{vacancy.number_of_vacancies}</td>
            <td>{vacancy.end_date}</td>
            <td>
              <div className='d-flex align-items-center gap-1'>
                <img
                  width={12}
                  height={12}
                  src={
                    vacancy.status === 'Publish'
                      ? checkmarkIcon
                      : vacancy.status === 'Inactive'
                      ? warningIcon
                      : vacancy.status === 'Draft'
                      ? timelineIcon
                      : warningIcon
                  }
                  alt=''
                />
                <span>
                  {t(`vacanciesTablet.columns.state.${vacancy.status}`)}
                </span>
              </div>
            </td>

            <td>
              <div className='d-flex align-items-center'>
                <TableActionButton
                  customClass='me-2'
                  url={vacancy.id + '/detail'}
                  icon={eyeIcon}
                />
                <TableActionButton
                  customClass=''
                  url={vacancy.id + '/edit'}
                  icon={editIcon}
                />
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default VacanciesTableBody;
