import React, { useState, useEffect, useContext } from 'react';

import './ProfileCompany.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import icDone from '../../../assets/images/ic_done.svg';

import { ASSETS_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

const ProfilePlansDetailCompanySmart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showFinalModal, setShowFinalModal] = useState(false);

  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);
  const benefits: string[] = t('planPage.smart.benefits', {
    returnObjects: true,
  });

  return (
    <>
      <div className='zestur-card'>
        <div className='d-flex align-items-center justify-content-between'>
          <h2 className='general-heading fw-semibold'>
            {t('planPage.smart.title')} {t('planPage.smart.name')}
          </h2>
        </div>
        <hr />

        <p className='general-heading'>{t('planDetails.card.benefitsTitle')}</p>
        <ul className='mt-3 f-12'>
          {benefits.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <div className='zestur-card mt-4'>
        <Row>
          <Col md={6}>
            <div className='d-flex align-items-center justify-content-between'>
              <h2 className='general-heading fw-semibold'>
                Contacta Con Nosotros
              </h2>
            </div>
            <hr />
            <p className='general-sub-heading text-dark fw-semibold'>
              Haga clic en el botón y nos pondremos en contacto con usted con los detalles.
            </p>
            <Form className='mt-4'>
              <button
                className='btn btn-primary primary-btn-color w-100 py-3 mt-4'
                type='button'
                onClick={handleShowFinalModal}
              >
                Quiero Plan SMART
              </button>
              <p className='general-sub-heading mt-4'>
                {t('planDetails.form.paymentDescription')}
              </p>
            </Form>
          </Col>
        </Row>
      </div>

      <Modal show={showFinalModal} centered onHide={handleCloseFinalModal}>
        <Modal.Body className='text-center py-5'>
          <Image className='mt-5' src={ASSETS_URL + icDone} />
          <h1 className='fw-bold my-4'>{t('planDetails.modal.title')}</h1>
          <p className='f-12 mt-4'>{t('planDetails.modal.description')}</p>
          <button
            className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3'
            onClick={() => navigate('/company')}
          >
            {t('planDetails.modal.acceptButton')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfilePlansDetailCompanySmart;
