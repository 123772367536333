import LanguageOption from '../../models/language-option.interface';

export function getSortedLanguages(
  languages: LanguageOption[],
  localization: string
): LanguageOption[] {
  switch (localization) {
    case 'en':
      return languages.sort((a, b) => a.name.localeCompare(b.name));
    case 'fr':
      return languages.sort((a, b) =>
        (a.fr_name || '').localeCompare(b.fr_name || '')
      );
    case 'sp':
      return languages.sort((a, b) =>
        (a.es_name || '').localeCompare(b.es_name || '')
      );
    case 'pt':
      return languages.sort((a, b) =>
        (a.pr_name || '').localeCompare(b.pr_name || '')
      );
    default:
      return languages.sort((a, b) => a.name.localeCompare(b.name));
  }
}
