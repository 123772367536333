import React, { useState, useEffect } from 'react';
import './CompanyApplicantDetail.scss';
import { useParams } from 'react-router-dom';
import { Col, Row, Tabs, Tab, Badge } from 'react-bootstrap';
import ApiService from '../../../../core/services/network.service';
import * as endpoints from '../../../../constants/endpoints';
import Experience from '../../../../models/experience.interface';
import CandidateExperienceCard from '../../../../components/ui/candidate-experience-card/CandidateExperienceCard';
import Education from '../../../../models/education.interface';
import CandidateEducationCard from '../../../../components/ui/candidate-education-card/CandidateEducationCard';
import Certificate from '../../../../models/certificate.interface';
import CandidateCertificateCard from '../../../../components/ui/candidate-certificate-card/CandidateCertificateCard';
import { getLanguageLevel } from '../../../../utils/get-language-level';
import Language from '../../../../models/language.interface';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import { getLanguageTranslation } from '../../../../utils/languages-switch/get-language-translation';
import VacancyInfo from '../../../../components/ui/vacancy-info/VacancyInfo';
import ApplicantDetailCandidateCard from './ApplicantDetailCandidateCard';
import ApplicantNoteSpace from './ApplicantNoteSpace';
import {setCandidateSeen} from "../../../../store/slices/companyCandidatesSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../store/store";

const CompanyApplicantDetail = () => {
  const { t, i18n } = useTranslation();
  const { candidateId, offerId } = useParams<{
    candidateId: string;
    offerId: string;
  }>();
  const [data, setData] = useState<any>();
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    fetchCandidateData();
  }, []);

  const fetchCandidateData = () => {
    if (!candidateId) return;

    ApiService.request<any>(
        'GET',
        endpoints.CANDIDATES_DETAIL.replace('{id}', candidateId)
    ).then((res) => {
      setData(res.body);
      dispatch(
        setCandidateSeen({offer_id: offerId || '', candidate_id: candidateId, is_seen: true})
      );
    });
  };

  return (
      <Row>
        <Col xs={12} md={5}>
          <ApplicantDetailCandidateCard
              data={data}
              candidateId={candidateId || ''}
              offerId={offerId || ''}
          />
          <ApplicantNoteSpace
              candidateId={candidateId || ''}
              note={data?.notes?.at(-1)?.note || ''}
          />
        </Col>
        <Col xs={12} md={7} className='tablet-mt-4'>
          <div className='zestur-card'>
            <Tabs
                defaultActiveKey='experience'
                id='uncontrolled-tab-example'
                className='mb-3'
                fill
            >
              <Tab
                  eventKey='experience'
                  title={t('candidateEditPage.tabs.experience')}
              >
                <h4 className='tab-heading mt-4 mb-5'>
                  {t('candidateEditPage.titles.experience')}
                </h4>
                {data?.experience?.map((item: Experience, index: number) => (
                    <CandidateExperienceCard
                        key={item.id || index}
                        index={index}
                        experience={item}
                        editHandler={() => {}}
                        deleteHandler={() => {}}
                        withEdit={false}
                    />
                ))}
              </Tab>
              <Tab
                  eventKey='education'
                  title={t('candidateEditPage.tabs.education')}
              >
                <h4 className='tab-heading mt-4 mb-5'>
                  {t('candidateEditPage.titles.education')}
                </h4>

                {data?.education.map((item: Education, index: number) => {
                  return (
                      <CandidateEducationCard
                          index={index}
                          education={item}
                          editHandler={() => {}}
                          deleteHandler={() => {}}
                          key={item.id || index}
                          withEdit={false}
                      />
                  );
                })}

                <h4 className='tab-heading mt-4 mb-5 mt-5'>
                  {t('candidateEditPage.titles.certificate')}
                </h4>
                {data?.certificates?.map((item: Certificate, index: number) => (
                    <CandidateCertificateCard
                        index={index}
                        certificate={item}
                        editHandler={() => {}}
                        deleteHandler={() => {}}
                        key={item.id || index}
                        withEdit={false}
                    />
                ))}
              </Tab>
              <Tab
                  eventKey='contact'
                  title={t('candidateEditPage.tabs.language')}
              >
                <h4 className='tab-heading mt-4 mb-5'>
                  {t('candidateEditPage.titles.language')}
                </h4>
                {data?.language?.map((item: Language, index: number) => {
                  return (
                      <div className='d-flex justify-content-between' key={index}>
                        <h5>
                          {getLanguageTranslation(item, i18n.language)}:
                          <span className='ms-2'>
                        {getLanguageLevel(item.level, levels)}
                      </span>
                        </h5>
                      </div>
                  );
                })}
                <h4 className='tab-heading mt-4 mb-5 mt-5'>
                  {t('hardSkillLabel')}
                </h4>
                <div>
                  {data?.hard_skills?.map((_: any, index: number) => (
                      <Badge
                          key={`${index}`}
                          pill
                          bg=''
                          className='zesture-badge me-2 mb-2'
                      >
                        {getItemById(aptitudeList, _.name)}
                      </Badge>
                  ))}
                </div>

                <h4 className='tab-heading mt-4 mb-5 mt-5'>
                  {t('softSkillLabel')}
                </h4>
                <div>
                  {data?.skill?.map((_: any, index: number) => (
                      <Badge
                          key={`${index}`}
                          pill
                          bg=''
                          className='zesture-badge me-2 mb-2'
                      >
                        {getItemById(aptitudeList, _.name)}
                      </Badge>
                  ))}
                </div>

                <h4 className='tab-heading mt-4 mb-5 mt-5'>
                  {t('vacancyDetails.subtitles.license')}
                </h4>
                <div className='details__info'>
                  <div>
                    <VacancyInfo
                        title={`${t('vacancyDetails.labels.license')}:`}
                        info={
                          data?.license?.length
                              ? (data.license as unknown as string[]).join(', ')
                              : t('licenseForm.noLicense')
                        }
                    />
                    <VacancyInfo
                        title={`${t('licenseForm.vehicleLabel')}:`}
                        info={
                          data?.is_vehicle
                              ? t('licenseForm.secondOption')
                              : t('licenseForm.firstOption')
                        }
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Col>
      </Row>
  );
};

export default CompanyApplicantDetail;
