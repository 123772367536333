import { createSlice } from '@reduxjs/toolkit';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import { MatchPayload } from '../../models/match-payload';

interface ModalState {
  isMatchModal: boolean;
  matchData: AssignedCandidate | null;

  matchWarningModal: boolean;
  selectedMatchPayload: MatchPayload[] | null;

  isLimitReachedModal: boolean;
}

const initialState: ModalState = {
  isMatchModal: false,
  matchData: null,

  matchWarningModal: false,
  selectedMatchPayload: null,

  isLimitReachedModal: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setIsMatchModal: (state, action) => {
      state.isMatchModal = action.payload;
    },

    setMatchData: (state, action) => {
      state.matchData = action.payload;
    },

    setIsMatchWarningModal: (state, action) => {
      state.matchWarningModal = action.payload;
    },

    setClickedMatchPayload: (state, action) => {
      state.selectedMatchPayload = action.payload;
    },

    setIsLimitReachedModal: (state, action) => {
      state.isLimitReachedModal = action.payload;
    },
  },
});

export const {
  setIsMatchModal,
  setMatchData,
  setIsMatchWarningModal,
  setClickedMatchPayload,
  setIsLimitReachedModal,
} = modalSlice.actions;
export default modalSlice.reducer;
