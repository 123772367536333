import axios from 'axios';
import { setCompanyTokenExhausted } from '../store/slices/companySlice';
import { setCandidateTokenExhausted } from '../store/slices/candidateSlice';
import { setTokenExhausted } from '../store/slices/authSlice';

export const axiosMiddleware = (store: any) => (next: any) => (action: any) => {
  setInterceptors(store);

  return next(action);
};

export const setInterceptors = (store: any) => {
  if (!store) {
    return;
  }

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { dispatch } = store;

      // 403 token exhausted
      if (error.response.status === 403) {
        dispatch(setCandidateTokenExhausted(true));
        dispatch(setCompanyTokenExhausted(true));
        dispatch(setTokenExhausted(true));
      }
      //  401 no token in local storage
      if (error.response.status === 401) {
        dispatch(setTokenExhausted(true));
      }
      return Promise.reject(error);
    }
  );
};
