import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './VerificationWarning.module.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { resendVerificationEmail } from '../../../store/slices/emailVerificationSlice';

interface Props {
  userEmail: string;
}

const VerificationWarning = ({ userEmail }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const resendEmail = () => {
    dispatch(resendVerificationEmail(userEmail));
  };

  return (
    <div className={styles.warning}>
      {t('verifyProfileMessage.askForVerify')},{' '}
      {t('verifyProfileMessage.restriction')},{' '}
      <span
        className='cursor-pointer text-decoration-underline'
        onClick={() => resendEmail()}
      >
        {t('verifyProfileMessage.resend')}
      </span>
    </div>
  );
};

export default VerificationWarning;
