import React from 'react';

interface Props {
  image: string;
  imageAlt: string;
  title: string;
  description: string;
}

const SideContentFeature = ({ image, imageAlt, title, description }: Props) => {
  return (
    <div className='feature d-flex align-items-center gap-4'>
      <div className='services d-flex flex-column align-items-center justify-content-evenly'>
        <img src={image} alt={imageAlt} />
        <h4>{title}</h4>
      </div>
      <div className='about-services'>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default SideContentFeature;
