export const getCandidateNumberByStatus = (status: string) => {
  // See models.py
  switch (status) {
    case 'pending':
      return 1;
    case 'seen':
      return 2;
    case 'selected':
      return 3;
    case 'discarded':
      return 4;
    case 'withdrawn':
      return 5;
    default:
      return 1;
  }
};
