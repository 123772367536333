import React, { useState } from "react";
import SignUpForm from "./SignUp/SignUpForm";
import RegistrationStep1 from "./Registration Form/RegistrationStep1";
import RegistrationStepFirst from "./Registration Form/RegistrationStepFirst";
import { Row, Col } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store/store";
import SubmittedForm from "./Registration Form/SubmittedForm";
import {candidateBg} from "../../../utils/AppConstants";
import {setCandidate, setContactInfo} from "../../../store/slices/candidateSlice";
import {useNavigate} from "react-router-dom";


const steps = [
  { title: "Step 0", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SignUpForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 1", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStepFirst currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 2", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep1 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 3", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SubmittedForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> }
];
export default function SignUpCandidate() {
  const isFormView = useSelector((state: RootState) => state.authPageReducer.isFormView);
  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const ssoData = sessionStorage.getItem('ssoUserDetails');
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    if (ssoData && currentStep !== 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    } else {
      navigate('/');
    }
  };

  if (currentStep === 0 && ssoData) {
    const ssoDetails = JSON.parse(ssoData);
    if (ssoDetails && ssoDetails.sub) {
      dispatch(
        setCandidate({
          email: ssoDetails.email,
          password: ssoDetails.sub + "Zas!1234",
          userId: '',
        })
      );
      dispatch(
        setContactInfo({
          name: ssoDetails.given_name,
          lastName: ssoDetails.family_name
        })
      );
    }

    setCurrentStep(1);
  }

  return (
    <Row className="text-start" data-step={currentStep} style={candidateBg}>
      {
        currentStep === 0 && <Col className={`p-0 ${isFormView ? '' : ''}`} xs={12} lg={6}>

        </Col>
      }
      <Col className={`p-0 ${currentStep === 12 ? "offset-md-2" : ''} ${currentStep === 0 && !isFormView  ? '' : ''}`} xs={12} lg={currentStep === 0 ? 6 : 12}>
        {steps[currentStep].component(currentStep, handleNext, handlePrev)}
      </Col>
    </Row>
  );
}
