// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button {
  background-color: transparent;
  border: none; }

.action-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 2px solid #213867;
  border-radius: 1rem;
  background-color: white;
  width: 180px;
  top: 40px;
  left: -160px;
  z-index: 1; }
  .action-dropdown > :first-child {
    border-bottom: 1px solid #213867; }
  .action-dropdown button {
    padding: 8px 15px;
    text-align: start; }
  .action-dropdown img {
    margin-right: 6px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/dots-button/DotsButton.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,6BAA6B;EAC7B,YAAY,EAAA;;AAGd;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,yBCTiB;EDUjB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,SAAS;EACT,YAAY;EACZ,UAAU,EAAA;EAVZ;IAaI,gCClBe,EAAA;EDKnB;IAiBI,iBAAiB;IACjB,iBAAiB,EAAA;EAlBrB;IAsBI,iBAAiB,EAAA","sourcesContent":["@import './../../../assets/scss/variable';\n\n.action-button {\n  background-color: transparent;\n  border: none;\n}\n\n.action-dropdown {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  border: 2px solid $dark-blue;\n  border-radius: 1rem;\n  background-color: white;\n  width: 180px;\n  top: 40px;\n  left: -160px;\n  z-index: 1;\n\n  &>:first-child {\n    border-bottom: 1px solid $dark-blue;\n  }\n\n  button {\n    padding: 8px 15px;\n    text-align: start;\n  }\n\n  img {\n    margin-right: 6px;\n  }\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
