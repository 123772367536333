import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SideContentFeature from '../Auth/SideContentFeature/SideContentFeature';
import { Image } from 'react-bootstrap';
import logo from '../../assets/images/job-portal/zestur-logo-internal.svg';
import contentLifecycle from '../../assets/icons/content-lifecycle.svg';
import sheet from '../../assets/icons/sheet.svg';
import eye from '../../assets/icons/eye.svg';
import { useNavigate } from 'react-router-dom';
import { ASSETS_URL } from '../../constants';
import './Home.scss';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/language-selector/LanguageSelector';

interface InfoSection {
  title: string;
  description: string;
}

const images = [contentLifecycle, sheet, eye];
const alts = ['content lifecycle', 'sheet', 'eye'];

const HomeComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const companyInfo: InfoSection[] = t('auth.companyInfo', {
    returnObjects: true,
  });
  const candidateInfo: InfoSection[] = t('auth.candidateInfo', {
    returnObjects: true,
  });

  return (
    <>
      <Row className='content home justify-content-center'>
        <div className='content__home-logo d-flex justify-content-between'>
          <Image src={ASSETS_URL + logo} alt='logo Image' fluid />

          <div className='home-lang-select'>
            <LanguageSelector />
          </div>
        </div>
        <Col xs={11} lg={5}>
          <h2 className='content__home-header'>{t('homePage.companyTitle')}</h2>
          <Button
            className='w-100 primary-button p-3 mt-2'
            type='button'
            onClick={() => navigate('/auth/company/sign-in')}
          >
            {t('auth.loginButton')}
          </Button>
          <div className='signAbout px-0'>
            {companyInfo.map((item, index) => {
              return (
                <SideContentFeature
                  key={index}
                  image={images[index]}
                  imageAlt={alts[index]}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
          </div>
        </Col>
        <Col xs={11} lg={5}>
          <h2 className='content__home-header'>
            {t('homePage.candidateTitle')}
          </h2>
          <Button
            className='w-100 primary-button p-3 mt-2'
            type='button'
            onClick={() => navigate('/auth/candidate/sign-in')}
          >
            {t('auth.loginButton')}
          </Button>
          <div className='signAbout px-0'>
            {candidateInfo.map((item, index) => {
              return (
                <SideContentFeature
                  key={index}
                  image={images[index]}
                  imageAlt={alts[index]}
                  title={item.title}
                  description={item.description}
                />
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default HomeComponent;
