import React, { useState, useEffect } from 'react';

import { Col, Image, Row } from 'react-bootstrap';

import companyImage from '../../../assets/icons/avatar-placeholder.svg';
import { ASSETS_URL } from '../../../constants';
import ChatBox from '../../../components/chat-box/ChatBox';
import ChatCompanyList from '../../../components/chat-company-list/ChatCompanyList';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { getCompaniesChatList } from '../../../store/slices/chatSlice';
import { ChatCompany } from '../../../models/chat-company.interface';

const ChatCandidate = () => {
  const dispatch = useDispatch<AppDispatch>();
  const candidateAvatar = useSelector(
    (state: RootState) => state.candidateReducer.avatar
  );

  const storedCompanies = useSelector(
    (state: RootState) => state.chatReducer.companyList
  );
  const [companies, setCompanies] = useState<ChatCompany[]>([]);
  const [currentUser, setCurrentUser] = useState<ChatCompany | null>(null);
  const candidateName = useSelector(
    (state: RootState) => state.candidateReducer.contactInfo.name
  );
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getCompaniesChatList());
  }, []);

  useEffect(() => {
    setCompanies(storedCompanies);
  }, [storedCompanies]);

  const selectedUser = (e: any) => {
    if (e) {
      setCurrentUser(e);
    }
  };

  const loadMore = (e: any) => {
    setPage((prevPage: number) => {
      const newPage = prevPage + 1;
      return newPage;
    });
  };

  const onSearch = (value: string) => {
    setCompanies(() =>
      storedCompanies.filter((item) =>
        item.company_name.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <div className='zestur-card p-0'>
            <ChatCompanyList
              candidateName={candidateName}
              companies={companies}
              userClick={selectedUser}
              loadMore={loadMore}
              search={onSearch}
            />
          </div>
        </Col>
        <Col md={8}>
          <div className='zestur-card h-100'>
            <div className='cb-user-crr fw-semibold mb-4'>
              {currentUser ? (
                <>
                  <Image
                    src={
                      ASSETS_URL + (currentUser.company_logo || companyImage)
                    }
                    className='object-fit-cover'
                  />
                  {currentUser.company_name} - {currentUser.offer_title}
                </>
              ) : null}
            </div>
            {currentUser ? (
              <ChatBox
                currentUserAvatar={candidateAvatar}
                dialogPersonAvatar={currentUser.company_logo}
                dialogPersonName={currentUser.company_name}
                loggedUserName={candidateName}
                userId={currentUser.company_id}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ChatCandidate;
