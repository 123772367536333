import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLimitReachedModal } from '../../store/slices/modalSlice';

const AiLimitReachedModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLimitReached = useSelector(
    (state: RootState) => state.modalReducer.isLimitReachedModal
  );

  const handleClose = () => {
    dispatch(setIsLimitReachedModal(false));
  };

  return (
    <Modal show={isLimitReached} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('limitReachedModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Row>
          <span>{t('limitReachedModal.description')}</span>
        </Row>
        <Row>
          <Col className='d-flex justify-content-start mt-4'>
            <button
              onClick={() => handleClose()}
              type='button'
              className='btn btn-outline-primary btn-outline-color px-4 py-2'
            >
              {t('vacanciesTablet.deleteModal.cancel')}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default AiLimitReachedModal;
