import React from 'react';
import styles from './ApplicantNoteSpace.module.scss';
import { Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { config } from '../../../../_config/config';
import tokenService from '../../../../_services/token.service';
import { toast } from 'react-toastify';

interface Props {
  note: string;
  candidateId: string;
}

const ApplicantNoteSpace = ({ candidateId, note }: Props) => {
  const { t } = useTranslation();
  const validationSchema = object({
    note: string().required(t('errors.fieldRequired')),
  });

  const saveNote = async (note: string) => {
    const token = tokenService.getLocalAccessToken();

    try {
      await axios.post(
        `${config.SERVER_URL}candidate/${candidateId}/note/`,
        { note },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      toast.success(t('messages.successfullyUpdated'));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik
      initialValues={{
        note: note,
      }}
      enableReinitialize
      onSubmit={(values) => {
        saveNote(values.note);
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <FormikForm className={`${styles.form} zestur-card`}>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <Form.Label className='offer-form-label mb-3'>
              {t('candidateDetail.noteLabel')}
            </Form.Label>
            <InputGroup hasValidation>
              <Field
                as='textarea'
                className='form-control'
                name='note'
                rows={5}
              />
              {errors.note && touched.note ? (
                <p className='text-danger position-absolute error-message candidate'>
                  {errors.note}
                </p>
              ) : null}
            </InputGroup>
          </Form.Group>
          <div className='d-flex justify-content-end mt-4'>
            <button
              className='btn btn-primary primary-btn-color px-5'
              type='submit'
            >
              {t('submitButton')}
            </button>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default ApplicantNoteSpace;
