// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-button {
  color: #213867;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
  border: none; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/edit-button/EditButton.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,cCDiB;EDEjB,gBCkBkB;EDjBlB,eAAe;EACf,6BAA6B;EAC7B,YAAY,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.edit-button {\n  color: $dark-blue;\n  font-weight: $font-semi-bold;\n  font-size: 16px;\n  background-color: transparent;\n  border: none;\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n\n// Redesign 2025\n$main-grey: #FAFAF4;\n$candidate: #4EC6DB;\n$candidate-border: #011640;\n$company: #D5006D;\n$company-border: #AE085A;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
