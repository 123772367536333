import React from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import Step from '../../../models/step.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {
  setName,
  setSector,
  setWebsite,
} from '../../../store/slices/companySlice';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { getWebsiteRegex } from '../../../utils/get-website-regex';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';
import {useNavigate} from "react-router-dom";

function RegistrationStep1(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;
  const navigate = useNavigate();

  const companyMainSchema = object({
    name: string().required(t('errors.fieldRequired')),
    industrialSector: string().required(t('errors.fieldRequired')),
    website: string()
      .matches(getWebsiteRegex(), t('errors.correctUrl'))
      .required(t('errors.fieldRequired')),
  });

  return (
    <div className='register-form'>
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={17}
            startStep={1}
            endStep={6}
          />
        </Col>

        <Col xs={12} md={9} lg={10} className='d-flex justify-content-center justify-items-center' style={{margin:"0 auto"}}>
          <div className='company-register-logo'>
            <Image src={ASSETS_URL + logo} alt='logo Image' fluid onClick={() => navigate('/')} />
          </div>
          <Formik
            initialValues={{
              name: company.name,
              industrialSector: company.industrialSector,
              website: company.website,
            }}
            onSubmit={(values) => {
              dispatch(setName(values.name));
              dispatch(setSector(values.industrialSector));
              dispatch(setWebsite(values.website));

              props.handleNext();
            }}
            validationSchema={companyMainSchema}
          >
            {({ errors, touched }) => (
              <FormikForm className='bg-blue-gradient form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={1}
                    endStep={5}
                    progressValue={20}
                  />
                </div>

                <Row className='form-fields candidate' style={{padding:'2em', borderRadius: "20px"}}>
                  <h2>{t('companyRegistration.step1.title')} </h2>
                  <Form.Group as={Col} xs={12} controlId='validationCustom01'>
                    <Form.Label className='label'>
                      {t('companyRegistration.nameLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control mt-2 px-4 py-3'
                        type='text'
                        placeholder=''
                        aria-describedby='inputGroupPrepend'
                        name='name'
                      />
                      {errors.name && touched.name ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.name}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId='validationCustom02'
                    className='mobile-mt-2'
                  >
                    <Form.Label className='label'>
                      {t('companyRegistration.sectorLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control mt-2 px-4 py-3'
                        type='text'
                        placeholder=''
                        aria-describedby='inputGroupPrepend'
                        name='industrialSector'
                      />
                      {errors.industrialSector && touched.industrialSector ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.industrialSector}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={12}
                    controlId='validationCustom03'
                    className='mobile-mt-2'
                  >
                    <Form.Label className='label'>
                      {t('companyRegistration.websiteLabel')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        className='form-control mt-2 px-4 py-3'
                        type='text'
                        placeholder='www.example.com'
                        aria-describedby='inputGroupPrepend'
                        name='website'
                      />
                      {errors.website && touched.website ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.website}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className='mt-4 no-mobile-hidden'></Row>
                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep1;
