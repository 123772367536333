// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-edit {
  position: absolute;
  cursor: pointer;
  bottom: -2px;
  right: -8px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/candidate-info-modal/CandidateInfoModal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,WAAW,EAAA","sourcesContent":[".avatar-edit {\n  position: absolute;\n  cursor: pointer;\n  bottom: -2px;\n  right: -8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
