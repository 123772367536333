import { defaultNotSelectedDate } from '../../constants/constants';
import Experience from '../../models/experience.interface';

export function getExperienceFromAiResult(aiData: Experience[]) {
  return aiData.map((item: Experience) => {
    item.longitude = 0;
    item.latitude = 0;
    if (!item.end_date || item.end_date === 'Today') {
      item.end_date = defaultNotSelectedDate;
    }

    return item;
  });
}
