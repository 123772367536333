import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsInEng from '../locales/en/translation.json';
import translationsInSpain from '../locales/sp/translation.json';
import translationsInFrench from '../locales/fr/translation.json';
import translationsInPortugal from '../locales/pt/translation.json';

const resources = {
  en: {
    translation: translationsInEng,
  },
  sp: {
    translation: translationsInSpain,
  },
  fr: {
    translation: translationsInFrench,
  },
  pt: {
    translation: translationsInPortugal,
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  fallbackLng: localStorage.getItem('lang') || 'sp',
  interpolation: {
    escapeValue: false,
  },
  ns: 'translation', // namespaces help to divide huge translations into multiple small files.
  defaultNS: 'translation',
});

export default i18n;
