import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  text: string;
  url: string;
  classes: string;
}

const NavigationButton = ({ text, url, classes }: Props) => {
  const navigate = useNavigate();

  return (
    <button className={classes} onClick={() => navigate(url)}>
      {text}
    </button>
  );
};

export default NavigationButton;
