import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import tokenService from '../../_services/token.service';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';

class ApiService {
  public axiosInstance = axios.create({
    // baseURL: 'https://api.example.com', // Set your API base URL
    headers: {
      'Content-Type': 'application/json',
      // Add any other default headers here
    },
  });

  constructor() {
    this.setupInterceptors();
  }

  // Set headers for the request
  setHeaders(headers: Record<string, string>): void {
    this.axiosInstance.defaults.headers = {
      ...this.axiosInstance.defaults.headers,
      ...headers,
    };
  }

  // Set content type for the request
  setContentType(contentType: string): void {
    this.axiosInstance.defaults.headers['Content-Type'] = contentType;
  }

  // Set up request interceptors
  private setupInterceptors(): void {
    // Add a request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Attach token to headers if available
        const token = tokenService.getLocalAccessToken();

        if (token && token !== '') {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // Do something with the response data
        return response;
      },
      (error: AxiosError) => {
        this.handleApiError(error);
        return Promise.reject(error);
      }
    );
  }

  // Generic method for making HTTP requests
  async request<T>(
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | any,
    url: string | any,
    data?: any,
    isFormData = false,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      if (isFormData) {
        this.setContentType('multipart/form-data');
      }

      const response = await this.axiosInstance.request<T>({
        method,
        url,
        data: isFormData ? this.convertToFormData(data) : data,
        ...config,
      });
      // this.showSuccessToast('Solicitud exitosa');
      return response.data;
    } catch (error: any) {
      this.handleApiError(error);
      // throw error;
      let errorMessage = this.getErrorMessage(error.response?.status);
      for (var key in error?.response?.data) {
        errorMessage += "\n" + key + " : " + error.response.data[key];
      }
      this.showErrorToast(errorMessage);
      console.log('Error api: ', error?.response?.status);
      return error;
    }
  }

  // Convert object to FormData
  private convertToFormData(data: Record<string, any>): FormData {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }

    return formData;
  }

  // Handle API errors (you can customize this based on your needs)
  private handleApiError(error: AxiosError): void {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.error('Response error:', error.response.data);
      this.showErrorToast(error.response.data as string);
      if (error.response.status === 403) {
        window.location.replace('/');
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      console.error('Response error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
      console.error('Response error:', error.message);
    }
  }

  private getErrorMessage(errorCode: number): string {
    return (
      i18n.t(`errorMessages.${errorCode}`) || i18n.t('messages.unexpectedError')
    );
  }

  // Display a success toast message
  public showSuccessToast(message: string): void {
    toast.success(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }

  // Display an error toast message
  private showErrorToast(message: string): void {
    toast.error(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  }
}

export default new ApiService();
