import React, { useState } from 'react';

import './ProfileCompany.scss';
import { Outlet } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CompanyQRModal from '../../../components/ui/company-qr-modal/CompanyQRModal';
import CompanyInfoSidebar from '../../../components/ui/company-info-sidebar/CompanyInfoSidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const ProfileCompany = () => {
  const companyDetails = useSelector(
    (state: RootState) => state.companyReducer
  );
  const [showFinalModal, setShowFinalModal] = useState(false);
  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);

  return (
    <>
      <Row>
        <Col lg={9}>
          <Outlet />
        </Col>
        <Col lg={3} className='tablet-mt-4'>
          {companyDetails && (
            <CompanyInfoSidebar
              companyDetails={companyDetails}
              handleShowFinalModal={handleShowFinalModal}
            />
          )}
        </Col>
      </Row>
      <CompanyQRModal
        show={showFinalModal}
        handleClose={handleCloseFinalModal}
      />
    </>
  );
};

export default ProfileCompany;
