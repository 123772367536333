// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadResumeButton_uploadButton__kMcis {
  border: none;
  background-color: transparent;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/upload-resume-button/UploadResumeButton.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe,EAAA","sourcesContent":[".uploadButton {\n  border: none;\n  background-color: transparent;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadButton": `UploadResumeButton_uploadButton__kMcis`
};
export default ___CSS_LOADER_EXPORT___;
