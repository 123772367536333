import VacancyInfo from '../models/vacancy-info.interface';

export function processSkills(list: VacancyInfo[]): {
  name: string;
  description: string;
}[] {
  return list.map((item) => {
    return {
      name: item.id?.length! > 12 ? item.name : item.id,
      description: item.description || '',
    };
  });
}
