// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TriggerMatchButton_triggerButton__Jb-6Z {
  background: none;
  border: none;
  display: flex;
  align-items: center; }
  .TriggerMatchButton_triggerButton__Jb-6Z img {
    fill-opacity: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/trigger-match-button/TriggerMatchButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,mBAAmB,EAAA;EAJrB;IAOI,eAAe,EAAA","sourcesContent":[".triggerButton {\n  background: none;\n  border: none;\n  display: flex;\n  align-items: center;\n\n  img {\n    fill-opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"triggerButton": `TriggerMatchButton_triggerButton__Jb-6Z`
};
export default ___CSS_LOADER_EXPORT___;
