import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';

interface Props {
  url: string;
  icon: string;
  customClass: string;
}

const TableActionButton = ({ url, icon, customClass }: Props) => {
  const navigate = useNavigate();

  return (
    <button
      className={`btn ${customClass}`}
      onClick={(event) => {
        event.stopPropagation();
        navigate(url);
      }}
    >
      <Image src={ASSETS_URL + icon} width={20} className='blue-filter' />
    </button>
  );
};

export default TableActionButton;
