import React from 'react';
import { ASSETS_URL } from '../../../constants';
import addIcon from '../../../assets/icons/add.svg';
import { Image } from 'react-bootstrap';
import AddButton from '../add-button/AddButton';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  openModal: () => void;
}

const CandidateEditHeader = ({ openModal, title }: Props) => {
  const { t } = useTranslation();

  return (
    <h4 className='tab-heading mt-4 mb-5 d-flex align-items-center gap-4'>
      {title}
      <Image
        src={ASSETS_URL + addIcon}
        className='cursor-pointer mobile-hidden'
        onClick={() => openModal()}
      />
      <div className='mobile-add-button no-mobile-hidden'>
        <AddButton
          handleClick={openModal}
          text={t('registrationForm.steps.addButton')}
        />
      </div>
    </h4>
  );
};

export default CandidateEditHeader;
