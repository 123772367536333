import React from 'react';
import { Modal, Image, ModalBody, Button } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import warningIcon from '../../../assets/icons/warning.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { calculateCandidateMatch } from '../../../store/slices/companyCandidatesSlice';
import { setClickedMatchPayload } from '../../../store/slices/modalSlice';
import { increaseLimitCounter } from '../../../store/slices/companySlice';

interface Props {
  handleClose: () => void;
  selectedMatchIds: string[];
}

const MatchSubmitModal = ({ handleClose, selectedMatchIds }: Props) => {
  const show = useSelector(
    (state: RootState) => state.modalReducer.matchWarningModal
  );
  const selectedMatch = useSelector(
    (state: RootState) => state.modalReducer.selectedMatchPayload
  );
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Modal show={show} centered onHide={handleClose}>
      <ModalBody className='text-center py-5'>
        <h1 className='f-20 mt-4 mb-5'>
          {t('candidatesTablet.matchModal.header')}
        </h1>
        <button
          onClick={handleClose}
          type='button'
          className='btn btn-outline-primary btn-outline-color px-5 py-3 me-4'
        >
          {t('candidatesTablet.matchModal.cancel')}
        </button>
        <Button
          type='button'
          className='btn btn-primary primary-btn-color px-5 py-3'
          onClick={() => {
            if (!selectedMatch?.length) return;
            dispatch(
              calculateCandidateMatch({
                matchArr: selectedMatch,
                selectedMatchIds,
              })
            );
            dispatch(increaseLimitCounter(selectedMatch.length));
            dispatch(setClickedMatchPayload(null));
            handleClose();
          }}
        >
          {t('candidatesTablet.actionBar.match')}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default MatchSubmitModal;
