import React, { useState, useEffect } from 'react';
import './VacancyDetailCompany.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Breadcrumbs from '../../../../components/breadcrumbs/Breadcrumbs';
import { salaryRange } from '../../../../utils/format-salary';
import VacancyInfo from '../../../../components/ui/vacancy-info/VacancyInfo';
import { getLanguageLevel } from '../../../../utils/get-language-level';
import Language from '../../../../models/language.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  getVacancyDetails,
  resetVacancyDetails,
} from '../../../../store/slices/vacanciesSlice';
import VacancyExperience from '../../../../models/vacancy-experience';
import { resetVacancyForm } from '../../../../store/slices/vacancyCreateSlice';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';
import { getItemById } from '../../../../utils/get-item-by-id';
import { getLanguageTranslation } from '../../../../utils/languages-switch/get-language-translation';
import { getCurrentLevelEducationName } from '../../../../utils/get-current-level-education-name';

const VacancyDetailCompany = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const vacancyDetails = useSelector(
    (state: RootState) => state.vacanciesReducer.vacancyDetails
  );
  const [pathSegments, setPathSegments] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [vacancyId, setVacancyId] = useState<string>('');
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });
  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });
  const educationLevelList: ListItem[] = t('educationLevelList', {
    returnObjects: true,
  });

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const id = pathSegments.at(-2);
    setPathSegments(pathSegments.slice(1));

    if (id) {
      setVacancyId(id);
      dispatch(getVacancyDetails(id));
    }

    return () => {
      dispatch(resetVacancyDetails());
      dispatch(resetVacancyForm());
    };
  }, []);

  return (
    <div className='zestur-card'>
      <Row>
        <Col md={9}>
          {pathSegments.length && (
            <Breadcrumbs
              names={[
                t('breadcrumbs.home'),
                t('breadcrumbs.vacancies'),
                vacancyDetails?.position || '',
              ]}
              segments={[
                pathSegments[0],
                pathSegments[1],
                pathSegments[2] + '/' + pathSegments[3],
              ]}
            />
          )}
        </Col>
        <Col md={3}>
          <button
            className='btn btn-outline-primary btn-outline-color w-100'
            onClick={() => {
              navigate(`/company/vacancies/${vacancyId}/edit`);
            }}
          >
            {t('editButton')}
          </button>
        </Col>
      </Row>
      <Row className='offer-details-row'>
        {vacancyDetails && (
          <>
            <Col>
              <h2 className='page-heading-2 fw-bold mt-5 mb-4'>
                {vacancyDetails.position}
              </h2>
              <h2 className='general-heading fw-bold mt-4 mb-4 text-dark-blue-alt'>
                {t('companyVacancyDetails.title')}
              </h2>

              <Row>
                <Col md={6}>
                  <VacancyInfo
                    title={t('companyVacancyDetails.companyNameLabel') + ':'}
                    info={vacancyDetails.company.name}
                  />
                </Col>
                <Col md={6}>
                  <VacancyInfo
                    title={t('companyVacancyDetails.sector') + ':'}
                    info={getItemById(sectorOptions, vacancyDetails.sector)}
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={6}>
                  <VacancyInfo
                    title={t('companyVacancyDetails.position') + ':'}
                    info={vacancyDetails.position}
                  />
                </Col>
                <Col md={6}>
                  <VacancyInfo
                    title={t('companyVacancyDetails.vacanciesAmount') + ':'}
                    info={vacancyDetails.number_of_vacancies.toString()}
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={6}>
                  <VacancyInfo
                    title={t('vacancyDetails.labels.range') + ':'}
                    info={salaryRange(
                      +vacancyDetails.salary_min,
                      +vacancyDetails.salary_max,
                      t('salary.minPrefix'),
                      t('salary.maxPrefix')
                    )}
                  />
                </Col>
                <Col md={6}>
                  <VacancyInfo
                    title={t('vacancyDetails.labels.type') + ':'}
                    info={getItemById(jobTypeList, vacancyDetails.job_type)}
                  />
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={12}>
                  <VacancyInfo
                    title={t('vacancyDetails.labels.description') + ':'}
                    info={vacancyDetails.description}
                  />
                </Col>
              </Row>

              <h2 className='general-heading fw-bold mt-4 mb-4 text-dark-blue-alt'>
                {t('vacancyDetails.infoTitle')}
              </h2>
              {vacancyDetails.experience?.length > 0 && (
                <>
                  <h6 className='offer-form-header mt-5 mb-4'>
                    {t('vacancyDetails.subtitles.experience')}
                  </h6>

                  {vacancyDetails.experience?.map(
                    (experience: VacancyExperience, index: number) => (
                      <Row className='mt-3' key={index}>
                        <Col md={6}>
                          <VacancyInfo
                            title={t('vacancyDetails.labels.experience') + ':'}
                            info={experience.position}
                          />
                        </Col>
                        <Col md={6}>
                          <VacancyInfo
                            title={
                              t('vacancyDetails.labels.experienceAmount') + ':'
                            }
                            info={experience.duration}
                          />
                        </Col>
                      </Row>
                    )
                  )}
                </>
              )}

              {vacancyDetails.language?.length > 0 && (
                <>
                  <h6 className='offer-form-header mt-5 mb-4'>
                    {t('vacancyDetails.subtitles.language')}
                  </h6>
                  {vacancyDetails.language.map(
                    (language: Language, index: number) => (
                      <Row className='mt-3' key={index}>
                        <Col md={6}>
                          <VacancyInfo
                            title={t('vacancyDetails.labels.language') + ':'}
                            info={getLanguageTranslation(
                              language,
                              i18n.language
                            )}
                          />
                        </Col>
                        <Col md={6}>
                          <VacancyInfo
                            title={
                              t('vacancyDetails.labels.languageLevel') + ':'
                            }
                            info={getLanguageLevel(+language.level, levels)}
                          />
                        </Col>
                      </Row>
                    )
                  )}
                </>
              )}

              {vacancyDetails.qualities && (
                <>
                  <h6 className='offer-form-header mt-5 mb-4'>
                    {t('vacancyDetails.subtitles.skill')}
                  </h6>

                  <Row className='mt-3'>
                    <Col md={6}>
                      {vacancyDetails.qualities && (
                        <div className='details__list'>
                          <p>{t('vacancyDetails.labels.quality')}: </p>
                          <ul>
                            {vacancyDetails.qualities.map(
                              (item, index: number) => (
                                <li key={item.id || index}>
                                  {getItemById(aptitudeList, item.name)}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      {vacancyDetails.offercapabilitie && (
                        <div className='details__list'>
                          <p>{t('vacancyDetails.labels.capability')}: </p>
                          <ul>
                            {vacancyDetails.offercapabilitie?.map(
                              (item, index) => (
                                <li key={index}>
                                  {getItemById(aptitudeList, item.name)}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}

              {vacancyDetails.drivingLicense?.length > 0 && (
                <>
                  <h6 className='offer-form-header mt-5 mb-4'>
                    {t('vacancyDetails.subtitles.license')}
                  </h6>

                  <Row className='mt-3'>
                    <Col md={12}>
                      <VacancyInfo
                        title={t('vacancyDetails.labels.license') + ':'}
                        info={
                          (
                            vacancyDetails.drivingLicense as unknown as string[]
                          ).join(', ') || t('vacancyDetails.notRequired')
                        }
                      />

                      <VacancyInfo
                        title={t('licenseForm.vehicleLabel') + ':'}
                        info={
                          vacancyDetails.is_vehicle
                            ? t('licenseForm.secondOption')
                            : t('licenseForm.firstOption')
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}

              {vacancyDetails.training?.[0] && (
                <>
                  <h6 className='offer-form-header mt-5 mb-4'>
                    {t('vacancyDetails.subtitles.education')}
                  </h6>
                  <Row className='mt-3'>
                    <Col md={12}>
                      <VacancyInfo
                        title={t('vacancyDetails.labels.education') + ':'}
                        info={getItemById(
                          educationLevelList,
                          vacancyDetails.training[0].level
                        )}
                      />
                      <ul>
                        {vacancyDetails.training[0].name?.map((id, index) => (
                          <li key={index}>
                            {getCurrentLevelEducationName(
                              vacancyDetails.training?.[0].level || '0',
                              id,
                              i18n.language
                            )}
                          </li>
                        ))}
                      </ul>
                    </Col>
                  </Row>
                </>
              )}

              <h6 className='offer-form-header mt-5 mb-4'>
                {t('vacancyDetails.subtitles.location')}
              </h6>

              <Row className='mt-3'>
                <Col md={12}>
                  <VacancyInfo
                    title={t('vacancyDetails.labels.location') + ':'}
                    info={vacancyDetails.location}
                  />
                </Col>
              </Row>

              <h6 className='offer-form-header mt-5 mb-4'>
                {t('companyVacancyDetails.endDate')}
              </h6>
              <Row className='mt-3'>
                <Col md={12}>
                  <VacancyInfo
                    title={t('companyVacancyDetails.endDateLabel') + ':'}
                    info={vacancyDetails.end_date}
                  />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default VacancyDetailCompany;
