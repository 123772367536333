import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tokenService from '../../_services/token.service';
import axios from 'axios';
import { config } from '../../_config/config';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import { MatchPayload } from '../../models/match-payload';
import { AIModels, matchStarted } from '../../constants/constants';
import { IAIModels } from '../../models/ai-models';
import {getCandidateNumberByStatus} from "../../utils/get-candidate-status-number";

interface CompanyCandidatesSlice {
  assignedCandidates: AssignedCandidate[];
  total: number;
}

const initialState: CompanyCandidatesSlice = {
  assignedCandidates: [],
  total: 0,
};

export const getAssignedCandidates = createAsyncThunk(
  'companyCandidates/getAssignedCandidates',
  async ({
    pageNumber,
    searchValue,
    size,
    statusValue,
    name,
    position,
    aiMatch,
  }: {
    pageNumber: number;
    searchValue: string;
    size: number;
    statusValue: string;
    name?: boolean;
    position?: boolean;
    aiMatch?: boolean;
  }) => {
    const token = tokenService.getLocalAccessToken();
    const filterQuery = `?page=${pageNumber}&size=${size}&offer_id=${searchValue}${
      statusValue ? `&status=${getCandidateNumberByStatus(statusValue)}` : ''
    }${name ? `&name=${name}` : ''}${position ? `&position=${position}` : ''}${
      aiMatch ? `&ai_match=${aiMatch}` : ''
    }`;

    const response = await axios.get(
      config.SERVER_URL + `candidate/company_list/${filterQuery}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);


export const setCandidateStatus = createAsyncThunk(
  '/companyCandidates/setCandidateStatus',

  async ({
    offer_id,
    candidate_id,
    status
  }: {
    offer_id: string;
    candidate_id: string;
    status: number;
  }) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.put(
      config.SERVER_URL + 'offer/applied/change/status/' + offer_id + '/' + candidate_id,
      { status: status },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const setCandidateSeen = createAsyncThunk(
  '/companyCandidates/setCandidateSeen',

  async ({
    offer_id,
    candidate_id,
    is_seen
  }: {
    offer_id: string;
    candidate_id: string;
    is_seen: boolean;
  }) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.put(
      config.SERVER_URL + 'offer/applied/change/seen/' + offer_id + '/' + candidate_id,
      { is_seen: is_seen },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);


export const saveCandidate = createAsyncThunk(
  '/companyCandidates/saveCandidate',
  async (id: string) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.post(
      config.SERVER_URL + 'company/save/candidate/',
      { candidate: id },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const unsaveCandidate = createAsyncThunk(
  '/companyCandidates/unsaveCandidate',
  async (id: string) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.delete(
      config.SERVER_URL + 'company/save/candidate/' + id + '/',
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

export const calculateCandidateMatch = createAsyncThunk(
  '/companyCandidate/calculateMatch',
  async ({
    matchArr,
    selectedMatchIds,
  }: {
    matchArr: MatchPayload[];
    selectedMatchIds: string[];
  }) => {
    const token = tokenService.getLocalAccessToken();
    await axios.post(
      `${config.SERVER_URL}candidate/ai-match/`,
      { data: matchArr },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return selectedMatchIds;
  }
);

const companyCandidatesSlice = createSlice({
  name: 'companyCandidates',
  initialState,
  reducers: {
    updateMatchDataById: (state, action) => {
      const index = state.assignedCandidates.findIndex(
        (candidate) => candidate.id === action.payload.id
      );
      if (index >= 0) {
        state.assignedCandidates[index].ai_match?.unshift(
          action.payload.ai_match
        );
      }
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAssignedCandidates.fulfilled, (state, action) => {
        state.total = action.payload.body.totalElements;
        state.assignedCandidates = action.payload.body.data;
        state.assignedCandidates.map((item) => (item.checked = false));
      })
      .addCase(calculateCandidateMatch.fulfilled, (state, action) => {
        const selectedMatchIds = action.payload;
        selectedMatchIds.map((id) => {
          const selectedItemIndex = state.assignedCandidates.findIndex(
            (cand) => cand.id === id
          );

          state.assignedCandidates[selectedItemIndex].ai_match?.unshift({
            ai_model: AIModels.main as unknown as IAIModels,
            process_status: matchStarted,
          });
        });

        toast.success(i18n.t('messages.matchMessage'));
      });
  },
});

export const { updateMatchDataById } = companyCandidatesSlice.actions;
export default companyCandidatesSlice.reducer;
