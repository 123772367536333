import { Field, Formik, Form as FormikForm } from 'formik';
import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import OffersStepProps from '../../../../models/offer-step.interface';
import OfferStepActions from '../../../../components/ui/offer-step-actions/OfferStepActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  setStepThreeCount,
  setVacancyFormJobDescription,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';

const OffersStep3 = ({
  currentStep,
  goToPreviousStep,
  goToNextStep,
  openResultWindow,
}: OffersStepProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const offerForm = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy
  );
  const validationSchema = object({
    salary: string().required(t('errors.fieldRequired')),
    jobType: string().required(t('errors.fieldRequired')),
    description: string().required(t('errors.fieldRequired')),
  });
  const salaryRangeList: { value: string; option: string }[] = t(
    'salaryRangeList',
    { returnObjects: true }
  );
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });

  function onFormChange(values: any) {
    let count = 0;
    for (const [key, value] of Object.entries(values)) {
      const val = (value as unknown as string) ? count++ : count;
    }

    dispatch(setStepThreeCount(count));
  }

  return (
    <>
      <h2 className='page-heading-2 text-dark-blue-alt my-3 fw-bold'>
        {t('createOffer.card3.title')}
      </h2>
      <div className='mt-5'>
        <Formik
          validate={(values) => {
            onFormChange(values);
          }}
          initialValues={{
            salary: offerForm.salary_min
              ? offerForm.salary_min + ' - ' + offerForm.salary_max
              : salaryRangeList[0].value,
            jobType: offerForm.job_type || '1',
            description: offerForm.description,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(setVacancyFormJobDescription(values));
            dispatch(updateProgress());
            openResultWindow();
          }}
        >
          {({ errors, touched }) => (
            <FormikForm>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='offer-form-label mb-3'>
                  {t('createOffer.step3.salaryLabel')}
                </Form.Label>
                <Row>
                  <Col md={12}>
                    <InputGroup hasValidation>
                      <Field
                        as='select'
                        name='salary'
                        className='form-control modal-input cursor-pointer'
                        aria-label='Default select example'
                      >
                        {salaryRangeList.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.option}
                          </option>
                        ))}
                      </Field>
                      {errors.salary && touched.salary ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.salary}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col md={12}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('vacancyDetails.labels.type')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        as='select'
                        name='jobType'
                        className='form-control modal-input cursor-pointer'
                        aria-label='Default select example'
                      >
                        {jobTypeList.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.jobType && touched.jobType ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.jobType}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('vacancyDetails.labels.description')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        as='textarea'
                        className='form-control modal-input'
                        name='description'
                        rows={7}
                      />
                      {errors.description && touched.description ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.description}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <OfferStepActions
                currentStep={currentStep}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                isLastStep={true}
              />
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OffersStep3;
