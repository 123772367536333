import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Col, Image, Row } from 'react-bootstrap';
import tokenService from '../../../_services/token.service';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { decodeToken } from '../../../models/decode-token';
import styles from "../../company/home/HomeCompany.module.scss";
import {getCompanies} from "../../../store/slices/companySlice";
import {getCandidates} from "../../../store/slices/candidateSlice";
import {getApplications, getOffers} from "../../../store/slices/vacanciesSlice";
import Tabs from '../../../components/ui/tabs/Tabs';
import Table from '../../../components/ui/tab-content/TabContent';
import './HomeAdmin.scss';
import {AdminCompany} from "../../../models/admin-company.interface";
import {AdminCandidate} from "../../../models/admin-candidate.interface";
import {AdminOffer} from "../../../models/admin-offer.interface";
import {AdminApplication} from "../../../models/admin-application.interface";

const HomeAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const token = tokenService.getLocalAccessToken();
  const decodedToken = decodeToken(token);
  const navigate = useNavigate();
  const [companyPageNumber, setCompanyPageNumber] = useState<number>(1);
  const [candidatePageNumber, setCandidatePageNumber] = useState<number>(1);
  const [offerPageNumber, setOfferPageNumber] = useState<number>(1);
  const [applicationPageNumber, setApplicationPageNumber] = useState<number>(1);

  const [companiesList, setCompaniesList] = useState([]);

  const companyCount = useSelector(
    (state: RootState) => state.companyReducer.companyCount
  );
  const candidateCount = useSelector(
    (state: RootState) => state.candidateReducer.candidateCount
  );
  const offerCount = useSelector(
    (state: RootState) => state.vacanciesReducer.offerCount
  );
  const applicationCount = useSelector(
    (state: RootState) => state.vacanciesReducer.applicationCount
  );

  const adminCompanies = useSelector(
    (state: RootState) => state.companyReducer.adminCompanies
  );

  const adminCandidates = useSelector(
    (state: RootState) => state.candidateReducer.adminCandidates
  );

  const adminOffers = useSelector(
    (state: RootState) => state.vacanciesReducer.adminOffers
  );

  const adminApplications = useSelector(
    (state: RootState) => state.vacanciesReducer.adminApplications
  );

  useEffect(() => {

    if (!token || !decodedToken || !decodedToken.user_id) {
      navigate('/');
    }

    dispatch(
      getCompanies({
        pageNumber: companyPageNumber,
        searchValue: '',
        size: 50,
        orderBy: '-created_at'
      })
    );
    dispatch(
      getCandidates({
        pageNumber: candidatePageNumber,
        searchValue: '',
        size: 50,
        orderBy: '-created_at'
      })
    );
    dispatch(
      getOffers({
        pageNumber: offerPageNumber,
        searchValue: '',
        size: 50,
        orderBy: '-created_at'
      })
    );
    dispatch(
      getApplications({
        pageNumber: applicationPageNumber,
        searchValue: '',
        size: 50,
        orderBy: '-created_at'
      })
    );
  }, []);


  const dataForCompanies = adminCompanies.map((item: AdminCompany) => {
        return [item.business_name, item.nif, item.created_at, item.sector, item.email, item.offers.length.toString()];
      }
  );

  const dataForCandidates = adminCandidates.map((item: AdminCandidate) => {
        return [item.nif, item.first_name, item.last_name, item.created_at, item.experience[0]?.job_position];
      }
  );

  const dataForOffers = adminOffers.map((item: AdminOffer) => {
        return [item.company.name, item.position, item.sector_text, item.created_at, item.number_of_vacancies.toString()];
      }
  );

  const dataForApplications = adminApplications.map((item: AdminApplication) => {
        return [item.offer.company.name, item.candidate.first_name + " " + item.candidate.last_name, item.offer.position, item.created_at, item.is_seen.toString()];
      }
  );

  const headings = [
      ['Name', 'NIF', 'Created', 'Sector', 'Email', 'Offers'],
      ['NIF', 'Name', 'Surname', 'Created', 'Experience'],
      ['Company', 'Position', 'Sector', 'Created', 'Vacancies'],
      ['Company', 'Candidate', 'Offer', 'Created', 'Seen'],
  ];

  const dataForTabs = [
    dataForCompanies,
    dataForCandidates,
    dataForOffers,
    dataForApplications,
  ];

function onCompaniesPageChange(page: number): void {
  dispatch(
    getCompanies({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  );
  setCompanyPageNumber(page);
}

function onCandidatesPageChange(page: number): void {
  dispatch(
    getCandidates({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  );
  setCandidatePageNumber(page);
}

function onOffersPageChange(page: number): void {
  dispatch(
    getOffers({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  );
  setOfferPageNumber(page);
}

function onApplicationsPageChange(page: number): void {
  dispatch(
    getApplications({
      pageNumber: page,
      searchValue: '',
      size: 50,
      orderBy: '-created_at',
    })
  );
  setApplicationPageNumber(page);
}

  return (
    <>
      <Row>
        <Col lg={9}>
          <div className='zestur-card'>
            <div className='zestur-card__header'>
              <h4>Zas!Jobs Administration</h4>
            </div>
          </div>
          <div className='zestur-card__body'>
            <Row className={styles.tabletGap}>
              <Col lg={3}>
                <div className='card-fixed'>
                  Companies : {companyCount}
                </div>
              </Col>
              <Col lg={3}>
                <div className='card-fixed'>
                  Candidates : {candidateCount}
                </div>
              </Col>
              <Col lg={3}>
                <div className='card-fixed'>
                  Offers : {offerCount}
                </div>
              </Col>
              <Col lg={3}>
                <div className='card-fixed'>
                  Applications : {applicationCount}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
         <div className="App">
            <Tabs tabTitles={['Companies', 'Candidates', 'Offers', 'Applications']}
                  pageHandlers={[onCompaniesPageChange, onCandidatesPageChange, onOffersPageChange, onApplicationsPageChange]}
                  pageNumbers={[companyPageNumber, candidatePageNumber, offerPageNumber, applicationPageNumber]}
            >
              {dataForTabs.map((data, index) => (
                <Table key={index} headings={headings[index]} data={data} />
              ))}
            </Tabs>
          </div>
        </Col>
      </Row>

    </>
  );
};

export default HomeAdmin;
