import React, { useEffect, useState } from 'react';
import styles from './InternetObserver.module.scss';
import wifi from '../../assets/images/no-wifi.png';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../constants';

const InternetObserver: React.FC = () => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {!isOnline && (
        <div className={styles.observer}>
          <div>
            <div>
              <Image src={ASSETS_URL + wifi} />
            </div>
            <span>Unstable internet connection</span>
          </div>
        </div>
      )}
    </>
  );
};

export default InternetObserver;
