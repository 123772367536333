import React from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import Certificate from '../../../models/certificate.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { defaultNotSelectedDate } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  index: number;
  handleClose: () => void;
  handleSubmit: (data: Certificate) => void;
}

const CandidateCertificationModal = ({
  show,
  index,
  handleClose,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const candidateCertificates = useSelector(
    (state: RootState) => state.candidateReducer.certificates
  );
  const candidateId = useSelector(
    (state: RootState) => state.candidateReducer.candidate.userId
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('certificationModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            qualification:
              index >= 0 ? candidateCertificates[index].qualification : '',
            institution:
              index >= 0 ? candidateCertificates[index].institution : '',
            registrationDate:
              index >= 0 ? candidateCertificates[index].registration_date : '',
            certificateCode:
              index >= 0 ? candidateCertificates[index].certificate_code : '',
            certificateLink:
              index >= 0 ? candidateCertificates[index].certificate_link : '',
            description:
              index >= 0 ? candidateCertificates[index].description : '',
          }}
          onSubmit={(values) => {
            if (index >= 0) {
              handleSubmit({
                id: candidateCertificates[index].id || '',
                user: candidateId,
                qualification: values.qualification,
                institution: values.institution,
                registration_date:
                  values.registrationDate || defaultNotSelectedDate,
                certificate_code: values.certificateCode,
                certificate_link: values.certificateLink,
                description: values.description,
              });
            } else {
              handleSubmit({
                user: candidateId,
                qualification: values.qualification,
                institution: values.institution,
                registration_date:
                  values.registrationDate || defaultNotSelectedDate,
                certificate_code: values.certificateCode,
                certificate_link: values.certificateLink,
                description: values.description,
              });
            }
          }}
        >
          {({ errors, touched, values }) => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('certificationModal.qualification')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='qualification'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('certificationModal.institution')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='institution'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('certificationModal.date')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='date'
                      aria-describedby='inputGroupPrepend'
                      name='registrationDate'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('certificationModal.code')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='certificateCode'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('certificationModal.link')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='certificateLink'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('certificationModal.description')}
                  </Form.Label>
                  <InputGroup hasValidation className='textarea'>
                    <Field
                      as='textarea'
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      name='description'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      maxLength={250}
                    />
                    <div className='textarea__counter'>
                      {values.description.length}/250
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateCertificationModal;
