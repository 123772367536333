import React from 'react';

import save from '../../../assets/icons/save.svg';
import bookmark from '../../../assets/icons/bookmark.svg';
import './SaveButton.scss';
import {useTranslation} from "react-i18next";

interface Props {
  saved?: boolean;
  id: string;
  toggleSave: (id: string, saved: boolean) => void;
}

const SaveButton = ({ saved, id, toggleSave }: Props) => {
  const { t } = useTranslation();
  return (
    <button
      className={`save-btn${saved ? ' activated' : ''}`}
      onClick={(event) => {
        event.stopPropagation();
        toggleSave(id, !saved);
      }}
      title={t('submitButton')}
    >
      <img
        src={saved ? bookmark : save}
        alt='save'
        className={saved ? 'white-filter' : 'grey-filter'}
      />
    </button>
  );
};

export default SaveButton;
