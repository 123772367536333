// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyNewestCandidate_candidateList__4DrVg {
  display: flex;
  flex-direction: column;
  gap: 26px;
  font-weight: 300;
  width: 100%; }

.CompanyNewestCandidate_candidateListItem__Jk1P1 {
  display: grid;
  grid-template-columns: 2.5fr repeat(2, 1fr) auto;
  column-gap: 1rem;
  align-items: center;
  justify-content: center; }

.CompanyNewestCandidate_candidateListMobile__FZKw9 {
  display: flex;
  flex-direction: column;
  gap: 26px; }

.CompanyNewestCandidate_avatar__LZnky {
  width: 61px;
  height: 61px;
  border-radius: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/company-newest-candidates/CompanyNewestCandidate.module.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAGA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBCYc;EDXd,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,gDAAgD;EAChD,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB,EAAA;;AAGzB;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS,EAAA;;AAGX;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB,EAAA","sourcesContent":["@import '../../../assets/scss/breakpoint/breakpoints-mixins';\n@import '../../../assets/scss/variable';\n\n.candidateList {\n  display: flex;\n  flex-direction: column;\n  gap: 26px;\n  font-weight: $font-light;\n  width: 100%;\n}\n\n.candidateListItem {\n  display: grid;\n  grid-template-columns: 2.5fr repeat(2, 1fr) auto;\n  column-gap: 1rem;\n  align-items: center;\n  justify-content: center;\n}\n\n.candidateListMobile {\n  display: flex;\n  flex-direction: column;\n  gap: 26px;\n}\n\n.avatar {\n  width: 61px;\n  height: 61px;\n  border-radius: 100%;\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"candidateList": `CompanyNewestCandidate_candidateList__4DrVg`,
	"candidateListItem": `CompanyNewestCandidate_candidateListItem__Jk1P1`,
	"candidateListMobile": `CompanyNewestCandidate_candidateListMobile__FZKw9`,
	"avatar": `CompanyNewestCandidate_avatar__LZnky`
};
export default ___CSS_LOADER_EXPORT___;
