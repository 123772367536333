import { defaultNotSelectedDate } from '../../constants/constants';
import Education from '../../models/education.interface';

export function getEducationFromAiResult(aiData: Education[]) {
  return aiData.map((item: Education) => {
    item.title = item.title.substring(0, 99);
    item.longitude = 0;
    item.latitude = 0;

    if (!item.start_date) item.start_date = getDefaultNoDate();
    if (!item.end_date) item.end_date = getDefaultNoDate();

    if (item.end_date === defaultNotSelectedDate || item.end_date === 'Today') {
      item.end_date = item.start_date || defaultNotSelectedDate;
    }

    return item;
  });
}

const getDefaultNoDate = () => '2024-05-05';
