// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #213867;
  background-color: transparent;
  border: none; }
  .download img {
    width: 26px;
    height: 26px;
    margin-right: 6px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/download-resume-button/DownloadResumeButton.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,gBCkBkB;EDjBlB,iBAAiB;EACjB,cCJiB;EDKjB,6BAA6B;EAC7B,YAAY,EAAA;EANd;IASI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.download {\n  font-size: 16px;\n  font-weight: $font-semi-bold;\n  line-height: 16px;\n  color: $dark-blue;\n  background-color: transparent;\n  border: none;\n\n  img {\n    width: 26px;\n    height: 26px;\n    margin-right: 6px;\n  }\n}","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
