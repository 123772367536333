import React from 'react';
import { Modal, Image, ModalBody, Button } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import warningIcon from '../../../assets/icons/warning.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import {useNavigate} from "react-router-dom";

interface Props {
  show: boolean;
  handleClose: () => void;
}

const UnknownSocialModal = ({ show, handleClose }: Props) => {

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  return (
    <Modal className='unknownsocial' show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <h4>{t('auth.unknownUserHeading')}</h4>
      </Modal.Header>
      <ModalBody className='text-center py-3'>
        <p className='pb-3'>{t('auth.unknownUserText')}</p>
        <div className='py-2 cursor-pointer bold' onClick={() => navigate('/auth/company/sign-up')}>
          {t('loginForm.registerCompany')}
        </div>
        <div className='cursor-pointer bold' onClick={() => navigate('/auth/candidate/sign-up')}>
          {t('loginForm.registerCandidate')}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UnknownSocialModal;
