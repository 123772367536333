import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedCount: number;
  children: React.JSX.Element[];
}

const ActionBar = ({ selectedCount, children }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='action-row'>
      <div className='action-row-name'>
        {selectedCount} {t('vacanciesTablet.actionBar.countLabel')}
      </div>
      <div className='action-row-actions'>{children}</div>
    </div>
  );
};

export default ActionBar;
