import React from 'react';
import './DeleteButton.scss';
import deleteIcon from '../../../assets/icons/blue-delete.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  isRed?: boolean;
  action: (index: number) => void;
}

const DeleteButton = ({ action, index, isRed }: Props) => {
  const { t } = useTranslation();

  return (
    <button
      className={`delete-button ${isRed ? 'red' : ''}`}
      type='button'
      onClick={() => action(index)}
    >
      <img
        src={deleteIcon}
        alt='delete'
        className={isRed ? 'red-filter' : ''}
      />
      {t('deleteButton')}
    </button>
  );
};

export default DeleteButton;
