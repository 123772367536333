import i18n from '../i18n/i18n';
import CandidateTag from '../models/candidate-tag.interface';
import { ListItem } from '../models/list-item';

export function getLocalSkillListFromAI(
  skillType: 'hard' | 'soft',
  data: { name: string }[]
) {
  const result: CandidateTag[] = [];
  const localSkillList: ListItem[] = i18n.t(
    'aptitudeList',
    {
      returnObjects: true,
      lng: 'en',
    }
  );

  data.forEach((item: { name: string }) => {
    const listSkill = localSkillList.find(
      (skl) => skl.name.toLowerCase() === item.name.toLowerCase()
    );
    if (listSkill) {
      result.push({
        name: listSkill.id,
        description: listSkill.name,
      });
    }
  });

  return result;
}
