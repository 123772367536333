import React from 'react';
import checkmarkIcon from '../../../assets/icons/checkmark-with-background.svg';
import styles from './MatchNotification.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  notification: any;
}

const MatchNotification = ({ notification }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.successMessage}>
      <img src={checkmarkIcon} alt='checkmark' />
      <span>
        {notification.message === 'Ai Match Process Completed'
          ? t('notifications.match.success')
          : notification.message}
      </span>
    </div>
  );
};

export default MatchNotification;
