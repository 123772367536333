import React from 'react';
import greenDot from '../../../assets/icons/green-circle.svg';
import yellowDot from '../../../assets/icons/yellow-circle.svg';
import redDot from '../../../assets/icons/red-circle.svg';
import { Spinner } from 'react-bootstrap';

interface Props {
  percentage: string;
  processing?: boolean;
  withManual: boolean;
}

const MatchButton = ({ percentage, withManual, processing }: Props) => {
  const checkColor = (percentage: number) => {
    if (percentage >= 75) return greenDot;
    if (percentage >= 50) return yellowDot;
    if (percentage >= 0) return redDot;
  };

  return (
    <div
      style={{ width: withManual ? '41px' : '' }}
      className='d-flex justify-content-center'
    >
      {processing ? (
        <Spinner
          animation='border'
          variant='info'
          style={{ width: '1,5rem', height: '1,5rem' }}
        />
      ) : (
        <img
          src={checkColor(+percentage * 10)}
          alt='match'
          style={{ width: '16px' }}
        />
      )}
    </div>
  );
};

export default MatchButton;
