import { Field, Formik, Form as FormikForm } from 'formik';
import React from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import OffersStepProps from '../../../../models/offer-step.interface';
import OfferStepActions from '../../../../components/ui/offer-step-actions/OfferStepActions';
import { date, object, string } from 'yup';
import {
  setStepOneCount,
  setVacancyFormMainInfo,
} from '../../../../store/slices/vacancyCreateSlice';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../../models/list-item';

const OfferStep1 = ({
  currentStep,
  goToPreviousStep,
  goToNextStep,
}: OffersStepProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const vacancyForm = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy
  );
  const sectorOptions: ListItem[] = t('sectorOptions', { returnObjects: true });
  const validationSchema = object({
    sector: string().required(t('errors.fieldRequired')),
    position: string().required(t('errors.fieldRequired')),
    places: string().required(t('errors.fieldRequired')),
    location: string().required(t('errors.fieldRequired')),
    date: date().required(t('errors.fieldRequired')),
  });
  const today = new Date().toISOString().split('T')[0];

  function onFormChange(values: any) {
    let count = 0;
    for (const [key, value] of Object.entries(values)) {
      const val = (value as unknown as string) ? count++ : count;
    }

    dispatch(setStepOneCount(count));
  }

  return (
    <>
      <h2 className='page-heading-2 text-dark-blue-alt my-3 fw-bold'>
        {t('createOffer.step1.title')}
      </h2>
      <div className='mt-5'>
        <Formik
          validate={(values) => {
            onFormChange(values);
          }}
          initialValues={{
            sector: vacancyForm.sector,
            position: vacancyForm.position,
            places: vacancyForm.number_of_vacancies,
            location: vacancyForm.location,
            date: vacancyForm.end_date,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(setVacancyFormMainInfo(values));
            goToNextStep();
          }}
        >
          {({ values, errors, touched }) => (
            <FormikForm>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='offer-form-label mb-3'>
                  {t('createOffer.card1.key1')}
                </Form.Label>
                <InputGroup hasValidation>
                  <Field
                    as='select'
                    name='sector'
                    className='form-control modal-input'
                  >
                    <option value=''></option>
                    {sectorOptions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  {errors.sector && touched.sector ? (
                    <p className='text-danger position-absolute error-message candidate'>
                      {errors.sector}
                    </p>
                  ) : null}
                </InputGroup>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('companyVacancyDetails.position')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        type='text'
                        name='position'
                        placeholder=''
                        className='form-control modal-input'
                      />
                      {errors.position && touched.position ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.position}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('createOffer.step1.vacanciesAmount')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        type='number'
                        min={0}
                        name='places'
                        className='form-control modal-input'
                        placeholder=''
                      />
                      {errors.places && touched.places ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.places}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('createOffer.card1.key2')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        type='text'
                        name='location'
                        className='form-control modal-input'
                        placeholder=''
                      />
                      {errors.location && touched.location ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.location}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='offer-form-label mb-3'>
                      {t('createOffer.step1.endDate')}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Field
                        type='date'
                        name='date'
                        className='form-control modal-input'
                        min={today}
                      />
                      {errors.date && touched.date ? (
                        <p className='text-danger position-absolute error-message candidate'>
                          {errors.date}
                        </p>
                      ) : null}
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <OfferStepActions
                currentStep={currentStep}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
              />
            </FormikForm>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OfferStep1;
