import React, { useState } from 'react';
import i18n from '../../i18n/i18n';
import './LanguageSelector.scss';
import SpainFlag from '../../assets/flags/es.svg';
import BritainFlag from '../../assets/flags/gb.svg';
import FranceFlag from '../../assets/flags/fr.svg';
import PortugalFlag from '../../assets/flags/pt.svg';

const languages = [
  {
    acronym: 'fr',
    icon: FranceFlag,
    name: 'Français',
  },
  {
    acronym: 'en',
    icon: BritainFlag,
    name: 'English',
  },
  {
    acronym: 'sp',
    icon: SpainFlag,
    name: 'Español',
  },
  {
    acronym: 'pt',
    icon: PortugalFlag,
    name: 'Português',
  },
];

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const chooseLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    localStorage.setItem('lang', e.target.value);
    i18n.changeLanguage(e.target.value);
    setSelectedLanguage(e.target.value);
  };

  const getFlagIcon = (): string => {
    return languages.find((lang) => lang.acronym === i18n.language)?.icon || '';
  };

  return (
    <div className='language-selector cursor-pointer'>
      <img src={getFlagIcon()} alt='flag' />
      <select
        className='language-selector__box'
        defaultValue={selectedLanguage}
        onChange={chooseLanguage}
      >
        {languages.map((lang) => (
          <option value={lang.acronym} key={lang.acronym}>{lang.name}</option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
