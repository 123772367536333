import { LOGOUT, REFRESH_TOKEN } from '../actions/types';

const userString = localStorage.getItem('user');
const user = userString ? JSON.parse(userString) : null;

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function (state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: { ...user, accessToken: payload },
      };
    default:
      return state;
  }
}
