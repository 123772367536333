import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { toast } from 'react-toastify';
import { setEmailVerification } from '../../store/slices/emailVerificationSlice';

const ActivationMessageCheck = ({
  component,
}: {
  component: React.JSX.Element;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const isActivated = useSelector(
    (state: RootState) => state.emailVerificationReducer.withMessage
  );

  useEffect(() => {
    if (isActivated) {
      toast.success(t('messages.emailVerificated'));
      dispatch(setEmailVerification(false));
    }
  }, []);
  return component;
};

export default ActivationMessageCheck;
