// A1 = 1-2
// A2 = 3-4
// B1 = 5
// B2 = 6
// C1 = 7
// C2 = 8-9
// Native = 10
export function getLangLevelFromAiResult(level: number): number {
  return level === 1 || level === 2
    ? 1
    : level === 3 || level === 4
    ? 2
    : level === 5
    ? 3
    : level === 6
    ? 4
    : level === 7
    ? 5
    : level === 8 || level === 9
    ? 6
    : 7;
}
