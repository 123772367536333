import React from 'react';
import Experience from '../../../models/experience.interface';
import RegistrationCardActions from '../registration-card-actions/RegistrationCardActions';
import DotsButton from '../dots-button/DotsButton';
import { getYear } from '../../../utils/get-year';
import { getExperienceEndDateString } from '../../../utils/get-experience-end-date-string';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  experience: Experience;
  editHandler: (index: number) => void;
  deleteHandler: (index: number) => void;
  withEdit?: boolean;
}

const CandidateExperienceCard = ({
  experience,
  index,
  editHandler,
  deleteHandler,
  withEdit = true,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className='info-section mb-4'>
      <div className='d-flex align-items-center justify-content-start'>
        <div className='ms-1 w-100'>
          <div className='info-section-title d-flex justify-content-between'>
            {experience.job_position}
            {withEdit && (
              <>
                <div className='actions mobile-hidden'>
                  <RegistrationCardActions
                    edit={editHandler}
                    deleteCard={deleteHandler}
                    index={index}
                  />
                </div>
                <div className='no-mobile-hidden'>
                  <DotsButton
                    edit={editHandler}
                    deleteHandler={deleteHandler}
                    index={index}
                  />
                </div>
              </>
            )}
          </div>
          <div className='d-flex align-items-center mt-3'>
            <div className='info-section-sub-title'>{experience.company}</div>
            <div className='info-section-date ms-5'>
              {getYear(experience.start_date)} -{' '}
              {getExperienceEndDateString(
                getYear(experience.end_date),
                t('experienceForm.actualPlaceholder')
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='ms-1 mt-3 mb-3'>
        <div className='info-section-description'>{experience.description}</div>
      </div>
    </div>
  );
};

export default CandidateExperienceCard;
