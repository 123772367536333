import React from 'react';
import styles from './CompanyNewestCandidate.module.scss';
import { Image } from 'react-bootstrap';
import mapPin from '../../../assets/icons/map-pin.svg';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import { ASSETS_URL } from '../../../constants';
import { AssignedCandidate } from '../../../models/assigned-candidate.interface';
import { useTranslation } from 'react-i18next';
import NavigationButton from '../../../components/ui/navigation-button/NavigationButton';
import EyeIcon from '../eye-icon/EyeIcon';
import { useNavigate } from 'react-router-dom';
import MatchButtonGroup from '../../match-button-group/MatchButtonGroup';

interface Props {
  candidates: AssignedCandidate[];
}

const CompanyNewestCandidates = ({ candidates }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className={`${styles.candidateList} w-100 tablet-hidden`}>
        {candidates
          .slice(0, 4)
          .map((candidate: AssignedCandidate, index: number) => (
            <div className={styles.candidateListItem} key={index}>
              <div className='d-flex align-items-center gap-3 mw-0'>
                <Image
                  className={`${styles.avatar} object-fit-cover`}
                  src={candidate.candidate.avatar || candidateImage}
                />
                <span className='text-truncate'>
                  {candidate.candidate.first_name}{' '}
                  {candidate.candidate.last_name}
                </span>
              </div>
              <span>{candidate.offer?.name || ''}</span>
              <div className='d-flex'>
                <Image src={ASSETS_URL + mapPin} />
                <span className='ps-2'>{candidate.candidate.city || ''}</span>
              </div>

              <div className='d-flex align-items-center gap-2'>
                <NavigationButton
                  text={t('companyHomePage.candidates.navigation')}
                  url={`/company/applicants/${candidate.candidate.id}/${candidate.offer?.id}`}
                  classes='btn btn-primary text-nowrap w-auto'
                />

                <MatchButtonGroup candidate={candidate} />
              </div>
            </div>
          ))}
      </div>

      <div className={`no-tablet-hidden ${styles.candidateListMobile} mw-0`}>
        {candidates
          .slice(0, 4)
          .map((candidate: AssignedCandidate, index: number) => {
            return (
              <div
                key={index}
                className='d-flex align-items-center justify-content-between'
              >
                <div className='d-flex align-items-center justify-content-between gap-3 mw-0'>
                  <Image
                    className={`object-fit-cover ${styles.avatar}`}
                    src={candidate.candidate.avatar || candidateImage}
                  />

                  <span className='text-truncate'>
                    {candidate.candidate.first_name}{' '}
                    {candidate.candidate.last_name}
                  </span>
                </div>

                <div className='d-flex align-items-center gap-3'>
                  <EyeIcon
                    isOpen={true}
                    action={() =>
                      navigate(
                        `/company/applicants/${candidate.candidate.id}/${candidate.offer?.id}`
                      )
                    }
                    className='position-static'
                  />

                  <MatchButtonGroup candidate={candidate} />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CompanyNewestCandidates;
