import React, { useRef, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import DropdownPopup from '../../dropdown-popup/DropdownPopup';
import Option from '../../../models/option.interface';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import {
  licenseOptionSelect,
  setIsVehicle,
  setLicenseList,
} from '../../../store/slices/licenseSlice';
import { useOutsideHandler } from '../../../utils/outside-click-handler';
import { useTranslation } from 'react-i18next';

interface Props {
  titleClass?: string;
  inputClass?: string;
  licenseList: Option[];
  isVehicle: boolean;
}

const SelectLicenseForm = ({
  titleClass,
  inputClass,
  licenseList,
  isVehicle,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  useOutsideHandler(wrapperRef, setOpen);

  function selectLicense(selectedOption: Option): void {
    if (selectedOption.name === t('licenseForm.noLicense')) {
      dispatch(setIsVehicle(false));
      dispatch(setLicenseList());

      return;
    }

    dispatch(licenseOptionSelect(selectedOption.name));
  }

  function setOpen(isOpen: boolean): void {
    setIsDropdownOpen(isOpen);
  }

  return (
    <Row className='align-items-end'>
      <Form.Group
        className='mobile-mt-2'
        as={Col}
        md={6}
        xs={12}
        controlId='validationCustom02'
      >
        <Form.Label className={`label ${titleClass}`}>
          {t('licenseForm.title')}{' '}
          <span className='font-14'>({t('licenseForm.subtitle')})</span>
        </Form.Label>
        <InputGroup hasValidation>
          <div className='position-relative w-100' ref={wrapperRef}>
            <button
              type='button'
              className={`form-select form-control text-start mt-2 ${
                inputClass ? inputClass : 'px-4 py-3'
              }`}
              onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
            >
              <p className='d-flex gap-2 text-nowrap text-truncate'>
                {licenseList.map((item: Option) => {
                  if (!item.selected) return;
                  return <span key={item.name}>{item.name}</span>;
                })}
              </p>
            </button>

            {isDropdownOpen && (
              <DropdownPopup
                width={'100%'}
                optionList={licenseList}
                selectOption={selectLicense}
              />
            )}
          </div>
        </InputGroup>
      </Form.Group>

      {!licenseList[0].selected && (
        <Form.Group
          className='mobile-mt-2'
          as={Col}
          md={6}
          xs={12}
          controlId='validationCustom02'
        >
          <Form.Label className={`label ${titleClass}`}>
            {t('licenseForm.vehicleLabel')}
          </Form.Label>
          <InputGroup hasValidation>
            <select
              className={`form-select form-control text-start mt-2  ${
                inputClass ? inputClass : 'px-4 py-3'
              }`}
              value={isVehicle ? 'Si' : 'No'}
              onChange={(event) =>
                dispatch(setIsVehicle(event.target.value === 'Si'))
              }
            >
              <option value='No'>{t('licenseForm.firstOption')}</option>
              <option value='Si'>{t('licenseForm.secondOption')}</option>
            </select>
          </InputGroup>
        </Form.Group>
      )}
    </Row>
  );
};

export default SelectLicenseForm;
