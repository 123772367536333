import React, { useState } from 'react';
import back from '../../../assets/icons/back.svg';
import Step from '../../../models/step.interface';
import './RegistrationBackArrow.scss';

interface DataStep {
  data: Step;
  title?: string;
  white?: boolean;
  class: string;
}

const RegistrationBackButton = (props: DataStep) => {
  const [stateVariable] = useState(props.data);

  return (
    <div className='back-arrow'>
      <button
        onClick={stateVariable.handlePrev}
        type='button'
        className={`position-static ${props.class} ${
          props.white ? 'white-filter auth-back' : ''
        }`}
      >
        <img src={back} alt='back' />
      </button>
      {props.title && <h4>{props.title}</h4>}
    </div>
  );
};

export default RegistrationBackButton;
