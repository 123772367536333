import React from 'react';

import warningIcon from '../../../assets/icons/warning-black.svg';

interface Props {
  openModal: () => void;
}

const ReportOfferButton = ({ openModal }: Props) => {
  return (
    <button
      className='save-btn'
      onClick={(event) => {
        event.stopPropagation();
        openModal();
      }}
    >
      <img src={warningIcon} alt='save' className={'grey-filter'} />
    </button>
  );
};

export default ReportOfferButton;
