import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ExperienceModal from '../modals/ExperienceModal';
import TrainingModal from '../modals/TrainingModal';
import LanguagesModal from '../modals/LanguagesModal';
import QualitiesModal from '../modals/QualitiesModal';
import CapabilitiesModal from '../modals/CapabilitiesModal';
import LicenseModal from '../modals/LicenseModal';
import { useSelector } from 'react-redux';
import OffersStepProps from '../../../../models/offer-step.interface';
import { RootState } from '../../../../store/store';
import OfferStepActions from '../../../../components/ui/offer-step-actions/OfferStepActions';
import { useTranslation } from 'react-i18next';

interface Content {
  content: React.ReactNode;
  title: string;
}

const OffersStep2 = ({
  currentStep,
  goToPreviousStep,
  goToNextStep,
}: OffersStepProps) => {
  const { t } = useTranslation();
  const offerForm = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy
  );
  const [showModal, setShowModal] = useState(false);
  const [currentContent, setCurrentContent] = useState<any>({});

  const handleShowModal = (content: React.ReactNode, title: string) => {
    const data: Content = {
      content,
      title,
    };
    setCurrentContent(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const components = [
    {
      id: 1,
      label: t('createOffer.card2.key1'),
      content: (
        <ExperienceModal
          experience={
            offerForm.offerexperience[0] || {
              id: '',
              sector: '',
              position: '',
              duration: '',
              offer: '',
            }
          }
          close={handleCloseModal}
        />
      ),
    },
    {
      id: 2,
      label: t('vacancyDetails.labels.education'),
      content: (
        <TrainingModal
          education={
            offerForm.offertraining[0] || {
              level: '',
              level_description: '',
              name: '',
              name_description: [],
            }
          }
          close={handleCloseModal}
        />
      ),
    },
    {
      id: 3,
      label: t('vacancyDetails.subtitles.language'),
      content: (
        <LanguagesModal
          language={offerForm.offerlanguage || { language: '', level: '' }}
          close={handleCloseModal}
        />
      ),
    },
    {
      id: 4,
      label: t('vacancyDetails.labels.quality'),
      content: (
        <QualitiesModal
          qualities={offerForm.offerqualities}
          close={handleCloseModal}
        />
      ),
    },
    {
      id: 5,
      label: t('vacancyDetails.labels.capability'),
      content: (
        <CapabilitiesModal
          capabilities={offerForm.offercapabilitie}
          close={handleCloseModal}
        />
      ),
    },
    {
      id: 6,
      label: t('vacancyDetails.labels.license'),
      content: (
        <LicenseModal
          license={offerForm.drivingLicense}
          close={handleCloseModal}
        />
      ),
    },
  ];

  return (
    <>
      <h2 className='page-heading-2 text-dark-blue-alt my-3 fw-bold'>
        {t('createOffer.card2.title')}
      </h2>
      <div className='mt-5'></div>
      {components.map(({ id, label, content }) => (
        <div key={id} className='d-flex align-items-center mb-4'>
          <h6 className='offer-form-label py-2'>{label} </h6>
          <button
            type='button'
            className='add-btn ms-2'
            onClick={() => handleShowModal(content, label)}
          >
            +
          </button>
        </div>
      ))}

      <OfferStepActions
        currentStep={currentStep}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
        type='button'
      />

      <Modal show={showModal} onHide={handleCloseModal} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{currentContent?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{currentContent.content}</Modal.Body>
      </Modal>
    </>
  );
};

export default OffersStep2;
