import React from 'react';
import {Modal} from 'react-bootstrap';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {CompanyState} from "../../../store/slices/companySlice";
import {toast} from "react-toastify";


interface Props {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (data: CompanyState) => void;
}

const SmartPlanModal = ({
  show,
  handleClose,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Body className='text-center py-5'>
        <h1 className='fw-bold my-4'>{t('planPage.smart.title')} {t('planPage.smart.name')}</h1>
        <p className='f-12 mt-4'>{t('smartModal.smartModalText')}</p>
        <div className='d-flex align-items-center justify-content-center mt-4'>
          <button
            className='btn btn-primary primary-btn-color'
            onClick={() => {
              handleSubmit(company);
              handleClose();
            }}
          >
            {t('smartModal.submitButton')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SmartPlanModal;
