import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import blogImg1 from '../../../assets/images/blog-img-1.svg';
import SearchBar from '../../../components/shared/search-bar/SearchBar';

const Blog = () => {
  return (
    <>
      <Row>
        <Col md={6}>
          <SearchBar location='blog' />
        </Col>
      </Row>
      <Row className='gy-4'>
        {Array(4)
          .fill(null)
          .map((_, index: number) => {
            return (
              <Col md={4} key={index}>
                <div className='blog-card'>
                  <div className='blog-card-image'>
                    <Image src={ASSETS_URL + blogImg1} />
                  </div>
                  <div className='blog-card-title'>
                    <span>12 Consejos para entrevistas de trabajo </span>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default Blog;
