import React from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import { ASSETS_URL } from '../../constants';
import warningIcon from '../../assets/icons/warning.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  showDeleteModal: boolean;
  handleCloseDeleteModal: () => void;
  deleteChecked: () => void;
}

const VacanciesTableDeleteModal = ({
  showDeleteModal,
  handleCloseDeleteModal,
  deleteChecked,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModal}>
      <Modal.Body className='text-center py-5'>
        <Image className='mt-5' src={ASSETS_URL + warningIcon} />
        <h1 className='f-20 mt-4 mb-5'>
          {t('vacanciesTablet.deleteModal.header')}
        </h1>
        <button
          onClick={handleCloseDeleteModal}
          type='button'
          className='btn btn-outline-primary btn-outline-color px-5 py-3 me-4'
        >
          {t('vacanciesTablet.deleteModal.cancel')}
        </button>
        <Button
          type='button'
          className='btn btn-primary primary-btn-color px-5 py-3'
          onClick={() => {
            deleteChecked();
            handleCloseDeleteModal();
          }}
        >
          {t('vacanciesTablet.deleteModal.submit')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default VacanciesTableDeleteModal;
