import React from 'react';
import {
  Document,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
  Font,
  Svg,
} from '@react-pdf/renderer';
import { MatchData } from '../../models/match-data.interface';
import { useTranslation } from 'react-i18next';
import { CompanyState } from '../../store/slices/companySlice';

import bgimg from '../../assets/images/pdfbg.png';
import bgimg2 from '../../assets/images/pdfbg2.png';
import abilitiesimg from '../../assets/images/abilities.png';
import educationimg from '../../assets/images/education.png';
import experienceimg from '../../assets/images/experience.png';
import languagesimg from '../../assets/images/languages.png';
import licenceimg from '../../assets/images/licence.png';
import qualitiesimg from '../../assets/images/qualities.png';
import { ASSETS_URL } from '../../constants';
import candidateImage from "../../assets/icons/avatar-placeholder.svg";

const bgURL: string = `${ASSETS_URL}`;

Font.register({
    family: 'Oswald', fonts: [
      { src: require('../../assets/fonts/Oswald/Oswald-Regular.ttf'), fontWeight: 'normal' },
      { src: require('../../assets/fonts/Oswald/Oswald-Bold.ttf'), fontWeight: 'bold' },
      { src: require('../../assets/fonts/Oswald/Oswald-Light.ttf'), fontWeight: 'light' },
      { src: require('../../assets/fonts/Oswald/Oswald-Medium.ttf'), fontWeight: 'medium' },
    ]
});

interface Props {
  matchData: MatchData;
  company: CompanyState;
  name: string;
  avatar: string;
  position?: string;
  email: string;
  dob: string;
  phone: string;
  offer?: string;
}

const blue = '#1F487D';
const innerBorder = '#b6bfd6';
const styles = StyleSheet.create({
  cover: {
    fontSize: '10px',
    color: 'white',
    fontFamily: 'Oswald',
  },
  body: {
    fontFamily: 'Oswald',
    color:'#1C427A',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: 'auto',
    zIndex: -1, // To place the image in the background
  },
  table: {
    display: "flex",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
    paddingTop: 20,
  },
  tableCol1: {
    width: "25%",
    paddingLeft: 60,
    paddingTop: 10,
  },
  tableCol2: {
    width: "75%",
    paddingLeft: 10,
    paddingRight: 20,
    paddingTop: 10,
  },
  tableCell: {
  },
});

const MatchDocument = ({ matchData, company, name, avatar, position, email, dob, phone, offer }: Props) => {
  const { t } = useTranslation();

  return (
      <Document>
        <Page style={styles.cover} size={{ width: 500, height: 1000 }}>
        <Image
          style={styles.backgroundImage}
          src={window.location.origin + bgURL + bgimg}
        />
          <View>
            <Text style={{position: 'absolute', top: '0', left: '35', fontSize: '16px'}}>{t('matchDocument.reportTitle')}</Text>
            <Text style={{position: 'absolute', top: '20', left: '35', fontSize: '10px', fontWeight: 'light'}}>10/09/2024</Text>
            <Image
              style={{position: 'relative', top: '85', margin: '0 auto', zIndex: -2, width: 100, height: 100, }}
              src={avatar || candidateImage}
            />
            <Text style={{color:'white', position: 'relative', top: '90', margin: '0 auto', fontSize: '16px'}}>{name}</Text>
            <Text style={{color:'white', position: 'relative', top: '90', margin: '0 auto', fontSize: '14px', fontWeight: 'light'}}>{position || " "}</Text>
            <Text style={{color:'#1C427A', position: 'relative', top: '112', margin: '0 auto', fontSize: '18px'}}>Match: {+(matchData.ai_match || 0) * 10}%</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '146', left: '40', fontSize: '14px', fontWeight: 'medium'}}>{t('matchDocument.dataHeading')}</Text>
            <Text style={{color:'#1C427A', position: 'relative', top: '148', left: '40', fontSize: '12px', fontWeight: 'light'}}>{t('matchDocument.companyHeading')}: {company.businessName}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '172', left: '87', fontSize: '11px', textTransform: 'uppercase', fontWeight: 'medium' }}>{name}</Text>
            <Text style={{color:'#A8A8A8', position: 'relative', top: '168', left: '87', fontSize: '9px', fontWeight: 'light'}}>{t('matchDocument.nameDescription')}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '142', left: '300', fontSize: '11px', fontWeight: 'medium' }}>{email}</Text>
            <Text style={{color:'#A8A8A8', position: 'relative', top: '139', left: '300', fontSize: '9px', fontWeight: 'light'}}>{t('matchDocument.emailDescription')}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '163', left: '87', fontSize: '11px', fontWeight: 'medium' }}>{dob}</Text>
            <Text style={{color:'#A8A8A8', position: 'relative', top: '160', left: '87', fontSize: '9px', fontWeight: 'light'}}>{t('matchDocument.dobDescription')}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '135', left: '300', fontSize: '11px', fontWeight: 'medium' }}>{phone}</Text>
            <Text style={{color:'#A8A8A8', position: 'relative', top: '132', left: '300', fontSize: '9px', fontWeight: 'light'}}>{t('matchDocument.phoneDescription')}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '170', margin: '0 auto', fontSize: '15px', fontWeight: 'medium' }}>{t('matchDocument.offerHeading')}</Text>

            <Text style={{color:'#1C427A', position: 'relative', top: '180', margin: '0 auto', paddingBottom: '6px', width: '90%', fontSize: '11px', fontWeight: 'light', borderBottom: '1px', borderBottomColor: '#A8A8A8'}}>{matchData.offerRating?.description}</Text>

            {avatar}
          </View>
        </Page>
        <Page style={styles.body} size={{ width: 500, height: 1000 }}>
          <Image
            style={{height: '100%', zIndex: '-1', position: 'absolute', width: '80%'}}
            src={bgimg2}
          />
          <View style={styles.table}>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={experienceimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('createOffer.card2.key1')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.work_experience}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.experience_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_work_experience}</Text>
                </View>
              </View>
            </View>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={educationimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('vacancyDetails.labels.education')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.training}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.training_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_training}</Text>
                </View>
              </View>
            </View>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={languagesimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('vacancyDetails.subtitles.language')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.languages}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.language_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_languajes}</Text>
                </View>
              </View>
            </View>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={qualitiesimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('vacancyDetails.labels.quality')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.qualities}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.qualities_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_qualities}</Text>
                </View>
              </View>
            </View>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={abilitiesimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('vacancyDetails.labels.capability')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.abilities}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.capabilities_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_abilities}</Text>
                </View>
              </View>
            </View>
            {/* Table Row */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol1}>
                <Image src={licenceimg} style={{height: 60, width: 60}} />
              </View>
              <View style={styles.tableCol2}>
                <View style={{paddingBottom: 3}}>
                  <Text style={{...styles.tableCell, fontWeight: 'medium', fontSize: 15, color: '#5379F6'}}>{t('vacancyDetails.labels.license')}</Text>
                  <View style={{display: "flex",}}>
                    <View style={{flexDirection: "row"}}>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%'}}>{t('matchDocument.levelHeading')}: {matchData.driving_license || "0.0"}</Text>
                      <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 12, width: '50%', textAlign: "right", paddingRight: 40 }}>{t('matchDocument.labels.weight')}: {matchData.offerRating?.license_rating}</Text>
                    </View>
                  </View>
                </View>
                <View style={{borderTop: '1px solid grey', paddingTop: 5}}>
                  <Text style={{...styles.tableCell, fontWeight: 'light', fontSize: 11}}>{matchData.response?.match?.[0]?.reason_license || ''}</Text>
                </View>
              </View>
            </View> {/* end row */}

          </View>{/* end table */}
        </Page>
      </Document>
  )

};

export default MatchDocument;
