import React from 'react';
import './EditButton.scss';
import editIcon from '../../../assets/icons/edit.svg';
import { ASSETS_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  index: number;
  action: (index: number) => void;
}

const EditButton = ({ action, index }: Props) => {
  const { t } = useTranslation();
  return (
    <button className='edit-button' type='button' onClick={() => action(index)}>
      <img src={ASSETS_URL + editIcon} alt='edit' />
      {t('editButton')}
    </button>
  );
};

export default EditButton;
