// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offer-details-row {
  margin: 0 48px !important; }
  @media (max-width: 960px) {
    .offer-details-row {
      margin: 0 1rem !important; } }
  @media (max-width: 600px) {
    .offer-details-row {
      margin: 0 !important; } }
`, "",{"version":3,"sources":["webpack://./src/views/company/vacancies/detail/VacancyDetailCompany.scss","webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss"],"names":[],"mappings":"AAEA;EACE,yBAAyB,EAAA;ECYvB;IDbJ;MAII,yBAAyB,EAAA,EAM5B;ECTG;IDDJ;MAQI,oBAAoB,EAAA,EAEvB","sourcesContent":["@import '../../../../assets/scss/breakpoint/breakpoints-mixins';\n\n.offer-details-row {\n  margin: 0 48px !important;\n\n  @include tablet-breakpoint {\n    margin: 0 1rem !important;\n  }\n\n  @include mobile-breakpoint {\n    margin: 0 !important;\n  }\n}\n","@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
