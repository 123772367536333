// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.g-counter {
  position: absolute;
  top: 0.5em;
  right: 0.5em; }
  .g-counter .circle {
    height: 50px;
    width: 50px; }
`, "",{"version":3,"sources":["webpack://./src/views/company/offers/Offer.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY,EAAA;EAHd;IAMI,YAAY;IACZ,WAAW,EAAA","sourcesContent":[".g-counter {\n  position: absolute;\n  top: 0.5em;\n  right: 0.5em;\n\n  .circle {\n    height: 50px;\n    width: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
