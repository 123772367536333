import React from 'react';
import LanguageSelector from '../../language-selector/LanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { HeaderIconAction } from '../../../models/header-action-icon.interface';
import bell from '../../../assets/icons/bell.svg';
import chat from '../../../assets/icons/message-square (1).svg';
import chatread from '../../../assets/icons/message-square.svg';
import chatnone from '../../../assets/icons/message.svg';
import logoWhite from '../../../assets/images/logo-white.png';
import companyImage from '../../../assets/icons/avatar-placeholder.svg';
import { useNavigate } from 'react-router-dom';
import { toggleNotificationMenuState } from '../../../store/slices/notificationSlice';
import menu from '../../../assets/icons/menu.svg';
import HeaderActionIcon from './HeaderActionIcon';
import { ASSETS_URL } from '../../../constants';
import { Image } from 'react-bootstrap';
import { getCurrentPlanMatchLimit } from '../../../utils/get-current-plan-match-limit';
import { plans } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  setIsMenu: (val: boolean) => void;
}

const CompanyHeader = ({ setIsMenu }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const company = useSelector((state: RootState) => state.companyReducer);

  const companyActions: HeaderIconAction[] = [
    {
      action: () => dispatch(toggleNotificationMenuState()),
      icon: bell,
      alt: 'notification',
    },
    {
      action: () => navigate('/company/chat'),
      icon: chatnone,
      alt: 'chat - no unread messages',
    },
    {
      action: () => navigate('/company'),
      icon: company.logo || companyImage,
      className: 'header-profile-desktop object-fit-cover',
      alt: 'logo',
    },
  ];

  const companyMobileActions: HeaderIconAction[] = [
    {
      action: () => navigate('/company'),
      icon: company.logo || companyImage,
      className: 'header-profile object-fit-cover',
      alt: 'User Image',
    },
    {
      action: () => navigate('/company'),
      icon: logoWhite,
      className: 'header-logo company',
      alt: 'logo Image',
    },
    {
      action: () => setIsMenu(true),
      icon: menu,
      className: 'header-menu white-filter cursor-pointer',
      alt: 'menu Image',
    },
  ];

  const getCreditPrefix = (plan?: string) => {
    if (!plan || plan === plans.trial.name)
      return t('plans.trial.headerPrefix');
    if (plan === plans.premium.name) return t('plans.premium.headerPrefix');
  };

  return (
    <>
      <div className='zestur-header mobile-hidden justify-content-end'>
        {company.companyId && (
          <div className='me-4'>
            {getCreditPrefix(company.plan)}:{' '}
            {getCurrentPlanMatchLimit(company.plan) -
              (company.request_counter?.gpt_4_requests || 0)}
            /{getCurrentPlanMatchLimit(company.plan)}
          </div>
        )}
        <div className='zestur-header__icons align-items-center'>
          <LanguageSelector />

          {companyActions.map((item, index) => (
            <HeaderActionIcon key={index} item={item} />
          ))}
        </div>
      </div>

      <div className={`zestur-header-mobile company no-mobile-hidden`}>
        {companyMobileActions.map((item, index) => (
          <Image
            key={index}
            src={ASSETS_URL + item.icon}
            className={item.className}
            alt={item.alt}
            onClick={item.action}
          />
        ))}
      </div>
    </>
  );
};

export default CompanyHeader;
