import React from 'react';

interface Props {
  title: string;
  info: string;
}

const VacancyInfo = ({ title, info }: Props) => {
  return (
    <p>
      <span className='fw-bold'>{title} </span>
      {info}
    </p>
  );
};

export default VacancyInfo;
