import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Vacancy from '../../models/vacancy.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  filterByOffer: (filterType: string) => void;
  filterByStatus: (filterStatus: string) => void;
}

const CandidateTableFilter = ({ filterByOffer, filterByStatus }: Props) => {
  const { t } = useTranslation();
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const [selectedFavoriteFilter, setSelectedFavoriteFilter] =
    useState<string>('');
  const companyOffers = useSelector(
    (state: RootState) => state.companyReducer.offers
  );

  function handleSelectedOfferChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const value = event.target.value;
    setSelectedOffer(value);
    filterByOffer(value);
  }

  function handleSelectedFavoriteChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    const value = event.target.value;
    setSelectedFavoriteFilter(value);
    filterByStatus(value);
  }

  return (
    <div className='d-flex align-items-center justify-content-between gap-4'>
      <select
        className='form-select'
        value={selectedOffer}
        onChange={(e) => handleSelectedOfferChange(e)}
      >
        <option value=''>{t('candidatesTablet.filters.vacancyDefault')}</option>
        {companyOffers.map((offer: Vacancy, optionIndex: number) => (
          <option key={optionIndex} value={offer.id}>
            {offer.position}
          </option>
        ))}
      </select>

      <select
        className='form-select'
        value={selectedFavoriteFilter}
        onChange={(e) => handleSelectedFavoriteChange(e)}
      >
        <option value=''>
          {t('candidatesTablet.filters.allCandidates')}
        </option>
        <option value='pending'>
          {t('candidatesTablet.filters.pendingCandidates')}
        </option>
        <option value='seen'>
          {t('candidatesTablet.filters.seenCandidates')}
        </option>
        <option value='selected'>
          {t('candidatesTablet.filters.selectedCandidates')}
        </option>
        <option value='discarded'>
          {t('candidatesTablet.filters.discardedCandidates')}
        </option>
        <option value='withdrawn'>
          {t('candidatesTablet.filters.withdrawnCandidates')}
        </option>
      </select>
    </div>
  );
};

export default CandidateTableFilter;
