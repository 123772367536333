import api from './api';
import apiService from '../core/services/network.service';
import TokenService from './token.service';
import { toast } from 'react-toastify';
import i18n from '../i18n/i18n';

class AuthService {
  async login(email: string, password: string) {
    try {
      const response = await api.post('token/', {
        email,
        password,
      });

      if (response.data.access) {
        TokenService.setUser(response.data);
        return response.data;
      }
    } catch (error) {
      toast.error(i18n.t('messages.credentialError'), {
        position: 'top-center',
        autoClose: 5000,
        theme: 'light',
      });
    }
  }
}

export default new AuthService();
