// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 600px) {
  .language-form .language {
    display: flex;
    flex-direction: column; } }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss","webpack://./src/components/ui/candidate-language-modal/CandidateLanguageModal.scss"],"names":[],"mappings":"AAGI;ECDJ;IAGM,aAAa;IACb,sBAAsB,EAAA,EACvB","sourcesContent":["@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n","@import '../../../assets/scss/breakpoint/breakpoints-mixins';\n\n.language-form {\n  @include mobile-breakpoint {\n    .language {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
