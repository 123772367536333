import React from 'react';
import './DropdownPopup.scss';
import Option from '../../models/option.interface';

interface Props {
  optionList: Option[];
  width?: string;
  selectOption: (option: Option) => void;
  additionalClass?: string;
}

const DropdownPopup = ({
  optionList,
  selectOption,
  width = '120px',
  additionalClass = '',
}: Props) => {
  return (
    <div className={`dropdown ${additionalClass}`} style={{ width }}>
      {optionList.map((option, index) => (
        <div
          key={index}
          className={`dropdown__option cursor-pointer ${
            option.selected ? 'selected' : ''
          }`}
          onClick={() => selectOption(option)}
        >
          {option.PrefixComponent && option.PrefixComponent}
          {option.name && <span>{option.name}</span>}
        </div>
      ))}
    </div>
  );
};

export default DropdownPopup;
