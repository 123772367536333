import React from 'react';
import { getItemById } from '../../utils/get-item-by-id';
import VacancyInfo from '../../models/vacancy-info.interface';
import { ListItem } from '../../models/list-item';

interface Props {
  title: string;
  list: VacancyInfo[];
  translationList: ListItem[];
}

const VacancyDetailsQualities = ({ title, list, translationList }: Props) => {
  return (
    <div className='details__list'>
      <p>{title}: </p>
      <ul className='mb-0'>
        {list.map((item, index) => (
          <li key={item.id || index}>
            {getItemById(translationList, item.name)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VacancyDetailsQualities;
