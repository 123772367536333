import React, { useState } from "react";
import SignUpForm from "./SignUp/SignUpForm";
import RegistrationStep1 from "./Registration Form/RegistrationStep1";
import RegistrationStepFirst from "./Registration Form/RegistrationStepFirst";
import { Row, Col } from "react-bootstrap";
import SideContent from "../SideContent";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import SubmittedForm from "./Registration Form/SubmittedForm";

const steps = [
  { title: "Step 0", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SignUpForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 1", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStepFirst currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 2", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <RegistrationStep1 currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> },
  { title: "Step 3", component: (currentStep: number, handleNext: () => void, handlePrev: () => void) => <SubmittedForm currentStep={currentStep} handleNext={handleNext} handlePrev={handlePrev} steps={steps.length} /> }
];
export default function SignUpCandidate() {
  const isFormView = useSelector((state: RootState) => state.authPageReducer.isFormView);
  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  return (
    <Row className="text-start">
      {
        currentStep === 0 && <Col className={`p-0 ${isFormView ? 'tablet-hidden' : ''}`} xs={12} lg={6}>
          <SideContent isCompany={false} />
        </Col>
      }
      <Col className={`p-0 ${currentStep === 12 ? "offset-md-2" : ''} ${currentStep === 0 && !isFormView  ? 'tablet-hidden' : ''}`} xs={12} lg={currentStep === 0 ? 6 : 12}>
        {steps[currentStep].component(currentStep, handleNext, handlePrev)}
      </Col>
    </Row>
  );
}
