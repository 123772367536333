import { TokenType } from '../models/token.interface';

class TokenService {
  userString: string | null = localStorage.getItem('user');

  getLocalRefreshToken() {
    this.userString = localStorage.getItem('user');

    const user: TokenType = this.userString
      ? JSON.parse(this.userString)
      : null;
    return user?.refresh;
  }

  getLocalAccessToken() {
    this.userString = localStorage.getItem('user');

    const user: TokenType = this.userString
      ? JSON.parse(this.userString)
      : null;
    return user?.access;
  }

  updateLocalAccessToken(token: string) {
    this.userString = localStorage.getItem('user');

    const user: TokenType = this.userString
      ? JSON.parse(this.userString)
      : null;
    user.access = token;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(this.userString || '');
  }

  setUser(user: TokenType) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

export default new TokenService();
