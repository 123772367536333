export function validateNif(cif: string): boolean {
  let isNif: boolean = false;
  let isCif: boolean = false;
  let isNie: boolean = false;
  let chechXYZ: boolean = false;

  const num: string[] = [];
  cif = cif.toUpperCase();

  for (let i = 0; i < 9; i++) {
    num[i] = cif.substring(i, i + 1);
  }

  // if it does not have a valid format, return error
  if (
    !cif.match(
      '((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)'
    )
  ) {
    return false;
  }

  // standard NIF check
  if (cif.match('(^[0-9]{8}[A-Z]{1}$)')) {
    isNif =
      num[8] ==
      'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(parseInt(cif.substring(0, 8)) % 23);
  }

  // CIF check
  if (cif.match('^[ABCDEFGHJNPQRSUVW]{1}')) {
    isCif = validateCIF(cif);
  }

  // NIE check
  // T
  if (cif.match('^[T]{1}')) {
    isNie = num[8] == cif.match('^[T]{1}[A-Z0-9]{8}$')?.toString();
  }
  // XYZ
  if (cif.match('^[XYZ]{1}')) {
    let tmpstr = cif.replace('X', '0');
    tmpstr = tmpstr.replace('Y', '1');
    tmpstr = tmpstr.replace('Z', '2');

    chechXYZ =
      num[8] ==
      'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(parseInt(tmpstr.substring(0, 8)) % 23);
  }

  return isNif || isCif || isNie || chechXYZ;
}

function validateCIF(cif: string) {
  cif = cif.trim().toUpperCase(); // Convert to uppercase and remove whitespace

  if (!/^[A-Z][0-9]{7}[A-Z0-9]$/.test(cif)) {
    return false; // CIF doesn't have the correct format
  }

  const letters = 'JABCDEFGHI';
  const digits = cif.substring(1, 7 + 1);
  const controlLetter = cif.substring(8, 1 + 8);

  let sum = 0;
  for (let i = 0; i < 7; i++) {
    let digit = parseInt(digits[i], 10);
    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }

  const remainder = (10 - (sum % 10)) % 10;
  if (!isNaN(parseInt(controlLetter, 10))) {
    // If the last character is a digit
    return remainder === parseInt(controlLetter, 10);
  } else {
    // If the last character is a letter
    return controlLetter === letters[remainder];
  }
}
