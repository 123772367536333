import React from 'react';
import RegistrationBackButton from '../ui/registration-back-arrow/RegistrationBackArrow';
import { useTranslation } from 'react-i18next';
import RegistrationMobileProgress from '../ui/registration-mobile-progress/RegistrationMobileProgress';
import Step from '../../models/step.interface';

interface Props {
  data: Step;
  progressValue: number;
  startStep: number;
  endStep: number;
}

const MobileProgress = ({ data, progressValue, startStep, endStep }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='register-mobile-header__background'>
      <div className='register-mobile-header flex-column align-items-start justify-content-center'>
        <RegistrationBackButton
          data={data}
          title={t('registrationForm.steps.backButton')}
          white={true}
          class=''
        />
        <RegistrationMobileProgress
          value={progressValue}
          step={startStep}
          maxStep={endStep}
        />
      </div>
    </div>
  );
};

export default MobileProgress;
