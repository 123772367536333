// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBarTitle_progressBarTitle__6md-P h4 {
  font-size: 22px;
  font-weight: 700; }

.ProgressBarTitle_progressBarTitle__6md-P h5 {
  font-size: 18px;
  font-weight: 700;
  color: #a7a7a7; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/progress-bar-title/ProgressBarTitle.module.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EAEI,eAAe;EACf,gBCkBW,EAAA;;ADrBf;EAMI,eAAe;EACf,gBCcW;EDbX,cCFe,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.progressBarTitle {\n  h4 {\n    font-size: 22px;\n    font-weight: $font-bold;\n  }\n  h5 {\n    font-size: 18px;\n    font-weight: $font-bold;\n    color: $graylight;\n  }\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n\n// Redesign 2025\n$main-grey: #FAFAF4;\n$candidate: #4EC6DB;\n$candidate-border: #011640;\n$company: #D5006D;\n$company-border: #AE085A;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarTitle": `ProgressBarTitle_progressBarTitle__6md-P`
};
export default ___CSS_LOADER_EXPORT___;
