import React from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Field, Formik, Form as FormikForm } from 'formik';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { object } from 'yup';
import Education from '../../../models/education.interface';
import { defaultNotSelectedDate } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  index: number;
  handleClose: () => void;
  handleSubmit: (data: Education) => void;
}

const educationSchema = object();

const CandidateEducationModal = ({
  show,
  index = -1,
  handleClose,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const candidateId: string = useSelector(
    (state: RootState) => state.candidateReducer.candidate.userId
  );
  const candidateEducation = useSelector(
    (state: RootState) => state.candidateReducer.education
  );

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('educationForm.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            title: index >= 0 ? candidateEducation[index].title : '',
            institution:
              index >= 0 ? candidateEducation[index].institution : '',
            endDate: index >= 0 ? candidateEducation[index].end_date : '',
            description:
              index >= 0 ? candidateEducation[index].description : '',
          }}
          onSubmit={(values) => {
            if (index >= 0) {
              handleSubmit({
                id: candidateEducation[index].id || '',
                user: candidateId,
                title: values.title,
                institution: values.institution,
                end_date: values.endDate || defaultNotSelectedDate,
                description: values.description,
              });
            } else {
              handleSubmit({
                title: values.title,
                user: candidateId,
                institution: values.institution,
                end_date: values.endDate || defaultNotSelectedDate,
                description: values.description,
              });
            }
            handleClose();
          }}
          validationSchema={educationSchema}
        >
          {({ errors, touched, values }) => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('educationForm.title')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='title'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('educationForm.institution')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='institution'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('educationForm.endDate')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='date'
                      aria-describedby='inputGroupPrepend'
                      name='endDate'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('educationForm.description')}
                  </Form.Label>
                  <InputGroup hasValidation className='textarea'>
                    <Field
                      as='textarea'
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      name='description'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      maxLength={400}
                    />
                    <div className='textarea__counter'>
                      {values.description.length}/400
                    </div>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Button className='submitbtn sans p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateEducationModal;
