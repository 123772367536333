// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApplicantNoteSpace_form__4525q {
  background-color: white;
  margin-top: 12px; }
`, "",{"version":3,"sources":["webpack://./src/views/company/applicants/applicant-detail/ApplicantNoteSpace.module.scss"],"names":[],"mappings":"AAEA;EACE,uBAAuB;EACvB,gBAAgB,EAAA","sourcesContent":["@import '../../../../assets/scss/variable';\n\n.form {\n  background-color: white;\n  margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ApplicantNoteSpace_form__4525q`
};
export default ___CSS_LOADER_EXPORT___;
