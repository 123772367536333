import React from 'react';
import { useTranslation } from 'react-i18next';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';
import { getLanguageTranslation } from '../../utils/languages-switch/get-language-translation';
import { getLanguageLevel } from '../../utils/get-language-level';
import { ListItem } from '../../models/list-item';
import { VacancyProps } from '../../models/vacancy-props.interface';

const VacancyDetailsLanguage = ({ vacancy }: VacancyProps) => {
  const { t, i18n } = useTranslation();
  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });

  return (
    <div className='details__info'>
      <h5>{t('vacancyDetails.subtitles.language')}</h5>
      {vacancy.language.map((lang, index) => (
        <div key={index} className='details__info-language'>
          <VacancyInfo
            title={`${t('vacancyDetails.labels.language')}:`}
            info={getLanguageTranslation(lang, i18n.language)}
          />
          <VacancyInfo
            title={`${t('vacancyDetails.labels.languageLevel')}:`}
            info={getLanguageLevel(+lang.level, levels)}
          />
        </div>
      ))}
    </div>
  );
};

export default VacancyDetailsLanguage;
