import { useEffect, useState } from 'react';
import WebSocketService from '../core/services/socket';
import tokenService from '../_services/token.service';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import {
  addNotification,
  setNewNotificationComes,
} from '../store/slices/notificationSlice';
import { fetchMatchData } from '../store/slices/matchDocumentSlice';
import { MatchData } from '../models/match-data.interface';
import { updateMatchDataById } from '../store/slices/companyCandidatesSlice';
import { useLocation } from 'react-router-dom';

enum NotificationEnum {
  Default = 1,
  AI = 2,
  Payment = 3,
}

export const useOpenNotificationWebsocket = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const token = tokenService.getLocalAccessToken();
  const [webSocketService, setWebsocketService] =
    useState<WebSocketService | null>();

  useEffect(() => {
    if (!token) return;

    const newWebSocket = new WebSocketService(`notification/?token=${token}`);
    newWebSocket.onMessage(handleReceiveNotification);
    setWebsocketService(newWebSocket);
  }, []);

  const handleAINotification = async (message: any) => {
    const lastPath = location.pathname.split('/').at(-1);

    if (lastPath !== 'applicants' && lastPath !== 'company') return;

    const response = await dispatch(
      fetchMatchData({
        offer_id: message.extra.offer_id,
        candidate_id: message.extra.candidate_id,
        withEffect: false,
      })
    );

    const data = (
      response.payload as {
        data: MatchData;
        withEffect: boolean;
      }
    ).data;
    dispatch(updateMatchDataById(data));
  };

  const handleReceiveNotification = (message: any) => {
    const parsedMessage = JSON.parse(JSON.parse(message));
    dispatch(addNotification(parsedMessage));
    dispatch(setNewNotificationComes(true));

    if (NotificationEnum[parsedMessage.type] === 'AI') {
      handleAINotification(parsedMessage);
    }
  };

  return webSocketService;
};
