// src/components/ui/tabs/Tabs.tsx
import React, { useState } from 'react';
import Pagination from "../../pagination/Pagination";
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';

interface TabsProps {
  children: React.ReactNode[];
  tabTitles: string[];
  pageHandlers: ((page:number) => void)[];
  pageNumbers: number[];
}


const Tabs: React.FC<TabsProps> = ({ children, tabTitles, pageHandlers , pageNumbers}) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <ul className="tab-list">
        {tabTitles.map((title, index) => (
          <li
            key={index}
            className={`tab-item ${index === activeTab ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
            style={{ cursor: 'pointer', padding: '10px', display: 'inline-block' }}
          >
            {title}
          </li>
        ))}
      </ul>
      <div className="tab-content">
          {children[activeTab]}
          <Pagination
            totalPages={5}
            currentPage={pageNumbers[activeTab]}
            pageSize={50}
            withAmountSelection={true}
            setAmount={value => 1}
            changeCurrentPage={pageHandlers[activeTab]}
            /**
            totalPages={Math.ceil(count[activeFilter] / maxAmount) || 1}
            currentPage={pageNumber}
            pageSize={maxAmount}
            withAmountSelection={true}
            setAmount={setAmount}
            changeCurrentPage={onPageChange}
            **/
          />
      </div>
    </div>
  );
};

export default Tabs;
