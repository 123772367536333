import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import * as endpoints from '../../constants/endpoints';
import tokenService from '../../_services/token.service';

interface AuthState {
  tokenExhausted: boolean;
}

const initialState: AuthState = {
  tokenExhausted: false,
};

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
  const response = await axios.post(endpoints.REFRESH_TOKEN, {
    refresh: tokenService.getLocalRefreshToken(),
  });

  return response.data;
});

const authPageSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokenExhausted: (state, action) => {
      state.tokenExhausted = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(refreshToken.fulfilled, (state, action) => {
        const accessToken: string = action.payload.access;
        tokenService.updateLocalAccessToken(accessToken);
        state.tokenExhausted = false;
      })

      .addCase(refreshToken.rejected, (state, action) => {
        state.tokenExhausted = true;
      });
  },
});

export const { setTokenExhausted } = authPageSlice.actions;
export default authPageSlice.reducer;
