// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-filter-button {
  color: #a7a7a7;
  border: none;
  border-bottom: 2px solid #a7a7a7;
  padding-top: 7px;
  padding-bottom: 14px;
  background-color: transparent;
  text-align: center;
  font-family: 'Oswald';
  font-size: 18px;
  font-weight: 500; }
  .table-filter-button.active {
    border-color: #0c85d0;
    color: #0c85d0; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/table-filter-button/TableFilterButton.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,cCKiB;EDJjB,YAAY;EACZ,gCCGiB;EDFjB,gBAAgB;EAChB,oBAAoB;EACpB,6BAA6B;EAC7B,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,gBCSe,EAAA;EDnBjB;IAaI,qBCXgB;IDYhB,cCZgB,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.table-filter-button {\n  color: $graylight;\n  border: none;\n  border-bottom: 2px solid $graylight;\n  padding-top: 7px;\n  padding-bottom: 14px;\n  background-color: transparent;\n  text-align: center;\n  font-family: 'Oswald';\n  font-size: 18px;\n  font-weight: $font-medium;\n\n  &.active {\n    border-color: $light-blue;\n    color: $light-blue;\n  }\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
