import React from 'react';
import { NavLink } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../../constants';
import './MenuLink.scss';

interface Props {
  icon: string;
  text: string;
  to: string;
  closeMenu: () => void;
  onAction?: () => void;
}

const MenuLink = ({
  icon,
  text,
  to,
  closeMenu,
  onAction = () => {},
}: Props) => {
  const assetsURL: string = `${ASSETS_URL}`;

  return (
    <NavLink
      end
      to={to}
      className='zestur-sidebar__links-item'
      onClick={() => {
        closeMenu();
        onAction();
      }}
    >
      <div className='icon-container'>
        <Image src={assetsURL + icon} />
      </div>
      <span className='item-text'>{text}</span>
    </NavLink>
  );
};

export default MenuLink;
