import React, { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import fileUpload from '../../../assets/images/login/FileUplode.png';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import Dropzone from 'react-dropzone';
import Step from '../../../models/step.interface';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { setCompanyAvatar } from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';
import { acceptableFileSize } from '../../../utils/acceptable-file-size';
import { maxLogoSize } from '../../../constants/constants';
import { toast } from 'react-toastify';
import { readUploadedFile } from '../../../utils/read-uploaded-file';
import {useNavigate} from "react-router-dom";

function RegistrationStep4(props: Step) {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const companyAvatar = useSelector(
    (state: RootState) => state.companyReducer.uploadedAvatar
  );
  const [file, setFile] = useState<any>();
  const logoURL: string = `${ASSETS_URL}`;
  const navigate = useNavigate();

  const handleFileChange = (files: File[]) => {
    const file: File = files[0];

    if (!file) return;
    if (!acceptableFileSize(file.size, maxLogoSize)) {
      toast.error(`${t('messages.sizeLimitError')} ${maxLogoSize} MB`);
      return;
    }

    readUploadedFile(file, setFile);
  };

  useEffect(() => {
    if (companyAvatar) {
      handleFileChange([companyAvatar]);
    }
  }, []);

  return (
    <div className='register-form'>
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={80}
            startStep={4}
            endStep={5}
          />
        </Col>

        <Col
          xs={12}
          md={9}
          lg={10}
          className='d-flex justify-content-center justify-items-center'
          style={{margin:"0 auto"}}
        >
          <div className='company-register-logo'>
            <Image src={ASSETS_URL + logo} alt='logo Image' fluid onClick={() => navigate('/')} />
          </div>
          <Formik
            initialValues={{}}
            onSubmit={() => {
              if (file) {
                dispatch(setCompanyAvatar(file.file));
              }

              props.handleNext();
            }}
          >
            <Form className='bg-blue-gradient form w-100 d-flex justify-content-evenly flex-column justify-items-center'>
              {/* main-ProgressBar */}
              <div className='main-ProgressBar mobile-hidden'>
                <ProgressBarTitle
                  startStep={4}
                  endStep={5}
                  progressValue={68}
                />
                <h2>{t('companyRegistration.step1.title')} </h2>
              </div>
              {/* form-fields */}
              <Dropzone
                onDrop={(acceptedFiles: File[]) => {
                  handleFileChange(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className='file-upload'>
                    <h5>{t('companyRegistration.step4.dropzoneHeader')}</h5>
                    <p>{t('companyRegistration.step4.dropzoneSubtitle')}</p>
                    <div className='fileuploaddata w-100' {...getRootProps()}>
                      <label>
                        <Image
                          className='FileUplodeImg object-fit-cover'
                          src={
                            file && file?.preview
                              ? file?.preview
                              : logoURL + fileUpload
                          }
                          alt='logo Image'
                          fluid
                        />
                      </label>
                      {file ? <small>{file?.name}</small> : null}
                      <input
                        type='file'
                        id='upload-button'
                        style={{ display: 'none' }}
                        {...getInputProps()}
                      />
                    </div>
                    <p>
                      {t('companyRegistration.step4.dropzoneDescription')}{' '}
                      <label className='cursor-pointer' {...getRootProps()}>
                        <span>
                          {t('companyRegistration.step4.dropzoneUrl')}
                        </span>
                      </label>
                    </p>
                  </div>
                )}
              </Dropzone>

              <Row className='mt-4 no-mobile-hidden'></Row>

              <RegistrationActionButton
                data={props}
                isSubmit={true}
              ></RegistrationActionButton>
            </Form>
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep4;
