import React from 'react';
import close from '../../../assets/icons/close.svg';
import './RegistrationTag.scss';
import { ListItem } from '../../../models/list-item';

const RegistrationTag = ({
  tag,
  deleteTag,
}: {
  tag: ListItem;
  deleteTag: (tag: ListItem) => void;
}) => {
  return (
    <button className='tag-button' type='button'>
      <span>{tag.name}</span>
      <img
        src={close}
        alt='close'
        height={18}
        width={18}
        className='white-filter'
        onClick={() => deleteTag(tag)}
      />
    </button>
  );
};

export default RegistrationTag;
