import React from 'react';
import './DownloadResumeButton.scss';
import downloadIcon from '../../../assets/icons/ic_download.svg';
import { Link } from 'react-router-dom';
import { ASSETS_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface Props {
  fileUrl: string;
}

const DownloadResumeButton = ({ fileUrl }: Props) => {
  const { t } = useTranslation();

  return (
    <Link to={fileUrl} target='_blank' download>
      <button className='download' type='button'>
        <img
          src={ASSETS_URL + downloadIcon}
          alt='download'
          className='blue-filter'
        />
        {t('resumeButton')}
      </button>
    </Link>
  );
};

export default DownloadResumeButton;
