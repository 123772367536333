import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { useLocation, useNavigate } from 'react-router-dom';
import './VacancyLocation.scss';
import {
  getVacancyDetails,
  setVacancyDetails,
} from '../../store/slices/vacanciesSlice';
import { useTranslation } from 'react-i18next';
import companyImage from '../../assets/icons/avatar-placeholder.svg';

const VacancyLocation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const vacancyReducer = useSelector(
    (state: RootState) => state.vacanciesReducer
  );

  useEffect(() => {
    const vacancyPathId = location.pathname.split('/').at(-2);
    const pathVacancy = vacancyReducer.initialVacancies.find(
      (vacancy) => vacancy.id === vacancyPathId
    );

    if (!vacancyPathId) return;

    if (pathVacancy) {
      dispatch(setVacancyDetails(pathVacancy));
    } else {
      dispatch(getVacancyDetails(vacancyPathId));
    }
  }, [location, navigate, vacancyReducer.initialVacancies]);

  return (
    <>
      {vacancyReducer.vacancyDetails && (
        <div className='location'>
          <div className='preview position-relative'>
            {vacancyReducer.vacancyDetails.company.cover ? (
              <img
                src={vacancyReducer.vacancyDetails.company.cover}
                alt='preview'
                className='w-100 preview__image'
              />
            ) : (
              <div className='w-100 preview__image'></div>
            )}
            <div className='preview__general d-flex justify-content-between'>
              <div className='position-relative d-flex gap-3 align-items-center'>
                <div
                  className={`preview__logo-container position-absolute object-fit-cover rounded-circle`}
                >
                  <img
                    src={vacancyReducer.vacancyDetails.company.logo || companyImage}
                    alt='logo'
                    className='preview__logo object-fit-cover rounded-circle'
                  />
                </div>
                <div className='preview__title d-flex flex-column gap-1'>
                  <p>{vacancyReducer.vacancyDetails.company.name}</p>
                  <p>{vacancyReducer.vacancyDetails.locationType}</p>
                </div>
              </div>
            </div>
          </div>

          <div className='preview location__info position-relative'>
            <div className='d-flex flex-column gap-4 mb-4'>
              <div className='d-flex gap-4'>
                <p className='fw-bold'>URL</p>
                <p>{vacancyReducer.vacancyDetails.company.url}</p>
              </div>
              <div className='d-flex gap-4'>
                <p className='fw-bold'>{t('vacancyLocationPage.location')}</p>
                <p>{vacancyReducer.vacancyDetails.location}</p>
              </div>
            </div>

            <div className='mt-4'>
              <p className='fw-bold mb-3'>
                {t('vacancyLocationPage.description')}
              </p>
              <p className='breaklines'>{vacancyReducer.vacancyDetails.company.description}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VacancyLocation;
