import React from 'react';
import './TableFilterButton.scss';

interface Props {
  title: string;
  count: number;
  isActive: boolean;
  handleClick: () => void;
}

const TableFilterButton = ({ title, count, isActive, handleClick }: Props) => {
  return (
    <button
      onClick={handleClick}
      className={`table-filter-button${isActive ? ' active' : ''}`}
    >
      {title} ({count})
    </button>
  );
};

export default TableFilterButton;
