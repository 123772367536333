import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import VacancyForm from '../../models/vacancy-form.interface';
import ApiService from '../../core/services/network.service';
import axios from 'axios';
import { config } from '../../_config/config';
import tokenService from '../../_services/token.service';
import { RootState } from '../store';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import { processSkills } from '../../utils/process-skills';
import { ListItem } from '../../models/list-item';
import { getItemById } from '../../utils/get-item-by-id';

interface VacancyState {
  vacancy: VacancyForm;

  createdVacancyId: string;
  stepOneProgress: number;
  stepTwoProgress: number;
  stepThreeProgress: number;
}

const initialVacancyFormData = {
  description: '',
  job_type: '1',
  location: '',
  number_of_vacancies: '',
  end_date: '',
  position: '',
  salary_max: '20000',
  salary_min: '0',
  salary_type: 'every month',
  sector: '',
  status: 1,
  drivingLicense: { license: [], isVehicle: false },
  offercapabilitie: [],
  offerexperience: [],
  offerlanguage: [],
  offerqualities: [],
  offertraining: [],

  license_rating: 0,
  capabilities_rating: 0,
  experience_rating: 0,
  language_rating: 0,
  qualities_rating: 0,
  training_rating: 0,
};

const engSectorOptions: ListItem[] = i18n.t('sectorOptions', {
  returnObjects: true,
  lng: 'en',
});

function createPayload(vacancy: VacancyForm) {
  return {
    description: vacancy.description,
    job_type: vacancy.job_type,
    location: vacancy.location,
    number_of_vacancies: vacancy.number_of_vacancies,
    end_date: vacancy.end_date,
    position: vacancy.position,
    salary_max: vacancy.salary_max,
    salary_min: vacancy.salary_min,
    salary_type: vacancy.salary_type,
    sector: vacancy.sector,
    sector_text: getItemById(engSectorOptions, vacancy.sector),
    status: vacancy.status,

    driving_license: vacancy.drivingLicense.license.map((item) => item.name),
    is_vehicle: vacancy.drivingLicense.isVehicle,
    offercapabilitie: processSkills(vacancy.offercapabilitie),
    offerexperience: vacancy.offerexperience,
    offerlanguage: vacancy.offerlanguage,
    offerqualities: processSkills(vacancy.offerqualities),
    offertraining: vacancy.offertraining,

    license_rating: vacancy.license_rating,
    capabilities_rating: vacancy.capabilities_rating,
    experience_rating: vacancy.experience_rating,
    language_rating: vacancy.language_rating,
    qualities_rating: vacancy.qualities_rating,
    training_rating: vacancy.training_rating,
  };
}

export const createVacancy = createAsyncThunk(
  'vacancy/createVacancy',
  async (vacancy: VacancyForm) => {
    const token = tokenService.getLocalAccessToken();
    const payload = createPayload(vacancy);
    const response = await axios.post(`${config.SERVER_URL}offer/`, payload, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return response.data.body;
  }
);

export const updateVacancy = createAsyncThunk(
  'vacancy/updateVacancy',
  async (
    { vacancyId, withMessage }: { vacancyId: string; withMessage: boolean },
    { getState }
  ) => {
    const token = tokenService.getLocalAccessToken();
    const state: RootState = getState() as RootState;
    const vacancy = state.vacancyCreateReducer.vacancy;
    const payload = createPayload(vacancy);
    await axios.put(`${config.SERVER_URL}offer/${vacancyId}/`, payload, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    return withMessage;
  }
);

const initialState: VacancyState = {
  vacancy: initialVacancyFormData,
  createdVacancyId: '',
  stepOneProgress: 0,
  stepTwoProgress: 0,
  stepThreeProgress: 0,
};

const vacancyCreateSlice = createSlice({
  name: 'vacancy',
  initialState,
  reducers: {
    setVacancyFormMainInfo: (state, action) => {
      state.vacancy.position = action.payload.position;
      state.vacancy.end_date = action.payload.date;
      state.vacancy.location = action.payload.location;
      state.vacancy.number_of_vacancies = action.payload.places;
      state.vacancy.sector = action.payload.sector;
    },

    setVacancyFormExperience: (state, action) => {
      state.vacancy.offerexperience = action.payload;
    },

    setVacancyFormEducation: (state, action) => {
      state.vacancy.offertraining = action.payload;
    },

    setVacancyFormLanguage: (state, action) => {
      state.vacancy.offerlanguage = action.payload;
    },

    setVacancyFormQualities: (state, action) => {
      state.vacancy.offerqualities = action.payload;
    },

    setVacancyFormCapabilities: (state, action) => {
      state.vacancy.offercapabilitie = action.payload;
    },

    setVacancyFormLicense: (state, action) => {
      state.vacancy.drivingLicense = action.payload;
    },

    setVacancyFormExperienceRating: (state, action) => {
      state.vacancy.experience_rating = action.payload;
    },

    setVacancyFormEducationRating: (state, action) => {
      state.vacancy.training_rating = action.payload;
    },

    setVacancyFormLanguageRating: (state, action) => {
      state.vacancy.language_rating = action.payload;
    },

    setVacancyFormQualitiesRating: (state, action) => {
      state.vacancy.qualities_rating = action.payload;
    },

    setVacancyFormCapabilitiesRating: (state, action) => {
      state.vacancy.capabilities_rating = action.payload;
    },

    setVacancyFormLicenseRating: (state, action) => {
      state.vacancy.license_rating = action.payload;
    },

    setVacancyFormJobDescription: (state, action) => {
      const [minSalary, maxSalary] = action.payload.salary.split(' - ');
      state.vacancy.salary_min = minSalary;
      state.vacancy.salary_max = maxSalary;

      state.vacancy.description = action.payload.description;
      state.vacancy.job_type = action.payload.jobType;
    },

    setStepOneCount: (state, action) => {
      state.stepOneProgress = action.payload;
    },

    setStepTwoCount: (state, action) => {
      state.stepTwoProgress = action.payload;
    },

    setStepThreeCount: (state, action) => {
      state.stepThreeProgress = action.payload;
    },

    setStatus: (state, action) => {
      state.vacancy.status = action.payload;
    },

    updateProgress: (state) => {
      let stepOneProgress = 0;
      let stepTwoProgress = 0;
      let stepThreeProgress = 0;
      // Step one
      if (state.vacancy.location) stepOneProgress++;
      if (state.vacancy.position) stepOneProgress++;
      if (state.vacancy.end_date) stepOneProgress++;
      if (state.vacancy.sector) stepOneProgress++;
      if (state.vacancy.number_of_vacancies) stepOneProgress++;

      // Step two
      if (state.vacancy.offerexperience.length) stepTwoProgress++;
      if (state.vacancy.offertraining.length) stepTwoProgress++;
      if (state.vacancy.offercapabilitie.length) stepTwoProgress++;
      if (state.vacancy.offerlanguage.length) stepTwoProgress++;
      if (state.vacancy.offerqualities.length) stepTwoProgress++;
      if (state.vacancy.drivingLicense.license.length) stepTwoProgress++;

      if (state.vacancy.salary_min && state.vacancy.salary_max)
        stepThreeProgress++;
      if (state.vacancy.description) stepThreeProgress++;
      if (state.vacancy.job_type) stepThreeProgress++;

      state.stepOneProgress = stepOneProgress;
      state.stepTwoProgress = stepTwoProgress;
      state.stepThreeProgress = stepThreeProgress;
    },

    resetVacancyForm: (state) => {
      state.vacancy = initialVacancyFormData;
      state.createdVacancyId = '';
    },
  },

  extraReducers(builder) {
    builder
      .addCase(createVacancy.fulfilled, (state, action) => {
        state.createdVacancyId = action.payload.id;
        toast.info(i18n.t('messages.vacancyDraftCreate'));
      })

      .addCase(updateVacancy.fulfilled, (_, action) => {
        if (action.payload) {
          ApiService.showSuccessToast(i18n.t('messages.vacancyUpdate'));
        }
      })

      .addCase(updateVacancy.rejected, () => {
        toast.error(i18n.t('messages.somethingWrong'));
      });
  },
});

export const {
  setVacancyFormLanguage,
  setVacancyFormMainInfo,
  setStepOneCount,
  setStepTwoCount,
  setStepThreeCount,
  updateProgress,
  setVacancyFormExperience,
  setVacancyFormEducation,
  setVacancyFormQualities,
  setVacancyFormCapabilities,
  setVacancyFormLicense,
  setVacancyFormJobDescription,
  resetVacancyForm,
  setStatus,
  setVacancyFormExperienceRating,
  setVacancyFormEducationRating,
  setVacancyFormLanguageRating,
  setVacancyFormQualitiesRating,
  setVacancyFormCapabilitiesRating,
  setVacancyFormLicenseRating,
} = vacancyCreateSlice.actions;
export default vacancyCreateSlice.reducer;
