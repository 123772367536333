import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { calculateCandidateMatch } from '../../../store/slices/companyCandidatesSlice';
import { Spinner } from 'react-bootstrap';
import contentLyfecicleIcon from '../../../assets/icons/content-lifecycle.svg';
import styles from './TriggerMatchButton.module.scss';
import {
  setClickedMatchPayload,
  setIsLimitReachedModal,
  setIsMatchWarningModal,
} from '../../../store/slices/modalSlice';
import { AIModels, plans } from '../../../constants/constants';
import { checkIfMatchLimitReached } from '../../../utils/check-if-match-limit-reached';
import { increaseLimitCounter } from '../../../store/slices/companySlice';

interface Props {
  id: string;
  offerId: string;
  candidateId: string;
  size?: string;
}

const TriggerMatchButton = ({
  id,
  offerId,
  candidateId,
  size = '1.5rem',
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const company = useSelector((state: RootState) => state.companyReducer);
  const [matchCalc, setMatchCalc] = useState<boolean>(false);

  const getMatchCalculation = async () => {
    try {
      await dispatch(
        calculateCandidateMatch({
          matchArr: [
            {
              ai_model: AIModels.main,
              offer_id: offerId,
              candidate_id: candidateId,
            },
          ],
          selectedMatchIds: [id],
        })
      );
      dispatch(increaseLimitCounter(1));
    } catch (error) {
      console.log(error);
    } finally {
      setMatchCalc(false);
    }
  };

  const triggerCalculation = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (
      checkIfMatchLimitReached(
        company.request_counter?.gpt_4_requests,
        company.plan
      )
    ) {
      dispatch(setIsLimitReachedModal(true));
      return;
    }

    if (company.plan === plans.premium.name) {
      setMatchCalc(true);
      getMatchCalculation();
      return;
    }

    dispatch(
      setClickedMatchPayload([
        {
          ai_model: AIModels.main,
          offer_id: offerId,
          candidate_id: candidateId,
        },
      ])
    );
    dispatch(setIsMatchWarningModal(true));
  };

  return (
    <button
      className={styles.triggerButton}
      onClick={async (e) => triggerCalculation(e)}
    >
      {matchCalc ? (
        <Spinner
          animation='border'
          variant='info'
          style={{ width: size, height: size }}
        />
      ) : (
        <img
          src={contentLyfecicleIcon}
          alt='Calculate'
          style={{ width: size, height: size }}
        />
      )}
    </button>
  );
};

export default TriggerMatchButton;
