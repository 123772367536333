import React, { useEffect, useState } from 'react';
import './Vacancies.scss';
import HomeSortButton from '../../../../components/home-sort-button/HomeSortButton';
import VacancyCard from '../../../../components/vacancy-card/VacancyCard';
import Vacancy from '../../../../models/vacancy.interface';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  filterVacancies,
  getActivatedVacancies,
  setVacanciesSearchValue,
  setVacancyDetailsRequestStatus,
  setVacancySaveStatus,
} from '../../../../store/slices/vacanciesSlice';
import {
  addRequestedVacancy,
  deleteOffer,
  deleteRequestedVacancy,
  getSavedOffers,
  offerApply,
  saveOffer,
} from '../../../../store/slices/candidateSlice';
import { useTranslation } from 'react-i18next';
import Pagination from '../../../../components/pagination/Pagination';
import {toast} from "react-toastify";
import moment from "moment/moment";
import {setCandidateStatus} from "../../../../store/slices/companyCandidatesSlice";
import {getCandidateNumberByStatus} from "../../../../utils/get-candidate-status-number";

const Vacancies = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const vacancies: Vacancy[] = useSelector(
    (state: RootState) => state.vacanciesReducer.displayedVacancies
  );
  const vacancyReducer = useSelector(
    (state: RootState) => state.vacanciesReducer
  );
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const offersCount = useSelector(
    (state: RootState) => state.vacanciesReducer.vacanciesCount
  );
  const vacanciesSearchValue = useSelector(
    (state: RootState) => state.vacanciesReducer.seachValue
  );
  const [pageNumber, setPageNumber] = useState<number>(1);

  const sortOptions = useSelector(
    (state: RootState) => state.vacanciesReducer.sortOptions
  );

  useEffect(() => {
    if (!candidate.savedOffers.length) {
      dispatch(getSavedOffers());
    }

    return () => {
      dispatch(setVacanciesSearchValue(''));
    };
  }, []);

  useEffect(() => {
    (async () => {
      await dispatch(getActivatedVacancies(pageNumber));
      dispatch(filterVacancies());
    })();
  }, [vacanciesSearchValue]);

  useEffect(() => {
    function updateVacanciesSaveState() {
      candidate.savedOffers.forEach((saved) => {
        const vacancyIndex = vacancyReducer.initialVacancies.findIndex(
          (vacancy) => vacancy.id === saved.offer
        );
        if (vacancyIndex < 0) return;

        dispatch(setVacancySaveStatus({ id: saved.offer, status: true }));
      });
    }

    if (candidate.savedOffersReceived) {
      updateVacanciesSaveState();
    }
  }, [candidate.savedOffersReceived, vacancyReducer.vacanciesReceived]);

  function toggleSaveHandler(id: string, saved: boolean): void {
    if (saved) {
      dispatch(saveOffer(id));
    } else {
      dispatch(deleteOffer(id));
    }

    dispatch(setVacancySaveStatus({ id, status: saved }));
    dispatch(filterVacancies());
  }

  function toggleApplyHandler(vacancy: Vacancy, applied: boolean): void {
    if (!candidate.is_verified) {
      toast.error(t('verifyProfileMessage.askForVerify'));
      return;
    }
    if (applied) {
      dispatch(offerApply(vacancy?.id));
      const vac = { ...vacancy };
      vac.requestDate = moment(new Date()).format('DD/MM/YY');
      dispatch(addRequestedVacancy(vac));
      dispatch(setVacancyDetailsRequestStatus(true));
    } else {
      dispatch(setCandidateStatus({offer_id: vacancy.id, candidate_id: candidate.candidate.userId, status: getCandidateNumberByStatus('withdrawn')}));
      dispatch(deleteRequestedVacancy(vacancy));
      dispatch(setVacancyDetailsRequestStatus(false));
    }

    //dispatch(setVacancySaveStatus({ id, status: saved }));
    dispatch(filterVacancies());
  }

  function onPageChange(page: number): void {
    setPageNumber(page);
    dispatch(getActivatedVacancies(page));
  }

  return (
    <div className='vacancies'>
      <div className='vacancies__title d-flex justify-content-between'>
        <h1>{t('candidateHomePage.vacancies.title')}</h1>

        <div className='vacancies__sort mobile-hidden align-items-center gap-2 '>
          <span className='text-nowrap'>
            {t('candidateHomePage.vacancies.sort')}:
          </span>
          <HomeSortButton />
        </div>
      </div>

      {vacancies && (
        <div className='vacancies__list'>
          {vacancies.map((vacancy) => {
            return (
              <VacancyCard
                key={vacancy.id}
                vacancy={vacancy}
                toggleSave={toggleSaveHandler}
                toggleApply={toggleApplyHandler}
              />
            );
          })}
        </div>
      )}

      {offersCount > 0 && (
        <Pagination
          totalPages={Math.ceil(offersCount / 10)}
          currentPage={pageNumber}
          pageSize={10}
          changeCurrentPage={onPageChange}
        />
      )}
    </div>
  );
};

export default Vacancies;
