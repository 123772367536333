import { config } from '../../_config/config';

class WebSocketService {
  private socket: WebSocket;
  private onMessageCallback?: (message: string) => void;

  constructor(socketUrl: string) {
    const url = config.WS_URL + socketUrl;

    this.socket = new WebSocket(url);

    this.socket.addEventListener('open', () => {
      // console.log('WebSocket connection opened');
    });

    this.socket.addEventListener('message', (event) => {
      const message = event;
      // console.log('Received message:', message?.data);
      // Handle the received message as needed
      if (this.onMessageCallback) {
        this.onMessageCallback(message?.data);
      }
    });

    this.socket.addEventListener('close', (event) => {
      // console.log('WebSocket connection closed:', event);
    });

    this.socket.addEventListener('error', (error) => {
      // console.error('WebSocket error:', error);
    });
  }

  sendMessage(message: string | ArrayBufferLike | Blob | ArrayBufferView) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.warn('WebSocket connection not open. Unable to send message.');
    }
  }

  onMessage(callback: (message: string) => void) {
    this.onMessageCallback = callback;
  }

  disconnect() {
    this.socket.close();
  }
}

export default WebSocketService;
