import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';

interface ResetState {}

const initialState: ResetState = {};

export const resetPassword = createAsyncThunk(
  'reset/password',
  async (email: string) => {
    const response = await axios.post(
      `${config.SERVER_URL}account/users/reset_password/`,
      { email }
    );

    return response.data;
  }
);

export const confirmReseting = createAsyncThunk(
  'reset/confirm',
  async ({
    password,
    uid,
    token,
  }: {
    password: string;
    uid: string;
    token: string;
  }) => {
    const response = await axios.post(
      `${config.SERVER_URL}account/users/reset_password_confirm/`,
      { new_password: password, uid, token }
    );

    return response.data;
  }
);

const resetSlice = createSlice({
  name: 'reset',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        const msg = i18n.t('messages.emailError');
        toast.success(msg, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })

      .addCase(resetPassword.rejected, (state, action) => {
        const emailNotExists = action.error.message?.includes('404');

        if (emailNotExists) {
          const msg = i18n.t('messages.emailNotExists');
          toast.error(msg, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
        }
      });
  },
});

export default resetSlice.reducer;
