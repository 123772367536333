import React, { useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import searchIcon from '../../../assets/icons/search.svg';
import { ASSETS_URL } from '../../../constants';
import './SearchWithButton.scss';

interface Props {
  search: (value: string) => void;
  placeholder: string;
}

const SearchWithButton = ({ search, placeholder }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      search(searchValue);
    }
  };

  return (
    <div className='search__input'>
      <Form.Group
        className='mb-3 users-search'
        controlId='exampleForm.ControlInput1'
      >
        <Form.Control
          className='input'
          type='text'
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onChange={(e: any) => setSearchValue(e?.target?.value)}
        />
        <button
          type='button'
          className='btn search__button'
          onClick={() => search(searchValue)}
        >
          <Image src={ASSETS_URL + searchIcon} />
        </button>
      </Form.Group>
    </div>
  );
};

export default SearchWithButton;
