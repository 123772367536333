import React from 'react';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import MatchManualButton from '../ui/match-percentage/MatchManualButton';
import MatchButton from '../ui/match-button/MatchButton';
import { AIModels, matchStarted } from '../../constants/constants';
import { setIsMatchModal, setMatchData } from '../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { checkIfAdvancedMatch } from '../../utils/check-if-advanced-match';

interface Props {
  candidate?: AssignedCandidate;
}

const MatchButtonGroup = ({ candidate }: Props) => {
  const lastMatch = candidate?.ai_match?.[0];
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {checkIfAdvancedMatch(lastMatch?.ai_model) ? (
        candidate?.ai_match && (
          <MatchManualButton
            classes='btn btn-primary px-1'
            percentage={lastMatch?.ai_match || ''}
            processing={lastMatch?.process_status === matchStarted}
            action={() => {
              dispatch(setMatchData(candidate));
              dispatch(setIsMatchModal(true));
            }}
          />
        )
      ) : (
        <MatchButton
          withManual={true}
          processing={
            lastMatch?.process_status === matchStarted &&
            lastMatch?.ai_model === AIModels.turbo
          }
          percentage={lastMatch?.ai_match || ''}
        />
      )}
    </>
  );
};

export default MatchButtonGroup;
