// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-arrow {
  display: flex;
  align-items: center;
  gap: 4px;
  z-index: 12; }
  .back-arrow h4 {
    font-size: 24px !important; }
  .back-arrow button {
    background-color: transparent;
    border: none; }
    .back-arrow button > :first-child {
      margin-right: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/registration-back-arrow/RegistrationBackArrow.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,WAAW,EAAA;EAJb;IAOI,0BAA0B,EAAA;EAP9B;IAWI,6BAA6B;IAC7B,YAAY,EAAA;IAZhB;MAeM,iBAAiB,EAAA","sourcesContent":[".back-arrow {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n  z-index: 12;\n\n  h4 {\n    font-size: 24px !important;\n  }\n\n  button {\n    background-color: transparent;\n    border: none;\n\n    &>:first-child {\n      margin-right: 4px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
