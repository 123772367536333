import { AIMatchStatus } from '../models/ai_match_status';

export const defaultNotSelectedDate: string = '1900-01-01';
export const maxAvatarSize = 16;
export const maxLogoSize = 16;
export const maxCompanyCoverSize = 16;

export const linkedInCredentials = {
  clientId: '77eqwyvumyvj3b',
  clientSecret: 'u9hOvlLr90cVakNY',
  redirectURI: `${window.location.origin}/linkedin`,
  accessTokenPath: 'https://www.linkedin.com/oauth/v2/accessToken',
};

export const matchStarted: AIMatchStatus = 'STARTED';
export const AIModels = {
  turbo: 'gpt-3.5-turbo',
  main: 'gpt-4-1106-preview',
};
