import React from 'react';
import close from '../../assets/icons/close.svg';
import location from '../../assets/icons/location.svg';
import work from '../../assets/icons/work.svg';
import Option from '../../models/option.interface';
import './FilterTag.scss';

const FilterTag = ({ icon, name, uncheckTag }: Option) => {
  return (
    <div className='d-flex align-items-center tag'>
      {icon && (
        <img
          className={`tag-icon ${
            icon === 'location' && 'bigger-icon'
          } grey-filter`}
          src={icon === 'location' ? location : work}
          alt={icon}
        />
      )}
      <span>{name}</span>
      <img
        className='cursor-pointer tag-close'
        src={close}
        alt='close'
        onClick={() => uncheckTag?.({ name })}
      />
    </div>
  );
};

export default FilterTag;
