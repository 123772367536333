import React, { useEffect } from 'react';
import './App.scss';

import './assets/scss/backend.scss';
import './theme/index.scss';
import AppRouting from './routes/app.routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store/store';
import { useTranslation } from 'react-i18next';
import { updateLicenseTranslation } from './store/slices/licenseSlice';
import { updateVacanciesTranslation } from './store/slices/vacanciesSlice';

function App() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  /* Remove "ResizeObserver loop completed with undelivered notifications" error which is appear due to LastPass extension */
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.startsWith('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove();
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(updateLicenseTranslation(t('licenseForm.noLicense')));
    dispatch(
      updateVacanciesTranslation({
        vacancyFilter: t('vacancyFilter', { returnObjects: true }),
        vacancySort: t('vacancySort', { returnObjects: true }),
      })
    );
  }, [i18n.language]);

  return (
    <div className='App'>
      <ToastContainer />
      <AppRouting />
    </div>
  );
}

export default App;
