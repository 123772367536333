import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import LanguageOption from '../../models/language-option.interface';

interface LanguageState {
  languages: LanguageOption[];
}

const initialState: LanguageState = {
  languages: [],
};

export const getLanguages = createAsyncThunk('languages/get', async () => {
  const response = await axios.get(`${config.SERVER_URL}lookups/language/`);

  return response.data;
});

const languageSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
  },
});

export default languageSlice.reducer;
