import React, { useState, useEffect } from 'react';
import './RoundedProgressBar.scss';

interface RoundedProgressBarProps {
  value: number;
  min: number;
  max: number | any;
}

const RoundedProgressBar: React.FC<RoundedProgressBarProps> = ({
  value,
  min,
  max,
}) => {
  const [rotateValue, setRotateValue] = useState<number>(0);

  useEffect(() => {
    const calculatedRotateValue = (value / max) * 100;
    setRotateValue(calculatedRotateValue);
  }, [value, min, max]);

  return (
    <div
      className='circle'
      style={{
        backgroundImage: `conic-gradient(#264176 ${rotateValue}%, $grey-neutral 0)`,
      }}
    >
      <div className='inner'>
        {value}/{max}
      </div>
    </div>
  );
};

export default RoundedProgressBar;
