// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-btn {
  width: 3rem;
  height: 3rem;
  padding: 12px;
  border: none;
  border-radius: 0.5rem;
  background-color: rgba(233, 233, 233, 0.6); }
  .save-btn:hover {
    background-color: rgba(233, 233, 233, 0.8); }
  .save-btn > :first-child {
    height: 1.5rem;
    width: 1.5rem; }
  .save-btn.activated {
    background-color: #0c85d0 !important; }
    .save-btn.activated:hover {
      background-color: #0c6daa !important; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/save-button/SaveButton.scss","webpack://./src/assets/scss/_mixins.scss"],"names":[],"mappings":"AAEA;ECCE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,qBAAqB;EACrB,0CAA0C,EAAA;EDN5C;ICSI,0CAA0C,EAAA;EDT9C;ICaI,cAAc;IACd,aAAa,EAAA;EDdjB;ICkBI,oCAAwC,EAAA;IDlB5C;MCqBM,oCAAoC,EAAA","sourcesContent":["@import '../../../assets/scss/mixins';\n\n.save-btn {\n  @include grey-icon-button;\n}\n","@import './variable';\n\n@mixin grey-icon-button {\n  width: 3rem;\n  height: 3rem;\n  padding: 12px;\n  border: none;\n  border-radius: 0.5rem;\n  background-color: rgba(233, 233, 233, 0.6);\n\n  &:hover {\n    background-color: rgba(233, 233, 233, 0.8);\n  }\n\n  >:first-child {\n    height: 1.5rem;\n    width: 1.5rem;\n  }\n\n  &.activated {\n    background-color: $light-blue !important;\n\n    &:hover {\n      background-color: #0c6daa !important;\n    }\n  }\n}\n\n@mixin dark-blue-filter {\n  filter: invert(13%) sepia(52%) saturate(2343%) hue-rotate(206deg) brightness(93%) contrast(84%);\n}\n\n@mixin ligth-grey-filter {\n  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(99%) contrast(94%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
