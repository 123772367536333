// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-heading-main {
  font-size: 26px !important;
  font-weight: 400 !important;
  margin: 0;
  line-height: 34px; }
  @media (max-width: 600px) {
    .upload-heading-main {
      font-size: 24px !important;
      font-weight: 500 !important;
      line-height: 30px; } }

.bg-blue-gradient {
  background: linear-gradient(135deg, #ffffff 20%, #72c6e9 80%) !important;
  height: 100%; }

.fixedlogo {
  position: absolute;
  top: 2em;
  left: 2em; }
`, "",{"version":3,"sources":["webpack://./src/views/Auth/candidate/Registration Form/form-utils.scss","webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss"],"names":[],"mappings":"AAGA;EACE,0BAA0B;EAC1B,2BAAoC;EACpC,SAAS;EACT,iBAAiB,EAAA;ECJf;IDAJ;MAOI,0BAA0B;MAC1B,2BAAoC;MACpC,iBAAiB,EAAA,EAEpB;;AAEC;EACE,wEAAwE;EACxE,YAAW,EAAA;;AAGb;EACE,kBAAiB;EACjB,QAAO;EACP,SAAQ,EAAA","sourcesContent":["@import '../../../../assets/scss/variable';\n@import '../../../../assets/scss/breakpoint/breakpoints-mixins';\n\n.upload-heading-main {\n  font-size: 26px !important;\n  font-weight: $font-normal !important;\n  margin: 0;\n  line-height: 34px;\n\n  @include mobile-breakpoint {\n    font-size: 24px !important;\n    font-weight: $font-medium !important;\n    line-height: 30px;\n  }\n}\n\n  .bg-blue-gradient {\n    background: linear-gradient(135deg, #ffffff 20%, #72c6e9 80%) !important;\n    height:100%;\n  }\n\n  .fixedlogo {\n    position:absolute;\n    top:2em;\n    left:2em;\n  }\n","@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
