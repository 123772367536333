import React from 'react';
import { Image } from 'react-bootstrap';
import { ASSETS_URL } from '../../constants';
import candidatePicture from '../../assets/icons/avatar-placeholder.svg';
import { AssignedCandidate } from '../../models/assigned-candidate.interface';
import { useNavigate } from 'react-router-dom';
import EyeIcon from '../ui/eye-icon/EyeIcon';
import TriggerMatchButton from '../ui/trigger-match-button/TriggerMatchButton';
import MatchButtonGroup from '../match-button-group/MatchButtonGroup';

interface Props {
  candidates: AssignedCandidate[];
}

const CandidatesTableMobile = ({ candidates }: Props) => {
  const navigate = useNavigate();

  return (
    <div className='d-flex flex-column gap-2 '>
      {candidates.map((candidate: AssignedCandidate, index: number) => {
        return (
          <div
            className='list-item-wrapper d-flex justify-content-between align-items-center'
            key={index}
          >
            <div className='d-flex align-items-center gap-2 mw-0'>
              <Image
                className='object-fit-cover logo'
                width={61}
                height={61}
                src={
                  ASSETS_URL + (candidate.candidate.avatar || candidatePicture)
                }
              />
              <p className='text-truncate'>
                {candidate.candidate.first_name +
                  ' ' +
                  candidate.candidate.last_name}
              </p>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <EyeIcon
                isOpen={true}
                action={() =>
                  navigate(
                    `/company/applicants/${candidate.candidate.id}/${candidate.offer?.id}`
                  )
                }
                className='position-static'
              />

              <MatchButtonGroup candidate={candidate} />

              <TriggerMatchButton
                id={candidate.id || ''}
                offerId={candidate.offer?.id || ''}
                candidateId={candidate.candidate.id}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CandidatesTableMobile;
