import React from 'react';

import './ProfileCompany.scss';
import { Col, Row, InputGroup } from 'react-bootstrap';
import { Form as RbForm } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';

import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import axios from "axios";
import {config} from "../../../_config/config";
import {toast} from "react-toastify";
import tokenService from "../../../_services/token.service";
import {object, string} from "yup";
import {submitPaymentAddress} from "../../../utils/submit-payment";

const ProfilePlansDetailCompanyAnnual = () => {
  const { t } = useTranslation();

  const benefits: string[] = t('planDetails.card.benefits', {
    returnObjects: true,
  });

  const validationSchema = object({
    firstname: string().required(t('errors.fieldRequired')),
    lastname: string().required(t('errors.fieldRequired')),
    email: string().required(t('errors.fieldRequired')),
    address: string().required(t('errors.fieldRequired')),
    city: string().required(t('errors.fieldRequired')),
    state: string().required(t('errors.fieldRequired')),
    postcode: string().required(t('errors.fieldRequired')),
  });

  const companyDetails = useSelector(
      (state: RootState) => state.companyReducer
  );

  return (
      <>
        <div className='zestur-card'>
          <div className='d-flex align-items-center justify-content-between'>
            <h2 className='general-heading fw-semibold'>
              {t('planDetails.card.title')}
            </h2>
            <h1 className='fw-bold m-0'>250,00 €</h1>
          </div>
          <hr />

          <p className='general-heading'>{t('planDetails.card.benefitsTitle')}</p>
          <ul className='mt-3 f-12'>
            {benefits.map((item) => (
                <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
        <div className='zestur-card mt-4'>
          <Row>
            <Col md={6}>
              <div className='d-flex align-items-center justify-content-between'>
                <h2 className='general-heading fw-semibold'>
                  {t('planDetails.formInfo.title')}
                </h2>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between mt-5'>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  {t('planDetails.formInfo.subtitle')}
                </h2>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  250,00 €
                </h2>
              </div>
              <h2 className='general-sub-heading mt-2 mb-4'>
                {t('planDetails.formInfo.planSubtitle')}
              </h2>
              <hr />
              <Row className='mt-4 mb-4'>
                <Col md={9}>
                  <RbForm.Group controlId='paymentForm.discountCode'>
                    <RbForm.Control
                        type='text'
                        className='form-control-gray'
                        placeholder={t('planDetails.formInfo.discountPlaceholder')}
                    />
                  </RbForm.Group>
                </Col>
                <Col md={3}>
                  <button className='btn btn-success w-100 py-2'>
                    {t('planDetails.formInfo.discountButton')}
                  </button>
                </Col>
              </Row>
              <hr />
              <div className='d-flex align-items-center justify-content-between mt-5'>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  {t('planDetails.formInfo.subtotal')}
                </h2>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  250,00 €
                </h2>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-between mt-5'>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  {t('planDetails.formInfo.total')}
                </h2>
                <h2 className='general-sub-heading mt-2'>
                  {t('planDetails.formInfo.includingVatTitle')}
                </h2>
                <h2 className='general-sub-heading fw-semibold text-dark'>
                  250,00 €
                </h2>
              </div>
            </Col>
            <Col md={6}>
              <div className='d-flex align-items-center justify-content-between'>
                <h2 className='general-heading fw-semibold'>
                  {t('planDetails.form.title')}
                </h2>
              </div>
              <hr />
              <Formik
                  initialValues={{
                    firstname: companyDetails.firstName,
                    lastname: companyDetails.lastName,
                    email: companyDetails.email,
                    address: companyDetails.address,
                    city: companyDetails.city,
                    state: '',
                    country: 'ES',
                    postcode: ''
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    submitPaymentAddress(values.firstname, values.lastname, values.email, values.address, values.city, values.state, values.postcode, values.country, 'yearly');
                  }}
              >
                {({ values, errors, touched, setFieldValue }) => (
                    <Form className='mt-4'>
                      <RbForm.Group
                          className='mb-3'
                          controlId='paymentForm.name'
                      >
                        <Row>
                          <Col md={6}>
                            <RbForm.Label className='fw-bold mb-3'>
                              {t('payments.firstname')}
                            </RbForm.Label>
                            <InputGroup hasValidation>
                              <Field
                                  type='text'
                                  className='form-control form-control-gray'
                                  name='firstname'
                              />
                              {errors.firstname && touched.firstname ? (
                                  <p className='text-danger position-absolute error-message candidate'>
                                    {errors.firstname}
                                  </p>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col md={6}>
                            <RbForm.Label className='fw-bold mb-3'>
                              {t('payments.lastname')}
                            </RbForm.Label>
                            <InputGroup hasValidation>
                              <Field
                                  type='text'
                                  className='form-control form-control-gray'
                                  name='lastname'
                              />
                              {errors.lastname && touched.lastname ? (
                                  <p className='text-danger position-absolute error-message candidate'>
                                    {errors.lastname}
                                  </p>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                      </RbForm.Group>
                      <RbForm.Group
                          className='mb-3'
                          controlId='paymentForm.email'
                      >
                        <RbForm.Label className='fw-bold mb-2'>
                          {t('payments.email')}
                        </RbForm.Label>
                        <InputGroup hasValidation>
                          <Field
                              type='text'
                              className='form-control form-control-gray'
                              name='email'
                          />
                          {errors.email && touched.email ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.email}
                              </p>
                          ) : null}
                        </InputGroup>
                      </RbForm.Group>
                      <RbForm.Group
                          className='mb-3'
                          controlId='paymentForm.address'
                      >
                        <RbForm.Label className='fw-bold mb-2'>
                          {t('payments.address')}
                        </RbForm.Label>
                        <InputGroup hasValidation>
                          <Field
                              type='text'
                              className='form-control form-control-gray'
                              name='address'
                          />
                          {errors.address && touched.address ? (
                              <p className='text-danger position-absolute error-message candidate'>
                                {errors.address}
                              </p>
                          ) : null}
                        </InputGroup>
                      </RbForm.Group>
                      <Row>
                        <Col md={6}>
                          <RbForm.Group
                              className='mb-3'
                              controlId='paymentForm.city'
                          >
                            <RbForm.Label className='fw-bold mb-2'>
                              {t('payments.city')}
                            </RbForm.Label>
                            <InputGroup hasValidation>
                              <Field
                                  type='text'
                                  className='form-control form-control-gray'
                                  name='city'
                              />
                              {errors.city && touched.city ? (
                                  <p className='text-danger position-absolute error-message candidate'>
                                    {errors.city}
                                  </p>
                              ) : null}
                            </InputGroup>
                          </RbForm.Group>
                        </Col>
                        <Col md={6}>
                          <RbForm.Group
                              className='mb-3'
                              controlId='paymentForm.state'
                          >
                            <RbForm.Label className='fw-bold mb-2'>
                              {t('payments.state')}
                            </RbForm.Label>
                            <InputGroup hasValidation>
                              <Field
                                  type='text'
                                  className='form-control form-control-gray'
                                  name='state'
                              />
                              {errors.state && touched.state ? (
                                  <p className='text-danger position-absolute error-message candidate'>
                                    {errors.state}
                                  </p>
                              ) : null}
                            </InputGroup>
                          </RbForm.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <RbForm.Group
                              className='mb-3'
                              controlId='paymentForm.postcode'
                          >
                            <RbForm.Label className='fw-bold mb-2'>
                              {t('payments.postcode')}
                            </RbForm.Label>
                            <InputGroup hasValidation>
                              <Field
                                  type='text'
                                  className='form-control form-control-gray'
                                  name='postcode'
                              />
                              {errors.postcode && touched.postcode ? (
                                  <p className='text-danger position-absolute error-message candidate'>
                                    {errors.postcode}
                                  </p>
                              ) : null}
                            </InputGroup>
                          </RbForm.Group>
                        </Col>
                        <Col md={6}>
                          <RbForm.Group
                              className='mb-3'
                              controlId='paymentForm.country'
                          >
                            <RbForm.Label className='fw-bold mb-2'>
                              {t('payments.country')}
                            </RbForm.Label>
                            <Field as='select' name='country' className='form-control form-control-gray' value='ES'>
                              <option value="ES">{t('country.Spain')}</option>
                              <option value="PT">{t('country.Portugal')}</option>
                              <option value="FR">{t('country.France')}</option>
                              <option value="GB">{t('country.UnitedKingdom')}</option>
                            </Field>
                          </RbForm.Group>
                        </Col>
                      </Row>
                      <button
                          className='btn btn-primary primary-btn-color w-100 py-3 mt-4'
                          type='submit'
                      >
                        {t('planDetails.form.pay')} 250,00 €
                      </button>
                      <p className='general-sub-heading mt-4'>
                        {t('planDetails.form.paymentDescription')}
                      </p>
                    </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>

        <RbForm id='paymentForm' action="" method="POST"></RbForm>
      </>
  );
};

export default ProfilePlansDetailCompanyAnnual;
