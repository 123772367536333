import React from 'react';
import './RegistrationLanguageSelect.scss';
import LanguageOption from '../../../models/language-option.interface';
import { useTranslation } from 'react-i18next';
import { getLocalizedLanguageOption } from '../../../utils/languages-switch/get-localized-language-option';
import { getSortedLanguages } from '../../../utils/languages-switch/get-sorted-languages';
import { ListItem } from '../../../models/list-item';

interface Props {
  withHeaders: boolean;
  index: number;
  lang: string;
  level: number;
  languages: LanguageOption[];
  selectLanguage: (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  selectLevel: (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
}

const RegistrationLanguageSelect = ({
  withHeaders,
  index,
  lang,
  level,
  languages,
  selectLanguage,
  selectLevel,
}: Props) => {
  const { t, i18n } = useTranslation();

  const levels: ListItem[] = t('languageForm.levels', {
    returnObjects: true,
  });

  return (
    <div className='language'>
      <div>
        {withHeaders && <h5>{t('languageForm.languageTitle')} *</h5>}
        <select
          className='form-select'
          value={lang}
          onChange={(event) => selectLanguage(event, index)}
        >
          <option value=''></option>
          {getSortedLanguages([...languages], i18n.language).map((option) => (
            <option key={option.id} value={option.id}>
              {getLocalizedLanguageOption(option, i18n.language)}
            </option>
          ))}
        </select>
      </div>

      <div>
        {withHeaders && <h5>{t('languageForm.levelTitle')} *</h5>}
        <select
          value={level}
          className='form-select'
          onChange={(event) => selectLevel(event, index)}
        >
          <option value=''></option>
          {levels.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default RegistrationLanguageSelect;
