import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { toast } from 'react-toastify';
import i18n from '../../i18n/i18n';
import tokenService from '../../_services/token.service';

interface ReportState {}

const initialState: ReportState = {};

export const sendOfferReport = createAsyncThunk(
  'report/sendOfferReport',
  async ({ offerId, report }: { offerId: string; report: string }) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.post(
      `${config.SERVER_URL}offer/report/${offerId}/`,
      {
        report,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return response.data;
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder.addCase(sendOfferReport.fulfilled, () => {
      toast.success(i18n.t('messages.reportSent'));
    });
  },
});

export const {} = reportSlice.actions;
export default reportSlice.reducer;
