import React from 'react';
import { useTranslation } from 'react-i18next';
import VacancyDetailsQualities from './VacancyDetailsQualities';
import { ListItem } from '../../models/list-item';
import { VacancyProps } from '../../models/vacancy-props.interface';

const VacancyDetailsSkills = ({ vacancy }: VacancyProps) => {
  const { t } = useTranslation();

  const aptitudeList: ListItem[] = t('aptitudeList', { returnObjects: true });

  return (
    <div className='details__info'>
      <h5>{t('vacancyDetails.subtitles.skill')}</h5>
      <div>
        {vacancy.qualities && (
          <VacancyDetailsQualities
            title={t('vacancyDetails.labels.quality')}
            list={vacancy.qualities}
            translationList={aptitudeList}
          />
        )}

        {vacancy.offercapabilitie && (
          <VacancyDetailsQualities
            title={t('vacancyDetails.labels.capability')}
            list={vacancy.offercapabilitie}
            translationList={aptitudeList}
          />
        )}
      </div>
    </div>
  );
};

export default VacancyDetailsSkills;
