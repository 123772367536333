import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { config } from '../../_config/config';
import { MatchData } from '../../models/match-data.interface';
import tokenService from '../../_services/token.service';

interface MatchDocumentState {
  matchData: MatchData | null;
}

const initialState: MatchDocumentState = {
  matchData: null,
};

export const fetchMatchData = createAsyncThunk(
  'matchDocument/get',
  async ({
    offer_id,
    candidate_id,
    withEffect = true,
  }: {
    offer_id: string;
    candidate_id: string;
    withEffect?: boolean;
  }) => {
    const token = tokenService.getLocalAccessToken();
    const response = await axios.get(
      `${config.SERVER_URL}offer/${offer_id}/ai-match-latest/${candidate_id}/`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return { data: response.data, withEffect };
  }
);

const matchDocumentSlice = createSlice({
  name: 'matchDocument',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchMatchData.fulfilled, (state, action) => {
      if (!action.payload.withEffect) return;
      state.matchData = {
        ...action.payload.data.ai_match,
        offerRating: action.payload.data.offer,
      };
    });
  },
});

export const {} = matchDocumentSlice.actions;
export default matchDocumentSlice.reducer;
