import axios from "axios";
import { config } from "../_config/config";
const instance = axios.create({
  baseURL: config.SERVER_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

export default instance;
