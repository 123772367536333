import React from 'react';
import { Spinner } from 'react-bootstrap';
import './Loader.scss';

const Loader = () => {
  return (
    <div className='loader position-absolute'>
      <Spinner
        animation='border'
        variant='info'
        style={{ width: '2rem', height: '2rem' }}
      />
    </div>
  );
};

export default Loader;
