import React, { ChangeEvent } from 'react';
import editIcon from '../../../assets/icons/edit.svg';
import { ASSETS_URL } from '../../../constants';
import styles from './UploadResumeButton.module.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { addResume, uploadResume } from '../../../store/slices/candidateSlice';

const UploadResumeButton = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const selectedFile: File[] = Array.from(e.target.files);
    if (selectedFile[0].type !== 'application/pdf') {
      return;
    }

    handleUpload(selectedFile[0]);
  };

  const handleUpload = (file: File) => {
    if (!file) return;

    dispatch(addResume({ file: file }));
    dispatch(uploadResume());
  };

  return (
    <>
      <label htmlFor='upload-button'>
        <img
          src={ASSETS_URL + editIcon}
          alt='edit'
          className={styles.uploadButton}
        />
      </label>

      <input
        type='file'
        id='upload-button'
        style={{ display: 'none' }}
        accept='.pdf'
        onChange={handleChange}
      />
    </>
  );
};

export default UploadResumeButton;
