import { object, ref, string } from 'yup';
import i18n from '../i18n/i18n';

export function getPasswordSchema() {
  return object({
    password: string()
      .min(8, i18n.t('errors.passwordMin'))
      .required(i18n.t('errors.passwordRequired'))
      .matches(/^(?=.*[a-z])/, i18n.t('errors.passwordOneLowerCase'))
      .matches(/^(?=.*[A-Z])/, i18n.t('errors.passwordOneUpperCase'))
      .matches(/^(?=.*[0-9])/, i18n.t('errors.passwordOneNumber'))
      .matches(/^(?=.*[?!@#$%^&*])/, i18n.t('errors.passwordOneSpecial')),
    confirmPassword: string()
      .min(8, i18n.t('errors.passwordMin'))
      .required(i18n.t('errors.passwordReEnter'))
      .matches(/^(?=.*[a-z])/, i18n.t('errors.passwordOneLowerCase'))
      .matches(/^(?=.*[A-Z])/, i18n.t('errors.passwordOneUpperCase'))
      .matches(/^(?=.*[0-9])/, i18n.t('errors.passwordOneNumber'))
      .matches(/^(?=.*[?!@#$%^&*])/, i18n.t('errors.passwordOneSpecial'))
      // use oneOf to match one of the values inside the array.
      // use "ref" to get the value of passwrod.
      .oneOf([ref('password')], i18n.t('errors.passwordNotMatch')),
  });
}
