export const formatSalaryCandidateCard = (
  salaryMin: number,
  salaryMax: number,
  minPrefix: string,
  maxPrefix: string
): string => {
  let min: string = Math.abs(salaryMin).toString();
  let max: string = Math.abs(salaryMax).toString();

  const resultMin = min.slice(0, 2) + '.' + min.slice(2);
  const resultMax = max.slice(0, 2) + '.' + max.slice(2);

  if (min === '0') {
    return `${minPrefix} ${resultMax}`;
  }

  if (max === '0') {
    return `${maxPrefix} ${resultMin}`;
  }
  return resultMin;
};

export const salaryRange = (
  minSalary: number,
  maxSalary: number,
  minPrefix: string,
  maxPrefix: string
): string => {
  let min: string = Math.abs(minSalary).toString();
  let max: string = Math.abs(maxSalary).toString();

  const minSlice = min.length > 5 ? 3 : 2;
  const maxSlice = max.length > 5 ? 3 : 2;

  if (min !== '0') min = min.slice(0, minSlice) + '.' + min.slice(minSlice);
  if (max !== '0') max = max.slice(0, maxSlice) + '.' + max.slice(maxSlice);

  if (min === '0') return `${minPrefix} ${max} €`;
  if (max === '0') return `${maxPrefix} ${min} €`;

  return `${min} € - ${max} €`;
};
