import React from 'react';
import { getYear } from '../../../utils/get-year';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { ASSETS_URL } from '../../../constants';
import { ProgressBar, Image } from 'react-bootstrap';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import './CandidateViewInfoSection.scss';
import { defaultNotSelectedDate } from '../../../constants/constants';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CandidateViewInfoSection = () => {
  const { t } = useTranslation();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const navigate = useNavigate();

  function getExperienceYears(): number {
    if (!candidate.experience.length) return 0;

    const startExperience = candidate.experience.reduce((prev, curr) => {
      if (curr.start_date === defaultNotSelectedDate) {
        return prev;
      }
      return getYear(prev.start_date) < getYear(curr.start_date) ? prev : curr;
    });

    const endExperience = candidate.experience.reduce((prev, curr) =>
      getYear(prev.end_date) > getYear(curr.end_date) ? prev : curr
    );

    let endDate = endExperience.end_date;
    if (endExperience.end_date === defaultNotSelectedDate) {
      endDate = moment(new Date()).format('YYYY-MM-DD');
    }

    return getYear(endDate) - getYear(startExperience.start_date);
  }

  function getProgress(): number {
    let progress = 2;
    if (candidate.avatar) progress++;
    if (candidate.cv.length) progress++;
    if (candidate.education.length) progress++;
    if (candidate.experience.length) progress++;
    if (candidate.certificates.length) progress++;
    if (candidate.languages.length) progress++;
    if (candidate.skills.length) progress++;
    if (candidate.description && candidate.seniorityLevel && candidate.role)
      progress++;

    if (progress > 10) return 100;
    return progress * 10;
  }

  return (
    <div className='zestur-card mobile-hidden'>
      <div className='candidate-profile'>
        <Image
          className='candidate-profile__avatar object-fit-cover'
          src={candidate.avatar || candidateImage}
        />
        <h2>
          {candidate.contactInfo.name + ' ' + candidate.contactInfo.lastName}
        </h2>
        <p className='m-0'>{candidate.experience[0]?.job_position}</p>
      </div>
      <div className='progress-title my-3'>
        {t('candidateViewSection.progressLabel')}
      </div>
      <ProgressBar now={getProgress()} label={`${getProgress()}%`} />

      <div className='info-title my-3 text-dark'>
        {t('candidateViewSection.infoTitle')}
      </div>

      {candidate.seniorityLevel && (
        <>
          <div className='info-subtitle mt-3'>
            {t('candidateViewSection.seniorityLabel')}
          </div>
          <small className='fw-semibold'>{candidate.seniorityLevel}</small>
        </>
      )}

      <div className='info-subtitle mt-3'>
        {t('candidateViewSection.experienceLabel')}
      </div>
      <small className='fw-semibold'>
        {getExperienceYears()}{' '}
        {getExperienceYears() === 1
          ? t('time.singularYear')
          : t('time.pluralYear')}
      </small>

      {candidate.description?.length > 1 && (
        <>
          <div className='info-subtitle mt-3'>
            {t('candidateViewSection.descriptionLabel')}
          </div>
          <small className='fw-semibold'>{candidate.description}</small>
        </>
      )}

      <button
        className='info-button btn w-100 btn-gray py-3 mt-4'
        onClick={() => navigate('/candidate/applicants/detail')}
      >
        {t('candidateViewSection.editButton')}
      </button>
    </div>
  );
};

export default CandidateViewInfoSection;
