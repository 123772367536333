import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import tokenService from '../../_services/token.service';
import axios from 'axios';
import { config } from '../../_config/config';
import Vacancy from '../../models/vacancy.interface';
import ApiService from '../../core/services/network.service';
import i18n from '../../i18n/i18n';
import { decodeToken } from '../../models/decode-token';

interface CompanyVacanciesSlice {
  companyVacancies: Vacancy[];
  total: number;
  active: number;
  inactive: number;
  pending: number;
}

const initialState: CompanyVacanciesSlice = {
  companyVacancies: [],
  total: 0,
  active: 0,
  inactive: 0,
  pending: 0,
};

export const getCompanyVacancies = createAsyncThunk(
  'company/getCompanyVacancies',
  async ({
    pageNumber,
    status,
    searchValue,
    size,
    orderBy = '',
  }: {
    pageNumber: number;
    status: number;
    searchValue: string;
    size: number;
    orderBy?: string;
  }) => {
    const token = tokenService.getLocalAccessToken();
    const decodedToken = decodeToken(token);

    const response = await axios.get(
      config.SERVER_URL +
        'offer/company/' +
        decodedToken?.company_id +
        `/?page=${pageNumber}&size=${size}&status=${
          status ? status : ''
        }&search=${searchValue}&order_by=${orderBy}`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return { response: response.data.body, status, searchValue };
  }
);

export const deleteVacancies = createAsyncThunk(
  'company/deleteVacancies',
  async (ids: string[]) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.post(
      config.SERVER_URL + 'offer/bulk_destroy/',
      { ids },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return { ids, data: response.data };
  }
);

export const deactivateVacancies = createAsyncThunk(
  'company/deactivateVacancies',
  async (ids: string[]) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.post(
      config.SERVER_URL + 'offer/bulk_deactivate/',
      { ids },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return { ids, data: response.data };
  }
);

export const activateVacancies = createAsyncThunk(
  'company/activateVacancies',
  async (ids: string[]) => {
    const token = tokenService.getLocalAccessToken();

    const response = await axios.post(
      config.SERVER_URL + 'offer/bulk_activate/',
      { ids },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );

    return { ids, data: response.data };
  }
);

const companyVacanciesSlice = createSlice({
  name: 'companyVacancies',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(deleteVacancies.fulfilled, (state, action) => {
        state.companyVacancies = state.companyVacancies.filter(
          (vacancy) =>
            !action.payload.ids.some((id: string) => id === vacancy.id)
        );
        ApiService.showSuccessToast(i18n.t('messages.successDelete'));
        state.total = action.payload.data.body.total;
        state.active = action.payload.data.body.active;
        state.inactive = action.payload.data.body.inactive;
        state.pending = action.payload.data.body.pending;
      })

      .addCase(getCompanyVacancies.fulfilled, (state, action) => {
        action.payload.response.data.map(
          (item: Vacancy) => (item.checked = false)
        );
        state.companyVacancies = action.payload.response.data;

        if (action.payload.status || action.payload.searchValue) return;
        state.total = action.payload.response.total;
        state.active = action.payload.response.active;
        state.inactive = action.payload.response.inactive;
        state.pending = action.payload.response.pending;
      })

      .addCase(deactivateVacancies.fulfilled, (state, action) => {
        state.companyVacancies.map((vacancy) => {
          action.payload.ids.map((id: string) => {
            if (id === vacancy.id) {
              vacancy.status = 'Inactive';
            }
          });
        });

        state.total = action.payload.data.body.total;
        state.active = action.payload.data.body.active;
        state.inactive = action.payload.data.body.inactive;
        state.pending = action.payload.data.body.pending;
      })

      .addCase(activateVacancies.fulfilled, (state, action) => {
        state.companyVacancies.map((vacancy) => {
          action.payload.ids.map((id: string) => {
            if (id === vacancy.id) {
              vacancy.status = 'Publish';
            }
          });
        });

        state.total = action.payload.data.body.total;
        state.active = action.payload.data.body.active;
        state.inactive = action.payload.data.body.inactive;
        state.pending = action.payload.data.body.pending;
      });
  },
});

export default companyVacanciesSlice.reducer;
