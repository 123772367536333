import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue?: number;
  setRating: (rate: number) => void;
}

const ImportanceSelect = ({ defaultValue = 1, setRating }: Props) => {
  const { t } = useTranslation();

  function selectOption(e: React.ChangeEvent<HTMLSelectElement>): void {
    setRating(+e.target.value);
  }

  return (
    <div className='d-flex flex-column align-items-end'>
      <p className='fw-bold mb-1 form-label'>
        {t('createOffer.importanceTitle')}
      </p>
      <select
        defaultValue={defaultValue || 1}
        onChange={selectOption}
        className='mx-1 cursor-pointer'
      >
        {Array.from({ length: 10 }, (_, i) => ++i).map((item) => (
          <option value={item} key={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ImportanceSelect;
