import React, { useState } from 'react';
import SaveButton from '../ui/save-button/SaveButton';
import { VacancyProps } from '../../models/vacancy-props.interface';
import ReportOfferButton from '../ui/report-offer-button/ReportOfferButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { deleteOffer, saveOffer } from '../../store/slices/candidateSlice';
import { setVacancyDetailsSave } from '../../store/slices/vacanciesSlice';
import ReportModal from '../ui/report-modal/ReportModal';
import ShareButton from '../ui/share-button/ShareButton';

const VacancyDetailsActionIcons = ({ vacancy }: VacancyProps) => {
  const [isReportModal, setIsReportModal] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  function openReportModal(): void {
    setIsReportModal(true);
  }

  function toggleSaveHandler(id: string, saved: boolean): void {
    if (saved) {
      dispatch(saveOffer(id));
    } else {
      dispatch(deleteOffer(id));
    }

    dispatch(setVacancyDetailsSave(saved));
  }

  return (
    <>
      <SaveButton
        id={vacancy.id}
        toggleSave={toggleSaveHandler}
        saved={vacancy.saved}
      />
      <ShareButton />
      <ReportOfferButton openModal={openReportModal} />
      <ReportModal
        show={isReportModal}
        handleClose={() => setIsReportModal(false)}
        id={vacancy?.id || ''}
      />
    </>
  );
};

export default VacancyDetailsActionIcons;
