// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 600px) {
  .menu {
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 100;
    top: 0;
    background-color: white; }
    .menu__close {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1.5rem; }
      .menu__close-icon {
        cursor: pointer;
        margin-right: 1rem;
        width: 42px;
        height: 42px; } }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/breakpoint/_breakpoints-mixins.scss","webpack://./src/components/shared/menu/Menu.scss"],"names":[],"mappings":"AAGI;ECAF;IACE,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,MAAM;IACN,uBAAuB,EAAA;IAEvB;MACE,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;MACnB,gBAAgB;MAChB,qBAAqB,EAAA;MAErB;QACE,eAAe;QACf,kBAAkB;QAClB,WAAW;QACX,YAAY,EAAA,EACb","sourcesContent":["@import \"_breakpoints\";\n\n@mixin mobile-breakpoint {\n    @media (max-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-mobile-breakpoint {\n    @media (min-width: $xsBreakpoint) {\n        @content;\n    }\n}\n\n@mixin tablet-breakpoint {\n    @media (max-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin no-tablet-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin md-desktop-breakpoint {\n    @media (max-width: $mdBreakpoint) {\n        @content;\n    }\n}\n\n@mixin desktop-breakpoint {\n    @media (min-width: $smBreakpoint) {\n        @content;\n    }\n}\n\n@mixin large-breakpoint {\n    @media (min-width: $lgBreakpoint) {\n        @content;\n    }\n}\n","@import '../../../assets/scss/breakpoint/breakpoints-mixins';\n\n@include mobile-breakpoint {\n  .menu {\n    position: fixed;\n    height: 100vh;\n    width: 100%;\n    z-index: 100;\n    top: 0;\n    background-color: white;\n\n    &__close {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      margin-top: 1rem;\n      margin-bottom: 1.5rem;\n\n      &-icon {\n        cursor: pointer;\n        margin-right: 1rem;\n        width: 42px;\n        height: 42px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
