import React from 'react';
import { Row, Col, Image, Form, InputGroup } from 'react-bootstrap';
import RegistrationActionButton from '../../../components/RegistrationActionButton';
import logo from '../../../assets/images/job-portal/zestur-logo-internal.svg';
import { ASSETS_URL } from '../../../constants';
import { backgroundImage } from '../../../utils/AppConstants';
import { Field, Formik, Form as FormikForm } from 'formik';
import Step from '../../../models/step.interface';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { boolean } from 'yup';
import {
  createCompany,
  setCompanyContactInfo,
  setTerms
} from '../../../store/slices/companySlice';
import { useTranslation } from 'react-i18next';
import RestrictedInput from '../../../components/ui/restricted-input/RestrictedInput';
import ProgressBarTitle from '../../../components/ui/progress-bar-title/ProgressBarTitle';
import MobileProgress from '../../../components/mobile-progress/MobileProgress';
import {toast} from "react-toastify";
import {maxAvatarSize} from "../../../constants/constants";

function RegistrationStep5(props: Step) {
  const { t } = useTranslation();
  const company = useSelector((state: RootState) => state.companyReducer);
  const dispatch = useDispatch<AppDispatch>();
  const logoURL: string = `${ASSETS_URL}`;
  const validationSchema = object({
    firstName: string().required(t('errors.fieldRequired')),
    lastName: string().required(t('errors.fieldRequired')),
    phoneCode: string().required(t('errors.fillCode')),
    phoneNumber: string().required(t('errors.fieldRequired')),
    terms: boolean().oneOf([true], t('errors.termsNotAccepted')),
  });

  return (
    <div className='bg-white register-form'>
      <Row>
        <Col xs={12} className='no-mobile-hidden'>
          <MobileProgress
            data={props}
            progressValue={100}
            startStep={5}
            endStep={5}
          />
        </Col>
        <Col xs={12} md={3} className='mobile-hidden'>
          <div className='register-sidebar' style={backgroundImage}>
            <Image
              className='ms-3 mt-3'
              src={logoURL + logo}
              alt='logo Image'
              fluid
            />
          </div>
        </Col>

        <Col xs={12} className='no-mobile-hidden'>
          <h2 className='text-main-black px-4'>
            {t('companyRegistration.step5.title')}
          </h2>
        </Col>

        <Col
          xs={12}
          md={9}
          className='d-flex justify-content-center justify-items-center'
        >
          <Formik
            initialValues={{
              firstName: company.firstName,
              lastName: company.lastName,
              phoneCode: company.phoneCode,
              phoneNumber: company.phoneNumber,
              terms: company.terms,
            }}
            onSubmit={(values) => {
              dispatch(setCompanyContactInfo(values));
              dispatch(setTerms(values.terms));
              dispatch(createCompany()).then((result) => {
                  if (!result.hasOwnProperty('error')) {
                      // Push the event to dataLayer upon success
                      if (window.dataLayer) {
                          window.dataLayer.push({
                              'event': 'SignUp'
                          });
                      }
                    props.handleNext();
                  } else {
                    toast.error(`${t('messages.unexpectedError')}`, {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light',
                      });
                  }
              })
            }}
            validationSchema={validationSchema}
          >
            {({ values, errors, touched, setFieldValue }) => (
              <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
                {/* main-ProgressBar */}
                <div className='main-ProgressBar mobile-hidden'>
                  <ProgressBarTitle
                    startStep={5}
                    endStep={5}
                    progressValue={100}
                  />
                  <h2>{t('companyRegistration.step5.title')}</h2>
                </div>

                {/* form-fields */}
                <Row className='form-fields candidate'>
                  <Row>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      controlId='validationCustom02'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.nameLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.firstName}
                              valueName='firstName'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.firstName && touched.firstName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.firstName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={6}
                      xs={12}
                      className='mobile-mt-2'
                      controlId='validationCustom03'
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.surnameLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <RestrictedInput
                              setFieldValue={setFieldValue}
                              fieldValue={values.lastName}
                              valueName='lastName'
                              restriction='text'
                            />
                          )}
                        </Field>
                        {errors.lastName && touched.lastName ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.lastName}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className='align-items-end'>
                    <Form.Group
                      controlId='validationCustom02'
                      as={Col}
                      md={2}
                      xs={3}
                      style={{ paddingRight: 0 }}
                    >
                      <Form.Label className='label'>
                        {t('companyRegistration.step5.phoneLabel')}*
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 text-center py-3'
                          type='text'
                          placeholder='+34'
                          aria-describedby='inputGroupPrepend'
                          name='phoneCode'
                        />
                        {errors.phoneCode && touched.phoneCode ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneCode}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={10}
                      xs={9}
                      controlId='validationCustom02'
                      className='mobile-mt-2'
                    >
                      <Form.Label className='label'></Form.Label>
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <div className='w-100'>
                              <RestrictedInput
                                setFieldValue={setFieldValue}
                                fieldValue={values.phoneNumber}
                                valueName='phoneNumber'
                                restriction='number'
                              />
                            </div>
                          )}
                        </Field>
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneNumber}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      xs={12}
                      controlId='validationCustom01'
                      className='mobile-mt-2'
                    >
                      {['checkbox'].map((type: any) => (
                        <div key={`default-${type}`} className='mb-3'>
                          <label
                            className={`register-checkbox position-relative${
                              errors.terms && touched.terms ? ' mb-4' : ''
                            }`}
                          >
                            <Field type='checkbox' name='terms' />{' '}
                            <span className='checkbox-text'>
                              {t('companyRegistration.step6.termsLabel')}
                            </span>
                            {errors.terms && touched.terms ? (
                              <p className='text-danger error-message candidate checkbox-error'>
                                {errors.terms}
                              </p>
                            ) : null}
                          </label>
                          <label className='register-checkbox mt-2'>
                            <Field name='comunication' type='checkbox' />
                            <span className='checkbox-text'>
                              {t('companyRegistration.step6.subscriptionLabel')}
                            </span>
                          </label>
                        </div>
                      ))}
                    </Form.Group>
                  </Row>
                </Row>

                <Row className='mt-4 no-mobile-hidden'></Row>

                <RegistrationActionButton
                  data={props}
                  isSubmit={true}
                ></RegistrationActionButton>
              </FormikForm>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
}

export default RegistrationStep5;
