// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-button {
  display: flex;
  align-items: center;
  padding: 4px 14px;
  gap: 10px;
  border-radius: 40px;
  border: none;
  background-color: #0c85d0; }
  .tag-button span {
    color: white;
    font-size: 14px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/registration-tag/RegistrationTag.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,mBAAmB;EACnB,YAAY;EACZ,yBCLkB,EAAA;EDFpB;IAUI,YAAY;IACZ,eAAe,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.tag-button {\n  display: flex;\n  align-items: center;\n  padding: 4px 14px;\n  gap: 10px;\n  border-radius: 40px;\n  border: none;\n  background-color: $light-blue;\n\n  span {\n    color: white;\n    font-size: 14px;\n  }\n}","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n\n// Redesign 2025\n$main-grey: #FAFAF4;\n$candidate: #4EC6DB;\n$candidate-border: #011640;\n$company: #D5006D;\n$company-border: #AE085A;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
