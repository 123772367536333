// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-tag {
  font-family: 'Poppins';
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  border: none;
  text-align: center;
  color: white;
  font-size: 14px;
  max-height: 28px;
  padding: 4px 12px;
  border-radius: 24px;
  opacity: 0.8;
  background-color: #213867; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/vacancy-match/VacancyMatchButton.scss","webpack://./src/assets/scss/_variable.scss"],"names":[],"mappings":"AAEA;EACE,sBAAsB;EACtB,uBAAkB;EAAlB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EAEjB,mBAAmB;EACnB,YAAY;EACZ,yBCbiB,EAAA","sourcesContent":["@import '../../../assets/scss/variable';\n\n.match-tag {\n  font-family: 'Poppins';\n  width: fit-content;\n  white-space: nowrap;\n  border: none;\n  text-align: center;\n  color: white;\n  font-size: 14px;\n  max-height: 28px;\n  padding: 4px 12px;\n\n  border-radius: 24px;\n  opacity: 0.8;\n  background-color: $dark-blue;\n}\n","// Colors\n$main-black: #333333;\n$dark-blue: #213867;\n$dark-blue-alt: #264176;\n$light-blue: #0c85d0;\n$dark-grey-alt: #5b5b5c;\n$dark-grey: #7c7c7c;\n$light-red: #ff6a55;\n$graylight: #a7a7a7;\n$gray-border: #efefef;\n$grey: #bebebe;\n$grey-neutral: #dddddd;\n\n$white-smoke: #f3f3f3;\n\n$black-900: #252525;\n// All Fonts weight Variables\n$font-thin: 100;\n$font-extra-light: 200;\n$font-light: 300;\n$font-normal: 400;\n$font-medium: 500;\n$font-semi-bold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n$font-black: 900;\n\n// Header\n$header-height: 88px;\n$header-height-mobile: 4rem;\n\n// Redesign 2025\n$main-grey: #FAFAF4;\n$candidate: #4EC6DB;\n$candidate-border: #011640;\n$company: #D5006D;\n$company-border: #AE085A;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
