import React from 'react';
import styles from './NotificationMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { setNotificationMenuState } from '../../store/slices/notificationSlice';
import closeIcon from '../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import MatchNotification from '../ui/match-notification/MatchNotification';

const NotificationMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const notifications = useSelector(
    (state: RootState) => state.notificationReducer.notifications
  );
  const isOpen = useSelector(
    (state: RootState) => state.notificationReducer.notificationMenuOpen
  );

  const getNotificationComponent = (notification: any) => {
    if (notification.type === 2) {
      return <MatchNotification notification={notification} />;
    }
  };

  return (
    <>
      {isOpen && (
        <div className={styles.notificationMenu}>
          <div className='d-flex justify-content-end'>
            <img
              className={`cursor-pointer ${styles.closeIcon}`}
              src={closeIcon}
              alt='close'
              onClick={() => dispatch(setNotificationMenuState(false))}
            />
          </div>

          <div className={styles.container}>
            <h4 className={styles.title}>{t('menu.notifications')}</h4>

            <div className={styles.notificationContainer}>
              {notifications.length ? (
                notifications.map((notification, index) => {
                  return (
                    <div key={index}>
                      {getNotificationComponent(notification)}
                    </div>
                  );
                })
              ) : (
                <p>{t('noNotification')}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationMenu;
