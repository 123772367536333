import axiosInstance from '../core/services/network.service';
import * as endpoints from '../constants/endpoints';
import TokenService from './token.service';
import { refreshToken } from '../actions/auth';

const setup = (store: any) => {
  axiosInstance.axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch } = store;
  axiosInstance.axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== '/auth/sign-in' && err.response) {
        // Access Token was expired
        if (err.response.status === 403 && !originalConfig._retry) {
          originalConfig._retry = true;
          if (originalConfig._retry == true) {
            axiosInstance
              .request('POST', endpoints.REFRESH_TOKEN, {
                refresh: TokenService.getLocalRefreshToken(),
              })
              .then((res: any) => {
                console.log('Intercept res data: ', res);

                const { access } = res;
                dispatch(refreshToken(access));
                TokenService.updateLocalAccessToken(access);
                originalConfig._retry = false;

                return axiosInstance.axiosInstance(originalConfig);
              })
              .catch((err: any) => {
                return Promise.reject(err);
              });
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
