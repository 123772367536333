import { Field, Formik, Form as FormikForm } from 'formik';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Image,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { object, string } from 'yup';
import {
  setAddress,
  setCandidate,
  setDescription,
  setContactInfo,
  setRole,
  setSeniorityLevel,
  updateCandidate,
  uploadAvatar,
} from '../../../store/slices/candidateSlice';
import { ASSETS_URL } from '../../../constants';
import candidateImage from '../../../assets/icons/avatar-placeholder.svg';
import editIcon from '../../../assets/icons/edit.svg';
import './CandidateInfoModal.scss';
import { useTranslation } from 'react-i18next';
import RestrictedInput from '../restricted-input/RestrictedInput';
import { toast } from 'react-toastify';
import { acceptableFileSize } from '../../../utils/acceptable-file-size';
import { maxAvatarSize } from '../../../constants/constants';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const CandidateInfoModal = ({ show, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const candidate = useSelector((state: RootState) => state.candidateReducer);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>('');
  const infoSchema = object({
    name: string().required(t('errors.fieldRequired')),
    lastName: string().required(t('errors.fieldRequired')),
    numberCode: string().required(t('errors.fillCode')),
    phoneNumber: string().required(t('errors.fieldRequired')),
    email: string()
      .email(t('errors.emailInvalid'))
      .required(t('errors.emailRequired')),
  });

  useEffect(() => {
    if (!selectedFile) {
      setPreview('');
      return;
    }

    const objectURL = URL.createObjectURL(selectedFile);
    setPreview(objectURL);

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [selectedFile]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (!acceptableFileSize(e.target.files[0].size, maxAvatarSize)) {
      toast.error(`${t('messages.sizeLimitError')} ${maxAvatarSize} MB`);
      return;
    }

    const selectedFiles: File[] = Array.from(e.target.files);
    setSelectedFile(selectedFiles[0]);
  };

  function imageUrl(): string {
    if (preview) return preview;
    if (candidate.avatar) return candidate.avatar;
    return candidateImage;
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName='edit-modal'
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('candidateInfoComponent.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            email: candidate.candidate.email,
            name: candidate.contactInfo.name,
            lastName: candidate.contactInfo.lastName,
            numberCode: candidate.contactInfo.numberCode,
            phoneNumber: candidate.contactInfo.phoneNumber,
            description: candidate.description || '',
            address: candidate.address || '',
            role: candidate.role || '',
            seniorityLevel: candidate.seniorityLevel || '',
          }}
          onSubmit={(values) => {
            dispatch(
              setCandidate({
                userId: candidate.candidate.userId,
                email: values.email,
                password: candidate.candidate.password,
              })
            );

            dispatch(
              setContactInfo({
                name: values.name,
                lastName: values.lastName,
                country: candidate.contactInfo.city,
                city: candidate.contactInfo.city,
                numberCode: values.numberCode,
                phoneNumber: values.phoneNumber,
                birthDate: candidate.contactInfo.birthDate,
                nif: candidate.contactInfo.nif,
              })
            );

            dispatch(setRole(values.role));
            dispatch(setSeniorityLevel(values.seniorityLevel));
            dispatch(setAddress(values.address));
            dispatch(setDescription(values.description));

            if (selectedFile) {
              dispatch(uploadAvatar(selectedFile));
            }

            dispatch(updateCandidate());
            handleClose();
          }}
          validationSchema={infoSchema}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <FormikForm className='form w-100 d-flex justify-content-evenly flex-column'>
              <div className='d-flex justify-content-center'>
                <div className='position-relative'>
                  <Image
                    className='candidate-profile__avatar object-fit-cover'
                    src={ASSETS_URL + imageUrl()}
                  />
                  <label htmlFor='upload-avatar-button'>
                    <img className='avatar-edit' src={editIcon} alt='edit' />
                  </label>
                </div>

                <input
                  type='file'
                  id='upload-avatar-button'
                  style={{ display: 'none' }}
                  onChange={handleChange}
                  multiple={false}
                />
              </div>
              <Row className='mobile-column first-row'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('candidateInfoComponent.name')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field>
                      {() => (
                        <RestrictedInput
                          setFieldValue={setFieldValue}
                          fieldValue={values.name}
                          valueName='name'
                          restriction='text'
                        />
                      )}
                    </Field>
                    {errors.name && touched.name ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.name}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom01'>
                  <Form.Label className='label modal-label mt-0'>
                    {t('candidateInfoComponent.surname')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field>
                      {() => (
                        <RestrictedInput
                          setFieldValue={setFieldValue}
                          fieldValue={values.lastName}
                          valueName='lastName'
                          restriction='text'
                        />
                      )}
                    </Field>
                    {errors.lastName && touched.lastName ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.lastName}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
              </Row>
              {/*
              <Row className='mobile-column'>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('candidateInfoComponent.role')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      aria-describedby='inputGroupPrepend'
                      name='role'
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={6} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('candidateInfoComponent.seniority')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      aria-describedby='inputGroupPrepend'
                      name='seniorityLevel'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              */}

              <Row className='mobile-column align-items-end'>
                <Form.Group as={Col} xs={6}>
                  <Row className='align-items-end'>
                    <Form.Group
                      as={Col}
                      md={2}
                      xs={3}
                      style={{ paddingRight: 0 }}
                      controlId='validationCustom02'
                    >
                      <Form.Label className='label modal-label'>
                        {t('candidateInfoComponent.phone')}
                      </Form.Label>
                      <InputGroup hasValidation>
                        <Field
                          className='form-control mt-2 text-center py-3'
                          type='text'
                          placeholder='+34'
                          aria-describedby='inputGroupPrepend'
                          name='numberCode'
                        />
                        {errors.numberCode && touched.numberCode ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.numberCode}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={10}
                      xs={9}
                      controlId='validationCustom02'
                    >
                      <InputGroup hasValidation>
                        <Field>
                          {() => (
                            <div className='w-100'>
                              <RestrictedInput
                                setFieldValue={setFieldValue}
                                fieldValue={values.phoneNumber}
                                valueName='phoneNumber'
                                restriction='number'
                              />
                            </div>
                          )}
                        </Field>
                        {errors.phoneNumber && touched.phoneNumber ? (
                          <p className='text-danger position-absolute error-message candidate'>
                            {errors.phoneNumber}
                          </p>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>

                <Form.Group as={Col} xs={6}>
                  <Form.Label className='label modal-label'>
                    {t('candidateInfoComponent.email')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='email'
                      aria-describedby='inputGroupPrepend'
                      name='email'
                    />
                    {errors.email && touched.email ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.email}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('candidateInfoComponent.location')}
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Field
                      className='form-control mt-2 px-4 py-3'
                      type='text'
                      placeholder=''
                      name='address'
                      aria-describedby='inputGroupPrepend'
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              {/*
              <Row>
                <Form.Group as={Col} xs={12} controlId='validationCustom02'>
                  <Form.Label className='label modal-label'>
                    {t('candidateInfoComponent.description')}
                  </Form.Label>
                  <InputGroup hasValidation className='textarea'>
                    <Field
                      as='textarea'
                      className='form-control mt-2 px-4 py-3'
                      maxLength={250}
                      type='text'
                      placeholder=''
                      name='description'
                      aria-describedby='inputGroupPrepend'
                    />
                    <div className='textarea__counter'>
                      {values.description.length}/250
                    </div>
                    {errors.description && touched.description ? (
                      <p className='text-danger position-absolute error-message candidate'>
                        {errors.description}
                      </p>
                    ) : null}
                  </InputGroup>
                </Form.Group>
              </Row>
              */}
              <Button className='submitbtn p-3' type='submit'>
                {t('submitButton')}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateInfoModal;
