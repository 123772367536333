import React, { useEffect, useState } from 'react';
import './SearchBar.scss';
import { ASSETS_URL } from '../../../constants';
import { Form, Image } from 'react-bootstrap';
import search from '../../../assets/icons/search-normal.svg';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { setVacanciesSearchValue } from '../../../store/slices/vacanciesSlice';
import { useTranslation } from 'react-i18next';
import { setRequestedVacanciesSearch } from '../../../store/slices/candidateSlice';
import { useSearchParams } from 'react-router-dom';

interface Props {
  location: 'candidate' | 'blog' | 'request';
}

const SearchBar = ({ location }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchValue, setSeachValue] = useState<string>('');
  const [searchParams] = useSearchParams();

  function triggerSearch(): void {
    if (location === 'candidate') {
      dispatch(setVacanciesSearchValue(searchValue));
    }

    if (location === 'request') {
      dispatch(setRequestedVacanciesSearch(searchValue));
    }
  }

  useEffect(() => {
    if (location !== 'candidate') return;

    const company = searchParams.get('company');
    const storageCompany = sessionStorage.getItem('searchedCompany');

    if (company) {
      setSeachValue(company);
      dispatch(setVacanciesSearchValue(company));
      return;
    }

    if (storageCompany) {
      setSeachValue(storageCompany);
      dispatch(setVacanciesSearchValue(storageCompany));
    }
  }, []);

  return (
    <>
      <div className='search d-flex align-items-center gap-3'>
        <Form.Group
          className='search-box w-100'
          controlId='exampleForm.ControlInput1'
        >
          <Image src={ASSETS_URL + search} className='icon' />
          <Form.Control
            type='search'
            placeholder={t('searchBarComponent.searchPlaceholder')}
            value={searchValue}
            onChange={(event) => setSeachValue(event.target.value)}
          />
        </Form.Group>
        <button
          className='search__confirm btn btn-primary primary-btn-color'
          onClick={() => triggerSearch()}
        >
          {t('searchBarComponent.submitButton')}
        </button>
      </div>
    </>
  );
};

export default SearchBar;
