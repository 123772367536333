// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MatchNotification_successMessage__cBnCL {
  display: flex;
  align-items: center;
  gap: 0.5rem; }
  .MatchNotification_successMessage__cBnCL > :first-child {
    width: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/match-notification/MatchNotification.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW,EAAA;EAHb;IAMI,WAAW,EAAA","sourcesContent":[".successMessage {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n\n  > :first-child {\n    width: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successMessage": `MatchNotification_successMessage__cBnCL`
};
export default ___CSS_LOADER_EXPORT___;
