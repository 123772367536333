// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  margin-bottom: 12px;
  gap: 32px; }
  .language h5 {
    margin-bottom: 1rem !important; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/registration-language-select/RegistrationLanguageSelect.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EAErC,mBAAmB;EACnB,SAAS,EAAA;EALX;IAQI,8BAA8B,EAAA","sourcesContent":[".language {\n  display: grid;\n  grid-template-columns: repeat(2, 2fr);\n\n  margin-bottom: 12px;\n  gap: 32px;\n\n  h5 {\n    margin-bottom: 1rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
