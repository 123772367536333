import React from 'react';
import VacancyInfo from '../ui/vacancy-info/VacancyInfo';
import { useTranslation } from 'react-i18next';
import { getItemById } from '../../utils/get-item-by-id';
import { VacancyProps } from '../../models/vacancy-props.interface';
import { getCurrentLevelEducationName } from '../../utils/get-current-level-education-name';
import { ListItem } from '../../models/list-item';

const VacancyDetailsEducation = ({ vacancy }: VacancyProps) => {
  const { t, i18n } = useTranslation();
  const educationLevelList: ListItem[] = t('educationLevelList', {
    returnObjects: true,
  });

  return (
    <div className='details__info'>
      <h5>{t('vacancyDetails.subtitles.education')}</h5>
      <div>
        <VacancyInfo
          title={t('vacancyDetails.labels.education') + ':'}
          info={getItemById(educationLevelList, vacancy.training?.[0].level)}
        />
        <ul>
          {vacancy.training?.[0].name?.map((id, index) => (
            <li key={index}>
              {getCurrentLevelEducationName(
                vacancy.training?.[0].level || '0',
                id,
                i18n.language
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VacancyDetailsEducation;
