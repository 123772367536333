import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SideContent from '../SideContent';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import LoginFormCandidate from './Login/LoginFormCandidate';

const SignInCandidate = () => {
  const isFormView = useSelector(
    (state: RootState) => state.authPageReducer.isFormView
  );

  return (
    <>
      <Row className='max-height'>
        <Col
          className={`p-0 ${isFormView ? 'tablet-hidden' : ''}`}
          xs={12}
          lg={6}
        >
          <SideContent isCompany={false} />
        </Col>
        <Col
          className={`p-0 ${isFormView ? '' : 'tablet-hidden'}`}
          xs={12}
          lg={6}
        >
          <LoginFormCandidate />
        </Col>
      </Row>
    </>
  );
};

export default SignInCandidate;
