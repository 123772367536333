import LanguageOption from '../../models/language-option.interface';
import Language from '../../models/language.interface';
import { getLangLevelFromAiResult } from './get-lang-level-from-ai-result';

export function getLanguagesFromAiResult(
  aiLanguages: { level: number; language: string }[],
  langList: LanguageOption[]
): Language[] {
  const result: Language[] = [];

  aiLanguages.forEach((lang) => {
    const langId = langList.filter((item) => lang.language === item.name)[0]
      ?.id;

    if (langId) {
      result.push({
        level: getLangLevelFromAiResult(lang.level),
        language: langId,
      });
    }
  });

  return result;
}
