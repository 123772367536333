import React from 'react';

const BlankLayout = () => {
  return (
    <>
      <div className='wrapper'>
        <h1>Not found</h1>
      </div>
    </>
  );
};

export default BlankLayout;
