import React from 'react';
import { checkIfLetter } from '../../../utils/check-if-letter';
import { checkIfNumber } from '../../../utils/check-if-number';

interface Props {
  valueName: string;
  fieldValue: string;
  setFieldValue: Function;
  restriction: 'number' | 'text';
}

const RestrictedInput = ({
  valueName,
  setFieldValue,
  fieldValue,
  restriction,
}: Props) => {
  return (
    <input
      className='form-control mt-2 px-4 py-3'
      placeholder=''
      type='text'
      value={fieldValue}
      onChange={(e) => {
        const { value } = e.target;

        if (!value) {
          setFieldValue(valueName, value);
          return;
        }

        if (
          restriction === 'text' ? checkIfLetter(value) : checkIfNumber(value)
        ) {
          setFieldValue(valueName, value);
        }
      }}
    />
  );
};

export default RestrictedInput;
