import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './MatchModal.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { decodeWithUnicode } from '../../utils/decode-with-unicode';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const MatchModal = ({ show, handleClose }: Props) => {
  const { t } = useTranslation();
  const matchData = useSelector(
    (state: RootState) => state.modalReducer.matchData?.ai_match?.[0]
  );

  const labels = [
    {
      title: t('createOffer.card2.key1'),
      match: matchData?.work_experience,
    },
    {
      title: t('vacancyDetails.labels.education'),
      match: matchData?.training,
    },
    {
      title: t('vacancyDetails.subtitles.language'),
      match: matchData?.languages,
    },
    {
      title: t('vacancyDetails.labels.quality'),
      match: matchData?.qualities,
    },
    {
      title: t('vacancyDetails.labels.capability'),
      match: matchData?.abilities,
    },
    {
      title: t('vacancyDetails.labels.license'),
      match: matchData?.driving_license,
    },
  ];

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('candidatesTablet.columns.match')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='match-modal'>
          <Col xs={6}>
            {labels.map((item, index: number) => (
              <div key={index} className='match-modal__info mb-2'>
                <h6 className='offer-form-label py-2'>{item.title}:</h6>
                <h6> {item.match}</h6>
              </div>
            ))}
          </Col>
          <Col xs={6}>
            <div className='match-modal__description'>
              <p>
                {decodeWithUnicode(
                  matchData?.response?.match?.[0]?.reason || ''
                )}
              </p>
            </div>
          </Col>
        </Row>
        <Row className='justify-content-end mt-4'>
          <Col>
            <button
              onClick={() => handleClose()}
              type='button'
              className='btn btn-outline-primary btn-outline-color px-4 py-2'
            >
              {t('vacanciesTablet.deleteModal.cancel')}
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MatchModal;
