// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seen {
  float: right; }
  .seen img {
    width: 24px;
    height: 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/candidates-table/CandidatesTable.scss"],"names":[],"mappings":"AAAA;EACE,YAAW,EAAA;EADb;IAII,WAAU;IACV,YAAW,EAAA","sourcesContent":[".seen {\n  float:right;\n\n  img {\n    width:24px;\n    height:24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
