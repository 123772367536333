import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
  percentage: string;
  classes: string;
  processing: boolean;
  action: () => void;
}

const MatchManualButton = ({
  percentage,
  action,
  classes,
  processing,
}: Props) => {
  return (
    <button
      style={{ minWidth: '41px' }}
      className={classes}
      onClick={percentage ? action : () => {}}
    >
      {processing ? (
        <Spinner
          animation='border'
          variant='info'
          style={{ width: '1rem', height: '1rem' }}
        />
      ) : (
        <>{+percentage * 10 || '0'}%</>
      )}
    </button>
  );
};

export default MatchManualButton;
