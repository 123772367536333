import React from 'react';
import Vacancy from '../../models/vacancy.interface';
import work from '../../assets/icons/work.svg';
import location from '../../assets/icons/location.svg';
import user from '../../assets/icons/user.svg';
import timeline from '../../assets/icons/timeline.svg';
import './VacancyCard.scss';
import { useNavigate } from 'react-router-dom';

import SaveButton from '../ui/save-button/SaveButton';
import ApplyButton from '../ui/apply-button/ApplyButton';
import VacancyMatchButton from '../ui/vacancy-match/VacancyMatchButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getItemById } from '../../utils/get-item-by-id';
import { ListItem } from '../../models/list-item';
import companyImage from '../../assets/icons/avatar-placeholder.svg';

interface Props {
  vacancy: Vacancy;
  toggleSave: (id: string, saved: boolean) => void;
  toggleApply: (vacancy: Vacancy, apply: boolean) => void;
}

const VacancyCard = ({ vacancy, toggleSave, toggleApply }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const info = [
    { icon: work, content: vacancy.job_type },
    { icon: location, content: vacancy.location },
    {
      icon: user,
      content: `${vacancy.number_of_vacancies} ${
        vacancy.number_of_vacancies === 1
          ? t('vacant.singular')
          : t('vacant.plural')
      }`,
    },
    {
      icon: timeline,
      content: `${t('time.upTo')}: ${getExpireDays()}`,
    },
  ];
  const jobTypeList: ListItem[] = t('jobTypeList', { returnObjects: true });

  function getExpireDays(): string {
    const endDate = moment(vacancy.end_date);
    const today = moment();
    const result = endDate.diff(today, 'days');
    const dayString =
      Math.abs(result) === 1
        ? ` ${t('time.singularDay')}`
        : ` ${t('time.pluralDay')}`;

    if (result === 0) return t('time.today');
    if (result < 0) return `${t('time.on')} ` + Math.abs(result) + dayString;
    return result.toString() + dayString;
  }

  return (
    <div
      className='d-flex flex-column vacancy'
      onClick={() => navigate(vacancy.id)}
    >
      <div className='d-flex'>
        <div className='d-flex gap-3 align-items-center'>
          <img
            className='vacancy__logo object-fit-cover rounded-circle'
            src={vacancy.company.logo || companyImage}
            alt='logo'
          />

          <div className='vacancy__title'>
            <h4>{vacancy.position}</h4>
            <p>{vacancy.company.name}</p>
          </div>
        </div>

        <div
          className={`vacancy__match d-flex flex-grow-1${
            vacancy.match ? '' : ' justify-content-end'
          }`}
        >
          {vacancy.match && (
            <VacancyMatchButton
              hideClass='mobile-hidden'
              match={vacancy.match}
            />
          )}
          <div className='details__buttons'>
            <ApplyButton
              applied={vacancy.apply}
              vacancy={vacancy}
              toggleApply={toggleApply}
            />
            <SaveButton
              id={vacancy.id}
              toggleSave={toggleSave}
              saved={vacancy.saved}
            />
          </div>
        </div>
      </div>

      {vacancy.match && (
        <div className='vacancy__match no-mobile-hidden'>
          <VacancyMatchButton match={vacancy.match} />
        </div>
      )}
      <p className='vacancy__description'>{vacancy.description}</p>

      <div className='vacancy__info'>
        {info.map((item, index) => {
          if (index === 0) {
            item.content = getItemById(jobTypeList, item.content);
          }
          return (
            <div
              key={index}
              className='vacancy__details d-flex gap-2 align-items-center'
            >
              <img className='blue-filter' src={item.icon} alt='icon' />
              <span>{item.content}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VacancyCard;
