import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import {
  setVacancyFormLicense,
  setVacancyFormLicenseRating,
  updateProgress,
} from '../../../../store/slices/vacancyCreateSlice';
import SelectLicenseForm from '../../../../components/ui/select-license-form/SelectLicenseForm';
import { setLicense } from '../../../../store/slices/candidateSlice';
import { License } from '../../../../models/license.interface';
import { useTranslation } from 'react-i18next';
import ImportanceSelect from '../../../../components/ui/importance-select/ImportanceSelect';

interface Props {
  license: License;
  close: () => void;
}

const LicenseModal = ({ license, close }: Props) => {
  const { t } = useTranslation();
  const [licenseRating, setLicenseRating] = useState<number>(0);
  const licenseList = useSelector(
    (state: RootState) => state.licenseReducer.licenseList
  );
  const isVehicle = useSelector(
    (state: RootState) => state.licenseReducer.isVehicle
  );
  const rating = useSelector(
    (state: RootState) => state.vacancyCreateReducer.vacancy.license_rating
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const licenses = {
      license: licenseList.slice(1).filter((license) => license.selected),
      isVehicle: isVehicle,
    };

    dispatch(setLicense(licenses));
    dispatch(setVacancyFormLicense(licenses));
    dispatch(setVacancyFormLicenseRating(licenseRating));
    dispatch(updateProgress());
    close();
  };

  return (
    <div className='px-4'>
      <p className='general-sub-heading mb-4 text-main-black'>
        {t('createOffer.step2.license.description')}
      </p>
      <ImportanceSelect
        defaultValue={rating}
        setRating={(rate: number) => setLicenseRating(rate)}
      />
      <Row>
        <SelectLicenseForm licenseList={licenseList} isVehicle={isVehicle} />
        <div className='d-flex align-items-center justify-content-end mt-4'>
          <button
            className='btn btn-primary primary-btn-color px-5'
            type='button'
            onClick={handleSubmit}
          >
            {t('addButton')}
          </button>
        </div>
      </Row>
    </div>
  );
};

export default LicenseModal;
