// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-list {
  list-style: none;
  padding: 0;
  display: flex; }

.tab-item {
  padding: 10px 20px;
  border: 1px solid #ccc; }

.tab-item.active {
  background-color: #f0f0f0;
  font-weight: bold; }

.tab-content {
  margin-top: 20px; }

table {
  width: 100%;
  border-collapse: collapse; }

th, td {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-align: left; }

th {
  background: #CCC; }
`, "",{"version":3,"sources":["webpack://./src/views/admin/home/HomeAdmin.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa,EAAA;;AAGf;EACE,kBAAkB;EAClB,sBAAsB,EAAA;;AAGxB;EACE,yBAAyB;EACzB,iBAAiB,EAAA;;AAGnB;EACE,gBAAgB,EAAA;;AAGlB;EACE,WAAW;EACX,yBAAyB,EAAA;;AAG3B;EACE,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB,EAAA;;AAGlB;EACE,gBAAgB,EAAA","sourcesContent":[".tab-list {\n  list-style: none;\n  padding: 0;\n  display: flex;\n}\n\n.tab-item {\n  padding: 10px 20px;\n  border: 1px solid #ccc;\n}\n\n.tab-item.active {\n  background-color: #f0f0f0;\n  font-weight: bold;\n}\n\n.tab-content {\n  margin-top: 20px;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n}\n\nth, td {\n  border: 1px solid #ccc;\n  padding: 5px 10px;\n  text-align: left;\n}\n\nth {\n  background: #CCC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
