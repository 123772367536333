import React, { useRef, useState } from 'react';
import ionFilter from '../../assets/icons/filter.svg';
import './HomeFilterButton.scss';
import DropdownPopup from '../dropdown-popup/DropdownPopup';
import Option from '../../models/option.interface';
import { ASSETS_URL } from '../../constants';
import { useOutsideHandler } from '../../utils/outside-click-handler';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  tagList: Option[];
  selectOption: (option: Option) => void;
}

const HomeFilterButton = ({ tagList, selectOption, className }: Props) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  useOutsideHandler(wrapperRef, setOpen);

  function setOpen(isOpen: boolean): void {
    setIsDropdownOpen(isOpen);
  }

  return (
    <div className='filter' ref={wrapperRef}>
      <button
        className={`filter__button ${className}`}
        onClick={() => setIsDropdownOpen((prevValue) => !prevValue)}
      >
        <img src={ASSETS_URL + ionFilter} alt='filter' />
        <span>{t('candidateHomePage.filter.button')}</span>
      </button>

      {isDropdownOpen && (
        <DropdownPopup optionList={tagList} selectOption={selectOption} />
      )}
    </div>
  );
};

export default HomeFilterButton;
